import React from "react";

import "./styles.css";

const DotFlashing = ({ classNameLoader = "dot-flashing", textLoader = "", classNameText = "text-loader" }) => {
  return (
    <div className="flex-column flex-center">
      <p className={classNameText}>{textLoader}</p>
      <div className={classNameLoader}></div>
    </div>
  );
};

export default DotFlashing;
