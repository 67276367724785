import classnames from "classnames";
import React from "react";

import { getInitials } from "../../../helpers/getInitials";
import { isChineseInString } from "../../../helpers/isChineseInString";
import "./styles.css";

const Initials = ({
  userFullName,
  disabled = false,
  isRounded = false,
  initialsBackgroundColor,
  initialsTextColor,
}) => {
  const classList = classnames("user-initials flex-center", { roundedInitials: isRounded, disabled });
  const initials = getInitials(userFullName);
  const isChinese = isChineseInString(initials) ? "cn" : "";

  return (
    <div style={{ backgroundColor: initialsBackgroundColor }} className={classList}>
      <span className={isChinese} style={{ color: initialsTextColor }}>
        {initials}
      </span>
    </div>
  );
};

export default Initials;
