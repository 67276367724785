import React from "react";

import { getTranslation } from "../../helpers/getLanguage";
import CopyLinkButton from "../common/CopyLinkButton";
import CustomSearchInput from "../inputs/CustomSearchInput";
import TeamLabel from "./UsersTable/TeamLabel";

const UsersHeader = ({
  handleChange,
  filters,
  handleEditTeamSettings,
  projectId,
  editMode,
  initialUsers,
  getUsersData,
  getProjectInfo,
  isLoading,
}) => {
  const teamsIds = initialUsers.filter((data) => data.maxPeople > 1).map((data) => data.teamId);
  const teamsCount = Array.from(new Set(teamsIds)).length;

  const individualsCount = initialUsers.filter((data) => data.maxPeople === 1).length;

  const onRequestEnd = () => {
    getUsersData();
    getProjectInfo();
  };

  return (
    <div className="width-100 flex flex-wrap justify-space-between">
      <div className="users-information flex mb-10">
        <div className="users-page-title-container">
          <span className="users-page-title">{getTranslation("PAGE_USERS_TITLE")}</span>
          {initialUsers.length > 0 && <span className="users-page-count">{initialUsers.length}</span>}
        </div>

        <div className="team-types-count flex">
          {teamsCount > 0 && <TeamLabel team={getTranslation("TEAM_TYPE_LABEL_TEAMS")} count={teamsCount} />}
          {individualsCount > 0 && <TeamLabel count={individualsCount} />}
        </div>
      </div>

      {!isLoading && (
        <div className="flex mb-10">
          {editMode && (
            <div className="flex">
              <button
                className="users-button team-button flex align-center justify-space-between"
                onClick={handleEditTeamSettings}
              >
                <span>{getTranslation("EDIT_TEAM_SETTINGS_BUTTON")}</span>
                <div className="button-triangle-icon"></div>
              </button>

              <div className="users-copy-link-button">
                <CopyLinkButton projectId={projectId} mode="share" onSuccess={onRequestEnd} onError={onRequestEnd} />
              </div>
            </div>
          )}

          <CustomSearchInput
            name="search"
            placeholder={getTranslation("SEARCH_USER")}
            containerClass="search-input-container"
            value={filters.search}
            onChange={handleChange}
          />
        </div>
      )}
    </div>
  );
};

export default UsersHeader;
