import store from "../store";
import { UPDATE_CURRENT_SOCKET } from "./types";

/**
 * Used outside of context react to update socket on connection.
 *
 * Note: This only replaces websocket and does not disconnect or clear event listeners!
 * @param {WebSocket} socket - a new socket to replace the old with.
 */
export const updateCurrentSocket = (socket) => {
  store.dispatch({ type: UPDATE_CURRENT_SOCKET, payload: socket });
};
