import React from "react";

import logo from "../Omnivati-Logo-Tagline.jpg";
import "./styles.css";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <div className="logo">
        <img src={logo} alt="" />
      </div>
      <p className="center">
        <u>
          <b>Privacy Policy</b>
        </u>
      </p>
      <p>
        Omnivati ("<b>Omnivati</b>", "<b>we</b>", "<b>our</b>") is committed to maintaining the privacy of its users ("
        <b>you</b>", "<b>user</b>"). The following information describes how Omnivati collects and processes information
        about you when you use our Platform (as defined below).
      </p>
      <p>Our Privacy Policy explains:</p>
      <ul>
        <li>What information we collect and why we collect it.</li>
        <li>How we use that information.</li>
        <li>How we secure your information.</li>
        <li>Your rights with regard to the collection of such information.</li>
      </ul>
      <p className="privacy-policy-header">General</p>
      <p>
        Omnivati provides a digital tooklit suitable for educators, students and businesses, which enables the rapid and
        systematic generation and management of creative ideas ("<b>Platform</b>"). The Platform enables users to
        intuitively apply multiple structured creativity methods and rapidly generate new valuable strategies, products,
        services, processes and tools ("<b>Services</b>").
      </p>
      <p className="privacy-policy-header">Collecting Information</p>
      <p>
        Please note that the scope of this Privacy Policy is limited only to information collected by Omnivati through
        your use of its Platform. Some information may be automatically collected, and some is collected when you
        interact with our Platform. The type of information that may be automatically collected is non-personal
        information, which includes your session durations, the content you accessed on the Platform, information about
        your computer and your internet connection and the frequency and scope of your use of the Platform.
      </p>
      <p>
        Information from which you can be personally identified may also be collected, including but not limited to your
        username, email address and the location of the computer through which you used the Platform (hereinafter: "
        <b>Personal Information</b>"). In the course of our provision of Platform, and to enable you to benefit from our
        Services, we will collect any content submitted to the Platform by you and will provide our analysis and reports
        accordingly. Please note, that some content may be available to other users of the Platform, with whom you are
        sharing and developing new strategies via the Platform.
      </p>
      <p>
        If applicable, you hereby approve that you have all the necessary rights to provide Omnivati with any users’
        Personal Information and that Omnivati may process such information in relation to the Service.
      </p>
      <p className="privacy-policy-header">Use of Information:</p>
      <p>
        We use the Personal Information we collect from you for a range of different business purposes according to
        different legal bases of processing. We may use or process your Personal Information for the following purposes.
        One or more purposes may apply simultaneously.
      </p>
      <p>
        <b>Providing the Requested Services:</b>
      </p>
      <ul>
        <li>
          We collect your Personal Information to provide you with the Services you contracted to receive when using the
          Platform. Such collection of information will enable us to provide you with technical and professional
          assistance, with regard to the functionality of the Platform or to any inquiry you submit to us through it.
        </li>
        <li>
          If you are a teacher, we will collect your Personal Information to provide you with our teaching tool to
          significantly enhance your teaching of innovation and creativity, improve your teaching methods, and manage
          student innovation projects and assignments.
        </li>
        <li>
          If you are a student, we will collect your Personal Information to provide you with a learning tool that will
          enable you to generate and manage creative ideas, both individually and in a shared team environment.
        </li>
        <li>
          If you are a business user utilizing the Platform, we will collect your Personal Information to provide you
          with a vital tool to maximize the creative potential of your business’ teams, while exploring and generating
          creative solutions, products, services, processes, and tools.
        </li>
      </ul>
      <p>
        We process the Personal Information where it is necessary for the adequate performance of the contract regarding
        your use of our Platform.
      </p>
      <p>
        <b>Improvement and Development of the Services:</b>
      </p>
      <p>
        We collect Personal Information to improve and develop our Platform and understand feedback on Omnivati's
        Services and to help provide more information on the use of those Services quickly and easily.
      </p>
      <p>
        We collect Personal Information for the ongoing review and improvement of the information provided on our
        Platform to ensure it is user friendly.
      </p>
      <p>
        We collect Personal Information to improve the management and administration of our business and maintain
        compliancy with our internal policies and procedures.
      </p>
      <p>
        We conduct surveys and research, test features in development, and analyze the information we have to evaluate
        and improve our Platform, develop new features, and conduct audits and troubleshooting activities.
      </p>
      <p>
        We process this information in light of our legitimate interest in improving the Platform and Services, to allow
        our users to have the best experience.
      </p>
      <p>
        <b>Maintain a Safe and Secure Environment</b>
      </p>
      <p>
        We may use your information to detect and prevent fraud, abuse and security incidents in the following ways;
      </p>
      <ul>
        <li>Verify and authenticate your identity and prevent unauthorized or illegal activity.</li>
        <li>Enhance the safety and security of our Platform.</li>
        <li>Conduct security investigations and risk assessments.</li>
        <li>
          Prevent or take action against activities that are, or may be, in breach of our terms of service or applicable
          law.
        </li>
      </ul>
      <p>
        We process this information in light of our legitimate interest in improving our Platform and enabling our users
        to browse in a secure environment.
      </p>
      <p>
        <b>Personalize Content, Advertising and Marketing</b>
      </p>
      <ul>
        <li>
          If you have used Omnivati’s Services in the past, we have a legitimate business interest for matching the data
          we collect with other data we had already collected.
        </li>
        <li>
          This enables us to understand your needs and interests, optimize the content we send you and make it more
          suitable and relevant to your needs.
        </li>
        <li>
          This also enables us to improve your experience on the Platform by providing you with personalized content,
          recommendations, and features.
        </li>
      </ul>
      <p>
        We process this information in light of our legitimate interest to personalize your experience and customize our
        content.
      </p>
      <p className="privacy-policy-header">Disclosure of Information and Transfer of Data</p>
      <p>
        Except as otherwise provided in this Privacy Policy, we reasonably attempt to ensure that we never intentionally
        disclose any of your Personal Information, to any third party without having received your permission, except as
        provided for herein or otherwise as permitted or required under law.
      </p>
      <p>
        We may share your Personal Information with our affiliates, subsidiaries or any third party companies and
        individuals to facilitate our Services or any portion thereof, such as marketing, data management or maintenance
        services. We will share your Personal Information with third party payment processors who will process your
        payment details in accordance with the highest data security standard. We may also share your information with
        analytics service providers for analytics purposes. Such analytics service providers set their own cookies or
        other identifiers on your computer, through which they can collect information about your usage of our Platform.
        This helps us compile aggregated statistics about the effectiveness of our Platform and Services.
      </p>
      <p>
        The above mentioned third parties may be located in countries other than your own, and we may send them
        information we receive. When such third parties process your Personal Information on our behalf, we will assure
        that they comply with obligations similar to those which are set forth in this Privacy Policy. We will also
        assure that they will abide by our data privacy and security requirements, and will be allowed to use the
        Personal Information solely for the purposes we set. We will transfer your Personal Information while using
        appropriate and suitable safeguards, while using a variety of legal mechanisms, including contracts, to ensure
        your rights and protections travel with your data.
      </p>
      <p>
        We may also transfer your information, including Personal Information, in connection with a corporate merger,
        consolidation, the sale of related assets or corporate division or other fundamental corporate changes.
        Furthermore, information about you may also be released in order to comply with any valid legal inquiry or
        process such as a search warrant, subpoena, statute or court order. We will also release specific information in
        special cases, such as if you use the Platform to perform an unlawful act or omission or take any act or
        omission that may damage Omnivati, its property and goodwill, or if there is an attempted breach of the security
        of the Platform or a physical or property threat to you or others. With respect to our data protection
        practices, you have the right to file a complaint to any relevant supervisory data protection authority.
      </p>
      <p className="privacy-policy-header">Your Rights</p>
      <p>
        You have the right at any time to request to access or modify your information. To exercise these options,
        please contact us at support@omnivati.com.
      </p>
      <p>
        In some jurisdictions, in particular those located within the European Union (the "<b>EU</b>") or within the
        European Economic Area (the "<b>EEA</b>"), you may be afforded specific rights regarding your Personal
        Information. Subject to such eligibility, you may have the following rights to:
      </p>
      <ul>
        <li>
          Request a rectification of your Personal Information where the information we hold about you is incorrect or
          incomplete.
        </li>
        <li>Object to the processing of your Personal Information for direct marketing purposes.</li>
        <li>
          Object to the processing of your Personal Information where our legal basis for that processing is that such
          processing is necessary for our legitimate interests.
        </li>
        <li>Object to an automated decision-making (including profiling) in certain circumstances.</li>
        <li>
          Request the erasure of your Personal Information in certain circumstances, such as where processing is no
          longer necessary for the purpose it was originally collected for, and there is no compelling reason for us to
          continue to process or store it;
        </li>
        <li>
          Receive your Personal Information, or ask us to transfer it to another organization that you have provided to
          us, which we process by automated means, where our processing is either based on your consent or is necessary
          for the performance of a contract with you.
        </li>
      </ul>
      <p>If you wish to file a request regarding any of the above, you may contact us at: support@omnivati.com.</p>
      <p className="privacy-policy-header">Cookies</p>
      <p>
        We may use "cookies" and/or other technologies or files (collectively, "<b>cookies</b>") to identify how users
        make use of our Platform. This aggregated tracking information may be used to help us improve and enhance the
        Platform’s experience for all of our users. In addition, cookies are used for adjusting the Platform to your
        personal preferences. Cookies contain information such as the pages you visited, the length of time you stayed
        on the Platform, the location from which you accessed the Platform and more. If you would prefer not to have
        cookies stored on your computer, you may modify your browser settings to reject most cookies, or manually remove
        cookies that have been placed on your computer. However, by rejecting the cookies, you may be unable to fully
        access the offerings on our Platform. To find out more about cookies,{" "}
        <u>
          <a href="http://www.allaboutcookies.org/" target="_blank" rel="noopener noreferrer">
            www.allaboutcookies.org.
          </a>
        </u>
      </p>
      <p className="privacy-policy-header">Opt In or Opt Out</p>
      <p>
        You are always in control of your data, and if you choose to receive information from us, or others, you can
        change your mind later. If, at any time, you would like to stop receiving such information or opt out of a
        feature, you may notify us by writing to support@omnivati.com. You should be aware, however, that it is not
        always possible to completely remove or modify information in our databases and servers, although we will always
        make reasonable efforts to do so upon your request.
      </p>
      <p className="privacy-policy-header">Links to Other Websites</p>
      <p>
        This Platform may provide links to other websites. Please be aware that these other websites are not covered by
        our Privacy Policy. This Privacy Policy does not cover the information practices exercised by other providers of
        products or services, advertisers or other websites, companies or individuals, which are not owned or controlled
        by Omnivati. We suggest that when linking to another website, you always read that website’s privacy policy
        before volunteering any personally identifiable information.
      </p>
      <p className="privacy-policy-header">Data Security</p>
      <p>
        We deploy industry standard, or better measures to ensure the security, confidentiality, integrity and
        availability of Personal Information we process. We maintain physical, technical and administrative safeguards,
        and test and update these periodically. We endeavor to restrict access to Personal Information on a ‘need to
        know’ basis for the provision of Services to you. In the event of a security breach, we will take all reasonable
        action to minimize any harm, including with regard to notifications. Although we will do our best to protect
        Personal Information, we cannot guarantee the security of data transmitted to our Platform, and transmission is
        at the users own risk.
      </p>
      <p className="privacy-policy-header">Data Retention</p>
      <p>
        Generally, Omnivati does not retain information longer than necessary to provide its Services and for its
        reasonable business and lawful needs. If you withdraw your consent to us processing your Personal Information,
        we will erase your Personal Information from our systems, unless the information is required for Omnivati to
        establish, exercise or defend against legal claims or it is necessary for the performance of the requested
        Services.
      </p>
      <p className="privacy-policy-header">California Online Privacy Protection Act</p>
      <p>
        CalOPPA requires commercial websites and online services to post a privacy policy. The law's reach stretches
        well beyond California to require any person or company in the United States (and conceivably the world) that
        operates websites collecting Personally Identifiable Information from California consumers, to post a
        conspicuous privacy policy on its website stating exactly the information being collected and those individuals
        or companies with whom it is being shared. See more at:{" "}
        <a
          href="http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf"
          target="_blank"
          rel="noopener noreferrer"
        >
          http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf
        </a>
        .
      </p>
      <p>
        <u>According to CalOPPA, we agree to the following:</u>
      </p>
      <ul>
        <li>
          Once this Privacy Policy is created, we will add a link to it on the first significant page after entering our
          Platform.
        </li>
        <li>Our Privacy Policy link includes the word 'Privacy' and can easily be found on the Platform.</li>
        <li>You can request to change your Personal Information by emailing us.</li>
      </ul>
      <p className="privacy-policy-header">California Privacy Rights</p>
      <p>
        The California Consumer Privacy Act of 2018 ("<b>CCPA</b>") permits users who are California residents to
        request to exercise certain rights. If you are a California resident, the CCPA grants you the right to request
        certain information about our practices with respect to your Personal Information. In particular, you can
        request to receive information on the following:
      </p>
      <ul>
        <li>The categories and specific pieces of your Personal Information that we have collected.</li>
        <li>The categories of sources from which we collected your Personal Information.</li>
        <li>The business or commercial purposes for which we collected your Personal Information.</li>
        <li>The categories of third parties with which we shared your Personal Information.</li>
      </ul>
      <p>
        You can be rest assured that we do not sell your Personal Information. If you choose to exercise your rights, we
        will not charge you different prices or provide different quality of our Services, unless those differences are
        related to your provision of your Personal Information.
      </p>
      <p>
        Please note that you must verify your identity and request before further action is taken. As a part of this
        process, government identification may be required. Moreover, you may designate an authorized agent to make a
        request on your behalf.
      </p>
      <p>
        We endeavor to respond to a verifiable consumer request within 45 days of its receipt. If we require more time
        (up to 90 days), we will inform you of the reason and extension period in writing. Any disclosures we provide,
        will only cover the 12 month period preceding your verifiable request's receipt. If, for some reason, we cannot
        reply within such time frame, our response will include an explanation for our inability to comply. If you wish
        to exercise your CCPA rights, please contact us at: support@omnivati.com.
      </p>
      <p>
        We will not discriminate against you for exercising any of your CCPA rights. Unless permitted by the CCPA, we
        will not:
      </p>
      <ol>
        <li>1. Deny you goods or services.</li>
        <li>
          2. Charge you different prices or rates for goods or services, including through granting discounts or other
          benefits, or imposing penalties.
        </li>
        <li>3. Provide you with a different level or quality of goods or services.</li>
        <li>
          4. Suggest that you may receive a different price or rate for goods or services or a different level or
          quality of goods or services.
        </li>
      </ol>
      <p className="privacy-policy-header">CAN SPAM Act</p>
      <p>
        The CAN-SPAM Act is a Federal US law that sets the rules for commercial email, establishes requirements for
        commercial messages, gives recipients the right to have emails stopped from being sent to them, and spells out
        tough penalties for violations.
      </p>
      <p>
        <u>To be in accordance with CANSPAM, we agree to the following:</u>
      </p>
      <ul>
        <li>Not use false or misleading subjects or email addresses.</li>
        <li>Identify the commercial message sent to you as an advertisement when required.</li>
        <li>Include the physical address of our business or site headquarters.</li>
        <li>Monitor third-party email marketing services for compliance, if one is used.</li>
        <li>Honor opt-out/unsubscribe requests quickly.</li>
        <li>Allow users to unsubscribe by using the link at the bottom of each email.</li>
      </ul>
      <p>
        If at any time you would like to unsubscribe from receiving future emails, you can email us at
        support@omnivati.com and we will promptly remove you from ALL correspondence.
      </p>
      <p className="privacy-policy-header">Children's Privacy</p>
      <p>
        The Platform is not intended for children under the age of 16. We do not, knowingly or intentionally, collect
        information about children who are under 16 years of age.
      </p>
      <p>
        <b>
          IF YOU ARE UNDER THE AGE OF 16 YOU MAY NOT USE THE PLATFORM, UNLESS PARENTAL CONSENT IS PROVIDED ACCORDINGLY
        </b>
      </p>
      <p className="privacy-policy-header">Questions Regarding Our Privacy Policy</p>
      <p>
        If you have any questions regarding this Privacy Policy or the practices described above, you are always welcome
        to contact us at support@omnivati.com.
      </p>
      <p className="privacy-policy-header">Revisions and Modifications to our Privacy Policy</p>
      <p>
        We reserve the right to revise, amend, or modify this Privacy Policy at any time. When changing the policy, we
        will update this posting accordingly. Please review this Privacy Policy often so that you will remain updated
        regarding our current policies.
      </p>
      <p className="privacy-policy-header">Governing Law and Jurisdiction</p>
      <p>
        This Privacy Policy will be governed and interpreted pursuant to the laws of the State of Israel without giving
        effect to its choice of law rules. You expressly agree that the exclusive jurisdiction for any claim or action
        arising out of or relating to this Privacy Policy shall be to the competent courts in Tel Aviv, Israel, to the
        exclusion of any other jurisdiction.
      </p>
      <p>This page was updated on August 2020.</p>
    </div>
  );
};

export default PrivacyPolicy;
