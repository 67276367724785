import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import { getCurrentUser } from "../actions/authActions";
import { getPreferences } from "../actions/preferencesActions";
import { REACT_APP_STRIPE_PUBLIC } from "../common/config";
import "../css/App.css";
import "../css/styles.css";
import WebSocketInstance from "../helpers/socketInstance";
import runResponseInterceptor from "../interceptors/runResponseInterceptor";
import store from "../store.js";
import AdminPanel from "./AdminPanel";
import Auth0 from "./Auth0";
import SideBar from "./common/SideBar";
import Components from "./ComponentsDashboard";
import ConfirmEmail from "./ConfirmEmail";
import Contradiction from "./Contradiction";
import Issues from "./CurrentIssues";
import Dashboard from "./Dashboard";
import DependencyMatrix from "./DependencyMatrix";
import FrequentlyAskedQuestion from "./documents/FrequentlyAskedQuestion";
import PrivacyPolicy from "./documents/PrivacyPolicy";
import TermsOfService from "./documents/TermsOfServices";
import Eureka from "./Eureka";
import Login from "./Login";
import Multiplication from "./MultiplicationsMatrix";
import MyAccount from "./MyAccount";
import PrivateRoute from "./PrivateRoute.jsx";
import ProjectsDashboard from "./ProjectsDashboard";
import PublicMatrixView from "./PublicMatrixView";
import RecoverPassword from "./RecoverPassword";
import Replacement from "./ReplacementMatrix";
import ProjectReport from "./Report";
import ResetPassword from "./ResetPassword";
import SAML from "./SAML";
import SignUp from "./SignUp";
import Subtraction from "./SubtractionsMatrix";
import Users from "./Users";

store.dispatch(getCurrentUser());
store.dispatch(getPreferences());

runResponseInterceptor();

document.title = "Omnivati";

const stripePromise = loadStripe(REACT_APP_STRIPE_PUBLIC);

const App = () => {
  useEffect(() => {
    // Start WebSocket
    if (!WebSocketInstance.currentSocket) {
      WebSocketInstance.connect();
    }
  }, []);

  return (
    <Elements stripe={stripePromise}>
      <Provider store={store}>
        <Router>
          <div className="App" id="root">
            <SideBar>
              <Switch>
                <PrivateRoute exact path="/" component={Dashboard} />
                <PrivateRoute exact path="/folders/:folderId" component={Dashboard} />
                <PrivateRoute exact path="/invite/:inviteId" component={Dashboard} />
                <PrivateRoute exact path="/invite/:inviteId/report" component={Dashboard} />
                <PrivateRoute exact path="/project/:projectId/matrix" component={DependencyMatrix} />
                <PrivateRoute exact path="/project/:projectId/subtraction" component={Subtraction} />
                <PrivateRoute exact path="/project/:projectId/contradiction" component={Contradiction} />
                <PrivateRoute exact path="/project/:projectId/multiplication" component={Multiplication} />
                <PrivateRoute exact path="/project/:projectId/replacement" component={Replacement} />
                <PrivateRoute exact path="/project/:projectId/eureka" component={Eureka} />
                <PrivateRoute exact path="/project/:projectId/report" component={ProjectReport} />
                <PrivateRoute exact path="/project/:projectId" component={ProjectsDashboard} />
                <PrivateRoute exact path="/project/:projectId/components" component={Components} />
                <PrivateRoute exact path="/project/:projectId/users" component={Users} />
                <PrivateRoute exact path="/profile" component={MyAccount} />
                <PrivateRoute exact path="/admin" component={AdminPanel} />
                <PrivateRoute exact path="/issues" component={Issues} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/auth0" component={Auth0} />
                <Route exact path="/saml-auth" component={SAML} />
                <Route exact path="/verify-email" component={ConfirmEmail} />
                <Route exact path="/signup" component={SignUp} />
                <Route exact path="/recover-password" component={RecoverPassword} />
                <Route exact path="/reset-password" component={ResetPassword} />
                <Route exact path="/terms-of-services" component={TermsOfService} />
                <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                <Route exact path="/frequently-asked-question" component={FrequentlyAskedQuestion} />
                <Route exact path="/view-matrix/:projectId" component={PublicMatrixView} />
                <Route exact path="*" component={Dashboard} />
              </Switch>
            </SideBar>
          </div>
        </Router>
      </Provider>
    </Elements>
  );
};

export default App;
