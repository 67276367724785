import React, { useRef, useState } from "react";

import { ReactComponent as AIGenerateIcon } from "../../../../assets/images/ai-generate-icon.svg";
import { getTranslation } from "../../../../helpers/getLanguage";
import CustomInput from "../../../inputs/CustomInput";
import CustomTextarea from "../../../inputs/CustomTextarea";
import { ImagesCarousel } from "../../../inputs/ImagesCarousel";
import ImageUploader from "../../../inputs/ImageUploader";
import ErrorMessageModal from "../../ErrorMessageModal";
import GenerateImageModal from "../../GenerateImageModal";
import GenerateNamesModal from "../../GenerateNamesModal";
import ImageModal from "../../ImageModal";

const IdentifyIdea = ({
  dependency,
  handleChange,
  uploadInfo,
  selectCurrentDependencyImageFromLibrary,
  removeImageFromLibrary,
  newGeneratedImage,
  handleCancelClick,
  handleFileSelect,
  isLoadingImage,
  productType,
  header,
  handleGenerateNames,
  handleGenerateImage,
  openGenerateNameModal,
  closeGenerateNameModal,
  suggestedNamesForIdeas,
  suggestedLoading,
  addIdeasName,
  isOpenGenerateImageModal,
  closeDialogGenerateImage,
  handleAcceptPotentialImage,
  generatedImage,
  reversed,
  potentialGeneratedImageUrl,
}) => {
  const [isOpenImage, setIsOpenImage] = useState(false);
  const [currentModalImage, setCurrentModalImage] = useState(null);
  const [error, setError] = useState({
    openErrorModal: false,
    message: "",
    headerError: "",
  });

  const closeErrorWindow = () => {
    setError({
      openErrorModal: false,
      message: "",
      headerError: "",
    });
  };

  const openImageModal = (newImage) => {
    setCurrentModalImage(newImage);
    setIsOpenImage(true);
  };

  const fileSelectRef = useRef(null);

  const getDependencyDescription = (header) => {
    return (
      <p className="dependency-modal-description">
        {`${getTranslation("DESCRIBE_NEW_DEPENDENCY_TITLE")}
                ${productType}
                ${getTranslation("DESCRIBE_NEW_DEPENDENCY_WHERE_THE")}`}
        <u>{header.secondAttribute}</u>
        {getTranslation("DEPENDENCY_MODAL_DEPENDENCY_DESCRIPTION_OF_THE")}
        <u>{header.secondComponent}</u>
        {productType === "process" && (
          <span style={{ marginRight: "10px" }}>{getTranslation("SUBTRACTION_MODAL_HEADER_STEP")}</span>
        )}

        {getTranslation("DEPENDENCY_MODAL_DEPENDENCY_DESCRIPTION_DEPENDS")}
        <u>{header.firstAttribute}</u>
        {getTranslation("DEPENDENCY_MODAL_DEPENDENCY_DESCRIPTION_OF_THE")}
        <u>{header.firstComponent}</u>
        {productType === "process" && (
          <span style={{ marginRight: "10px" }}>{getTranslation("SUBTRACTION_MODAL_HEADER_STEP")}</span>
        )}
      </p>
    );
  };

  const validateFile = (file) => {
    if (!file || file.size > 2097152) {
      setError({
        openErrorModal: true,
        message: "Uploaded file is too big, max image size is 2 MB",
        headerError: getTranslation("ERROR_MESSAGE_MODAL_HEADER"),
      });
      return false;
    }
    return true;
  };

  const handleFileUpload = (event) => {
    const file = event.target ? event.target.files[0] : event[0];

    if (!validateFile(file)) return;

    const reader = new FileReader();

    reader.onload = (e) => {
      const buffer = e.target.result;
      const imageUrl = `data:image/png;base64,${Buffer.from(buffer).toString("base64")}`;
      handleFileSelect(file, imageUrl);
    };
    reader.readAsArrayBuffer(file);
  };

  const isImagesLibraryFull = dependency.imagesLibrary.length > 9;

  const setMaxImageLibraryError = () => {
    setError({
      openErrorModal: true,
      message: (
        <p className="flex-column mt-25px">
          <span style={{ margin: "0" }}>{getTranslation("IMAGE_LIBRARY_MAX_SIZE_ERROR_IMAGES_COUNT")}</span>
          <span>{getTranslation("IMAGE_LIBRARY_MAX_SIZE_ERROR_DELETE")}</span>
        </p>
      ),
      headerError: getTranslation("IMAGE_LIMIT_REACHED"),
    });
  };

  return (
    <>
      <div className="flex-center">{getDependencyDescription(header)}</div>

      <div className="flex dependency-modal-container">
        <CustomTextarea
          value={dependency.comment}
          onChange={handleChange}
          height={287}
          maxHeight={287}
          name="comment"
          label={getTranslation("DESCRIBE_NEW_DEPENDENCY_DESCRIPTION")}
          containerClass="flex-column dependency-modal-input"
          textareaClass="bgc-white dependency-modal-container__describe"
        />

        <div className="flex-column" style={{ marginTop: "-36px" }}>
          <button
            className="dependency-modal__button-ai dependency-modal__button-ai_name-idea"
            onClick={handleGenerateNames}
          >
            <AIGenerateIcon style={{ marginRight: "10px", width: "18px" }} />
            <span>{getTranslation("GENERATE_IDEAS_NAMES_BUTTON")}</span>
          </button>
          <CustomInput
            labelClass="dependency-modal-container__label"
            value={dependency.ideaName}
            onChange={handleChange}
            name="ideaName"
            label={getTranslation("RATE_IDEA_CUSTOM_INPUT")}
            containerClass="flex-column dependency-modal-input"
            customInputClass="bgc-white dependency-modal-container__name"
          />

          <div style={{ marginTop: "-24px" }}>
            {dependency.imagesLibrary.length > 0 && (
              <>
                <input
                  type="file"
                  ref={fileSelectRef}
                  style={{ display: "none" }}
                  onChange={(e) => handleFileUpload(e)}
                />
                <button
                  className={`dependency-modal__upload-image-button dependency-modal__button-ai 
                                    ${isImagesLibraryFull && "dependency-modal__upload-image-button_disabled"}`}
                  onClick={() => (isImagesLibraryFull ? setMaxImageLibraryError() : fileSelectRef.current.click())}
                >
                  {getTranslation("UPLOAD_IMAGES")}
                </button>
              </>
            )}

            <button
              className={`dependency-modal__button-ai 
                                dependency-modal__button-ai_image 
                                ${isImagesLibraryFull && "dependency-modal__button-ai_image-disabled"}`}
              onClick={() => (isImagesLibraryFull ? setMaxImageLibraryError() : handleGenerateImage())}
              style={{ left: dependency.imagesLibrary.length ? "10px" : "95px" }}
            >
              <p>
                <AIGenerateIcon style={{ marginRight: "10px", width: "18px" }} />
                <span>{getTranslation("GENERATE_IMAGES_BUTTON")}</span>
              </p>
              <p>{getTranslation("GENERATE_IMAGES_BUTTON_AI_CREDITS")}</p>
            </button>
            {dependency.imagesLibrary.length ? (
              <ImagesCarousel
                openImageModal={openImageModal}
                imagesLibrary={dependency.imagesLibrary}
                currentImageId={dependency.image.id}
                selectCurrentDependencyImageFromLibrary={selectCurrentDependencyImageFromLibrary}
                startIndex={dependency.imagesLibrary.findIndex((image) => image.id === dependency.image.id)}
                removeImageFromLibrary={removeImageFromLibrary}
              />
            ) : (
              <ImageUploader
                reversed={reversed}
                label={getTranslation("DESCRIBE_NEW_SUBTRACTION_ADD_SKETCH")}
                uploadInfo={uploadInfo}
                handleCancelClick={handleCancelClick}
                handleFileSelect={handleFileSelect}
                displayPreview
                isLoadingImage={isLoadingImage}
                height={200}
                maxHeight={200}
                currentProjectImageId={dependency.image.id}
                openImage={() => setIsOpenImage(true)}
                generatedImage={generatedImage}
              />
            )}
          </div>
        </div>
      </div>

      {openGenerateNameModal && (
        <GenerateNamesModal
          closeGenerateNamesModal={closeGenerateNameModal}
          suggestedNamesForIdeas={suggestedNamesForIdeas}
          suggestedLoading={suggestedLoading}
          addIdeasName={addIdeasName}
        />
      )}

      {error.openErrorModal && (
        <ErrorMessageModal
          message={error.message}
          closeModalWindow={closeErrorWindow}
          textButton={getTranslation("CONFIRMATION_MODAL_DEFAULT_BUTTON_TEXT")}
          handleButton={closeErrorWindow}
          header={error.headerError}
        />
      )}

      {isOpenImage && (
        <ImageModal
          closeModal={() => setIsOpenImage(false)}
          imageData={currentModalImage}
          altImage={getTranslation("IMAGE_MODAL_ALT_SKETCH_IDEA")}
        />
      )}

      {isOpenGenerateImageModal && (
        <GenerateImageModal
          handleAcceptPotentialImage={handleAcceptPotentialImage}
          closeModalWindow={closeDialogGenerateImage}
          header={getTranslation("OPEN_AI_LOADER_HEADER_IMAGE")}
          message={getTranslation("OPEN_AI_LOADER_IMAGE")}
          imageData={potentialGeneratedImageUrl}
          suggestedLoading={suggestedLoading}
        />
      )}
    </>
  );
};

export default IdentifyIdea;
