import React from "react";

import CloseIcon from "../../../assets/images/close-icon.svg";
import { getTranslation } from "../../../helpers/getLanguage";
import Dialog from "../Dialog";
import "./styles.css";

const WarningModal = ({
  disabled = false,
  closeDialog,
  message,
  onConfirm,
  onCancel,
  closeOnClickOutside = true,
  closeOnEscape = true,
  rightButtonText = getTranslation("CONFIRMATION_MODAL_DEFAULT_BUTTON_TEXT"),
  leftButtonText = getTranslation("CONFIRMATION_MODAL_DEFAULT_BUTTON_TEXT"),
  hideCloseIcon = false,
  messageWidth = 0,
  autoFocus = false,
  messageHeight = 0,
  className,
  textContainerClass,
}) => {
  const handleCloseDialog = () => {
    closeDialog && closeDialog();
  };

  const popupTextStyles = {};
  if (messageWidth) popupTextStyles.width = messageWidth;
  if (messageHeight) popupTextStyles.height = messageHeight;

  return (
    <Dialog closeDialog={closeDialog} closeOnClickOutside={closeOnClickOutside} closeOnEscape={closeOnEscape}>
      <div className={`delete-popup flex-column ${className}`}>
        <div className="delete-popup-close flex width-100">
          {!hideCloseIcon && <img src={CloseIcon} alt="" className="pointer" onClick={handleCloseDialog} />}
        </div>

        <div className="flex-center">
          <div className="flex-column align-center width-100">
            <span className={`delete-popup-text ${textContainerClass}`} style={popupTextStyles}>
              {getTranslation(message)}
            </span>
            <div className="delete-popup-buttons-container">
              <button className="no-popup-button" autoFocus={autoFocus} disabled={disabled} onClick={() => onCancel()}>
                <span>{getTranslation(leftButtonText)}</span>
              </button>
              <button
                className="yes-popup-button"
                autoFocus={autoFocus}
                disabled={disabled}
                onClick={() => onConfirm()}
              >
                <span>{getTranslation(rightButtonText)}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default WarningModal;
