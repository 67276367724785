import axios from "axios";

import { baseApiUrl } from "../common/constants";
import { handleError, handleSuccess } from "./handlers";

const baseUrl = `${baseApiUrl}/upload`;

export const uploadImage = async (formData, config, onSuccess, onError) => {
  axios
    .post(`${baseUrl}/image`, formData, config)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};
