import React, { useEffect, useState } from "react";

import { tabsByPage } from "../../../../constants/tabs";
import TabHeader from "../../../common/TabHeader";
import ChangeVideoUrl from "./ChangeVideoUrl";
import "./styles.css";

const GlobalConfigurations = () => {
  const [activeTab, setActiveTab] = useState(tabsByPage[0].name);
  const [nameTabs, setNameTabs] = useState(tabsByPage[0].label);

  useEffect(() => {
    const tab = tabsByPage.find((page) => page.name === activeTab);
    setNameTabs(tab.label);
  }, [activeTab]);

  return (
    <div className="flex-column global-configurations-wrapper">
      <div className="admin-panel-tabs flex mb-30">
        {tabsByPage.map((page, index) => (
          <TabHeader
            key={index}
            index={page.name}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            label={page.label}
          />
        ))}
      </div>
      <div className="flex flex-wrap">
        <ChangeVideoUrl nameTabs={nameTabs} />
      </div>
    </div>
  );
};

export default GlobalConfigurations;
