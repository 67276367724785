import React from "react";

import { getTranslation } from "../../../helpers/getLanguage";
import "./styles.css";

const CustomLoginInput = ({
  value,
  name,
  onChange,
  onBlur,
  onKeyDown,
  type = "text",
  placeholder = "",
  containerClass = "",
  errorMessage = "",
}) => {
  const activeClass = errorMessage.length > 0 ? " has-errors" : "";

  return (
    <div className={"width-100 position-relative " + containerClass}>
      <input
        value={value}
        name={name}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        className={"custom-login-input " + activeClass}
      />
      {errorMessage.length > 0 && (
        <div className="custom-login-input-errors flex-start">
          <span>{getTranslation(errorMessage)}</span>
        </div>
      )}
    </div>
  );
};

export default CustomLoginInput;
