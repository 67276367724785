import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { getUserProfile, logoutUser, setCurrentUser, setLanguage } from "../../../actions/authActions";
import { setPreference } from "../../../actions/preferencesActions";
import { setUserLanguage } from "../../../api/users";
import LogoCompact from "../../../assets/images/nav-logo-compact.svg";
import Logo from "../../../assets/images/Omnivati-logo.svg";
import { redirectToHttps } from "../../../common/redirectToHttps";
import { currentLanguage, languageVersion } from "../../../constants/language";
import { adminSidenavItems, defaultSidenavItems, pageUrl, projectSidenavItems, userSidenavItem } from "./constants";
import NavItem from "./NavItem";
import ExpandItem from "./navItems/ExpandItem";
import IssuesItem from "./navItems/IssuesItem";
import LanguageItem from "./navItems/LanguageItem";
import ProfileItem from "./navItems/ProfileItem";
import SupportItem from "./navItems/SupportItem";
import "./styles.css";

const SideBar = ({
  children,
  compact,
  auth,
  location,
  history,
  logoutUser,
  setPreference,
  getUserProfile,
  setCurrentUser,
  project,
  setLanguage,
}) => {
  const { user, status: userStatus, errors } = auth;
  const [languageDropdownActive, setLanguageDropdownActive] = useState(false);
  const [activePath, setActivePath] = useState("");
  const [currentProjectId, setCurrentProjectId] = useState("");
  const [navItems, setNavItems] = useState([]);
  const [fullName, setFullName] = useState("user");
  const [disabled, setDisabled] = useState(true);
  const showUserItem = Boolean(project.isShared || project.teamId);
  const showAdminItem = userStatus.roles.includes("admin") || userStatus.roles.includes("admin_ai"); // || userStatus.roles.includes('support_admin');

  useEffect(() => {
    if (errors.authorization) {
      handleUnauthorizedError();
    }
  }, [errors]);

  const getProjectId = useCallback((path) => {
    const pathSplit = path.split("/");
    if (pathSplit[1] === "project" && pathSplit[2]) {
      return pathSplit[2];
    }

    return "";
  }, []);

  useEffect(() => {
    if (errors.authorization) {
      handleUnauthorizedError();
    }
  }, [errors]);

  useEffect(() => {
    redirectToHttps();
  }, []);

  useEffect(() => {
    if (user.id) getUserProfile();
  }, [getUserProfile, user]);

  useEffect(() => {
    const shouldValidate = localStorage.getItem("shouldValidateSubscriptions") === "true";

    if (shouldValidate) {
      localStorage.setItem("shouldValidateSubscriptions", false);

      const redirectUrl = `/profile?tab=1&modal=subscription`;
      if (!userStatus.premium) history.push(redirectUrl);
    }
  }, [history, userStatus]);

  useEffect(() => {
    const projectId = getProjectId(location.pathname) || currentProjectId;
    setCurrentProjectId(projectId);

    const path = location.pathname;

    for (let key in pageUrl) {
      const regExp = new RegExp(pageUrl[key]);

      if (path.match(regExp)) {
        if (key !== activePath) {
          setActivePath(key);
        }
        break;
      }
    }
  }, [location.pathname, getProjectId, currentProjectId, activePath]);

  useEffect(() => {
    if (Object.keys(user).length) {
      const { name, lastName } = user;
      const fullName = `${name} ${lastName}`;
      setFullName(fullName);
    }
  }, [user]);

  useEffect(() => {
    if (user.id && userStatus.roles.length) {
      const currentItems = showAdminItem ? adminSidenavItems : defaultSidenavItems;
      let items = [...currentItems];
      if (userStatus.premium) {
        if (activePath && !["projects", "profile", "admin", "default"].includes(activePath)) {
          items.push(...projectSidenavItems);
          setDisabled(false);
          if (showUserItem) {
            let index = 1;

            items.forEach((item, indexItem) => {
              if (item.value === "reports") index += indexItem;
            });

            items.splice(index, 0, userSidenavItem);
          }
        } else {
          setDisabled(true);
        }
      } else if (activePath && !["projects", "profile", "admin", "default"].includes(activePath)) {
        history.push("/profile?tab=1&modal=subscription");
      }

      setNavItems(items);
    }
  }, [activePath, showUserItem, user, userStatus, history, showAdminItem]);

  const changeLanguage = (language) => {
    //TODO: add correct onSuccess and onError func
    const onSuccess = () => {};
    const onError = (error) => {
      console.error("error", error);
    };

    const params = {
      language: language,
    };

    setLanguage(language);
    setUserLanguage(params, onSuccess, onError);
  };

  const handlePathChange = (option, absolute = false) => {
    if (!option || !option?.value || !option?.baseUrl) {
      return;
    }

    setActivePath(option.value);
    let url = option.value;

    if (!absolute) {
      const projectId = getProjectId(location.pathname) || currentProjectId;

      setCurrentProjectId(projectId);

      url = option.baseUrl.replace(":projectId", projectId);
    }

    history.push(url);
  };

  const items = navItems.map((item, index) => {
    return (
      <NavItem
        key={index}
        option={item}
        nextOption={navItems[index + 1]}
        activePath={activePath}
        handlePathChange={handlePathChange}
        compact={compact}
        disabled={disabled}
        showUserItem={showUserItem}
      />
    );
  });

  const handleUnauthorizedError = useCallback(() => {
    localStorage.removeItem("jwtToken");
    history.push("/login");
    setCurrentUser({});
  }, []);

  const isOnViewPage = location.pathname.includes("view");

  const activeClass = compact ? " compact" : "";
  const isloggedIn = Object.keys(user).length > 0;

  const shouldHideSideBar = ["termsOfService", "privacyPolicy", "faq"].includes(activePath);

  const getMainContentMargin = () => {
    if (isOnViewPage) {
      return "ml-0";
    }

    if (compact) {
      return "ml-80px";
    }

    return "ml-250px";
  };

  return (
    <div className="flex position-relative content-wrapper">
      {isloggedIn && !shouldHideSideBar && !isOnViewPage && (
        <div
          className={
            "sidebar flex-column align-center justify-space-between height-100" +
            activeClass +
            (window?.location?.href?.split("/")[1] === "issues" ? "active" : "")
          }
        >
          <div className={"flex-column align-center nav-items-container" + activeClass}>
            <div className={"sidenav-logo flex-center pointer" + activeClass} onClick={() => history.push("/")}>
              <img src={compact ? LogoCompact : Logo} alt="logo" />
            </div>
            <div className={"sidenav-items" + activeClass}>{items}</div>
          </div>
          <div className="flex-column person-left-panel width-100">
            {(!isOnViewPage || isloggedIn) && (
              <LanguageItem
                setLanguageDropdownActive={setLanguageDropdownActive}
                languageDropdownActive={languageDropdownActive}
                compact={compact}
                languageVersion={languageVersion}
                changeLanguage={changeLanguage}
                currentLanguage={currentLanguage}
              />
            )}

            <SupportItem compact={compact} activeClass={activeClass} setPreference={setPreference} />
            <IssuesItem
              compact={compact}
              activeClass={activeClass}
              handlePathChange={handlePathChange}
              activePath={activePath}
            />
            {(!isOnViewPage || isloggedIn) && (
              <ProfileItem
                compact={compact}
                fullName={fullName}
                history={history}
                logoutUser={logoutUser}
                activeClass={activeClass}
              />
            )}

            <ExpandItem compact={compact} activeClass={activeClass} setPreference={setPreference} />
          </div>
        </div>
      )}
      <div className={`main-content ${getMainContentMargin()}`}>{children}</div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  compact: state.preferences.sidenavCollapsed,
  project: state.project.product,
});

const mapDispatchToProps = {
  logoutUser,
  setPreference,
  getUserProfile,
  setLanguage,
  setCurrentUser,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SideBar));
