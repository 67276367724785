import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import { getPromoCodes } from "../../actions/promoCodes";
import { getAllUsers } from "../../actions/usersActions";
import { getFeatures } from "../../api/features";
import useContainerHeight from "../../common/useContainerHeight";
import { getTranslation } from "../../helpers/getLanguage";
import Loader from "../common/Loader";
import PromoCodeModal from "../dialogs/PromoCodeModal";
import UserModal from "../dialogs/UserModal";
import AdminTabs from "./AdminTabs";
import { filterPromoCodes, filterUsers, sortPromoCodes, sortUsers } from "./filters";
import "./styles.css";

const initialModalOpen = {
  promoCode: false,
};

const defaultSort = {
  field: "date",
  asc: false,
};

const initialPromoCodesFilters = {
  search: "",
  activation: "",
  discounts: "",
  durationType: "",
  isActive: "",
  generation: "admin",
  archived: "unarchived",
};

const initialUsersFilters = {
  search: "",
};

const AdminPanel = ({ auth, getPromoCodes, getAllUsers, promoCodes, users, language, history, match }) => {
  const isAdmin = auth.status.roles.includes("admin");
  const isSupportAdmin = auth.status.roles.includes("support_admin");
  const isAdminAI = auth.status.roles.includes("admin_ai");

  const { payload: promoCodesPayload, isLoading: promoCodesIsLoading } = promoCodes;
  const { payload: usersPayload, isLoading: usersIsLoading } = users;
  const [activeTab, setActiveTab] = useState(0);
  const [modalOpen, setModalOpen] = useState(initialModalOpen);
  const [promoCodesSort, setPromoCodesSort] = useState(defaultSort);
  const [usersSort, setUsersSort] = useState(defaultSort);
  const [promoCodesFilters, setPromoCodesFilters] = useState(initialPromoCodesFilters);
  const [usersFilter, setUsersFilter] = useState(initialUsersFilters);
  const [rawPromoCodes, setRawPromoCodes] = useState([]);
  const [filteredPromoCodes, setFilteredPromoCodes] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [currentCode, setCurrentCode] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [featuresList, setFeaturesList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const containerRef = useRef(null);
  const [containerHeight, updateHeight] = useContainerHeight(containerRef, 140);

  useEffect(() => {
    updateHeight();
  }, [promoCodesPayload, updateHeight]);

  const updateCodes = useCallback(() => {
    isAdmin && getPromoCodes();
  }, [isAdmin, getPromoCodes]);

  const updateUsers = useCallback(() => {
    isAdmin && getAllUsers(usersSort.field, usersSort.asc, currentPage, 10, usersFilter.search);
  }, [isAdmin, getAllUsers, usersSort, currentPage, usersFilter]);

  useEffect(() => {
    let isComponentExists = true;

    const onSuccess = (data) => {
      isComponentExists && setFeaturesList(data);
    };

    const onError = (error) => {
      console.error(error);
    };

    getFeatures({}, onSuccess, onError);

    return () => (isComponentExists = false);
  }, []);

  useEffect(() => {
    updateCodes();
  }, [updateCodes]);

  useEffect(() => {
    updateUsers();
  }, [updateUsers]);

  useEffect(() => {
    setRawPromoCodes(promoCodesPayload);
  }, [setRawPromoCodes, promoCodesPayload]);

  useEffect(() => {
    const filteredCodes = filterPromoCodes(rawPromoCodes, promoCodesFilters);
    const sortedCodes = sortPromoCodes(filteredCodes, promoCodesSort);

    setFilteredPromoCodes(sortedCodes);
  }, [promoCodesSort, promoCodesFilters, rawPromoCodes]);

  useEffect(() => {
    setFilteredUsers(usersPayload);
  }, [usersPayload]);

  const handleSortChange = (value, table, resetCurrentPage) => {
    if (!filteredUsers?.length) {
      return;
    }

    const promoCodesIsSelected = promoCodesSort.field === value;
    const usersIsSelected = usersSort.field === value;

    resetCurrentPage && setCurrentPage(1);

    const promoCodesAsc = promoCodesIsSelected ? !promoCodesSort.asc : true;
    const usersAsc = usersIsSelected ? !usersSort.asc : true;

    table === "promoCodes" && setPromoCodesSort({ field: value, asc: promoCodesAsc });
    table === "users" && setUsersSort({ field: value, asc: usersAsc });
  };

  const resetFilters = () => {
    setPromoCodesFilters({ ...initialPromoCodesFilters });
    setUsersFilter({ ...initialUsersFilters });
    setCurrentPage(1);
    resetSort();
  };

  const resetSort = () => {
    setUsersSort(defaultSort);
  };

  const openPromoCodeModal = (promoCode = null) => {
    setEditMode(Boolean(promoCode));
    setCurrentCode(promoCode);
    setModalOpen({ ...modalOpen, promoCode: true });
  };

  const openUserModal = (user = null) => {
    setEditMode(Boolean(user));
    setCurrentUser(user);
    setModalOpen({ ...modalOpen, user: true });
  };

  const handlePageChange = (page, absolute) => {
    if (absolute) {
      return setCurrentPage(page);
    }

    if (page > 0 && usersPayload?.length < 10) {
      return;
    }

    setCurrentPage(Math.max(currentPage + page, 1));
  };

  return (
    <>
      {(isAdmin || isAdminAI) && (
        <div className="admin-panel-wrapper">
          <div className="admin-panel-header-top flex">
            <span className="admin-panel-header-title">{getTranslation("PAGE_ADMIN_PANEL_HEADER_TITLE")}</span>
          </div>
          <hr />
          {promoCodesIsLoading && <Loader />}
          {!promoCodesIsLoading && (
            <AdminTabs
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              openPromoCodeModal={openPromoCodeModal}
              openUserModal={openUserModal}
              containerHeight={containerHeight}
              containerRef={containerRef}
              promoCodes={filteredPromoCodes}
              users={filteredUsers}
              usersIsLoading={usersIsLoading}
              promoCodesSort={promoCodesSort}
              usersSort={usersSort}
              handleSortChange={handleSortChange}
              promoCodesFilters={promoCodesFilters}
              usersFilter={usersFilter}
              setPromoCodesFilters={setPromoCodesFilters}
              setUsersFilter={setUsersFilter}
              resetFilters={resetFilters}
              isAdmin={isAdmin}
              isSupportAdmin={isSupportAdmin}
              isAdminAI={isAdminAI}
              language={language}
              setPageFn={handlePageChange}
              authorizedUser={auth.user}
            />
          )}
        </div>
      )}
      {modalOpen.promoCode && (
        <PromoCodeModal
          closeDialog={() => setModalOpen({ ...modalOpen, promoCode: !modalOpen.promoCode })}
          onCodeCreation={updateCodes}
          editMode={editMode}
          codeInfo={currentCode}
          language={language}
        />
      )}
      {modalOpen.user && (
        <UserModal
          closeDialog={() => setModalOpen({ ...modalOpen, user: !modalOpen.user })}
          onCodeCreation={updateCodes}
          editMode={editMode}
          userInfo={currentUser}
          language={language}
          featuresList={featuresList}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  language: state.auth.userInfo.language,
  promoCodes: state.promoCodes,
  users: state.users,
  errors: state.promoCodes.errors,
});

export default connect(mapStateToProps, { getPromoCodes, getAllUsers })(AdminPanel);
