import React, { useEffect, useRef, useState } from "react";

import { getTranslation } from "../../../helpers/getLanguage";
import CustomSearchInput from "../../inputs/CustomSearchInput";
import "./styles.css";

const ProjectCardModal = ({
  items,
  offsetX = 0,
  offsetY = 0,
  maxHeight = 0,
  minWidth = 0,
  width = 0,
  left = null,
  top = null,
  showSearch = false,
  searchPlaceholder = "",
  children = null,
  isOpen = false,
  onCancel = null,
  disabled = false,
  isSearch = false,
  compact,
}) => {
  const ref = useRef(null);
  const [isModalOpen, setModalOpen] = useState(isOpen);
  const [coordinates, setCoordinates] = useState({});
  const [search, setSearch] = useState("");

  useEffect(() => {
    const handleClose = () => {
      if (isModalOpen) {
        setModalOpen(false);
        onCancel && onCancel();
      }
    };

    const handleKeyDown = (e) => {
      if (e.key === "Escape") handleClose();
    };

    const handleMouseClick = (e) => {
      if (ref.current && !ref.current.contains(e.target)) handleClose();
    };

    document.addEventListener("mousedown", handleMouseClick, true);
    document.addEventListener("mouseup", handleMouseClick, true);
    document.addEventListener("keydown", handleKeyDown, true);
    return () => {
      document.removeEventListener("mousedown", handleMouseClick, true);
      document.removeEventListener("mouseup", handleMouseClick, true);
      document.removeEventListener("keydown", handleKeyDown, true);
    };
  }, [isModalOpen, onCancel]);

  const handleItemClick = (item) => {
    item.action();
    setModalOpen(false);
    setSearch("");
  };

  const menuItems = items
    .filter((item) => !item.hidden)
    .filter((item) => item.value.toLowerCase().includes(search.toLowerCase()))
    .map((item, index) => {
      if (!item.hidden) {
        return (
          <div
            key={index}
            className="card-modal-option pointer"
            onMouseDown={(e) => e.stopPropagation()}
            onMouseUp={(e) => {
              e.stopPropagation();
              handleItemClick(item);
              setModalOpen(false);
            }}
          >
            <span>{getTranslation(item.value)}</span>
          </div>
        );
      }
      return null;
    });

  useEffect(() => {
    const hasLeft = typeof left === "number";
    const hasTop = typeof top === "number";
    const rectLeft = hasLeft ? left + offsetX : "unset";
    const rectTop = hasTop ? top + offsetY : "unset";
    setCoordinates({ left: rectLeft, top: rectTop });
  }, [left, top, offsetX, offsetY]);

  const styles = !isSearch && !compact ? { ...coordinates, maxHeight: maxHeight || "unset" } : {};
  minWidth && (styles.minWidth = minWidth);
  width && (styles.width = width);

  const handleClick = (e) => {
    const rect = e.target.parentElement.getBoundingClientRect() || {};
    const { right, bottom } = rect;

    const hasLeft = typeof left === "number";
    const hasTop = typeof top === "number";
    const rectLeft = hasLeft ? left : right + offsetX;
    const rectTop = hasTop ? top : bottom + offsetY;
    setCoordinates({ left: rectLeft, top: rectTop });

    if (!disabled) {
      isModalOpen && setSearch("");
      setModalOpen(!isModalOpen);
    }
  };

  return (
    <div ref={ref} onClick={(e) => e.stopPropagation()} onMouseDown={handleClick}>
      {children}
      {isModalOpen && items.length > 0 && (
        <div
          className={
            isSearch && !compact ? "card-modal-container flex-column search-bar" : "card-modal-container flex-column"
          }
          style={styles}
        >
          {showSearch && (
            <div className="card-modal-search-input" onMouseDown={(e) => e.stopPropagation()}>
              <CustomSearchInput
                name="search"
                placeholder={searchPlaceholder}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          )}

          <div className="menu-items-container" onMouseDown={(e) => e.stopPropagation()}>
            {menuItems}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectCardModal;
