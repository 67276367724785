import {
  GET_USER_PROFILE_STATUS_ERROR,
  GET_USER_PROFILE_STATUS_SUCCESS,
  GET_USER_STATUS_ERROR,
  GET_USER_STATUS_SUCCESS,
  LOGIN_USER,
  LOGIN_USER_ERROR,
  LOGIN_USER_SUCCESS,
  REGISTER_USER,
  REGISTER_USER_ERROR,
  REGISTER_USER_SUCCESS,
  RESET_LOGIN_STATE,
  RESET_REGISTRATION,
  SET_CURRENT_USER,
  SET_LANGUAGE,
  UNAUTHORIZED_USER_ERROR,
  USER_LOADING,
} from "../actions/types";

const isEmpty = require("is-empty");

const initialState = {
  isAuthenticated: false,
  user: {},
  loading: false,
  errors: {},
  status: {
    roles: [],
    premium: null,
  },
  registration: {
    loading: false,
    payload: null,
    errors: {},
  },
  userInfo: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case RESET_REGISTRATION:
      return {
        ...state,
        registration: { ...initialState.registration },
      };
    case REGISTER_USER:
      return {
        ...state,
        registration: { loading: true, payload: null, errors: {} },
      };
    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        registration: { loading: false, payload: action.payload, errors: {} },
      };
    case REGISTER_USER_ERROR:
      return {
        ...state,
        registration: { loading: false, payload: null, errors: action.payload },
      };

    case LOGIN_USER:
      return {
        ...state,
        loading: true,
        errors: {},
      };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: {},
      };
    case LOGIN_USER_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case RESET_LOGIN_STATE:
      return {
        ...state,
        loading: false,
        errors: {},
      };
    case UNAUTHORIZED_USER_ERROR:
      return {
        ...state,
        errors: { ...state.errors, authorization: true },
      };

    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
      };
    case USER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_USER_STATUS_SUCCESS:
      return {
        ...state,
        status: action.payload,
      };
    case GET_USER_STATUS_ERROR:
      return {
        ...state,
        status: initialState.status,
      };

    case GET_USER_PROFILE_STATUS_SUCCESS:
      return {
        ...state,
        status: action.payload.status,
        userInfo: action.payload.userInfo,
      };
    case GET_USER_PROFILE_STATUS_ERROR:
      return {
        ...state,
        status: initialState.status,
        userInfo: initialState.userInfo,
      };
    case SET_LANGUAGE:
      return {
        ...state,
        userInfo: { language: action.payload, ...initialState.userInfo },
      };
    case UNAUTHORIZED_USER_ERROR:
      return {
        ...state,
        errors: { ...state.errors, authorization: true },
      };
    default:
      return state;
  }
}
