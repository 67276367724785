import React from "react";

import LoginIllustration from "../../../assets/images/login-illustration.png";
import Logo from "../../../assets/images/Omnivati-login-logo.svg";
import "./styles.css";

const LoginLayout = ({ children }) => {
  return (
    <div className="login-wrapper">
      <img className="login-logo" src={Logo} alt="" />

      <div className="flex height-100">
        <div className="width-100 flex-center flex-1">{children}</div>
        <div className="login-illustration-container flex flex-1">{<img src={LoginIllustration} alt="" />}</div>
      </div>
    </div>
  );
};

export default LoginLayout;
