import { useEffect, useState } from "react";

const useMouseClick = () => {
  const [lastClick, setLastClick] = useState(null);

  useEffect(() => {
    const handleClick = (e) => {
      setLastClick(e);
    };
    document.addEventListener("click", handleClick, true);
    return () => document.removeEventListener("click", handleClick, true);
  }, []);

  return lastClick;
};

export default useMouseClick;
