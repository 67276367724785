import React from "react";

import { getTranslation } from "../../../helpers/getLanguage";
import "./styles.css";

const isNumber = (value) => typeof value === "number";

const CustomNumberInput = ({
  value,
  onChange,
  placeholder = "",
  containerClass = "",
  minValue = null,
  maxValue = null,
  precision = 0,
  customLabels = null,
  disabled = false,
  couldBeEmpty = false,
  id,
}) => {
  const validateValue = (value) => {
    if (isNumber(minValue) && value < minValue) return minValue;
    if (isNumber(maxValue) && value > maxValue) return maxValue;

    if (couldBeEmpty && !value && value !== 0) return "";

    return Number((+value).toFixed(precision));
  };

  const handleArrowClick = (value, increment) => {
    if (!disabled) {
      const newValue = +value + increment;
      const currentValue = validateValue(newValue);
      onChange(currentValue);
    }
  };

  const handleChange = (value) => {
    if (!disabled) {
      const currentValue = validateValue(value);
      onChange(currentValue);
    }
  };

  const label = customLabels && customLabels[value];
  const disabledClass = disabled ? " disabled not-allowed" : "";
  const pointerClass = !disabled ? " pointer" : "";

  return (
    <div className={"custom-input-container " + containerClass + disabledClass}>
      <input
        id={id}
        value={value}
        type="number"
        placeholder={placeholder}
        onChange={(e) => handleChange(e.target.value)}
        className={"custom-number-input" + disabledClass}
      />
      {label && <div className="custom-number-input-label flex align-center">{getTranslation(label)}</div>}
      <div className="custom-number-input-arrows flex-column">
        <div className={"arrow-icon up flex-center" + pointerClass} onClick={() => handleArrowClick(value, 1)}>
          <div className="triangle-icon-up"></div>
        </div>

        <div className={"arrow-icon down flex-center" + pointerClass} onClick={() => handleArrowClick(value, -1)}>
          <div className="triangle-icon-down"></div>
        </div>
      </div>
    </div>
  );
};

export default CustomNumberInput;
