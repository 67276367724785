import React from "react";

const FlagItem = ({
  compact,
  languageVersion = "",
  changeLanguage,
  currentLanguage = "en",
  ariaLabels,
  shortTitleLanguage,
}) => {
  const activeClass = compact ? " compact" : "";
  return (
    <div
      className={"sidenav-item flex align-center position-relative pointer flag-item" + activeClass}
      style={{ width: !compact ? "224px" : "36px" }}
      onClick={() => changeLanguage(currentLanguage)}
      aria-label={ariaLabels}
      role="button"
    >
      {compact ? (
        <>
          <span>{shortTitleLanguage}</span>
          <div className="sidenav-item-tooltip flex-center">
            <span>{languageVersion}</span>
          </div>
        </>
      ) : (
        <div style={{ marginLeft: "40px" }}>
          <span>{languageVersion}</span>
        </div>
      )}
    </div>
  );
};

export default FlagItem;
