import React from "react";

import Benefits from "./Benefits";
import DescribeIdea from "./DescribeNewProduct";
import Issues from "./Issues/index";
import "./styles.css";

const SubtractionModalTabs = ({ currentTab, ...props }) => {
  return (
    <>
      {currentTab === 0 && (
        <DescribeIdea
          selectCurrentMultiplicationImageFromLibrary={props.selectCurrentMultiplicationImageFromLibrary}
          removeImageFromLibrary={props.removeImageFromLibrary}
          multiplication={props.multiplication}
          potentialGeneratedImageUrl={props.potentialGeneratedImageUrl}
          handleAcceptPotentialImage={props.handleAcceptPotentialImage}
          handleChange={props.handleChange}
          uploadInfo={props.uploadInfo}
          newGeneratedImage={props.newGeneratedImage}
          handleCancelClick={props.handleCancelClick}
          handleFileSelect={props.handleFileSelect}
          isLoadingImage={props.isLoadingImage}
          productType={props.productType}
          header={props.header}
          handleGenerateNames={props.handleGenerateNames}
          openGenerateNameModal={props.openGenerateModal}
          closeGenerateNamesModal={props.closeGenerateModal}
          suggestedNamesForIdeas={props.suggestedOptions}
          suggestedLoading={props.suggestedLoading}
          addIdeasName={props.addIdeasName}
          handleGenerateImage={props.handleGenerateImage}
          isOpenGenerateImageModal={props.isOpenGenerateImageModal}
          closeDialogGenerateImage={props.closeDialogGenerateImage}
          generatedImage={props.generatedImage}
        />
      )}

      {currentTab === 1 && (
        <Benefits
          benefits={props.multiplication.potentialBenefitsOfMultiplication}
          addBenefit={props.addBenefit}
          removeBenefit={props.removeBenefit}
          removeLastBenefit={props.removeLastBenefit}
          handleBenefitChange={props.handleBenefitChange}
          handleTargetChange={props.handleTargetChange}
          customCategories={props.customCategories}
          score={props.multiplication.score}
          handleChangeScore={props.handleChangeScore}
          handleGenerateBenefits={props.handleGenerateBenefits}
          openGenerateBenefitsModal={props.openGenerateModal}
          closeGenerateBenefitsModal={props.closeGenerateModal}
          suggestedLoading={props.suggestedLoading}
          suggestedBenefits={props.suggestedOptions}
          addGenerateBenefits={props.addGenerateBenefits}
          selectGenerateOption={props.selectGenerateOption}
          selectedGenerateOptions={props.selectedGenerateOptions}
          userFullName={props.userFullName}
          handleDeleteOption={props.handleDeleteOption}
          handleRenameOption={props.handleRenameOption}
          addCustomCategory={props.addCustomCategory}
        />
      )}

      {currentTab === 2 && (
        <Issues
          issues={props.multiplication.issues}
          userId={props.userId}
          userFullName={props.userFullName}
          messages={props.multiplication.issuesMessages}
          addIssue={props.addIssue}
          removeIssue={props.removeIssue}
          removeLastIssue={props.removeLastIssue}
          removeMessage={props.removeMessage}
          removeLastMessage={props.removeLastMessage}
          handleChange={props.handleIssueChange}
          addIssueMessage={props.addIssueMessage}
          handleIssueMessageChange={props.handleIssueMessageChange}
          handleChangeFeasibleScore={props.handleChangeFeasibleScore}
          feasibleScore={props.multiplication.feasibleScore}
          handleGenerateChallenges={props.handleGenerateChallenges}
          openGenerateChallengesModal={props.openGenerateModal}
          closeGenerateChallengesModal={props.closeGenerateModal}
          suggestedChallenges={props.suggestedOptions}
          suggestedLoading={props.suggestedLoading}
          addGenerateChallenges={props.addGenerateChallenges}
          selectGenerateOption={props.selectGenerateOption}
          selectedGenerateOptions={props.selectedGenerateOptions}
        />
      )}
    </>
  );
};

export default SubtractionModalTabs;
