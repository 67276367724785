import React from "react";

import { getTranslation } from "../../../helpers/getLanguage";

const TabHeader = ({ activeTab, index, setActiveTab, itemCount, label }) => (
  <div
    className={"project-dashboard-tab flex-center pointer" + (activeTab === index ? " active" : "")}
    onClick={() => setActiveTab(index)}
  >
    <span className="project-dashboard-tab-title">{getTranslation(label)}</span>
    {itemCount !== null && <span className="project-dashboard-tab-counter">{itemCount}</span>}
  </div>
);

export default TabHeader;
