import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";

import { getProjectInfo, getProjects, resetProjectInfo } from "../../../actions/projectActions";
import { getTranslation } from "../../../helpers/getLanguage";
import CardModal from "../../dialogs/CardModal";
import ProjectProgressBar from "../ProjectProgressBar";
import Tooltip from "../Tooltip";
import TranslateTemplate from "../TranslateTemplate";
import "./styles.css";

const ProjectTopBar = ({
  user,
  projects,
  product,
  projectId,
  getProjects,
  getProjectInfo,
  resetProjectInfo,
  currentProjectName,
  cellsCount,
  history,
  match,
  showElements = true,
  compact,
  language,
  isPublicView = false,
}) => {
  const ref = useRef();
  const isCompact = compact ? " compact" : "";
  const [containerWidth, setContainerWidth] = useState(0);
  const [isPending, setIsPending] = useState({ projects: false, project: false });

  useEffect(() => {
    if (!projects.length && !isPending.projects) {
      setIsPending((state) => ({ ...state, projects: true }));
    }

    if (!product.id && projectId && !isPending.project) {
      setIsPending((state) => ({ ...state, project: true }));
      getProjectInfo(projectId);
    }
  }, [getProjectInfo, getProjects, projects.length, product.id, projectId, isPending]);

  useLayoutEffect(() => {
    if (ref.current) {
      setContainerWidth(ref.current.getBoundingClientRect().width);
    }
  }, [currentProjectName, isCompact]);

  const selectOptions = projects
    .filter((project) => !project.product.archivedUserIds?.includes(user.id))
    .map((project) => ({
      value: project.product.name,
      action: () => {
        const currentProjectId = match.params.projectId;
        const nextProjectId = project.product.id;

        if (currentProjectId !== nextProjectId) resetProjectInfo();
        history.push(match.path.replace(":projectId", nextProjectId));
      },
    }));

  const projectTypeHeaderInfo = useMemo(() => {
    if (product.type) {
      switch (product.type) {
        case "process":
          return `${getTranslation("PROJECT_TOP_BAR_TITLE_PROCESS")} ${
            product.typeName ? product.typeName : product.name
          }`;
        case "product":
          return `${getTranslation("PROJECT_TOP_BAR_TITLE_PRODUCT")} ${
            product.typeName ? product.typeName : product.name
          }`;
        case "service":
          return `${getTranslation("PROJECT_TOP_BAR_TITLE_SERVICE")} ${
            product.typeName ? product.typeName : product.name
          }`;
        default:
          return "";
      }
    }

    return "";
  }, [product.type, product.name, product.typeName, language]);

  return (
    <div
      className={"project-bar-selector flex-column justify-space-between" + isCompact + isPublicView && "public-matrix"}
    >
      {showElements && (
        <div className="project-bar-header flex justify-space-between width-100 toolbar-border flex-wrap">
          <div ref={ref} className="projects-bar-select-container user-select-none border-0">
            <CardModal
              items={selectOptions}
              offsetX={-containerWidth + 16}
              offsetY={14}
              maxHeight={265}
              width={containerWidth}
              showSearch
              searchPlaceholder={getTranslation("SEARCH_PROJECTS")}
              isSearch={true}
              compact={compact}
            >
              <div className="flex align-center pointer justify-space-between">
                <TranslateTemplate as="span" className="flex projects-bar-selector-title">
                  {getTranslation("PROJECT_TOP_BAR_TITLE_PROJECT")}
                  <Tooltip
                    containerClass="ellipsed-text"
                    position="bottom"
                    innerTextClass="ml-10"
                    message={currentProjectName}
                    innerText={currentProjectName}
                    tooltipMessageClass="tooltip-message white"
                  />
                </TranslateTemplate>
                {!isPublicView && <div className="select-triangle-icon"></div>}
              </div>
            </CardModal>

            <div className="width-100 mt-10 flex justify-space-between">
              <span className="flex projects-bar-selector-title">
                <Tooltip
                  containerClass="ellipsed-text"
                  position="bottom"
                  innerTextClass=""
                  message={projectTypeHeaderInfo}
                  innerText={projectTypeHeaderInfo}
                  tooltipMessageClass="tooltip-message white"
                />
              </span>
            </div>
          </div>

          <div className="flex flex-column addressed-cells-counter">
            <span className="addressed-cells-count">
              {getTranslation("ADDRESSED")} {`${cellsCount.explored}/${cellsCount.total}`}
            </span>
            <div className="progress-bar-container">
              <ProjectProgressBar project={cellsCount} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  language: state.auth.userInfo.language,
  product: state.project.product,
  compact: state.preferences.sidenavCollapsed,
  cellsCount: state.project.cellsCount,
  projects: state.project.projects,
});

const mapDispatchToProps = {
  getProjects,
  getProjectInfo,
  resetProjectInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectTopBar);
