import { getUrl } from "../api/params";
import {
  GET_TUTORIAL_VIDEO_URL,
  GET_TUTORIAL_VIDEO_URL_ERROR,
  GET_TUTORIAL_VIDEO_URL_SUCCESS,
  SET_TUTORIAL_VIDEO_URL,
} from "./types";

export const getTutorialUrls = () => (dispatch) => {
  dispatch({ type: GET_TUTORIAL_VIDEO_URL });

  const onSuccess = (response) => dispatch({ type: GET_TUTORIAL_VIDEO_URL_SUCCESS, payload: response });
  const onError = (error) => dispatch({ type: GET_TUTORIAL_VIDEO_URL_ERROR, payload: error });

  getUrl({}, onSuccess, onError);
};

export const setTutorialUrls = (newTutorialUrls) => (dispatch) => {
  dispatch({ type: SET_TUTORIAL_VIDEO_URL, payload: newTutorialUrls });
};
