import axios from "axios";

import { baseApiUrl } from "../common/constants";
import { handleError, handleSuccess } from "./handlers";

const baseUrl = `${baseApiUrl}/admin`;

export const getStatistics = async (params, onSuccess, onError) => {
  axios
    .get(`${baseUrl}/stats`, params)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const getStatisticsReport = async (params, onSuccess, onError) => {
  axios
    .get(`${baseUrl}/report`, params)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const uploadTutorialVideo = async (data, onSuccess, onError) => {
  axios
    .post(`${baseApiUrl}/upload/tutorialVideo`, data)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const deleteTutorialVideo = async (data, onSuccess, onError) => {
  axios
    .delete(`${baseApiUrl}/upload/tutorialVideo`, { params: data })
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const updateDisplayNameTutorialVideo = async ({ name, displayName, language }, onSuccess, onError) => {
  axios
    .put(`${baseApiUrl}/upload/tutorialVideo`, { name, displayName, language })
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};
export const createCurrentIssue = async (data, onSuccess, onError) => {
  axios
    .post(`${baseApiUrl}/currentIssues`, data)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const updateCurrentIssue = async (data, onSuccess, onError) => {
  axios
    .put(`${baseApiUrl}/currentIssues/${data.id}`, data)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const deleteCurrentIssue = async (data, onSuccess, onError) => {
  axios
    .delete(`${baseApiUrl}/currentIssues/${data.id}`, data)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};
