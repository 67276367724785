import React from "react";

import { getTranslation } from "../../helpers/getLanguage";
import Tooltip from "../common/Tooltip";
import ViewTutorialButtonWithModal from "../common/ViewTutorialButtonWithModal";

const ContradictionMatrixHeader = ({ isPublicView = false }) => {
  const tooltipMessage =
    getTranslation("TOOLTIP_MESSAGE_FORECASTING_MATRIX") + "\n\n" + getTranslation("TOOLTIP_MESSAGE_MATRIX_ELEMENT");

  return (
    <div className="flex-wrap justify-space-between">
      {!isPublicView && (
        <div className="flex-column contradiction-matrix-header-tutorial">
          <ViewTutorialButtonWithModal name="Matrix" className="mb-10" />
        </div>
      )}
    </div>
  );
};

export default ContradictionMatrixHeader;
