import {
  GET_TUTORIAL_VIDEO_URL,
  GET_TUTORIAL_VIDEO_URL_ERROR,
  GET_TUTORIAL_VIDEO_URL_SUCCESS,
  SET_TUTORIAL_VIDEO_URL,
} from "../actions/types";

const initialState = {
  tutorialUrls: [],
  errors: null,
  isLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TUTORIAL_VIDEO_URL:
      return {
        ...state,
        isLoading: true,
        errors: null,
      };
    case GET_TUTORIAL_VIDEO_URL_SUCCESS:
      return {
        ...state,
        tutorialUrls: action.payload,
        errors: null,
        isLoading: false,
      };
    case GET_TUTORIAL_VIDEO_URL_ERROR:
      return {
        ...state,
        tutorialUrls: [],
        errors: action.payload,
        isLoading: false,
      };
    case SET_TUTORIAL_VIDEO_URL:
      return {
        ...state,
        tutorialUrls: action.payload,
        errors: null,
        isLoading: false,
      };
    default:
      return state;
  }
}
