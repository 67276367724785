import { GET_PREFERENCES, SET_PREFERENCE } from "../actions/types";

const LOCAL_STORAGE_KEY = "preferences";

const initialState = {
  componentsCompactView: true,
  sidenavCollapsed: false,
  matrixBinaryMode: false,
  attributesSortedAlphabetical: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PREFERENCES:
      const preferences = getPreferences();
      return { ...preferences };
    case SET_PREFERENCE:
      const newPreferences = { ...state, ...action.payload };
      savePreferences(newPreferences);
      return newPreferences;

    default:
      return state;
  }
}

function getPreferences() {
  const rawPreferences = localStorage[LOCAL_STORAGE_KEY];
  if (rawPreferences) {
    try {
      const preferences = JSON.parse(rawPreferences);
      if (Object.keys(preferences)) {
        return preferences;
      } else {
        return initialState;
      }
    } catch (error) {
      console.error(error);
      savePreferences(initialState);
      return initialState;
    }
  }
}

function savePreferences(preferences) {
  const preferencesString = JSON.stringify(preferences);
  localStorage.setItem(LOCAL_STORAGE_KEY, preferencesString);
}
