import classnames from "classnames";
import React from "react";

import { getTranslation } from "../../../helpers/getLanguage";

const ideasColors = {
  default: "#cdd1e5",
  0: "#eceef5",
  2: "#f9ae9f",
  1: "#ffcd67",
  3: "#5ac57a",
};

const optionType = {
  unaddressed: 0,
  inProgress: 1,
  notFeasible: 2,
  feasible: 3,
};

const DependencyOption = ({ option, onMouseUp, first, isOpen, isActive }) => {
  const unaddressedClass = option.value === optionType.unaddressed ? " unaddressed" : "";

  return (
    <div
      className={classnames(
        "custom-dropdown-container-option flex align-center width-100 pointer",
        { first },
        { open: isOpen },
        { active: isActive },
      )}
      onMouseUp={() => onMouseUp(option.value)}
    >
      <div className="flex align-center custom-dropdown-option">
        <div
          className={"dependency-indicator" + unaddressedClass}
          style={{ backgroundColor: ideasColors[option.value] }}
        ></div>
        <span>{getTranslation(option.label)}</span>
      </div>
    </div>
  );
};

export default DependencyOption;
