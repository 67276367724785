import React from "react";

import TabHeader from "../../common/TabHeader";

const AdminTabsHeader = ({ activeTab, setActiveTab, labels }) => {
  const tabs = labels.map((label, index) => (
    <TabHeader key={index} index={index} activeTab={activeTab} setActiveTab={setActiveTab} label={label} />
  ));

  return <div className="admin-panel-tabs flex mb-30">{tabs}</div>;
};

export default AdminTabsHeader;
