import React from "react";

import AdminTabsHeader from "./AdminTabsHeader";
import GlobalConfigurations from "./GlobalConfigurations";
import IssuesConfiguration from "./Issues";
import ManageUsers from "./ManageUsers";
import Plans from "./Plans";
import PrefabsChatGTP from "./PrefabsChatGTP";
import PromoCodes from "./PromoCodes";
import Statistics from "./Statistics/index";
import "./styles.css";
import UsersTable from "./UsersTable";

const adminTabs = [
  "PAGE_ADMIN_PANEL_TAB_PROMO_CODES",
  "PAGE_ADMIN_PANEL_TAB_USER_MANAGEMENT",
  "PAGE_ADMIN_PANEL_TAB_USER_TABLE",
  "PAGE_ADMIN_PANEL_TAB_STATISTICS",
  "PAGE_ADMIN_PANEL_TAB_GLOBAL_CONFIGURATIONS",
  "PAGE_ADMIN_PANEL_TAB_ISSUES",
  "PAGE_ADMIN_PANEL_TAB_PREFABS_CHAT_GPT",
  "PAGE_ADMIN_PANEL_TAB_PLANS",
];
const supportAdminTabs = [];

const adminAITabs = ["PAGE_ADMIN_PANEL_TAB_PREFABS_CHAT_GPT"];

const AdminTabs = ({
  activeTab,
  setActiveTab,
  openPromoCodeModal,
  openUserModal,
  containerHeight,
  containerRef,
  promoCodes,
  promoCodesSort,
  usersSort,
  handleSortChange,
  promoCodesFilters,
  usersFilter,
  setPromoCodesFilters,
  setUsersFilter,
  resetFilters,
  isAdmin,
  isSupportAdmin,
  isAdminAI,
  language,
  users,
  setPageFn,
  usersIsLoading,
  authorizedUser,
}) => {
  const currentTabs = isAdmin ? adminTabs : isSupportAdmin ? supportAdminTabs : isAdminAI ? adminAITabs : [];

  const isTabActive = (name) => {
    return activeTab === currentTabs.findIndex((tabName) => tabName === name);
  };

  return (
    <>
      <AdminTabsHeader activeTab={activeTab} setActiveTab={setActiveTab} labels={currentTabs} />

      {isTabActive(adminTabs[0]) && isAdmin && (
        <PromoCodes
          containerHeight={containerHeight}
          containerRef={containerRef}
          openPromoCodeModal={openPromoCodeModal}
          promoCodes={promoCodes}
          sort={promoCodesSort}
          handleSortChange={handleSortChange}
          filters={promoCodesFilters}
          setFilters={setPromoCodesFilters}
          resetFilters={resetFilters}
        />
      )}

      {isTabActive(adminTabs[1]) && isAdmin && <ManageUsers language={language} />}
      {isTabActive(adminTabs[2]) && isAdmin && (
        <UsersTable
          containerHeight={containerHeight + 55}
          containerRef={containerRef}
          users={users}
          sort={usersSort}
          handleSortChange={handleSortChange}
          openUserModal={openUserModal}
          filters={usersFilter}
          setFilters={setUsersFilter}
          resetFilters={resetFilters}
          setPageFn={setPageFn}
          usersIsLoading={usersIsLoading}
        />
      )}
      {isTabActive(adminTabs[3]) && isAdmin && <Statistics />}
      {isTabActive(adminTabs[4]) && isAdmin && <GlobalConfigurations />}
      {isTabActive(adminTabs[5]) && isAdmin && <IssuesConfiguration />}
      {isTabActive(adminTabs[6]) && (isAdmin || isAdminAI) && <PrefabsChatGTP authorizedUser={authorizedUser} />}
      {isTabActive(adminTabs[7]) && isAdmin && <Plans />}
    </>
  );
};

export default AdminTabs;
