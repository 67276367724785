import { Auth0Provider } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom";

import "./assets/fonts/Inter-VariableFont_slnt,wght.ttf";
import "./assets/fonts/Space-Grotesk-Medium.ttf";
import {
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_SENTRY_ENV,
  REACT_APP_SENTRY_URL,
} from "./common/config";
import App from "./components/App";
import "./css/index.css";
import * as serviceWorker from "./serviceWorker";

const SENTRY_ENV = REACT_APP_SENTRY_ENV;
const SENTRY_URL = SENTRY_ENV ? REACT_APP_SENTRY_URL : null;

Sentry.init({
  dsn: SENTRY_URL,
  environment: SENTRY_ENV,
  normalizeDepth: 10,
});

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={"An error has occurred"}>
    <Auth0Provider
      domain={REACT_APP_AUTH0_DOMAIN}
      clientId={REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
    >
      <App />
    </Auth0Provider>
  </Sentry.ErrorBoundary>,

  document.getElementById("root"),
);

serviceWorker.unregister();
