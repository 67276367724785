import React, { useEffect, useState } from "react";

import { acceptInvite, changeTeams } from "../../../api/invites";
import CloseIcon from "../../../assets/images/close-icon.svg";
import { getTranslation } from "../../../helpers/getLanguage";
import InvitationInput from "../../inputs/InvitationInput";
import InvitationDropdown from "../../inputs/InvitationInput/InvitationDropdown/InvitationDropdown";
import Dialog from "../Dialog";
import "./styles.css";

const teamSelectOptions = {
  join: { value: "join", label: "TEAM_SELECT_OPTIONS_JOIN_TEAM" },
  create: { value: "create", label: "TEAM_SELECT_OPTIONS_CREATE_NEW_TEAM" },
  individual: { value: "individual", label: "TEAM_SELECT_OPTIONS_WORK_INDIVIDUALLY" },
};

const teamChangeOptions = {
  join: { value: "join", label: "TEAM_CHANGE_OPTIONS_ADD_TEAM" },
  create: { value: "create", label: "TEAM_CHANGE_OPTIONS_ADD_NEW_TEAM" },
  individual: { value: "individual", label: "TEAM_CHANGE_OPTIONS_WORK_INDIVIDUALLY" },
};

const InvitationModal = ({ closeDialog, inviteInfo, onSuccess, edit = false, userInfo, onError }) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedTeam, setSelectedTeam] = useState("");
  const [options, setOptions] = useState([]);
  const [teams, setTeams] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const participantsCount = (inviteInfo.teams || []).reduce((p, n) => p + n.participants.length, 0);
  const availableCount = inviteInfo.groupsPerProject * inviteInfo.peoplePerGroup - participantsCount;

  useEffect(() => {
    if (Object.keys(inviteInfo).length) {
      const selectOptions = [];
      let teamOptions = [];

      if (availableCount <= 0) {
        !edit && closeDialog();
        return;
      }

      if (edit && !userInfo.length) {
        console.error("User info is required");
        return;
      }

      if (inviteInfo.peoplePerGroup === 1 && !edit) {
        const selected = inviteInfo.canWorkIndividually ? "individual" : "create";
        handleTeamSelect(selected);
        return;
      }

      const currentOptions = edit ? teamChangeOptions : teamSelectOptions;

      if (inviteInfo.teams && inviteInfo.teams.length) {
        let index = 1;

        teamOptions = inviteInfo.teams
          .map((team) => {
            const teamCount = team.participants.length + (edit ? userInfo.length : 1);

            const foundTeamId = userInfo && userInfo.find((user) => user.currentTeamId === team.id);
            const isDisabled = foundTeamId || teamCount > team.maxPeople || teamCount > team.peoplePerGroup;
            return {
              value: team.id,
              label: !team.isIndividual ? `Team ${index++}` : "Individual",
              labels: team.participants.map((participant) => participant.fullname || participant),
              disabled: isDisabled,
            };
          })
          .filter((team) => !team.disabled);
      }

      teamOptions.length && selectOptions.push(currentOptions.join);

      const teamsCount = teamOptions.filter((team) => team.label !== "Individual").length;
      if (teamsCount < inviteInfo.groupsPerProject) {
        if (!edit || (edit && userInfo.length <= inviteInfo.peoplePerGroup)) {
          selectOptions.push(currentOptions.create);
        }
      }

      inviteInfo.canWorkIndividually && selectOptions.push(currentOptions.individual);

      const shouldHandleJoin = !edit && !inviteInfo.canWorkIndividually;
      if (shouldHandleJoin && (availableCount === 1 || inviteInfo.groupsPerProject === 1)) {
        if (teamOptions[0]) {
          handleTeamSelect("join", teamOptions[0].value);
        } else {
          handleTeamSelect("create");
        }
        return;
      }

      setOptions(selectOptions);
      setTeams(teamOptions);
    }
  }, [inviteInfo, userInfo, availableCount, closeDialog, edit]);

  const handleTeamSelect = (option = selectedOption, team = selectedTeam) => {
    if (!option) {
      return;
    }

    const createTeam = ["create", "individual"].includes(option);
    const joinTeam = option === "join" && team.length > 0;

    if (joinTeam || createTeam) {
      const params = { id: inviteInfo.id };
      params[option] = true;
      joinTeam && (params.teamId = team);
      edit && (params.users = userInfo);

      const onSuccessCallback = (response) => {
        setIsPending(false);
        closeDialog();
        onSuccess(response);
      };

      const onErrorCallback = (error) => {
        setIsPending(false);
        closeDialog();
        onError && onError(error);
      };

      setIsPending(true);
      if (edit) {
        changeTeams(params, onSuccessCallback, onErrorCallback);
      } else {
        acceptInvite(params, onSuccessCallback, onErrorCallback);
      }
    }
  };

  if (inviteInfo.peoplePerGroup === 1 || (availableCount === 1 && !inviteInfo.canWorkIndividually)) {
    return null;
  }

  const isCreatingTeam = ["create", "individual"].includes(selectedOption);
  const isJoiningTeam = selectedOption === "join" && selectedTeam.length > 0;

  return (
    <Dialog closeDialog={closeDialog} draggable closeOnClickOutside={false}>
      <div className="invitation-dialog-wrapper">
        <div className="invitation-dialog-header flex draggable-handle">
          <div className="flex align-center">
            <span className="invitation-dialog-title">{inviteInfo.name}</span>
          </div>
          <img src={CloseIcon} draggable="false" alt="" className="pointer" onClick={() => closeDialog()} />
        </div>

        <div className="invitation-dialog-body flex-column align-center">
          <span className="invitation-dialog-text">{getTranslation("CHANGE_TEAM_TITLE")}</span>

          <div className="flex width-100">
            <InvitationInput value={selectedOption} handleChange={(e) => setSelectedOption(e)} options={options} />
          </div>

          {selectedOption === "join" && (
            <div className="flex-column align-center">
              <span className="invitation-dialog-text">{getTranslation("CHANGE_TEAM_SELECT_TEAM")}</span>

              <InvitationDropdown
                options={teams}
                isOpen={dropdownOpen}
                setIsOpen={setDropdownOpen}
                value={selectedTeam}
                setValue={setSelectedTeam}
                placeholder={getTranslation("CHANGE_TEAM_SELECT_TEAM")}
              />
            </div>
          )}

          {(isCreatingTeam || isJoiningTeam) && (
            <button
              className="invitation-dialog-button dark-blue-btn flex-center"
              onClick={() => handleTeamSelect()}
              disabled={isPending}
            >
              <span>{getTranslation("CHANGE_TEAM_DIALOG_BUTTON")}</span>
            </button>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default InvitationModal;
