import React from "react";
import { Droppable } from "react-beautiful-dnd";
import { connect } from "react-redux";

import { ReactComponent as AIGenerateIcon } from "../../../assets/images/ai-generate-icon.svg";
import HiddenIcon from "../../../assets/images/hidden-component-icon.svg";
import LinkIcon from "../../../assets/images/link-icon.svg";
import MenuIcon from "../../../assets/images/menu-icon.svg";
import { ReactComponent as PlusIconSmall } from "../../../assets/images/plus-icon-small.svg";
import ComponentIcon from "../../../assets/images/solid/component-icon-filled.svg";
import StepIcon from "../../../assets/images/solid/icon-step.svg";
import { getTranslation } from "../../../helpers/getLanguage";
import CustomScrollbar from "../../common/CustomScrollbar";
import Tooltip from "../../common/Tooltip";
import { sortByName, sortByOrder } from "../../common/utils/sort";
import CardModal from "../../dialogs/CardModal";
import AttributeCard from "./AttributeCard";
import Importance from "./Importance";

const ComponentCard = ({
  inputValues,
  component,
  attributes,
  handleModalOpen,
  handleDelete,
  handleDuplicate,
  handleDisable,
  compact,
  isSorted,
  HandleComponent,
  activeStatusClass,
  queryParams,
  setAttributeId,
  setComponentId,
  dragMode,
  suggestedMode,
  setSuggestedMode,
  handleSuggestingAttributes,
  isHorizontalScroll,
  isProcessType,
  numberOpenAIRequests,
  language,
}) => {
  const componentType = component.internal
    ? "PAGE_COMPONENT_COMPONENT_TYPE_INTERNAL"
    : "PAGE_COMPONENT_COMPONENT_TYPE_EXTERNAL";
  const activeClass = compact ? " compact" : "";
  const disabledClass = component.disabled ? " disabled" : "";

  const sortFunction = isSorted ? sortByName : sortByOrder;
  const sortedAttributes = attributes.slice().sort(sortFunction);

  const attributeElements = sortedAttributes.map((attribute, index) => {
    const activeAttribute = queryParams.attribute === attribute.id ? " active-card-element" : " ";
    return (
      <AttributeCard
        key={attribute.id}
        index={index}
        compact={compact}
        attribute={attribute}
        handleModalOpen={handleModalOpen}
        handleDelete={handleDelete}
        handleDuplicate={handleDuplicate}
        handleDisable={handleDisable}
        activeAttribute={activeAttribute}
        setAttributeId={setAttributeId}
        isSorted={isSorted}
      />
    );
  });

  const menuItems = [
    {
      value: isProcessType ? "PAGE_COMPONENT_MENU_ITEM_EDIT_STEP" : "PAGE_COMPONENT_MENU_ITEM_EDIT_COMPONENT",
      action: () => handleModalOpen("component", { element: component }, true),
    },
    {
      value: isProcessType ? "PAGE_COMPONENT_MENU_DUPLICATE_STEP" : "PAGE_COMPONENT_MENU_DUPLICATE_COMPONENT",
      action: () => handleDuplicate("component", component),
    },
    {
      value: component.disabled
        ? "PAGE_COMPONENT_MENU_ITEM_ENABLE_COMPONENT"
        : "PAGE_COMPONENT_MENU_ITEM_DISABLE_COMPONENT",
      action: () => handleDisable("component", component),
    },
    {
      value: isProcessType ? "PAGE_COMPONENT_MENU_ITEM_DELETE_STEP" : "PAGE_COMPONENT_MENU_ITEM_DELETE_COMPONENT",
      action: () => handleDelete("component", component.id),
    },
  ];

  const getTooltipText = () => {
    if (component.internal) return getTranslation("TOOLTIP_MESSAGE_INTERNAL_ATTRIBUTE");
    return getTranslation("TOOLTIP_MESSAGE_EXTERNAL_ATTRIBUTE");
  };

  const tooltipClass = component.internal ? "tooltip-inner-text-blue" : "tooltip-inner-text-red";

  const handleComponentClick = () => {
    if (!dragMode.current) setComponentId(component.id);
  };

  const handleSuggestComponent = () => {
    handleSuggestingAttributes(component.name, component.id, language);
    setSuggestedMode({
      ...suggestedMode,
      attribute: {
        component: {
          name: component.name,
          element: null,
          componentId: component.id,
          internal: component.internal,
          disabled: component.disabled,
        },
      },
    });

    handleModalOpen("generateAttributes", {
      element: null,
      componentId: component.id,
      internal: component.internal,
      disabled: component.disabled,
    });
  };

  const smallCartModeStyle = activeClass ? { height: "370px" } : {};

  return (
    <div
      onClick={handleComponentClick}
      className={"component-card" + activeClass + disabledClass + activeStatusClass}
      style={{ height: isHorizontalScroll && "99%", ...smallCartModeStyle }}
    >
      <div
        className="component-card-header-container pointer"
        onClick={() => handleModalOpen("component", { element: component }, true)}
      >
        <div className={"component-card-header flex" + activeClass + disabledClass}>
          <div className="flex component-card-header-text">
            <HandleComponent>
              {isProcessType ? (
                <img className="component-icon-step" src={StepIcon} alt="" />
              ) : (
                <img className="component-icon" src={ComponentIcon} alt="" />
              )}

              <Tooltip
                containerClass="component-card-tooltip"
                position="top"
                innerTextClass=""
                message={component.name}
                innerText={component.name}
                tooltipMessageClass="tooltip-message white"
              />
            </HandleComponent>
          </div>

          <div className="flex align-center">
            {component.disabled && <img className="link-icon" src={HiddenIcon} alt="" />}
            {component.locked && <img className="link-icon" src={LinkIcon} alt="" />}
            {component.locked && <Tooltip message={getTooltipText()} />}

            <CardModal items={menuItems} offsetX={-200}>
              <button className="component-card-menu-button flex-center">
                <img className="menu-icon" src={MenuIcon} alt=""></img>
              </button>
            </CardModal>
          </div>
        </div>
      </div>

      <div className="component-card-body-dashboard flex-column align-center">
        <div
          className={
            "component-type-container flex align-center justify-space-between width-100" + activeClass + disabledClass
          }
        >
          <div className="flex">
            <div className={"component-type flex-center" + (!component.internal ? " external" : "")}>
              <Tooltip
                message={getTooltipText()}
                innerText={getTranslation(componentType)}
                innerTextClass={tooltipClass}
              />
            </div>
          </div>
          <Importance value={component.importance} compact={inputValues.compact} />
        </div>
        <Droppable droppableId={component.id}>
          {(provided) => (
            <div
              className={"subtract-component-card-container flex-column align-center" + activeClass}
              style={activeClass ? { height: 150 } : isHorizontalScroll ? { height: "90%" } : { height: 550 }}
            >
              <CustomScrollbar
                width={5}
                right={-10}
                backgroundColor="transparent"
                dependencies={[inputValues, attributes, compact]}
              >
                <div {...provided.droppableProps} ref={provided.innerRef} className="component-droppable-container">
                  {attributeElements}
                  {provided.placeholder}
                </div>
              </CustomScrollbar>
            </div>
          )}
        </Droppable>
        <span className="add-text-attributes">{getTranslation("PAGE_COMPONENT_ADD_ATTRIBUTES")}</span>
        <div className="flex width-100 justify-space-evenly ">
          {!component.disabled && (
            <button
              className={
                "add-attribute-button flex align-center justify-space-between add-new-attribute" + disabledClass
              }
              onClick={() =>
                handleModalOpen("attribute", {
                  element: null,
                  componentId: component.id,
                  internal: component.internal,
                  disabled: component.disabled,
                })
              }
            >
              <PlusIconSmall style={{ marginRight: "5px" }} />
              <span>{getTranslation("PAGE_COMPONENT_ADD")}</span>
            </button>
          )}
          {!component.disabled && suggestedMode && (
            <button
              className={
                "add-attribute-button flex align-center justify-space-between generate-attribute" + disabledClass
              }
              onClick={handleSuggestComponent}
              style={{ width: 118 }}
            >
              <AIGenerateIcon />
              <span style={{ marginLeft: 8 }}>
                {getTranslation(
                  component?.hasAIResponseCached || numberOpenAIRequests ? "GENERATE_LABEL" : "BUY_CREDITS",
                )}
              </span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  language: state.auth.userInfo.language,
});

export default connect(mapStateToProps)(ComponentCard);
