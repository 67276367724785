import _ from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { setPreference } from "../../actions/preferencesActions";
import { getProjectInfo } from "../../actions/projectActions";
import MessageShowNotFound from "../common/MessageShowNotFound";
import ProjectTopBar from "../common/ProjectTopBar";
import { sortByName } from "../common/utils/sort";
import DependencyEditModal from "../dialogs/DependencyEditModal";
import DependencyMatrixHeader from "./DependencyMatrixHeader";
import DependencyMatrixTable from "./DependencyMatrixTable";
import "./styles.css";

const toggleLabels = ["TOGGLE_LABELS_ADVANCED", "TOGGLE_LABELS_BINARY"];

const initialFiltersState = {
  filterBy: "",
  ideas: "",
  search: "",
};

const initialColumnBands = [{ title: "", children: [{ name: "", attributeId: "", componentId: "" }] }];

const DependencyMatrix = ({
  auth,
  attributes,
  components,
  detailsMatrix,
  errors,
  match,
  getProjectInfo,
  setPreference,
  history,
  product,
  resetProjectInfo,
  ...props
}) => {
  const { projectId } = match.params;
  const {
    user: { name, lastName, id: userId },
  } = auth;
  const [edit, setEdit] = useState(false);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selectedRow, setSelectedRow] = useState({ id: "", name: "" });
  const [selectedCol, setSelectedCol] = useState({ id: "", name: "" });
  const [selDetailsMatrix, setSelDetailsMatrix] = useState({});
  const [reverse, setReverse] = useState(false);
  const [columnBands, setColumnBands] = useState(initialColumnBands);
  const [selComponentRow, setSelComponentRow] = useState("");
  const [selComponentCol, setSelComponentCol] = useState("");
  const [dependencyModalOpen, setDependencyModalOpen] = useState(false);
  const [filters, setFilters] = useState(initialFiltersState);

  useEffect(() => {
    getProjectInfo(projectId);
  }, [projectId, getProjectInfo]);

  const closeModal = () => {
    getProjectInfo(projectId);
    setSelDetailsMatrix({});
    setReverse(false);
    setEdit(false);
    setDependencyModalOpen(false);
  };

  useEffect(() => {
    if ((attributes.length > 0 && detailsMatrix.length > 0, components.length > 0)) {
      const sortComponents = components.slice().sort((a, b) => {
        if (a.locked) {
          return -1;
        } else if (b.locked) {
          return 1;
        }
        return sortByName(a, b);
      });

      const sortAttributes = attributes.slice().sort(sortByName);

      const newSortAttributes = [];

      sortComponents.forEach((component) => {
        const filteredAttributes = sortAttributes.filter((attribute) => attribute.componentId === component.id);

        for (const attribute of filteredAttributes) {
          newSortAttributes.push(attribute);
        }
      });

      const columnMatrix = newSortAttributes
        .filter((attribute) => attribute.internal)
        .map((attribute) => {
          const dataAttribute = attribute;
          return { name: dataAttribute.id, title: dataAttribute.name };
        });

      const bands = sortComponents
        .map((component) => {
          const newComponent = { ...component };
          const childrenColumns = [];
          newSortAttributes.forEach((attribute) => {
            const newAttribute = { ...attribute };
            if (newAttribute.componentId === newComponent.id) {
              childrenColumns.push({
                name: newAttribute.name,
                attributeId: newAttribute.id,
                componentId: newAttribute.componentId,
              });
            }
          });
          if (childrenColumns) {
            return {
              title: newComponent.name,
              children: childrenColumns,
              internal: newComponent.internal,
              componentId: newComponent.id,
            };
          }
          return null;
        })
        .filter((band) => band && band.children.length);

      const rowsMatrix = newSortAttributes.map((attributeRow) => {
        const dataAttribute = attributeRow;
        const generatedEmptyRows = [];
        newSortAttributes.forEach((attributeCol) => {
          let status = 0;
          const currentDetails = detailsMatrix.find(
            (detail) =>
              (detail.idAttributeRow === attributeRow.id && detail.idAttributeCol === attributeCol.id) ||
              (detail.idAttributeRow === attributeCol.id && detail.idAttributeCol === attributeRow.id),
          );
          if (currentDetails) {
            status = currentDetails.status;
          }
          generatedEmptyRows[attributeCol.id] = attributeRow.id !== attributeCol.id ? status : "";
        });
        const currentComponent = components.find((elem) => elem.id === dataAttribute.componentId);
        let component = {};
        if (currentComponent) {
          component = {
            components: currentComponent.name,
            internal: currentComponent.internal,
            componentId: currentComponent.id,
            locked: currentComponent.locked,
          };
        }
        return { ...component, attributes: dataAttribute.name, ...generatedEmptyRows };
      });

      const groupRows = _.groupBy(rowsMatrix, "componentId");
      const finishRows = Object.values(groupRows).map((row) => {
        const newRow = _.cloneDeep(row);
        const rowObj = newRow.map((elem, index) => {
          const newElem = { ...elem };
          newElem.rowSpan = index === 0 ? newRow.length : 0;
          return newElem;
        });
        return rowObj;
      });

      const result = finishRows.reduce((acc, val) => acc.concat(val), []);
      setColumns([
        { name: "components", title: " " },
        { name: "attributes", title: " " },
        { name: "rowSpan", title: "rowSpan" },
        ...columnMatrix,
      ]);
      setColumnBands(bands);
      setRows([...result.filter((row) => row.internal), ...result.filter((row) => !row.internal)]);
    } else {
      setColumns([]);
      setColumnBands(initialColumnBands);
      setRows([]);
    }
  }, [attributes, detailsMatrix, components]);

  const handleOpenDetails = (rowData, column, details) => {
    setReverse(details?.isReversed);
    const rowId = Object.entries(rowData).find(([key, value]) => value === "");
    if (rowId) {
      setSelectedRow({ id: rowId[0], name: rowData["attributes"], internal: rowData.internal });
      if (details) {
        const selectedDetails = detailsMatrix.find((detail) => detail.id === details.id);

        setSelDetailsMatrix(selectedDetails);
        setEdit(true);
      } else {
        const selectedDetails = detailsMatrix.find(
          (detail) =>
            ((detail.idAttributeCol === column.name && detail.idAttributeRow === rowId[0]) ||
              (detail.idAttributeCol === rowId[0] && detail.idAttributeRow === column.name)) &&
            detail.status === 0 &&
            (!detail.reversed || detail.reversed.status === 0),
        );
        if (selectedDetails) {
          setSelDetailsMatrix(selectedDetails);
          setEdit(true);
        }
      }
    }
    setSelectedCol({ id: column.name, name: column.title });
    if (rowData["components"]) {
      setSelComponentRow(rowData["components"]);
    }
    const currentAttributeCol = attributes.find((attribute) => attribute.id === column.name);
    const currentComponentCol = components.find((component) => component.id === currentAttributeCol.componentId);
    if (currentComponentCol) {
      setSelComponentCol(currentComponentCol.name);
    }
    setDependencyModalOpen(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const handleInputChange = (e) => {
    const { name, value } = e;
    setFilters({ ...filters, [name]: value });
  };

  const redirectToComponents = () => {
    history.push(`/project/${projectId}/components`);
  };

  const showNotFoundError = errors && errors.response && errors.response.status === 404;

  return (
    <div>
      <ProjectTopBar match={match} history={history} currentProjectName={product.name} />
      <div className="dependency-matrix-wrapper">
        {!errors && (
          <>
            <DependencyMatrixHeader
              filters={filters}
              setFilters={setFilters}
              binaryMode={props.binaryMode}
              setBinaryMode={(e) => setPreference({ matrixBinaryMode: e })}
              handleChange={handleChange}
              handleInputChange={handleInputChange}
              labels={toggleLabels}
            />

            <DependencyMatrixTable
              rows={rows}
              columns={columns}
              handleOpenDetails={handleOpenDetails}
              columnBands={columnBands}
              detailsMatrix={detailsMatrix}
              filters={filters}
              binaryMode={props.binaryMode}
              redirectToComponents={redirectToComponents}
              isLoading={props.isLoading}
              history={history}
              projectId={projectId}
              language={props.language}
            />
          </>
        )}
        {dependencyModalOpen && (
          <DependencyEditModal
            closeDialog={closeModal}
            userFullName={{ name, lastName }}
            userId={userId}
            edit={edit}
            rowAttribute={selectedRow}
            selectedComponentRow={selComponentRow}
            selectedComponentCol={selComponentCol}
            colAttribute={selectedCol}
            productId={projectId}
            detailsMatrix={selDetailsMatrix}
            teamId={product.teamId}
            categories={product.customCategories}
            isReversed={reverse}
            productType={product.type}
            productName={product.typeName}
            projectName={product.name}
          />
        )}

        {showNotFoundError && <MessageShowNotFound history={history} />}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  language: state.auth.userInfo.language,
  product: state.project.product,
  attributes: state.project.attributes,
  components: state.project.components,
  detailsMatrix: state.project.detailsMatrix,
  isLoading: state.project.isLoading,
  errors: state.project.errors,
  binaryMode: state.preferences.matrixBinaryMode,
});

export default connect(mapStateToProps, { getProjectInfo, setPreference })(DependencyMatrix);
