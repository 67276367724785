import React from "react";

import { ReactComponent as CellIcon } from "../../../assets/images/cells-icon-24px.svg";
import { getTranslation } from "../../../helpers/getLanguage";
import Tooltip from "../../common/Tooltip";
import BlockDiagram from "./diagrams/BlockDiagram";
import ProgressDiagram from "./diagrams/ProgressDiagram";

const blockLabels = ["BLOCK_LABEL_EXPLORED", "BLOCK_LABEL_UNEXPLORED"];

const DependenciesCard = ({ dependencies, history, projectId, projectType }) => {
  const blockValues = [dependencies.explored, dependencies.unexplored];

  const redirectToMatrix = () => {
    history.push(`/project/${projectId}/matrix`);
  };

  const isProcessType = projectType === "process";

  return (
    <div className="project-dashboard-card flex">
      {dependencies.total > 0 && (
        <div className="project-card-content flex-column">
          <div className="flex justify-space-between">
            <div
              className="project-dashboard-card-title-container flex align-center pointer"
              onClick={() => redirectToMatrix()}
            >
              <CellIcon style={{ color: "#8a93c3" }} />
              <Tooltip
                message={getTranslation("TOOLTIP_MESSAGE_ATTRIBUTE_DEPENDENCY")}
                containerClass="project-dashboard-card-title"
                innerText={getTranslation("TOOLTIP_INNER_TEXT_DEPENDENCIES")}
              />
            </div>

            <button className="dashboard-card-button medium flex dependency-button" onClick={() => redirectToMatrix()}>
              <span>{getTranslation("COMPLETE_MATRIX_BUTTON")}</span>
              <div className="button-triangle-icon"></div>
            </button>
          </div>

          <div className="dependency-card-content-container height-100">
            <div className="flex align-center justify-space-around">
              <div className="flex align-center">
                <ProgressDiagram dependencies={dependencies} redirectToMatrix={redirectToMatrix} />
                <Tooltip
                  message={getTranslation("TOOLTIP_MESSAGE_FORECASTING_MATRIX")}
                  containerClass="diagram-tooltip"
                />
              </div>
              <div className="card-right-diagram-container">
                <BlockDiagram isProcessType={isProcessType} values={blockValues} labels={blockLabels} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DependenciesCard;
