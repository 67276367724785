export const tabsByPage = [
  {
    name: "all-projects",
    label: "All projects",
  },
  {
    name: "matrix",
    label: "Matrix",
  },
  {
    name: "dashboard",
    label: "Dashboard",
  },
  {
    name: "replacement",
    label: "Replacement",
  },
  {
    name: "multiplication",
    label: "Multiplication",
  },
  {
    name: "components",
    label: "Components",
  },
  {
    name: "eureka",
    label: "Eureka",
  },
  {
    name: "subtraction",
    label: "Subtraction",
  },
  {
    name: "reports",
    label: "Reports",
  },
];
