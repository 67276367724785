import React from "react";

import OmnivatiLogo from "../../../assets/images/nav-logo-compact.svg";
import "./styles.css";

const BlueHeader = ({ headerText }) => (
  <div className="flex flex-row justify-between header">
    <div className="flex header-logo">
      <img src={OmnivatiLogo} alt="" />
      Omnivati
    </div>
    <div className="header-title">{headerText}</div>
    <div />
  </div>
);

export default BlueHeader;
