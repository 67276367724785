import axios from "axios";

import { baseApiUrl } from "../common/constants";
import { handleError, handleSuccess } from "./handlers";

const baseUrl = `${baseApiUrl}/folders`;

export const getProjectFolders = async (params, onSuccess, onError) => {
  axios
    .get(baseUrl, params)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const moveProjectToFolder = async ({ projectId, folderId }, onSuccess, onError) => {
  axios
    .post(`${baseUrl}/${projectId}/to/${folderId}`)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const moveProjectOutOfFolder = async ({ projectId, folderId }, onSuccess, onError) => {
  axios
    .delete(`${baseUrl}/${folderId}/remove/${projectId}`)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const renameFolder = async ({ newName, folderId }, onSuccess, onError) => {
  axios
    .put(`${baseUrl}/${folderId}`, { newName })
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const removeFolder = async ({ folderId }, onSuccess, onError) => {
  axios
    .delete(`${baseUrl}/${folderId}`)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const createFolder = async ({ folderName }, onSuccess, onError) => {
  axios
    .post(`${baseUrl}`, { folderName })
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const archiveFolder = async ({ folderId }, onSuccess, onError) => {
  axios
    .put(`${baseUrl}/archive/${folderId}`)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};
