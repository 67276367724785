import React from "react";

import { ReactComponent as AIGenerateIcon } from "../../../assets/images/ai-generate-icon.svg";
import CloseIcon from "../../../assets/images/close-icon.svg";
import { getTranslation } from "../../../helpers/getLanguage";
import CustomScrollbar from "../../common/CustomScrollbar";
import DotFlashing from "../../common/DotFlashing";
import Dialog from "../Dialog";
import "./styles.css";

const GenerateModalWindow = ({
  headerModalWindow,
  closeGenerateModal,
  suggestedOptions,
  suggestedLoading,
  handleClickButton,
  titleDisableButton,
  titleActionButton,
  selectGenerateOption,
  selectedGenerateOptions,
  textWhenNoGeneratedOption,
}) => {
  return (
    <Dialog>
      <div className="flex-column generate-modal-window">
        <div className="flex generate-modal-window-header">
          <div className="flex justify-between align-center">
            <AIGenerateIcon style={{ color: "#CC4B4F", width: 28, marginRight: "10px" }} />
            <h2>{headerModalWindow}</h2>
          </div>
          <img src={CloseIcon} alt="close modal" className="pointer" onClick={closeGenerateModal} />
        </div>

        <CustomScrollbar
          backgroundColor="#f5f5f5"
          scrollHandleColor="#C6CBED"
          isShowArrows
          width={5}
          className={`${suggestedLoading && "justify-content-center"}`}
          style={{ overflow: "hidden" }}
        >
          {suggestedLoading ? (
            <div className="flex-center height-100">
              <DotFlashing textLoader={getTranslation("OPEN_AI_LOADER_TEXT")} />
            </div>
          ) : (
            <div
              className={
                "flex-column align-flex-start generate-modal-window-list" +
                (!suggestedOptions.length ? " generate-modal-window-list__with-options" : "")
              }
            >
              {suggestedOptions.map((option, index) => (
                <div
                  key={index}
                  className={`generate-modal-window-list__option ${
                    selectedGenerateOptions.has(`${option}`) ? "selected" : ""
                  }`}
                  onClick={() => selectGenerateOption(option)}
                >
                  <p className="generate-modal-window-list__option-title">
                    {option.includes("：") ? option.split("：")[0] : option.split(":")[0]}
                  </p>
                  <p className="generate-modal-window-list__option-description">
                    {option.includes("：") ? option.split("：")[1] : option.split(":")[1]}
                  </p>
                </div>
              ))}

              {!suggestedOptions.length && (
                <div className="flex justify-center align-center width-100 height-100">
                  <span className="ai-generate-modal-window-message">{textWhenNoGeneratedOption}</span>
                </div>
              )}
            </div>
          )}
        </CustomScrollbar>

        <button
          className="generate-modal-window__button"
          disabled={!selectedGenerateOptions.size}
          onClick={() => handleClickButton(selectedGenerateOptions)}
        >
          {selectedGenerateOptions.size ? `+ ${titleActionButton}` : `+ ${titleDisableButton}`}
        </button>
      </div>
    </Dialog>
  );
};

export default GenerateModalWindow;
