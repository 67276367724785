import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { connect } from "react-redux";

import { createComponents as requestCreateComponents } from "../../../api/components";
import { updateOrder } from "../../../api/projects";
import { ReactComponent as AIGenerateIcon } from "../../../assets/images/ai-generate-icon.svg";
import CheckmarkIcon from "../../../assets/images/checkmark-red.svg";
import CloseIcon from "../../../assets/images/close-icon.svg";
import { ReactComponent as ProcessImage } from "../../../assets/images/process.svg";
import { ReactComponent as ProductImage } from "../../../assets/images/product.svg";
import { ReactComponent as ServiceImage } from "../../../assets/images/service.svg";
import { getTranslation } from "../../../helpers/getLanguage";
import CustomScrollbar from "../../common/CustomScrollbar";
import DotFlashing from "../../common/DotFlashing";
import Tooltip from "../../common/Tooltip";
import Dialog from "../../dialogs/Dialog";
import CustomCheckbox from "../../inputs/CustomCheckbox";
import CustomSearchInput from "../../inputs/CustomSearchInput";
import CustomSwitch from "../../inputs/CustomSwitch";
import "./styles.css";

const BodyComponents = ({
  isProcessTypeProject,
  suggestedComponents,
  currentComponents,
  getTooltipText,
  selectedItems,
  processSteps,
  selectItem,
  onDragEnd,
  onDragStart,
  selectGenerateSteps,
  isCheckedAllInternal,
  isCheckedAllExternal,
  isCheckedAllSteps,
  handleSelectAllSteps,
  handleSelectAll,
  processStepsBackup,
}) => {
  const selectStepsLabel = useMemo(
    () => getTranslation(currentComponents[0] ? "GENERATE_STEPS_SELECT_PLACE" : "GENERATE_STEPS_SELECT_DRAG"),
    [],
  );

  if (!isProcessTypeProject) {
    return (
      <div className="flex" style={{ height: 440 }}>
        {!suggestedComponents[0].length && !suggestedComponents[1].length && (
          <div className="flex justify-center align-center width-100">
            <span className="ai-generate-modal-window-message">
              {getTranslation("GENERATE_COMPONENTS_MODAL_TEXT_WHEN_NO_COMPONENTS")}
            </span>
          </div>
        )}

        {(suggestedComponents[0]?.at(0) || suggestedComponents[1]?.at(0)) &&
          suggestedComponents.map((components, isInternal) => (
            <div
              key={isInternal}
              className="generate-component-items"
              style={isInternal ? { marginLeft: 5 } : { paddingRight: 2 }}
            >
              <div className="generate-component-items-header">
                <div className="flex generate-items_component">
                  <div
                    className={`component-type generate-component-items_component flex-center ${
                      isInternal ? "external" : ""
                    }`}
                  >
                    <Tooltip
                      message={getTooltipText(isInternal)}
                      innerText={getTranslation(
                        isInternal
                          ? "PAGE_COMPONENT_COMPONENT_TYPE_EXTERNAL"
                          : "PAGE_COMPONENT_COMPONENT_TYPE_INTERNAL",
                      )}
                      containerClass="tooltip-inner-text generate"
                    />
                  </div>
                </div>
                {isInternal ? (
                  <div
                    className={`generate-component-items-header_select-all generate-component-items-header_select-all__red ${!suggestedComponents[1].length && "disabled"} pointer`}
                    onClick={() => handleSelectAll(components, isInternal)}
                  >
                    <div className="generate-component-items-header_checkbox">
                      <CustomCheckbox
                        checked={isCheckedAllExternal}
                        handleChange={() => handleSelectAll(components, isInternal)}
                        customActivClass="custom-class-checkbox"
                        checkboxIcon={CheckmarkIcon}
                        disabled={!suggestedComponents[1].length}
                      />
                      <label
                        className={`generate-component-items-header_label ${!suggestedComponents[1].length && "disabled"} generate-component-items-header_label__red pointer`}
                      >
                        {getTranslation("GENERATE_COMPONENT_MODAL_LABEL_BUTTON")}
                      </label>
                    </div>
                  </div>
                ) : (
                  <div
                    className={`generate-component-items-header_select-all generate-component-items-header_select-all__blue ${!suggestedComponents[0].length && "disabled"} pointer`}
                    onClick={() => handleSelectAll(components, isInternal)}
                  >
                    <div className="generate-component-items-header_checkbox">
                      <CustomCheckbox
                        checked={isCheckedAllInternal}
                        handleChange={() => handleSelectAll(components, isInternal)}
                        disabled={!suggestedComponents[0].length}
                      />
                      <label
                        className={`generate-component-items-header_label ${!suggestedComponents[0].length && "disabled"} generate-component-items-header_label__blue pointer`}
                      >
                        {getTranslation("GENERATE_COMPONENT_MODAL_LABEL_BUTTON")}
                      </label>
                    </div>
                  </div>
                )}
              </div>
              <CustomScrollbar
                heightScroll={170}
                backgroundColor="#f5f5f5"
                isShowArrows
                width={3}
                style={{ marginTop: 10, height: "77%", marginRight: 5 }}
                className={`suggested-scroll-container justify-content-center`}
              >
                {!components.length && (
                  <div className="flex justify-center align-center width-100 height-100">
                    <span className="ai-generate-modal-window-message">
                      {getTranslation("GENERATE_COMPONENTS_MODAL_TEXT_WHEN_NO_COMPONENTS")}
                    </span>
                  </div>
                )}

                <div className="flex flex-wrap">
                  {components?.map((name, index) => (
                    <span
                      className={`suggest-item ${selectedItems.has(`${name}_${isInternal}`) ? "border-item" : ""}`}
                      key={`${index}_${isInternal}`}
                      onClick={() => selectItem(name, isInternal)}
                    >
                      <Tooltip
                        containerClass="generate-component-items_tooltip ellipsed-text"
                        position="top"
                        innerTextClass=""
                        message={name}
                        innerText={name}
                        tooltipMessageClass="tooltip-message white"
                      />
                    </span>
                  ))}
                  {components?.length % 2 === 1 && <div style={{ width: 256 }} />}
                </div>
              </CustomScrollbar>
            </div>
          ))}
      </div>
    );
  }

  return (
    <div className="flex flex-column align-center">
      <div className="flex align-center">
        <span className="font-size-20">{selectStepsLabel}</span>
        <div
          className={`generate-component-items-header_select-all generate-component-items-header_select-all__steps ${!processStepsBackup?.length && "disabled"} pointer`}
          onClick={handleSelectAllSteps}
        >
          <div className="generate-component-items-header_checkbox">
            <CustomCheckbox
              checked={isCheckedAllSteps}
              handleChange={handleSelectAllSteps}
              disabled={!processStepsBackup?.length}
            />
            <label
              className={`generate-component-items-header_label ${!processStepsBackup?.length && "disabled"} generate-component-items-header_label__blue pointer`}
            >
              {getTranslation("GENERATE_COMPONENT_MODAL_LABEL_BUTTON")}
            </label>
          </div>
        </div>
      </div>
      <div className="flex" style={{ height: 1000 }}>
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
          {[
            { steps: processSteps, droppableId: "generate" },
            { steps: currentComponents, droppableId: "usual" },
          ].map(({ droppableId, steps }, index) => (
            <div key={index + "generate"} className={`scrollbar-steps ${droppableId === "usual" && `background-grey`}`}>
              <CustomScrollbar
                key={`${index}-steps`}
                style={{
                  width: 399,
                  backgroundColor: droppableId === "usual" ? "#eceef5" : "white",
                }}
                className={`flex flex-column mt-10`}
              >
                {!processStepsBackup?.length && droppableId === "generate" && (
                  <div className="flex justify-center align-center width-100 height-100">
                    <span className="ai-generate-modal-window-message">
                      {getTranslation("GENERATE_STEPS_MODAL_TEXT_WHEN_NO_STEPS")}
                    </span>
                  </div>
                )}
                <Droppable droppableId={droppableId}>
                  {(provide) => (
                    <div
                      ref={provide.innerRef}
                      {...provide.droppableProps}
                      className="flex flex-column align-center steps-container"
                    >
                      {steps?.map(({ name, id }, index) => (
                        <Draggable draggableId={id} index={index} key={id}>
                          {(provided, snapshot) => {
                            if (snapshot.isDragging) {
                              const topModalWindow = document
                                .getElementById("draggable-container")
                                .getBoundingClientRect().top;

                              provided.draggableProps.style = {
                                ...provided.draggableProps.style,
                                left: null,
                                top: provided.draggableProps.style.top - topModalWindow,
                              };
                            }

                            return (
                              <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                <div
                                  className={`flex flex-column align-flex-start justify-center step-item ${
                                    id.includes("generate") &&
                                    (droppableId !== "generate" || selectGenerateSteps === id) &&
                                    "border-generate-steps"
                                  } ${!id.includes("generate") && "border-current-steps"}`}
                                >
                                  <Tooltip
                                    containerClass="scrollbar-steps-tooltip"
                                    position="top"
                                    innerTextClass=""
                                    message={name}
                                    innerText={name}
                                    tooltipMessageClass="tooltip-message white"
                                  />
                                </div>
                              </div>
                            );
                          }}
                        </Draggable>
                      ))}
                      {provide.placeholder}
                    </div>
                  )}
                </Droppable>
              </CustomScrollbar>
            </div>
          ))}
        </DragDropContext>
      </div>
    </div>
  );
};

const GenerateComponentsModal = ({
  setLastWordsToGeneratedOpenAIComponents,
  handleSuggestingComponents,
  lastGeneratedWordsOpenAI,
  handleCreateSuggested,
  handleSuggestingSteps,
  setCurrentComponents,
  suggestedComponents,
  suggestedLoading,
  projectTypeName,
  setStepsOpenAI,
  closeDialog,
  projectType,
  productId,
  onSuccess,
  teamId,
  componentsCount,
  labelsPerson,
  isCustomerState,
  handleChangeSwitch,
  currentComponentsBackup,
  processStepsBackup,
  currentComponents,
  processSteps,
  changeProcessSteps,
  changeCurrentComponents,
  language,
}) => {
  const [fieldErrors, setFieldErrors] = useState({ lastGeneratedWordsOpenAI: "" });
  const [selectedItems, setSelectedItems] = useState(new Set());
  const [isCustomerValue, setIsCustomerValue] = useState(isCustomerState);
  const [selectGenerateSteps, setSelectGenerateSteps] = useState("");
  const [isDragEnd, setIsDragEnd] = useState(false);
  const [initialComponents, setInitialComponents] = useState([]);
  const [isCheckedAllInternal, setIsCheckedAllInternal] = useState(false);
  const [isCheckedAllExternal, setIsCheckedAllExternal] = useState(false);
  const [isCheckedAllSteps, setIsCheckedAllSteps] = useState(false);

  const countSelectedAttributes = selectedItems.size;
  const isProcessTypeProject = useMemo(() => projectType === "process" || projectType === "other", [projectType]);

  const CurrentProjectImage = useMemo(() => {
    switch (projectType) {
      case "product":
        return ProductImage;
      case "service":
        return ServiceImage;
      case "process":
      default:
        return ProcessImage;
    }
  }, [projectType]);

  const currentTypeProduct = useMemo(() => {
    switch (projectType) {
      case "product":
        return getTranslation("GENERATE_COMPONENTS_MODAL_SEARCH_TYPE_PRODUCT");
      case "service":
        return getTranslation("GENERATE_COMPONENTS_MODAL_SEARCH_TYPE_SERVICE");
      case "process":
      default:
        return getTranslation("GENERATE_COMPONENTS_MODAL_SEARCH_TYPE_PROCESS");
    }
  }, [projectType]);

  useEffect(() => {
    projectTypeName && setLastWordsToGeneratedOpenAIComponents(projectTypeName);

    getGeneratedComponents();

    if (!initialComponents?.length) {
      setInitialComponents(currentComponents);
    }

    currentComponents.sort((a, b) => a.sortOrder - b.sortOrder);
  }, []);

  const getGeneratedComponents = () => {
    isProcessTypeProject
      ? handleSuggestingSteps(null, isCustomerValue, language)
      : handleSuggestingComponents(language);
    setFieldErrors({ lastGeneratedWordsOpenAI: "" });
    setSelectedItems(new Set());
  };

  const handleSuggestedNameChange = useCallback(
    (e) => {
      if (!e.target.value) {
        setFieldErrors({ lastGeneratedWordsOpenAI: "Should not be empty" });
      } else {
        setFieldErrors({ lastGeneratedWordsOpenAI: "" });
      }

      setLastWordsToGeneratedOpenAIComponents(e.target.value);
    },
    [setFieldErrors, setLastWordsToGeneratedOpenAIComponents],
  );

  const handleButtonPress = useCallback(() => {
    if (!lastGeneratedWordsOpenAI) {
      return setFieldErrors({ lastGeneratedWordsOpenAI: "Should not be empty" });
    }

    getGeneratedComponents();
  }, [lastGeneratedWordsOpenAI, setFieldErrors, handleSuggestingComponents, handleSuggestingSteps, isCustomerValue]);

  const selectItem = (name, external) => {
    setSelectedItems((prev) => {
      const prevSelectedItem = new Set(prev);
      const key = `${name}_${external}`;

      prev.has(key) ? prevSelectedItem.delete(key) : prevSelectedItem.add(key);

      return prevSelectedItem;
    });
  };

  const selectAllItems = (name, external, isCheckedAllComponents) => {
    setSelectedItems((prev) => {
      const prevSelectedItem = new Set(prev);
      const key = `${name}_${external}`;

      isCheckedAllComponents
        ? prev.has(key) && prevSelectedItem.delete(key)
        : !prev.has(key) && prevSelectedItem.add(key);

      return prevSelectedItem;
    });
  };

  const getTooltipText = (attribute) => {
    if (attribute) return getTranslation("TOOLTIP_MESSAGE_EXTERNAL_ATTRIBUTE");

    return getTranslation("TOOLTIP_MESSAGE_INTERNAL_ATTRIBUTE");
  };

  const createComponent = () => {
    const components = [];

    suggestedComponents.map((item, external) =>
      item.map((name) => {
        if (name.trim() && selectedItems.has(`${name}_${external}`)) {
          const params = {
            productId: productId,
            name: name.trim(),
            importance: false,
            internal: !external,
            description: "",
            disabled: false,
            teamId: teamId || "",
          };
          handleCreateSuggested(name);
          components.push(params);
        }
      }),
    );

    requestCreateComponents(
      {
        productId,
        teamId,
        components,
      },
      onSuccess,
    );

    closeDialog();
  };

  const createSteps = () => {
    const components = [];

    currentComponents.forEach(({ name }) => {
      if (name.trim() && selectedItems.has(`${name}_internal`)) {
        const params = {
          productId: productId,
          name: name.trim(),
          importance: false,
          internal: true,
          isCustomer: isCustomerValue,
          description: "",
          disabled: false,
          teamId: teamId || "",
        };

        handleCreateSuggested(name);
        components.push(params);
      }
    });
    const mapComponentsNames = currentComponents.map((item) => item.name);

    requestCreateComponents(
      {
        productId,
        teamId,
        components,
        mapComponentsNames,
      },
      onSuccess,
    );

    closeDialog();
  };

  const textAddButton = useMemo(
    () =>
      countSelectedAttributes
        ? `+ ${getTranslation("DIALOG_BUTTON_ADD")} ${countSelectedAttributes} ${getTranslation(
            isProcessTypeProject ? "STEPS_DIALOG_BUTTON" : "COMPONENTS_DIALOG_BUTTON",
          )}`
        : isDragEnd
          ? getTranslation("SAVE_CURRENT_STEP_ORDER")
          : isProcessTypeProject && !isDragEnd
            ? `+ ${getTranslation("SELECT_STEPS_DIALOG_BUTTON")}`
            : `+ ${getTranslation("SELECT_COMPONENT_DIALOG_BUTTON")}`,
    [countSelectedAttributes, isProcessTypeProject, isDragEnd],
  );

  const handleSwitchGenerate = (e) => {
    setIsCustomerValue(!isCustomerValue);
    handleChangeSwitch(e);
  };

  const onDragStart = useCallback((item) => {
    setSelectGenerateSteps(item.draggableId);
  }, []);

  const onDragEnd = useCallback(
    (result) => {
      const { source, destination } = result;
      const isGenerateDroppable = source.droppableId.includes("generate");
      setSelectGenerateSteps("");

      const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
      };

      if (!result.destination) {
        return;
      }
      const isSameComponent = destination.droppableId === source.droppableId;
      const isSameIndex = destination.index === source.index;
      if (isSameComponent && isSameIndex) {
        return;
      }

      if (!isSameComponent) {
        if (result.draggableId.includes("generate")) {
          const copyProcessSteps = JSON.parse(JSON.stringify(processSteps));
          const copyCurrentComponents = JSON.parse(JSON.stringify(currentComponents));

          if (isGenerateDroppable) {
            copyProcessSteps.splice(source.index, 1);
            copyCurrentComponents.splice(destination.index, 0, processSteps[source.index]);
          } else {
            copyCurrentComponents.splice(source.index, 1);
            copyProcessSteps.splice(destination.index, 0, currentComponents[source.index]);
          }

          selectItem(
            [...processSteps, ...currentComponents].find(({ id }) => id === result.draggableId).name,
            "internal",
          );
          changeProcessSteps(copyProcessSteps);
          changeCurrentComponents(copyCurrentComponents);

          const componentsOrder = copyCurrentComponents.map((component) => component.id);
          handleUpdateOrder(componentsOrder);
        }
      }

      if (isSameComponent && !isSameIndex) {
        const reorderedStepsIds = reorder(
          (isGenerateDroppable ? processSteps : currentComponents) || [],
          source.index,
          destination.index,
        );
        isGenerateDroppable ? setStepsOpenAI(reorderedStepsIds) : setCurrentComponents(reorderedStepsIds);

        const componentsOrder = reorderedStepsIds.map((component) => component.id);
        handleUpdateOrder(componentsOrder);
        setIsDragEnd(true);
      }
    },
    [processSteps, currentComponents],
  );

  const handleUpdateOrder = (componentsOrder) => {
    const params = {
      id: productId,
      componentsOrder,
      teamId,
    };

    updateOrder(params);
  };

  const saveOrder = () => {
    if (countSelectedAttributes) {
      createSteps();
    }

    closeDialog();
    onSuccess();
  };

  const closeModalDialog = () => {
    handleUpdateOrder(initialComponents.map((item) => item.id));
    closeDialog();
  };

  const handleSelectAll = (components, isInternal) => {
    if (isInternal && suggestedComponents[1].length) {
      setIsCheckedAllExternal(!isCheckedAllExternal);
    }

    if (!isInternal && suggestedComponents[0].length) {
      setIsCheckedAllInternal(!isCheckedAllInternal);
    }

    const isCheckedAllComponents = isInternal ? isCheckedAllExternal : isCheckedAllInternal;

    components.map((component) => {
      const newArr = selectAllItems(component, isInternal, isCheckedAllComponents);
      return newArr;
    });
  };

  const handleSelectAllSteps = () => {
    if (processStepsBackup.length) {
      setIsCheckedAllSteps(!isCheckedAllSteps);
    } else {
      return;
    }

    if (!isCheckedAllSteps) {
      const suggestedSteps = processSteps.map((step) => step.name);

      suggestedSteps.map((step) => {
        const selectedSteps = selectAllSteps(step, isCheckedAllSteps);
        return selectedSteps;
      });

      changeProcessSteps([]);
      changeCurrentComponents([...currentComponents, ...processSteps]);
    }

    if (isCheckedAllSteps) {
      setSelectedItems(new Set());
      changeProcessSteps(processStepsBackup);
      changeCurrentComponents(currentComponentsBackup);
    }
  };

  const selectAllSteps = (nameStep, isCheckedAll) => {
    setSelectedItems((prev) => {
      const prevSelectedItem = new Set(prev);
      const key = `${nameStep}_internal`;

      isCheckedAll ? prev.has(key) && prevSelectedItem.delete(key) : !prev.has(key) && prevSelectedItem.add(key);

      return prevSelectedItem;
    });
  };

  return (
    <Dialog draggable closeOnClickOutside={false}>
      <div className={`component-dialog-wrapper ${isProcessTypeProject && "component-dialog-generate-steps"}`}>
        <div className="components-dialog-header flex flex-column generate-modal-title">
          <div
            className="draggable-handle"
            id="draggable-container"
            style={{ position: "fixed", width: "100%", height: 30 }}
          ></div>
          <div className="suggested-component-modal-title-container flex align-start">
            <div>
              <AIGenerateIcon style={{ color: "#CC4B4F", height: 30, width: 30 }} />
              <Tooltip
                innerText={getTranslation(
                  isProcessTypeProject ? "GENERATE_STEPS_DIALOG_TITLE" : "GENERATE_COMPONENT_DIALOG_TITLE",
                )}
                innerTextClass="suggested-component-modal-title"
                containerClass="attribute-dialog-title"
              />
            </div>
            <img src={CloseIcon} alt="" style={{ zIndex: 5001 }} className="pointer" onClick={closeModalDialog} />
          </div>
          <div className="generate-project-type-search">
            <div className="flex align-center justify-center type-illustration">
              <CurrentProjectImage style={{ color: "#8A93C3", width: 24, height: 24 }} />
              <span>{currentTypeProduct}</span>
            </div>
            <CustomSearchInput
              containerClass="flex-column align-items-center  suggest-input-container generate-component-input"
              errorMessage={fieldErrors?.suggestedComponentName}
              inputClass="text-align-center border-color-blue"
              placeholder={getTranslation("ENTER_PRODUCT_OR_SERVICE_MODAL_PLACEHOLDER")}
              value={lastGeneratedWordsOpenAI}
              onChange={handleSuggestedNameChange}
              customInputClass="suggest-input"
              onClick={handleButtonPress}
              onKeyDown={(event) => {
                event.key === "Enter" && handleButtonPress();
              }}
              onBlur={() => {}}
              disabledButton={suggestedLoading.component}
              imageClassName="search-generate-image"
              name="password"
            />
          </div>
          <div className="flex align-center justify-center width-100">
            {isProcessTypeProject && (
              <>
                <div className="flex">
                  <p style={{ marginBottom: 0 }}>{getTranslation("CUSTOM_INPUT_LABEL_VANTAGE_POINT")}:</p>

                  <CustomSwitch
                    value={isCustomerValue}
                    setValue={(e) => handleSwitchGenerate(e)}
                    labels={labelsPerson}
                    invert
                    disabled={componentsCount && true}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className="suggest-component-dialog-body flex-column">
          {suggestedLoading.component ? (
            <div className="flex-center height-100">
              <DotFlashing textLoader={getTranslation("OPEN_AI_LOADER_TEXT")} />
            </div>
          ) : (
            <BodyComponents
              isProcessTypeProject={isProcessTypeProject}
              getTooltipText={getTooltipText}
              selectedItems={selectedItems}
              selectItem={selectItem}
              currentComponents={currentComponents}
              processSteps={processSteps}
              suggestedComponents={suggestedComponents}
              onDragStart={onDragStart}
              onDragEnd={onDragEnd}
              selectGenerateSteps={selectGenerateSteps}
              isCheckedAllInternal={isCheckedAllInternal}
              isCheckedAllExternal={isCheckedAllExternal}
              handleSelectAll={handleSelectAll}
              isCheckedAllSteps={isCheckedAllSteps}
              handleSelectAllSteps={handleSelectAllSteps}
              processStepsBackup={processStepsBackup}
            />
          )}
        </div>
        {!suggestedLoading.component &&
          (suggestedComponents[0]?.at(0) || suggestedComponents[1]?.at(0) || isProcessTypeProject) && (
            <div className="flex align-center justify-center add-component-container">
              <button
                disabled={!countSelectedAttributes && !isDragEnd}
                onClick={isDragEnd ? saveOrder : isProcessTypeProject ? createSteps : createComponent}
                style={isProcessTypeProject ? { marginTop: 120 } : {}}
                className="suggest-attribute-button flex align-center justify-center generate-component-button"
              >
                {textAddButton}
              </button>
            </div>
          )}
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  language: state.auth.userInfo.language,
});

export default connect(mapStateToProps)(React.memo(GenerateComponentsModal));
