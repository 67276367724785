import React from "react";

import { ReactComponent as CheckmarkIcon } from "../../../../assets/images/checkmark-circle.svg";
import CloseIcon from "../../../../assets/images/close-icon.svg";
import Initials from "../../../common/Initials";
import "./styles.css";

const UserOptionComponent = ({ option, setValue, isFirst, isLast, value }) => {
  const activeClass = isFirst ? " first" : "";
  const disabledClass = option.disabled ? " disabled" : "";

  const isActive = value.includes(option.value);

  return (
    <div
      className={"user-dropdown-option flex-column pointer" + activeClass + disabledClass}
      onClick={() => {
        setValue(option.value);
      }}
    >
      <div className="flex align-center user-dropdown-name">
        <Initials userFullName={option.label} />

        <span className="user-dropdown-label-text">{option.label}</span>

        {isActive && <CheckmarkIcon color="#3e6fd9" fill="white" />}
        {!isActive && <div className="user-round-button"></div>}
      </div>

      {!isLast && !isFirst && <hr className="user-dropdown-divider" />}
    </div>
  );
};

const UserLabel = ({ value, label, setValue }) => {
  const handleMouseDown = (e) => {
    e.stopPropagation();
    setValue(value);
  };

  return (
    <div className="flex users-option-label-container">
      <div className="users-option-label">
        <span>{label}</span>
      </div>
      <button className="flex-center remove-user-button" onMouseDown={handleMouseDown}>
        <img src={CloseIcon} alt="" />
      </button>
    </div>
  );
};

export const FirstUserOption = ({ options, setValue, value }) => {
  const labels = options
    .map((option, index) => {
      if (value.includes(option.value)) {
        return <UserLabel key={index} value={option.value} label={option.label} setValue={setValue} />;
      }

      return "";
    })
    .filter((option) => option);

  return (
    <div className="user-dropdown-option first flex-column pointer">
      <div className="flex flex-wrap users-labels-container">{labels}</div>
    </div>
  );
};

export default UserOptionComponent;
