import { getAllUsers as requestAllUsers } from "../api/users";
import { GET_ALL_USERS, GET_ALL_USERS_ERROR, GET_ALL_USERS_SUCCESS } from "./types";

const frontToBackAdapter = {
  subscription: "currentPeriodEnd",
  email: "email",
  fullName: "name",
  date: "date",
};

export const getAllUsers = (sort, sortDir, page, limit, search) => (dispatch) => {
  dispatch({ type: GET_ALL_USERS });

  const onSuccess = (response) => dispatch({ type: GET_ALL_USERS_SUCCESS, payload: response });
  const onError = (error) => dispatch({ type: GET_ALL_USERS_ERROR, payload: error });

  const params = {
    sort: frontToBackAdapter[sort],
    sortDir: sortDir ? -1 : 1,
    page: Math.max(page, 1),
    limit,
    search,
  };
  requestAllUsers({ params }, onSuccess, onError);
};
