import React from "react";

import { getTranslation } from "../../helpers/getLanguage";
import ViewTutorialButtonWithModal from "../common/ViewTutorialButtonWithModal";
import CustomDropdown from "../inputs/CustomDropdown";
import BenefitOption from "../inputs/CustomDropdown/BenefitOption";
import DependencyOption from "../inputs/CustomDropdown/DependencyOption";
import CustomSearchInput from "../inputs/CustomSearchInput";

const ideasDropdownOptions = [
  { value: "", label: "IDEAS_DROPDOWN_OPTIONS_ALL_DEPENDENCIES" },
  { value: 2, label: "IDEAS_DROPDOWN_OPTIONS_NO_POTENTIAL" },
  { value: 1, label: "IDEAS_DROPDOWN_OPTIONS_IN_PROGRESS" },
  { value: 3, label: "IDEAS_DROPDOWN_OPTIONS_FEASIBLE_IDEAS" },
];

const options = [
  { value: "", label: "OPTIONS_ALL_BENEFITS" },
  { value: "Consumer", label: "OPTIONS_CONSUMER_BENEFITS" },
  { value: "Company", label: "OPTIONS_COMPANY_BENEFITS" },
  { value: "Other", label: "OPTIONS_OTHER" },
];

const ReportHeader = ({
  filters,
  handleChange,
  setFilters,
  handlePrint,
  handleSharing,
  showPrintButton,
  showShareButton,
}) => {
  return (
    <div className="reports-page-inputs-container width-100 flex justify-space-between mb-20">
      <div className="flex-wrap justify-space-between width-100 report-page-inputs">
        <div className="flex-column mt-10">
          <ViewTutorialButtonWithModal name="Reports" className="mb-10" />
          <span className="report-page-title">{getTranslation("PAGE_REPORTS_TITLE")}</span>
        </div>

        <div className="flex-wrap align-flex-start report-page-inputs-settings">
          <div className="flex align-center">
            <span className="input-label">{getTranslation("FILTERED_BY")}</span>
            <CustomDropdown
              value={filters.filterBy}
              handleChange={(e) => setFilters({ ...filters, filterBy: e })}
              options={options}
              OptionComponent={BenefitOption}
            />
          </div>
          <div className="reports-input-container flex">
            <CustomDropdown
              value={filters.ideas}
              handleChange={(e) => setFilters({ ...filters, ideas: e })}
              options={ideasDropdownOptions}
              OptionComponent={DependencyOption}
            />
          </div>

          {showPrintButton && (
            <div className="reports-input-container">
              <button className="print-report-button medium flex" onClick={() => handlePrint()}>
                <span>{getTranslation("PRINT_REPORTS_BUTTON")}</span>
                <div className="button-triangle-icon"></div>
              </button>
            </div>
          )}

          {showShareButton && (
            <div className="reports-input-container">
              <button className="print-report-button medium flex" onClick={() => handleSharing()}>
                <span>{getTranslation("SHARE_IDEAS_BUTTON")}</span>
                <div className="button-triangle-icon"></div>
              </button>
            </div>
          )}

          <div className="reports-input-container">
            <CustomSearchInput
              name="search"
              placeholder={getTranslation("SEARCH_REPORT")}
              value={filters.search}
              onChange={handleChange}
              containerClass="search-input-container report"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportHeader;
