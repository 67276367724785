import axios from "axios";

import { baseApiUrl } from "../common/constants";
import { handleError, handleSuccess } from "./handlers";

const baseUrl = `${baseApiUrl}/params`;

export const getUrl = async (params, onSuccess, onError) => {
  axios
    .get(`${baseUrl}/tutorial-video`, params)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};
