import axios from "axios";

import { baseApiUrl } from "../common/constants";
import { handleError, handleSuccess } from "./handlers";

const baseUrl = `${baseApiUrl}/credits`;

export const getAllCreditsInfo = async (onSuccess, onError) => {
  axios
    .get(`${baseUrl}`)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const updateOneCreditInfo = async ({ id, price, amount }, onSuccess, onError) => {
  axios
    .patch(`${baseUrl}/${id}`, { price, amount })
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};
