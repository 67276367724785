import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";

import {
  archiveFolder,
  createFolder,
  getProjectFolders,
  getProjects,
  moveProjectOutOfFolder,
  moveProjectToFolder,
  removeFolder,
  renameFolder,
  resetProjectInfo,
} from "../../actions/projectActions";
import {
  acceptInvite,
  declineInvite,
  getInvitesInfo,
  joinInvite,
  getInvites as requestInvites,
} from "../../api/invites";
import { archiveProject, deleteProject, duplicateProject } from "../../api/projects";
import { parseLocationSearch } from "../../common/parseLocationSearch";
import { getTranslation } from "../../helpers/getLanguage";
import DashboardHeader from "../common/DashboardHeader";
import Loader from "../common/Loader";
import ConfirmationModal from "../dialogs/ConfirmationModal";
import ImportModal from "../dialogs/ImportModal";
// import InvitationModal from "../dialogs/InvitationModal";
import ProjectEditModal from "../dialogs/ProjectEditModal";
import SingleNumberInputDialog from "../dialogs/SingleNumberInputDialog";
import DashboardInputs from "./DashboardInputs";
import { projectFilters, projectSorting } from "./filters";
import InfoBoard from "./InfoBoard";
import "./styles.css";
import DashboardTabs from "./tabs";

const initialFiltersState = {
  sortBy: "created",
  filterBy: "",
  search: "",
  reverseSort: true,
};

const initialModalOpen = { project: false, delete: false, invite: false, import: false, duplicate: false };

const popupMessages = {
  project: "CONFIRM_MODAL_DELETE_MESSAGE",
};

const inviteStatus = {
  pending: "pending",
  accepted: "accepted",
};

const Dashboard = ({
  history,
  location,
  match,
  auth,
  getProjects,
  isLoading,
  projects: rawProjects,
  folders,
  getProjectFolders,
  resetProjectInfo,
  moveProjectToFolder,
  moveProjectOutOfFolder,
  renameFolder,
  removeFolder,
  archiveFolder,
  createFolder,
  projectType,
}) => {
  const { user, status: userStatus } = auth;
  const inviteId = match.params.inviteId;
  const [projects, setProjects] = useState([]);
  const [archivedProjects, setArchivedProjects] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [selectedProject, setSelectedProject] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [filters, setFilters] = useState(initialFiltersState);
  const [initialProjects, setInitialProjects] = useState([]);
  const [modalOpen, setModalOpen] = useState(initialModalOpen);
  const [invites, setInvites] = useState([]);
  const [inviteInfo, setInviteInfo] = useState({});
  const [inviteLoading, setInviteLoading] = useState(true);
  const [projectsReady, setProjectsReady] = useState(false);
  const [countDuplicate, setCountDuplicate] = useState(1);
  const isProjectArchived = useCallback(
    (project) => {
      return project.archivedUserIds?.includes(user.id);
    },
    [user.id],
  );

  useEffect(() => {
    resetProjectInfo();
  }, [resetProjectInfo]);

  useEffect(() => {
    let componentExists = true;
    getProjects();
    getProjectFolders();

    const onSuccess = (response) => componentExists && setInvites(response);
    const onError = (error) => {
      console.error(error);
      componentExists && setInvites([]);
    };

    requestInvites({}, onSuccess, onError);

    return () => {
      componentExists = false;
      setInitialProjects([]);
      setProjects([]);
      setArchivedProjects([]);
    };
  }, [getProjects]);

  useEffect(() => {
    if (inviteId) {
      setInviteLoading(true);
      const params = {
        id: inviteId,
      };

      const onSuccess = (response) => {
        const { status, projectId } = response;
        if (status === inviteStatus.pending) {
          getInviteInfo(inviteId);
          getInvites();
          setActiveTab(1);
          setModalOpen((modalOpen) => ({ ...modalOpen, invite: true }));
        } else {
          const accepted = status === inviteStatus.accepted && projectId;
          const redirectToReport = match.path === "/invite/:inviteId/report";
          if (redirectToReport) {
            history.push(`/project/${projectId}/report`);
          } else {
            const redirectUrl = accepted ? `/project/${projectId}` : "/";
            history.push(redirectUrl);
          }
        }
      };

      const onError = (error) => {
        setActiveTab(0);
        console.error((error.response.data && error.response.data.message) || error);
        history.push("/");
      };

      joinInvite(params, onSuccess, onError);
    }
    if (location.search) {
      const params = parseLocationSearch(location.search);
      if ("tab" in params) setActiveTab(Number(params.tab) || 0);
    }
  }, [inviteId, history, location.search, match.path]);

  const filterProjects = useCallback(
    (projects) => {
      setProjectsReady(false);
      if (projects.length) {
        if (filters.sortBy || filters.search || filters.filterBy) {
          let filteredProjects = projects.slice();

          if (filters.filterBy) {
            filteredProjects = projectFilters[filters.filterBy](filteredProjects, user.id);
          }

          if (filters.search) {
            filteredProjects = projectFilters.search(filteredProjects, filters.search);
          }
          filteredProjects = projectSorting[filters.sortBy](filteredProjects, filters.reverseSort);
          setProjects(filteredProjects.filter((project) => !isProjectArchived(project)));
          setArchivedProjects(filteredProjects.filter((project) => isProjectArchived(project)));
          setProjectsReady(true);
        } else {
          setProjects(projects.filter((project) => !isProjectArchived(project)));
          setArchivedProjects(projects.filter((project) => isProjectArchived(project)));
          setProjectsReady(true);
        }
      }
    },
    [filters, isProjectArchived, user.id],
  );

  useEffect(() => {
    filterProjects(initialProjects);
  }, [filters, initialProjects, filterProjects]);

  const getInvites = () => {
    const onSuccess = (response) => setInvites(response);
    const onError = (error) => {
      console.error(error);
      setInvites([]);
    };

    requestInvites({}, onSuccess, onError);
  };

  useEffect(() => {
    const initialProjects = rawProjects.map((project) => ({
      ...project.product,
      cellsCount: project.cellsCount,
    }));
    setInitialProjects(initialProjects);
  }, [rawProjects]);

  const handleInvitationClick = (invite, accepted) => {
    if (accepted) {
      getInviteInfo(invite.id);
      setModalOpen({ ...modalOpen, invite: true });
    } else {
      const params = { id: invite.id };

      const onSuccess = () => {
        getInvites();
        history.push("/");
      };

      const onError = (error) => {
        console.error(error);
        history.push("/");
      };

      declineInvite(params, onSuccess, onError);
    }
  };

  const navigateToProfileSubscription = () => {
    history.push("/profile?tab=1&modal=subscription");
  };

  const refreshInvites = useCallback(
    (project = {}) => {
      const path = project.id ? `/project/${project.id}` : "/";
      history.push(path);

      getProjects();
    },
    [history, getProjects],
  );

  const handleCloseModal = useCallback(() => {
    setEditMode(false);
    setSelectedProject({});
    setInviteInfo({});
    setModalOpen({ project: false, delete: false, invite: false });
  }, []);

  const handleModalOpen = (type, entity = null) => {
    setModalOpen({ ...modalOpen, [type]: true });
    if (entity) {
      setSelectedProject(entity);
      setEditMode(true);
    }
  };
  const handleDuplicate = (project) => {
    setSelectedProject(project);
    setModalOpen({ ...modalOpen, duplicate: true });
  };

  const handleDuplicateProject = () => {
    const params = {
      id: selectedProject.id,
      teamId: selectedProject.teamId || "",
      folderId: location?.pathname.includes("/folders/") ? location?.pathname.split("/folders/").pop() : undefined,
      count: countDuplicate,
    };

    duplicateProject(params, () => {
      getProjects();
      getProjectFolders();
    });

    setModalOpen({ ...modalOpen, duplicate: false });
    setCountDuplicate(1);
  };

  const handleDelete = (project) => {
    setSelectedProject(project);
    setModalOpen({ ...modalOpen, delete: true });
  };

  const handleDeleteProject = () => {
    const params = { id: selectedProject.id, teamId: selectedProject.teamId || "" };

    const onSuccess = () => {
      setProjects(projects.filter((project) => project.id !== selectedProject.id));
      handleCloseModal();
      getProjects();
    };

    deleteProject(params, onSuccess);
  };

  const handleArchive = (project, value) => {
    const params = { id: project.id, archived: value, teamId: project.teamId || "" };
    archiveProject(params, () => {
      getProjects();
      getProjectFolders();
    });
  };

  const handleImport = (project) => {
    handleModalOpen("import", project);
  };

  const getInviteInfo = (inviteId) => {
    setInviteLoading(true);
    const params = { id: inviteId };

    const onSuccess = (response) => {
      setInviteInfo({ ...response, id: inviteId });
      setInviteLoading(false);
    };

    getInvitesInfo({ params }, onSuccess);
  };

  const handleArchiveFolder = (folderId, handleSuccessAction) => {
    archiveFolder(folderId, () => {
      handleSuccessAction && handleSuccessAction();
      getProjectFolders();
      getProjects();
    });
  };

  const handleTeamSelect = useCallback(() => {
    if (!inviteInfo.id) {
      return;
    }

    const shouldHandleJoin = !inviteInfo.canWorkIndividually;

    const option = shouldHandleJoin && inviteInfo.teams?.length ? "join" : "create";
    const params = { id: inviteInfo.id };
    params[option] = true;

    if (option === "join") {
      params.teamId = inviteInfo.teams[0].id;
    }

    const onSuccessCallback = (response) => {
      refreshInvites(response);
    };

    const onErrorCallback = () => {
      handleCloseModal();
    };

    acceptInvite(params, onSuccessCallback, onErrorCallback);
  }, [handleCloseModal, inviteInfo, refreshInvites]);

  useEffect(() => {
    if (modalOpen.invite && !inviteLoading) {
      handleTeamSelect("create");
    }
  }, [modalOpen, inviteLoading, handleTeamSelect]);

  return (
    <div>
      <div className="dashboard-wrapper">
        {isLoading && <Loader />}

        {!isLoading && (
          <>
            <div className="dashboard-header-container">
              <DashboardHeader />
              {userStatus.roles.length > 0 && !userStatus.premium && (
                <InfoBoard navigateToProfileSubscription={navigateToProfileSubscription} userName={user.name} />
              )}

              <DashboardInputs
                projectsCount={initialProjects.length}
                projectModalOpen={modalOpen.project}
                setModalOpen={setModalOpen}
                filters={filters}
                setFilters={setFilters}
                userStatus={userStatus}
                createFolder={createFolder}
                history={history}
                folders={folders}
              />
            </div>

            <DashboardTabs
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              projects={projects}
              archivedProjects={archivedProjects}
              invitations={invites}
              projectModalOpen={modalOpen.project}
              setProjectModalOpen={() => setModalOpen({ ...modalOpen, project: !modalOpen.project })}
              handleInvitationClick={handleInvitationClick}
              history={history}
              filters={filters}
              handleImport={handleImport}
              handleModalOpen={handleModalOpen}
              handleDuplicate={handleDuplicate}
              handleDelete={handleDelete}
              handleArchive={handleArchive}
              isProjectArchived={isProjectArchived}
              userStatus={userStatus}
              userId={user.id}
              userFeatures={userStatus.features || []}
              projectsReady={projectsReady}
              archiveFolder={handleArchiveFolder}
              {...{
                folders,
                moveProjectToFolder,
                moveProjectOutOfFolder,
                renameFolder,
                removeFolder,
                match,
              }}
            />
          </>
        )}

        {modalOpen.project && (
          <ProjectEditModal
            isProcessType={projectType === "process"}
            setProjects={setProjects}
            edit={editMode}
            closeDialog={handleCloseModal}
            currentProject={selectedProject}
            onSuccess={getProjects}
            {...{ match, moveProjectToFolder }}
          />
        )}

        {modalOpen.import && (
          <ImportModal
            edit={editMode}
            closeDialog={handleCloseModal}
            currentProject={selectedProject}
            getProjects={getProjects}
          />
        )}

        {modalOpen.delete && (
          <ConfirmationModal
            closeDialog={handleCloseModal}
            message={popupMessages.project}
            autoFocus={true}
            buttonText="CONFIRM_MODAL_DELETE_BUTTON_TEXT"
            onConfirm={handleDeleteProject}
          />
        )}

        {modalOpen.duplicate && (
          <SingleNumberInputDialog
            minValue={1}
            maxValue={50}
            onClick={handleDuplicateProject}
            handleCloseModal={() => {
              handleCloseModal();
              setCountDuplicate(1);
            }}
            titleText={getTranslation("FOLDER_DIALOG_DUPLICATE")}
            buttonText={getTranslation("MENU_ITEM_EDIT_DUPLICATE")}
            value={countDuplicate}
            onChange={setCountDuplicate}
            z
          />
        )}

        {/* Eli said not remove the modal but archive */}
        {/* {modalOpen.invite && !inviteLoading && (
          <InvitationModal closeDialog={handleCloseModal} inviteInfo={inviteInfo} onSuccess={refreshInvites} />
        )} */}
      </div>
    </div>
  );
};

Dashboard.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  projects: state.project.projects,
  folders: state.project.folders,
  isLoading: state.project.projectsLoading,
  projectType: state.project.product.type,
});

const mapDispatchToProps = {
  getProjects,
  resetProjectInfo,
  getProjectFolders,
  moveProjectToFolder,
  moveProjectOutOfFolder,
  renameFolder,
  removeFolder,
  createFolder,
  archiveFolder,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
