import React from "react";

import InvitationCard from "./InvitationCard";
import "./styles.css";

const Invitations = ({ invitations, handleInvitationClick, userStatus }) => {
  const invitationCards = invitations.map((invitation, index) => (
    <InvitationCard
      key={index}
      invitation={invitation}
      handleInvitationClick={handleInvitationClick}
      disabled={!userStatus.premium}
    />
  ));

  return <div className="flex-wrap">{invitations.length > 0 && invitationCards}</div>;
};

export default Invitations;
