import classNames from "classnames";
import React, { useMemo, useState } from "react";

import { ReactComponent as AISuggestIcon } from "../../../assets/images/attributes-generate-image.svg";
import { ReactComponent as CloseIcon } from "../../../assets/images/close-icon.svg";
import { getTranslation } from "../../../helpers/getLanguage";
import Dialog from "../../dialogs/Dialog";
import styles from "./WarningNotTokensModal.module.css";

const defaultButtonClasses = [
  "details-button",
  "dark-blue-btn",
  "flex",
  "align-center",
  "justify-center",
  styles["default-button"],
];

const flexCenterClasses = ["justify-center", "flex-column", "align-center", "width-100", "flex"];

const WarningNotTokensModal = ({
  closeDialog,
  closeOnClickOutside,
  closeOnEscape,
  openBuyModal,
  isEnoghTokensForImage = true,
}) => {
  const buttons = useMemo(
    () => [
      {
        value: "BUY_MORE_CREDITS_BUTTON",
        onClick: () => {
          closeDialog();
          openBuyModal();
        },
        className: classNames(...defaultButtonClasses, styles["buy-button"]),
      },
      {
        value: "PURCHASE_LATE_BUTTON",
        onClick: closeDialog,
        className: classNames(...defaultButtonClasses, styles["purchase-button"]),
      },
    ],
    [],
  );

  return (
    <Dialog closeDialog={closeDialog} closeOnClickOutside={closeOnClickOutside} closeOnEscape={closeOnEscape}>
      <div className={styles.dialog}>
        <div className={classNames(styles.header, "generate-modal-title", "justify-between", "align-center", "flex")}>
          <div className={classNames(styles["left-header"], "align-center", "flex")}>
            <AISuggestIcon className={styles["icon-generate"]} />
            <span className={styles["title-header"]}>{getTranslation("AI_CREDITS_SPAN")}</span>
          </div>
          <div className={styles["close-icon"]}>
            <CloseIcon onClick={closeDialog} />
          </div>
        </div>
        <div className={classNames(...flexCenterClasses)}>
          <span className={styles["body-label"]}>
            {getTranslation(
              isEnoghTokensForImage
                ? "YOU_CURRENTLY_HAVE_NO_AI_CREDITS_LABEL"
                : "YOU_CURRENTLY_HAVE_NO_AI_CREDITS_FOR_IMAGE_LABEL",
            )}
          </span>
          <div className={classNames(...flexCenterClasses)}>
            {buttons.map(({ value, className, onClick }) => (
              <button key={value} onClick={onClick} className={className}>
                {getTranslation(value).toUpperCase()}
              </button>
            ))}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default WarningNotTokensModal;
