import axios from "axios";

import { baseApiUrl } from "../common/constants";
import { handleError, handleSuccess } from "./handlers";

const baseUrl = `${baseApiUrl}/ideas/multiplications`;

export const createIdea = async (params, onSuccess, onError) => {
  axios
    .post(`${baseUrl}/create`, params)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const updateIdea = async (params, onSuccess, onError) => {
  axios
    .post(`${baseUrl}/update`, params)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const getMessagesByIssuesIds = async (params, onSuccess, onError) => {
  axios
    .post(`${baseUrl}/messages`, params)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const createIssuesWithMessages = async (params, onSuccess, onError) => {
  axios
    .post(`${baseUrl}/issues`, params)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};
