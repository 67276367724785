import React from "react";

import CloseIcon from "../../../assets/images/close-icon.svg";
import CustomDropdown from "../../inputs/CustomDropdown";
import Dialog from "../Dialog";
import "./styles.css";

export default function SingleDropdownDialog({
  onClick,
  handleCloseModal,
  titleText,
  buttonText,
  options = [{ value: "Test", label: "TEST_VALUE" }],
  value = "Test",
  onChange,
  height,
}) {
  return (
    <Dialog>
      <div className="information-modal flex-column overflow-visible">
        <div className="flex align-center justify-space-between width-100">
          <span className="manage-user-label">{titleText}</span>
          <img src={CloseIcon} alt="" className="pointer" onClick={handleCloseModal} />
        </div>

        <div className="flex-center">
          <div className="information-modal-button-contener flex-column align-center ">
            <div className="folder-input-container">
              <CustomDropdown
                value={value}
                handleChange={onChange}
                options={options}
                className="force-width-100"
                dropdownClassName="force-width-100"
                height={height}
              />
            </div>
            <button className="information-modal-button width-100" onClick={onClick}>
              <span>{buttonText}</span>
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
