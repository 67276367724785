import classNames from "classnames";
import React, { useMemo } from "react";

import { getTranslation } from "../../../helpers/getLanguage";
import styles from "./Profile.module.css";

const defaultTextClasses = [styles["text-color"], "text-align-start"];
const buttonClasses = [
  "dashboard-project-button",
  "team-button",
  "flex",
  "align-center",
  "justify-space-between",
  styles["default-button"],
];

const Profile = ({ user, openEditNameModal, openChangePasswordModal }) => {
  const profileInfo = useMemo(
    () => [
      {
        title: "CUSTOM_INPUT_LABEL_NAME",
        info: `${user.name} ${user.lastName}`,
        titleClass: classNames(...defaultTextClasses, styles["name-title"]),
        infoClass: classNames(...defaultTextClasses, styles["name-info"]),
      },
      {
        title: "CUSTOM_INPUT_EMAIL_ADDRESS",
        info: user.email,
        titleClass: classNames(...defaultTextClasses, styles["email-title"]),
        infoClass: classNames(...defaultTextClasses, styles["email-info"]),
      },
    ],
    [user.name, user.lastName, user.email],
  );

  const buttonsInfo = useMemo(
    () => [
      {
        label: "EDIT_PROFILE_LABEL",
        className: classNames(...buttonClasses, styles["edit-profile-button"]),
        onClick: openEditNameModal,
      },
      {
        label: "RESET_PASSWORD_LABEL",
        className: classNames(...buttonClasses, styles["reset-password-button"]),
        onClick: openChangePasswordModal,
      },
    ],
    [openEditNameModal, openChangePasswordModal],
  );

  return (
    <div className={classNames("flex", "flex-column", "align-flex-start", styles.profile)}>
      <div>
        <span className={styles.title}>{getTranslation("NAV_MENU_PROFILE")}</span>
      </div>
      {profileInfo.map(({ title, info, titleClass, infoClass }, index) => (
        <div key={title + index + titleClass} className={classNames("flex", "flex-column")}>
          <span key={title + index} className={titleClass}>
            {getTranslation(title)}
          </span>
          <span key={title} className={infoClass}>
            {info}
          </span>
        </div>
      ))}
      <div className={classNames("flex", "flex-column", "align-items-start", styles.buttons)}>
        {buttonsInfo.map(({ label, className, onClick }, index) => (
          <button key={label + index} className={className} onClick={onClick}>
            {getTranslation(label)}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Profile;
