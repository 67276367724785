import classNames from "classnames";
import React, { useCallback, useEffect, useState } from "react";

import { getAllCreditsInfo } from "../../../api/credits";
import { getActualPlans } from "../../../api/plans";
import { activatePromoCode } from "../../../api/promoCodes";
import { createPortalSession } from "../../../api/stripe";
import { getTranslatedErrorMessage, getTranslation } from "../../../helpers/getLanguage";
import Loader from "../../common/Loader";
import BuyMoreAICreditsModal from "../../dialogs/BuyMoreAICreditsModal";
import CancelConformationSubscriptionModal from "../../dialogs/CancelConformationSubscriptionModal";
import CheckoutModal from "../../dialogs/CheckoutModal";
import ConfirmationModal from "../../dialogs/ConfirmationModal";
import Dialog from "../../dialogs/Dialog";
import CustomInput from "../../inputs/CustomInput";
import styles from "./ManageSubscription.module.css";

const MONTH_INTERVAL = "month";
const YEAR_INTERVAL = "year";

const LEFT_SIDE = "left";
const RIGHT_SIDE = "right";

const initialInputValues = {
  quantity: 1,
  interval: YEAR_INTERVAL,
  intervalCount: 1,
  total: 0,
  promoCode: "",
  promoCodeOpenAI: "",
  key: "",
  promoCode: "",
  planId: null,
};

const getBillingInfo = ({
  amount_paid,
  planPrice,
  upcomingPrice,
  interval,
  currentPeriodEnd,
  intervalCount,
  isPromocode,
}) => {
  const isValidIntervalCount = (intervalCount ?? 1) === 1;
  const displayNumber = upcomingPrice ? upcomingPrice / 100 : planPrice ? planPrice : amount_paid / 100 || 0;
  const label1 = isPromocode ? "COUPON_SUBSCRIPTION" : "SUBSCRIPTION_PRICE";
  const label2 = isPromocode ? "CODE_DURATION" : "SUBSCRIPTION_BILLING_PERIOD";
  const label3 = isPromocode ? "EXPIRATION_DATE" : "SUBSCRIPTION";

  return [
    {
      label: label1,
      info: `$${displayNumber}/${isValidIntervalCount ? "" : intervalCount}${interval[0]}`,
    },
    {
      label: label2,
      info: `${intervalCount} ${interval}${intervalCount > 1 ? "s" : ""}`,
    },
    {
      label: label3,
      info: new Date(currentPeriodEnd).toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      }),
    },
  ];
};

const selectButtonsStyle = (interval, side) => {
  const isLeft = side === LEFT_SIDE;
  const isRight = side === RIGHT_SIDE;

  const isYear = interval === YEAR_INTERVAL;
  const isMonth = interval === MONTH_INTERVAL;

  const isSelect = (isYear && isLeft) || (isMonth && isRight);

  if (isSelect)
    return [
      styles["bulling-button-dark-blue"],
      isRight ? styles["billing-left-button"] : styles["billing-right-button"],
      "flex",
      "justify-center",
      "align-center",
    ];

  return [
    "dashboard-project-button",
    "team-button",
    "flex",
    "align-center",
    "justify-space-between",
    styles["bulling-button-blue"],
    isRight && styles["billing-left-blue"],
    isRight ? styles["billing-left-button"] : styles["billing-right-button"],
  ];
};

const OnePlan = ({ planId, price, type, description, setOpenBuyModal, tokensInfo, setFields, fields }) => {
  const [openOpenAISelect, setOpenOpenAISelect] = useState(false);

  useEffect(() => {
    window.addEventListener("click", closeOpenAISelect);

    return () => {
      window.removeEventListener("click", closeOpenAISelect);
    };
  });

  const closeOpenAISelect = (e) => {
    if (e.target?.id === "select-amount-active") return;
    if (
      e.target?.id === "select-amount-span-left" ||
      e.target?.id === "select-amount-span-right" ||
      e.target?.id === "select-amount-div" ||
      e.target?.id === "select-amount-arrow" ||
      e.target?.id === "select-amount-arrow-id"
    ) {
      return;
    }

    setOpenOpenAISelect(false);
  };

  return (
    <div key={planId} className={classNames(styles["plan"], "flex", "flex-column", "justify-between")}>
      <div className={classNames("flex", "flex-column")}>
        <span className={styles["plan-header"]}>{getTranslation("STANDARD_PLAN")}</span>
        <span className={classNames("flex", styles["plan-prev"])}>
          <b className={styles["plan-amount"]}>{`$${type === MONTH_INTERVAL ? price : price / 12}`}</b>
          <b className={styles["plan-period"]}>{`/${"month"}`}</b>
        </span>
        <span className={styles["plan-billed"]}>
          {getTranslation(type === MONTH_INTERVAL ? "BILLED_MONTHLY" : "BILLED_YEARLY")}
        </span>
        <button
          onClick={() => {
            setOpenBuyModal(true);
            setFields((prev) => ({ ...prev, interval: type, total: price, planId }));
          }}
          className={classNames("flex", "justify-center", "align-center", styles["plan-buy"])}
        >
          {getTranslation("BUY_PLAN")}
        </button>
        {fields.interval === MONTH_INTERVAL && (
          <a
            className={styles["cancel-subscription-link"]}
            onClick={() => setFields((prev) => ({ ...prev, interval: YEAR_INTERVAL }))}
          >
            {getTranslation("SAVE_WITH_ANNUAL_BILLING")}
          </a>
        )}
      </div>
      <div>
        <ul className={styles["plan-descriptions"]}>
          {description
            ?.filter((filteringItem) => filteringItem.message.trim())
            ?.map((item) => (
              <li className={styles["active-plan-item"]} key={item.id}>
                {getTranslation(item.message)}
              </li>
            ))}
        </ul>
        <div className={styles["plan-credits-buy"]}>
          <span className={styles["plan-credits-description"]}>{getTranslation("ADD_AI_CREDITS")}</span>
          <button
            className={classNames(
              openOpenAISelect ? styles["select-amount-active"] : styles["credits-buy"],
              "force-width-100",
              styles["select-amount"],
              "flex",
              "align-center",
              "justify-between",
            )}
            id="select-amount-active"
            onClick={() => setOpenOpenAISelect((prev) => !prev)}
          >
            {fields.credits?.key ? (
              <div id="select-amount-div" className={styles["select-credits-label"]}>
                <span id="select-amount-span-right">
                  {`${fields.credits?.requests} ${getTranslation("AI_TAB_TITLE")}`}
                </span>
                <span id="select-amount-span-left">{`$${fields.credits?.price}`}</span>
              </div>
            ) : (
              getTranslation("SELECT_AMOUNT")
            )}
            <div id="select-amount-arrow" className={styles["arrow-icon"]}>
              <div id="select-amount-arrow-id" className="custom-dropdown-triangle-icon"></div>
            </div>
          </button>
          {openOpenAISelect && (
            <div id="select-credits-block" className={styles["select-credits-block"]}>
              {[...tokensInfo, fields.credits?.key ? { amount: "", price: "", id: "" } : null]
                .filter((value) => Boolean(value))
                .map(({ amount: requests, price, id: key }) => (
                  <div
                    key={requests + price + key}
                    className={classNames("flex", "justify-between", styles["select-credits"])}
                    onClick={() => {
                      setFields((prev) => ({
                        ...prev,
                        credits: { requests, price, key },
                      }));
                      setOpenOpenAISelect(false);
                    }}
                  >
                    <span>
                      {requests ? `${requests} ${getTranslation("AI_TAB_TITLE")}` : getTranslation("NO_CREDITS")}
                    </span>
                    <span>{price ? `$${price}` : ""}</span>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const Plans = ({ setFields, fields, setOpenBuyModal, tokensInfo }) => {
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    getPlans();
  }, []);

  const getPlans = () => {
    const onSuccess = (actualPlans) => {
      setPlans(actualPlans);
    };

    const onError = (err) => {
      alert(err);
    };

    getActualPlans(onSuccess, onError);
  };

  return plans
    ?.filter((filteringPlan) => filteringPlan.type === fields.interval)
    ?.map(({ id: planId, price, type, description }) => (
      <OnePlan
        key={planId}
        planId={planId}
        price={price}
        type={type}
        description={description}
        setOpenBuyModal={setOpenBuyModal}
        tokensInfo={tokensInfo}
        setFields={setFields}
        fields={fields}
      />
    ));
};

const AiCredits = ({ setModalCreditsOpen, numberOpenAIRequests }) => {
  return (
    <div
      className={classNames(
        "flex",
        "flex-column",
        "justify-center",
        styles["border-ai-credits"],
        styles["padding-credits"],
        styles["mt-20"],
        styles["ml-10"],
        styles["padding-15"],
      )}
    >
      <span className={styles["credits-header"]}>{getTranslation("AI_CREDITS")}</span>
      <div className={classNames("flex", "flex-column")}>
        <span className={styles["credits"]}>{numberOpenAIRequests}</span>
        <span className={styles["credits-details"]}>{getTranslation("CREDITS_REMAINING")}</span>
      </div>
      <button onClick={() => setModalCreditsOpen(true)} className={classNames(styles["credits-buy"])}>
        {getTranslation("BUY_MORE_AI_CREDITS_BUTTON")}
      </button>
    </div>
  );
};

const ActivePlans = ({
  subscriptions,
  setOpenCancelModal,
  openBullingPage,
  numberOpenAIRequests,
  setModalCreditsOpen,
}) => {
  return subscriptions[0]
    ? subscriptions.map(
        (
          {
            amount_paid,
            interval,
            currentPeriodEnd,
            id,
            autoRenewal,
            plan,
            intervalCount,
            upcomingInvoice,
            promoCode,
            code,
            status,
          },
          index,
        ) => (
          <div className={classNames("flex")}>
            <div key={id} className={classNames(styles["subscription_block"])}>
              <span className={styles["header-active-subscription"]}>
                {status === "active"
                  ? getTranslation("MY_CURRENT_SUBSCRIPTION_LABEL")
                  : getTranslation("MY_FUTURE_SUBSCRIPTION_LABEL")}
              </span>
              <div className={classNames("flex", styles["body-active-subscription"])}>
                <div className={styles["left-body-active-subscription"]}>
                  <span className={styles["active-plan-header"]}>{getTranslation("PLAN_FEATURES")}</span>
                  <ul className={styles["active-plan-items"]}>
                    {plan?.description?.map(
                      ({ message, id }, index) =>
                        message?.trim() && (
                          <li key={id + index} className={styles["active-plan-item"]}>
                            {message}
                          </li>
                        ),
                    )}
                  </ul>
                </div>

                <div
                  className={classNames(styles["border-active-subscription"], "flex", "flex-column", "justify-between")}
                >
                  <div>
                    <span className={styles["active-plan-header"]}>{getTranslation("BILLING_PAYMENT")}</span>
                    <div className={styles["active-plan-body"]}>
                      {getBillingInfo({
                        amount_paid,
                        upcomingPrice: upcomingInvoice?.total,
                        planPrice: plan?.price,
                        interval,
                        currentPeriodEnd,
                        intervalCount,
                        isPromocode: Boolean(promoCode || code),
                      }).map(({ label, info }, index) => (
                        <div className={classNames("flex", "justify-between")} key={index + label}>
                          <span className={styles["billing-description"]}>{getTranslation(label)}</span>
                          <span className={styles["billing-description-right"]}>{info}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div>
                    <button
                      onClick={openBullingPage}
                      className={classNames(
                        styles["get-billing-details"],
                        "flex",
                        "justify-center",
                        "align-center",
                        !autoRenewal && styles["mb-32"],
                      )}
                    >
                      {getTranslation("SUBSCRIPTION_DETAILS_BUTTON")}
                    </button>
                    {autoRenewal && (
                      <a onClick={() => setOpenCancelModal(true)} className={styles["cancel-subscription-link"]}>
                        {getTranslation("CANCEL_MY_SUBSCRIPTION_LINK")}
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {index === 0 && (
              <AiCredits numberOpenAIRequests={numberOpenAIRequests} setModalCreditsOpen={setModalCreditsOpen} />
            )}
          </div>
        ),
      )
    : null;
};

const ManageSubscription = ({
  closeDialog,
  getUserRoles,
  history,
  language,
  setGeneratedOpenAIRequests,
  subscriptions,
  numberOpenAIRequests,
  getSubscriptionInfo,
  getUserProfile,
}) => {
  const [modalMessage, setModalMessage] = useState("");
  const [isPending, setIsPending] = useState(false);
  const [errorCode, setErrorCode] = useState("");
  const [mode, setMode] = useState("premium");
  const [fields, setFields] = useState(initialInputValues);
  const [promoCodeData, setPromoCodeData] = useState(null);

  const [modalCreditsOpen, setModalCreditsOpen] = useState(false);
  const [checkoutModalOpen, setCheckoutModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [openBuyModal, setOpenBuyModal] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [tokensInfo, setTokensInfo] = useState([]);

  useEffect(() => {
    getUserRoles();
    getTokens();
  }, []);

  useEffect(() => {
    getUserProfile();
  }, []);

  const getTokens = useCallback(() => {
    const onSuccess = (tokens) => {
      setTokensInfo(tokens);
    };

    const onError = (err) => {
      alert(err?.message ?? "");
    };

    getAllCreditsInfo(onSuccess, onError);
  }, []);

  const handleCloseDialog = () => {
    closeDialog && closeDialog();
  };

  const handleCheckoutClick = (interval, amount) => {
    if (promoCodeData) {
      const { totalLicenses, durationType, durationCount, price, discount, numberOpenAI } = promoCodeData;

      const data = {
        quantity: totalLicenses,
        interval: durationType,
        total: price * totalLicenses,
        intervalCount: durationCount,
        discountTotal: (price * totalLicenses * discount) / 100,
        discount,
        openAIRequests: numberOpenAI,
      };

      setMode(durationType ? "premium" : "openai");
      setFields({ ...fields, ...data });
      setCheckoutModalOpen(true);
    } else {
      setFields({
        ...fields,
        interval,
        total: amount,
      });
      setCheckoutModalOpen(true);
    }
  };

  const closeConfirmationModal = () => {
    if (errorCode && ["30306", "30307"].includes(errorCode)) {
      setModalOpen(false);
      handleCloseDialog();
      return;
    }

    if (promoCodeData?.requiresPayment) {
      setModalOpen(false);
      handleCheckoutClick();
      return;
    }

    handleCloseDialog();
    setModalOpen(false);
  };

  const handleApplyClick = (code = fields.promoCode) => {
    if (!code.trim()) {
      setModalMessage(getTranslation("EMPTY_CODE_ERROR"));
      setModalOpen(true);
      return;
    }

    const params = { code: code.trim() };

    const onSuccess = (response) => {
      if (response.numberOpenAI) setGeneratedOpenAIRequests(response.numberOpenAI);

      const { requiresPayment } = response;
      const message = requiresPayment ? getTranslation("PROCEED_WITH_PAYMENT") : getTranslation("CODE_ACTIVATED");
      setPromoCodeData(response);
      setModalMessage(message);
      getSubscriptionInfo();
      setErrorCode("");
      setModalOpen(true);
      setIsPending(false);
      !requiresPayment && getUserRoles();
    };

    const onError = (error) => {
      console.error(error);
      const { code, message } = (error.response && error.response.data) || {};
      const errorMessage = getTranslatedErrorMessage(message, code, language);

      setModalMessage(errorMessage || getTranslation("COULD_NOT_ACTIVATE_CODE_ERROR"));
      setErrorCode(code || "");
      setPromoCodeData(null);
      getSubscriptionInfo();
      setModalOpen(true);
      setIsPending(false);
    };

    setIsPending(true);
    activatePromoCode(params, onSuccess, onError);
  };

  const handleSubmitCodeOnEnter = (event) => {
    if (isPending || event.key !== "Enter") return;

    event.preventDefault();
    handleApplyClick();
  };

  const closeCheckoutModal = () => {
    setCheckoutModalOpen(false);
    handleCloseDialog();
  };

  const openBullingPage = () => {
    const onSuccess = ({ url }) => {
      if (url) window.location.replace(url);
    };

    const onError = (err) => {
      setModalOpen(true);
      setOpenCancelModal(false);
      setModalMessage(err.message);
      setIsPending(false);
    };

    setIsPending(true);
    createPortalSession(onSuccess, onError);
  };

  return (
    <>
      {isPending && (
        <Dialog isLoading={true}>
          <Loader />
        </Dialog>
      )}
      <div className={classNames("flex", "flex-column", "align-flex-start", styles["page"])}>
        <span className={styles.header}>{getTranslation("MANAGE_SUBSCRIPTION_TEXT")}</span>
        <div className={classNames("flex", styles["promo-code"], "align-center")}>
          <span>{getTranslation("HAVE_A_COUPON_CODE_TEXT")}</span>
          <CustomInput
            value={fields.promoCode || ""}
            placeholder={getTranslation("ENTER_CODE_PLACEHOLDER")}
            label={getTranslation("")}
            name="promoCode"
            customInputClass={styles["promo-code-field"]}
            containerClass="flex-column"
            onChange={(e) => setFields({ ...fields, promoCode: e.target.value })}
            onKeyDown={handleSubmitCodeOnEnter}
          />
          <button
            className={classNames(
              "dashboard-project-button",
              "team-button",
              "flex",
              "align-center",
              "justify-space-between",
              styles["promo-code-button"],
            )}
            disabled={isPending}
            onClick={() => handleApplyClick()}
          >
            {getTranslation("SUBMIT")}
          </button>
        </div>
        <ActivePlans
          subscriptions={subscriptions}
          setOpenCancelModal={setOpenCancelModal}
          openBullingPage={openBullingPage}
          numberOpenAIRequests={numberOpenAIRequests}
          setModalCreditsOpen={setModalCreditsOpen}
        />
        <div className={classNames("flex", styles["buy-subscription"])}>
          <button
            className={classNames(selectButtonsStyle(fields.interval, LEFT_SIDE))}
            onClick={() => setFields((prev) => ({ ...prev, interval: YEAR_INTERVAL }))}
          >
            {getTranslation("YEARLY_BILLING_BUTTON")}
          </button>
          <button
            className={classNames(selectButtonsStyle(fields.interval, RIGHT_SIDE))}
            onClick={() => setFields((prev) => ({ ...prev, interval: MONTH_INTERVAL }))}
          >
            {getTranslation("MONTHLY_BILLING_BUTTON")}
          </button>
        </div>
        <Plans setFields={setFields} fields={fields} setOpenBuyModal={setOpenBuyModal} tokensInfo={tokensInfo} />
      </div>

      {openCancelModal && (
        <CancelConformationSubscriptionModal
          date={new Date(subscriptions[0]?.currentPeriodEnd)?.toLocaleDateString("en-GB")}
          credits={numberOpenAIRequests}
          closeDialog={() => setOpenCancelModal(false)}
          closeOnEscape={() => {}}
          confirmDialog={openBullingPage}
        />
      )}

      {(checkoutModalOpen || fields.key) && (
        <CheckoutModal
          getSubscriptionInfo={getSubscriptionInfo}
          isOpenAISubscription={fields.key ? true : mode === "openai"}
          closeDialog={() => {
            closeCheckoutModal();
            setFields((prev) => ({ ...prev, key: "", promoCode: "" }));
          }}
          subscriptionInfo={fields}
          history={history}
          mode={fields.key ? "payment" : mode === "openai" ? "payment" : "checkout"}
          setGeneratedOpenAIRequests={setGeneratedOpenAIRequests}
          setDefaultFields={() => setFields(initialInputValues)}
        />
      )}

      {modalCreditsOpen && (
        <BuyMoreAICreditsModal setPaymentInfo={setFields} closeDialog={() => setModalCreditsOpen(false)} />
      )}

      {modalOpen && (
        <ConfirmationModal
          closeDialog={closeConfirmationModal}
          closeOnClickOutside={false}
          hideCloseIcon
          autoFocus={true}
          buttonText={getTranslation("CONFIRMATION_MODAL_DEFAULT_BUTTON_TEXT")}
          message={modalMessage}
          onConfirm={closeConfirmationModal}
        />
      )}

      {openBuyModal && (
        <BuyMoreAICreditsModal
          pageInfo={1}
          selectedTariffInfo={fields.credits?.key || ""}
          setPaymentInfo={() => {
            setCheckoutModalOpen(true);
          }}
          closeDialog={() => setOpenBuyModal(false)}
          subscriptionInfo={fields}
        />
      )}
    </>
  );
};

export default ManageSubscription;
