import React from "react";

import { ReactComponent as ComponentIcon } from "../../../assets/images/component-icon-big.svg";
import ComponentIconLarge from "../../../assets/images/components-icon-large.svg";
import { ReactComponent as StepIcon } from "../../../assets/images/steps.svg";
import { getTranslation } from "../../../helpers/getLanguage";
import Tooltip from "../../common/Tooltip";
import BlockDiagram from "./diagrams/BlockDiagram";
import DonutDiagram from "./diagrams/DonutDiagram";

const blockLabels = ["BLOCK_LABEL_INTERNAL", "BLOCK_LABEL_EXTERNAL"];
const tooltips = ["TOOLTIP_MESSAGE_INTERNAL_COMPONENT", "TOOLTIP_MESSAGE_EXTERNAL_COMPONENT"];

const ComponentsCard = ({ components, PlusIconSmall, navigateToComponents, projectType }) => {
  const internal = components.filter((component) => component.internal).length;
  const external = components.filter((component) => !component.internal).length;
  const diagramValues = [internal, external];

  return (
    <div className="project-dashboard-card flex">
      {!components.length && (
        <>
          <div className="project-card-content flex-column">
            <div
              className="project-dashboard-card-title-container flex align-center pointer"
              onClick={() => navigateToComponents()}
            >
              {projectType === "process" ? (
                <StepIcon style={{ color: "#8a93c3", height: "32", width: "32" }} />
              ) : (
                <ComponentIcon style={{ color: "#8a93c3" }} />
              )}

              <Tooltip
                message={getTranslation(
                  projectType === "process" ? "TOOLTIP_MESSAGE_STEP" : "TOOLTIP_MESSAGE_COMPONENT",
                )}
                containerClass="project-dashboard-card-title"
                innerText={getTranslation(
                  projectType === "process" ? "TOOLTIP_INNER_TEXT_STEPS" : "TOOLTIP_INNER_TEXT_COMPONENTS",
                )}
              />
            </div>

            <div className="dashboard-card-button-container">
              <span className="dashboard-card-description mb-20">
                {getTranslation("DASHBOARD_CARD_DESCRIPTION_COMPONENTS")}
              </span>
              <button className="dashboard-card-button flex mt-20" onClick={() => navigateToComponents()}>
                <span>
                  {getTranslation(projectType === "process" ? "ADD_EDIT_STEPS_BUTTON" : "ADD_EDIT_COMPONENTS_BUTTON")}
                </span>
                <PlusIconSmall />
              </button>
            </div>
          </div>

          <div className="flex-center card-image-container">
            <img src={ComponentIconLarge} alt="" />
          </div>
        </>
      )}
      {components.length > 0 && (
        <div className="project-card-content flex-column">
          <div className="flex justify-space-between">
            <div
              className="project-dashboard-card-title-container flex align-center pointer"
              onClick={() => navigateToComponents()}
            >
              {projectType === "process" ? (
                <StepIcon style={{ color: "#8a93c3", height: "32", width: "32" }} />
              ) : (
                <ComponentIcon style={{ color: "#8a93c3" }} />
              )}
              <Tooltip
                message={getTranslation(
                  projectType === "process" ? "TOOLTIP_MESSAGE_STEP" : "TOOLTIP_MESSAGE_COMPONENT",
                )}
                containerClass="project-dashboard-card-title"
                innerText={getTranslation(
                  projectType === "process" ? "TOOLTIP_INNER_TEXT_STEPS" : "TOOLTIP_INNER_TEXT_COMPONENTS",
                )}
              />
            </div>

            <button className="dashboard-card-button medium flex" onClick={() => navigateToComponents()}>
              <span>
                {getTranslation(projectType === "process" ? "ADD_EDIT_STEPS_BUTTON" : "ADD_EDIT_COMPONENTS_BUTTON")}
              </span>
              <PlusIconSmall />
            </button>
          </div>

          <div className="card-content-container height-100">
            <div className="donut-diagram flex align-center justify-space-around">
              <DonutDiagram values={diagramValues} />
              <div className="card-right-diagram-container">
                <BlockDiagram
                  values={diagramValues}
                  projectType={projectType}
                  labels={blockLabels}
                  tooltips={tooltips}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ComponentsCard;
