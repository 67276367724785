import React from "react";

import "./styles.css";

const DefaultOption = ({ option, setValue, setIsOpen, isFirst, isLast }) => {
  const activeClass = isFirst ? " first" : "";
  const disabledClass = option.disabled ? " disabled" : "";

  const labels = option.labels.map((label, index) => (
    <div key={index} className="invitation-option-label">
      <span>{label}</span>
    </div>
  ));

  return (
    <div
      className={"invitation-dropdown-option flex-column pointer" + activeClass + disabledClass}
      onClick={() => {
        setValue(option.value);
        setIsOpen(false);
      }}
    >
      <span className="invitation-option-label-text">{option.label}</span>
      <div className={"flex flex-wrap labels-container" + activeClass}>{labels}</div>
      {!isLast && !isFirst && <hr className="option-divider" />}
    </div>
  );
};

export default DefaultOption;
