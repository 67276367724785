import axios from "axios";

import { baseApiUrl } from "../common/constants";
import { handleError, handleSuccess } from "./handlers";

const baseUrl = `${baseApiUrl}/invites`;

export const getInvites = async (params, onSuccess, onError) => {
  axios
    .get(`${baseUrl}`, params)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const getInvitesInfo = async (params, onSuccess, onError) => {
  axios
    .get(`${baseUrl}/info`, params)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const getInviteUsers = async (params, onSuccess, onError) => {
  axios
    .get(`${baseUrl}/users`, params)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const joinInvite = async (params, onSuccess, onError) => {
  axios
    .post(`${baseUrl}/join`, params)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const acceptInvite = async (params, onSuccess, onError) => {
  axios
    .post(`${baseUrl}/accept`, params)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const declineInvite = async (params, onSuccess, onError) => {
  axios
    .post(`${baseUrl}/decline`, params)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const changeTeams = async (params, onSuccess, onError) => {
  axios
    .post(`${baseUrl}/change-teams`, params)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const circulateProject = async (params, onSuccess, onError) => {
  axios
    .post(`${baseUrl}/circulate`, params)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const shareProject = async (params, onSuccess, onError) => {
  axios
    .post(`${baseUrl}/share`, params)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const removeInviteParticipant = async (params, onSuccess, onError) => {
  axios
    .post(`${baseUrl}/remove`, params)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};
