import axios from "axios";

import { baseApiUrl } from "../common/constants";
import { handleError, handleSuccess } from "./handlers";

const baseUrl = `${baseApiUrl}/promo-codes`;

export const createPromoCode = async (params, onSuccess, onError) => {
  axios
    .post(`${baseUrl}/create`, params)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const activatePromoCode = async (params, onSuccess, onError) => {
  axios
    .post(`${baseUrl}/activate`, params)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const activatePromoCodeForUser = async (params, onSuccess, onError) => {
  axios
    .post(`${baseUrl}/activate-code-for-user`, params)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const getPromoCodes = async (params, onSuccess, onError) => {
  axios
    .get(`${baseUrl}`, params)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const duplicatePromoCode = async (params, onSuccess, onError) => {
  axios
    .post(`${baseUrl}/duplicate`, params)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const editPromoCode = async (params, onSuccess, onError) => {
  axios
    .post(`${baseUrl}/edit`, params)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const disablePromoCode = async (params, onSuccess, onError) => {
  axios
    .post(`${baseUrl}/disable`, params)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const archivePromoCode = async (params, onSuccess, onError) => {
  axios
    .post(`${baseUrl}/archive`, params)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const deletePromoCode = async (params, onSuccess, onError) => {
  axios
    .post(`${baseUrl}/delete`, params)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const fetchPromoCode = async (params, onSuccess, onError) => {
  axios
    .get(`${baseUrl}/code`, params)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};
