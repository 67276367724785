import React from "react";

import Cell, { EmptyCell, PlaceholderCell } from "./Cells";
import { getBinaryCellState, getCellState, getCellText, getColor } from "./cellState";

const getComponentHeight = (componentId, columnBands) => {
  const foundBand = columnBands.find((band) => band.componentId === componentId);
  return foundBand && Object.keys(foundBand.children).length;
};

export const getTableRows = (
  rows,
  columns,
  detailsMatrix,
  filters,
  binaryMode,
  columnBands,
  selectedCell,
  setSelectedCell,
  handleOpenDetails,
  history,
  projectId,
  activeCell,
  setActiveCell,
) =>
  rows.map((row, rowIndex) => {
    const tableColumns = columns.map((column, columnIndex) => {
      if (columnIndex - 3 > rowIndex) {
        return <PlaceholderCell key={`${rowIndex}${columnIndex}`} />;
      }

      if (column.name === "rowSpan") {
        return null;
      }

      const columnId = column.name;
      let rowId = "";
      const rowKeys = Object.keys(row);

      for (const key of rowKeys) {
        if (row[key] === "") {
          rowId = key;
          break;
        }
      }

      if (columnId === rowId) {
        return <EmptyCell key={`${rowIndex}${columnIndex}`} />;
      }

      const cellDetails = detailsMatrix.filter(
        (el) =>
          (el.idAttributeRow === rowId && el.idAttributeCol === columnId) ||
          (el.idAttributeRow === columnId && el.idAttributeCol === rowId),
      );

      const cellState = getCellState(cellDetails);
      const color = getColor({ ...cellState, rowId, columnId, filters, detailsMatrix, binaryMode });

      const restProps = {};
      if (!binaryMode) {
        const cellText = getCellText(cellDetails);
        restProps.cellText = cellText;
      } else {
        const binaryState = getBinaryCellState(cellState);
        restProps.binaryState = binaryState;
      }

      return (
        <Cell
          key={`${rowIndex}${columnIndex}`}
          row={row}
          column={column}
          color={color}
          rowIndex={rowIndex}
          columnIndex={columnIndex}
          binaryMode={binaryMode}
          cellDetails={cellDetails}
          selectedCell={selectedCell}
          setSelectedCell={setSelectedCell}
          handleOpenDetails={handleOpenDetails}
          getComponentHeight={() => getComponentHeight(row.componentId, columnBands)}
          history={history}
          projectId={projectId}
          activeCell={activeCell}
          setActiveCell={setActiveCell}
          {...restProps}
        />
      );
    });

    return <tr key={`${rowIndex}`}>{tableColumns}</tr>;
  });

export const getTableHeader = (columnBands, selectedCell, history, projectId) => {
  const filteredBands = columnBands.filter((band) => band.internal);
  const components = filteredBands.map((band) => ({
    title: band.title,
    width: Object.keys(band.children).length,
    componentId: band.componentId,
  }));
  const attributes = filteredBands.reduce((p, n) => [...p, ...n.children], []);

  let currentIndex = 0;

  const firstRow = components.map((component, index) => {
    const min = currentIndex;
    const max = currentIndex + component.width - 1;
    currentIndex += component.width;

    const activeClass =
      selectedCell.column !== null && selectedCell.column >= min && selectedCell.column <= max ? "highlighted" : "";

    const redirectToComponentPage = () => {
      history.push(`/project/${projectId}/components?component=${component.componentId}`);
    };

    return (
      <td key={index} colSpan={component.width} className={activeClass} onClick={() => redirectToComponentPage()}>
        <div className="matrix-component-title-container">
          <span className="matrix-component-title">{component.title}</span>
        </div>
      </td>
    );
  });

  const secondRow = attributes.map((attribute, index) => {
    const redirectToComponentPage = () => {
      history.push(
        `/project/${projectId}/components?component=${attribute.componentId}&attribute=${attribute.attributeId}`,
      );
    };

    return (
      <td
        key={index}
        className={selectedCell.column === index ? "highlighted" : ""}
        style={{ zIndex: 1 }}
        onClick={() => redirectToComponentPage()}
      >
        <div className="matrix-attribute-title-container flex-center">
          <span className="matrix-attribute-title column">{attribute.name}</span>
        </div>
      </td>
    );
  });

  return (
    <>
      <tr>
        <td rowSpan="2" colSpan="2" className="matrix-corner-cell"></td>
        {firstRow}
      </tr>
      <tr>{secondRow}</tr>
    </>
  );
};
