import React, { useEffect, useRef, useState } from "react";

import Scrollbar from "../../../stories/omnivati-phase-2/Components/common/CustomScrollbar/CustomScrollbar";
import DefaultOption from "./DefaultOption/DefaultOption";
import "./styles.css";

const CustomDropdown = ({
  value,
  handleChange,
  options,
  OptionComponent = DefaultOption,
  className,
  dropdownClassName,
  withMargin,
  startOpen,
  height,
  isTooltip = false,
}) => {
  const [isOpen, setIsOpen] = useState(startOpen ?? false);
  const ref = useRef(null);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    document.addEventListener("keydown", handleKeyDown, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
      document.removeEventListener("keydown", handleKeyDown, true);
    };
  });

  const handleClickOutside = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Escape") {
      setIsOpen(false);
    }
  };

  const selectedOption = options.find((option) => option.value === value);
  const selectedOptionElement = (
    <OptionComponent option={selectedOption ? selectedOption : value} first onMouseUp={() => {}} isOpen={isOpen} />
  );

  const optionElements = options.map((option, index) => (
    <OptionComponent
      key={index}
      option={option}
      onMouseUp={(e) => {
        handleChange(e);
        setIsOpen(false);
      }}
      isOpen={isOpen}
      isActive={option.value === value}
      isTooltip={isTooltip}
    />
  ));

  return (
    <div
      className={`custom-dropdown-container ${className}`}
      style={withMargin ? { marginBottom: `${39 * (options?.length - 1) + 25}px` } : null}
      ref={ref}
    >
      <div
        className="custom-dropdown-options-container flex align-center pointer"
        onMouseDown={() => setIsOpen((isOpen) => !isOpen)}
      >
        {selectedOptionElement}
        <div className="custom-dropdown-triangle-icon"></div>
      </div>
      {isOpen && (
        <div
          className={`custom-dropdown-options flex-column align-center pointer ${dropdownClassName}`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <Scrollbar height={height}>{optionElements}</Scrollbar>
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
