import { getStatistics as requestStatistics } from "../api/admin";
import { GET_STATISTICS, GET_STATISTICS_ERROR, GET_STATISTICS_SUCCESS } from "./types";

export const getStatistics = () => (dispatch) => {
  dispatch({ type: GET_STATISTICS });

  const onSuccess = (response) => dispatch({ type: GET_STATISTICS_SUCCESS, payload: response });
  const onError = (error) => dispatch({ type: GET_STATISTICS_ERROR, payload: error });

  const params = {};
  requestStatistics({ params }, onSuccess, onError);
};
