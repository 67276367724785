export const GET_ERRORS = "GET_ERRORS";
export const USER_LOADING = "USER_LOADING";
export const SET_CURRENT_USER = "SET_CURRENT_USER";

export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";
export const RESET_REGISTRATION = "RESET_REGISTRATION";

export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const RESET_LOGIN_STATE = "RESET_LOGIN_STATE";

export const GET_USER_STATUS_SUCCESS = "GET_USER_STATUS_SUCCESS";
export const GET_USER_STATUS_ERROR = "GET_USER_STATUS_ERROR";

export const RESET_RECOVERY_STATE = "RESET_RECOVERY_STATE";
export const SET_RECOVERY_EMAIL = "SET_RECOVERY_EMAIL";
export const REQUEST_PASSWORD_RECOVERY = "REQUEST_PASSWORD_RECOVERY";
export const REQUEST_PASSWORD_RECOVERY_SUCCESS = "REQUEST_PASSWORD_RECOVERY_SUCCESS";
export const REQUEST_PASSWORD_RECOVERY_ERROR = "REQUEST_PASSWORD_RECOVERY_ERROR";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";

export const GET_PROJECT_INFO = "GET_PROJECT_INFO";
export const GET_PROJECT_INFO_SUCCESS = "GET_PROJECT_INFO_SUCCESS";
export const GET_PROJECT_INFO_ERROR = "GET_PROJECT_INFO_ERROR";
export const RESET_PROJECT_INFO = "RESET_PROJECT_INFO";

export const GET_PROJECTS = "GET_PROJECTS";
export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS";
export const GET_PROJECTS_ERROR = "GET_PROJECTS_ERROR";
export const SET_PROJECT_CACHED_AI = "SET_PROJECT_CACHED_AI";
export const SET_COMPONENT_CACHED_AI = "SET_COMPONENT_CACHED_AI";

export const GET_PROJECT_FOLDERS = "GET_PROJECT_FOLDERS";
export const MOVE_PROJECT_TO_FOLDER = "MOVE_PROJECT_TO_FOLDER";
export const MOVE_PROJECT_OUT_OF_FOLDER = "MOVE_PROJECT_OUT_OF_FOLDER";
export const RENAME_PROJECT_FOLDER = "RENAME_PROJECT_FOLDER";
export const REMOVE_PROJECT_FOLDER = "REMOVE_PROJECT_FOLDER";
export const CREATE_PROJECT_FOLDER = "CREATE_PROJECT_FOLDER";

export const GET_PREFERENCES = "GET_PREFERENCES";
export const SET_PREFERENCE = "SET_PREFERENCE";

export const GET_SUBSCRIPTION_INFO = "GET_SUBSCRIPTION_INFO";
export const GET_SUBSCRIPTION_INFO_SUCCESS = "GET_SUBSCRIPTION_INFO_SUCCESS";
export const GET_SUBSCRIPTION_INFO_ERROR = "GET_SUBSCRIPTION_INFO_ERROR";

export const CREATE_SUBSCRIPTION = "CREATE_SUBSCRIPTION";
export const CREATE_SUBSCRIPTION_SUCCESS = "CREATE_SUBSCRIPTION_SUCCESS";
export const CREATE_SUBSCRIPTION_ERROR = "CREATE_SUBSCRIPTION_ERROR";
export const RESET_SUBSCRIPTION = "RESET_SUBSCRIPTION";

export const CREATE_SUBSCRIPTION_OPENAI = "CREATE_SUBSCRIPTION_OPENAI";
export const CREATE_SUBSCRIPTION_OPENAI_ERROR = "CREATE_SUBSCRIPTION_OPENAI_ERROR";
export const CREATE_SUBSCRIPTION_OPENAI_SUCCESS = "CREATE_SUBSCRIPTION_OPENAI_SUCCESS";

export const RETRY_SUBSCRIPTION_PAYMENT = "RETRY_SUBSCRIPTION_PAYMENT";
export const RETRY_SUBSCRIPTION_PAYMENT_SUCCESS = "RETRY_SUBSCRIPTION_PAYMENT_SUCCESS";
export const RETRY_SUBSCRIPTION_PAYMENT_ERROR = "RETRY_SUBSCRIPTION_PAYMENT_ERROR";

export const UPDATE_PAYMENT_METHOD = "UPDATE_PAYMENT_METHOD";
export const UPDATE_PAYMENT_METHOD_SUCCESS = "UPDATE_PAYMENT_METHOD_SUCCESS";
export const UPDATE_PAYMENT_METHOD_ERROR = "UPDATE_PAYMENT_METHOD_ERROR";

export const GET_PROMO_CODES = "GET_PROMO_CODES";
export const GET_PROMO_CODES_SUCCESS = "GET_PROMO_CODES_SUCCESS";
export const GET_PROMO_CODES_ERROR = "GET_PROMO_CODES_ERROR";

export const RESET_PROJECTS_STORE = "RESET_PROJECTS_STORE";

export const GET_STATISTICS = "GET_STATISTICS";
export const GET_STATISTICS_SUCCESS = "GET_STATISTICS_SUCCESS";
export const GET_STATISTICS_ERROR = "GET_STATISTICS_ERROR";

export const GET_TUTORIAL_VIDEO_URL = "GET_TUTORIAL_VIDEO_URL";
export const GET_TUTORIAL_VIDEO_URL_SUCCESS = "GET_TUTORIAL_VIDEO_URL_SUCCESS";
export const GET_TUTORIAL_VIDEO_URL_ERROR = "GET_TUTORIAL_VIDEO_URL_ERROR";
export const SET_TUTORIAL_VIDEO_URL = "SET_TUTORIAL_VIDEO_URL";

export const GET_USER_PROFILE_STATUS_SUCCESS = "GET_USER_PROFILE_STATUS_SUCCESS";
export const GET_USER_PROFILE_STATUS_ERROR = "GET_USER_PROFILE_STATUS_ERROR";
export const UNAUTHORIZED_USER_ERROR = "UNAUTHORIZED_USER_ERROR";

export const SET_LANGUAGE = "SET_LANGUAGE";

export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_ERROR = "GET_ALL_USERS_ERROR";

export const GET_CURRENT_ISSUES = "GET_CURRENT_ISSUES";
export const UPDATE_CURRENT_ISSUES = "SET_CURRENT_ISSUES";
export const UNSHIFT_CURRENT_ISSUES = "UNSHIFT_CURRENT_ISSUES";
export const REMOVE_FROM_CURRENT_ISSUES = "REMOVE_FROM_CURRENT_ISSUES";

export const UPDATE_CURRENT_SOCKET = "UPDATE_CURRENT_SOCKET";

export const RESET_OPENAI_GENERATE_WORDS = "RESET_OPENAI_GENERATE_WORDS";
export const RESET_OPENAI_GENERATE_COMPONENTS = "RESET_OPENAI_GENERATE_COMPONENTS";
export const DEFAULT_OPENAI_GENERATE = "DEFAULT_OPENAI_GENERATE";
export const SET_NUMBER_OPENAI_REQUESTS = "SET_NUMBER_OPENAI_REQUESTS";

export const RESET_CURRENT_COMPONENTS = "RESET_CURRENT_COMPONENTS";
export const SET_STEPS_OPENAI = "SET_STEPS_OPENAI";

export const GET_PREFABS_URL = "GET_PREFABS_URL";
export const GET_PREFABS_URL_SUCCESS = "GET_PREFABS_URL_SUCCESS";
export const GET_PREFABS_URL_ERROR = "GET_PREFABS_URL_ERROR";
export const SET_PREFAB_URL = "SET_PREFAB_URL";
export const SET_PREFAB_URL_SUCCESS = "SET_PREFAB_URL_SUCCESS";
export const SET_PREFAB_URL_ERROR = "SET_PREFAB_URL_ERROR";
