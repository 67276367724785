export const filterUsers = (unfilteredUsers, filters) => {
  const validateTeam = (team, searchString) => {
    const fields = [team.fullname, team.email, team.team || "Individual", getDate(team.joined)];
    for (let field of fields) {
      if (field.toLowerCase().includes(searchString.toLowerCase())) {
        return true;
      }
    }

    return false;
  };

  let filteredUsers = unfilteredUsers.slice();

  if (filters.search.length) {
    filteredUsers = filteredUsers.filter((team) => validateTeam(team, filters.search));
  }

  return filteredUsers;
};

export const sortUsers = (unsortedUsers, sort) => {
  let sortedUsers = unsortedUsers.slice();

  switch (sort.field) {
    case "fullname":
    case "email":
    case "team":
      sortedUsers.sort((a, b) => (a[sort.field].toLowerCase() < b[sort.field].toLowerCase() ? 1 : -1));
      sort.asc && sortedUsers.reverse();
      break;

    case "joined":
      sortedUsers.sort((a, b) => new Date(a.joined).getTime() - new Date(b.joined).getTime());
      sort.asc && sortedUsers.reverse();
      break;

    default:
      console.warn("Couldn't sort field ", sort.field);
      break;
  }

  return sortedUsers;
};

export const getDate = (date) => {
  const createdDate = new Date(date);
  const day = createdDate.getDate();
  const month = createdDate.getMonth() + 1;
  const year = createdDate.getFullYear();
  return `${day}/${month}/${year}`;
};

//TODO: will be used in future
export const filterUsersByTeam = (users, userEmail, editMode) => {
  if (editMode) {
    return users;
  }

  const userWithCurrentTeam = users.find((user) => user.email === userEmail);

  if (userWithCurrentTeam && userWithCurrentTeam.team) {
    const filteredUsers = users.filter((user) => user.team !== "");
    return filteredUsers;
  }

  return [userWithCurrentTeam];
};
