import { useEffect, useState } from "react";

const getHeight = () => window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

const useWindowHeight = (delay = 100) => {
  const [height, setHeight] = useState(getHeight());

  useEffect(() => {
    let timeoutId = null;
    const resize = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => setHeight(getHeight()), delay);
    };

    window.addEventListener("resize", resize);

    return () => window.removeEventListener("resize", resize);
  }, [delay]);

  return height;
};

export default useWindowHeight;
