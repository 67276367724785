import React from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";

import { ReactComponent as PlusIconSmall } from "../../../assets/images/plus-icon-small.svg";
import { getTranslation } from "../../../helpers/getLanguage";
import CustomScrollbar from "../../common/CustomScrollbar";
import Tooltip from "../../common/Tooltip";
import { sortByName } from "../../common/utils/sort";
import SubtractComponentCard from "./SubtractComponentCard";

const ComponentCard = ({
  internal,
  isSorted,
  inputValues,
  isModalOpen,
  handleDelete,
  handleDisable,
  handleModalOpen,
  handleDuplicate,
  projectType,
  activeComponent,
  filteredComponents,
  setActiveComponent,
  subtractionListsRef,
  subtractionsMatrixList,
}) => {
  const tooltipClass = internal ? "tooltip-inner-text-blue" : "tooltip-inner-text-red";
  const subtractComponents = filteredComponents.filter((component) => internal === component.internal);
  const sortedComponents = isSorted ? subtractComponents.slice().sort(sortByName) : subtractComponents;
  const componentType = internal ? "PAGE_COMPONENT_COMPONENT_TYPE_INTERNAL" : "PAGE_COMPONENT_COMPONENT_TYPE_EXTERNAL";

  const getTooltipText = () => {
    if (internal) return getTranslation("TOOLTIP_MESSAGE_INTERNAL_ATTRIBUTE");
    return getTranslation("TOOLTIP_MESSAGE_EXTERNAL_ATTRIBUTE");
  };

  const componentElements = sortedComponents.map((sortedComponent, index) => {
    return (
      <SubtractComponentCard
        key={index}
        isProcessType={projectType === "process"}
        index={index}
        isModalOpen={isModalOpen}
        component={sortedComponent}
        handleDelete={handleDelete}
        handleDisable={handleDisable}
        activeComponent={activeComponent}
        handleModalOpen={handleModalOpen}
        handleDuplicate={handleDuplicate}
        setActiveComponent={setActiveComponent}
        subtractionListsRef={subtractionListsRef}
        subtractionsMatrixList={subtractionsMatrixList}
      />
    );
  });

  const AddComponentButton = (
    <Draggable key={Date.now()} draggableId={internal.toString()} index={subtractComponents.length}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <button
            className="add-component-button flex align-center justify-space-between"
            onClick={() => handleModalOpen("component", { element: { internal } })}
          >
            <span>
              {getTranslation(
                projectType === "process" ? "PAGE_COMPONENT_ADD_STEP_BUTTON" : "PAGE_COMPONENT_ADD_COMPONENT_BUTTON",
              )}
            </span>
            <PlusIconSmall />
          </button>
        </div>
      )}
    </Draggable>
  );

  return (
    <div className={"subtraction-component-card compact"}>
      <div className="component-card-body flex-column align-center">
        <div
          className={"subtraction-component-type-container flex align-center justify-space-between width-100 compact"}
        >
          <div className="flex subtraction-component-card-header-top">
            <div className={"component-type flex-center" + (!internal ? " external" : "")}>
              <Tooltip
                message={getTooltipText()}
                innerText={getTranslation(componentType)}
                innerTextClass={tooltipClass}
              />
            </div>
          </div>
        </div>
        <Droppable droppableId={internal ? "internal" : "external"}>
          {(provided) => (
            <div className={"component-card-container flex-column align-center compact"}>
              {AddComponentButton}
              <CustomScrollbar
                width={5}
                right={-10}
                backgroundColor="transparent"
                dependencies={[inputValues, subtractComponents, filteredComponents]}
              >
                <div className="component-droppable-container" {...provided.droppableProps} ref={provided.innerRef}>
                  {componentElements}
                  {provided.placeholder}
                </div>
              </CustomScrollbar>
            </div>
          )}
        </Droppable>
      </div>
    </div>
  );
};

export default ComponentCard;
