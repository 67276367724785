import React from "react";

import { ReactComponent as Minus } from "../../../assets/images/subtraction-icon-blue.svg";
import { getTranslation } from "../../../helpers/getLanguage";

const colors = {
  1: "#ffcd67",
  2: "#f9ae9f",
  3: {
    1: "#daf1e1",
    2: "#b5e3c3",
    3: "#91d5a5",
    4: "#6cc687",
    5: "#5ac57a",
  },
};

const colorClass = {
  1: "yellow",
  2: "red",
  3: "green",
  4: "white",
};

const DropdownElem = ({ details, handleOpenDetails, index }) => {
  const getColorDropdownElem = () => {
    if (details.status === 3) {
      return colorClass[3];
    }

    if (details.status === 2) {
      return colorClass[2];
    }

    if (details.status === 1) {
      return colorClass[1];
    }

    return colorClass[4];
  };

  const getRating = () => {
    const rating = details.rating || 0;
    const feasibleRating = details.feasibleRating || 0;

    if (details?.feasibleRating === undefined || details.feasibleRating < 0) {
      return Math.min(rating, 5);
    }

    const sum = rating + feasibleRating;

    return Math.round(sum / 2);
  };

  const getStatusColor = () => {
    if (details.status < 3) {
      return colors[details.status];
    }

    return colors[details.status][details.rating];
  };

  return (
    <div className={"cell-dropdown-row flex align-center " + getColorDropdownElem()}>
      <div className="cell-dropdown-row-color-rating" style={{ backgroundColor: getStatusColor() ?? "#ffcd67" }} />
      <div className="cell-dropdown-row-rating">
        {details.status === 3 && details.rating > 0 && (
          <div className="cell-dropdown-idea-score flex flex-center">
            <span>{getRating()}</span>
          </div>
        )}
      </div>
      <div className="cell-dropdown-row-name flex">
        <span className="cell-dropdown-row-text">
          {details.subtractionName === "" ? `Potential idea #${index + 1}` : details.subtractionName}
        </span>
      </div>
      <div className="row-dropdown-container flex align-center">
        <button
          className="open-details-button-subtraction edit flex"
          onClick={() => handleOpenDetails(true, details.id)}
        >
          <Minus />
          <span>{getTranslation("EDIT_IDEA_BUTTON")}</span>
        </button>
      </div>
    </div>
  );
};

export default DropdownElem;
