import React, { useState } from "react";

import { getTranslation } from "../../helpers/getLanguage";
import CopyLinkButton from "../common/CopyLinkButton";

const ProjectsDashboardTopRow = ({ project, getProjectInfo, modalOpen, setModalOpen, canEdit, userId }) => {
  const [isPending, setIsPending] = useState(false);

  const handleRequestEnd = () => {
    setIsPending(false);
    getProjectInfo(project.id);
  };

  return (
    <>
      <div className="width-100 flex flex-wrap justify-space-between">
        {canEdit && (
          <div className="flex mb-10">
            <div className="flex">
              <button
                className="dashboard-project-button team-button flex align-center justify-space-between"
                onClick={() => setModalOpen(!modalOpen)}
              >
                <span>{getTranslation("PAGE_DASHBOARD_EDIT_PROJECT_BUTTON")}</span>
                <div className="button-triangle-icon"></div>
              </button>

              <CopyLinkButton
                projectId={project.id}
                mode="circulate"
                disabled={isPending}
                onRequestStart={() => setIsPending(true)}
                onSuccess={handleRequestEnd}
                onError={handleRequestEnd}
                containerClass="circulate-button"
                tooltipPosition="left"
              />

              <CopyLinkButton
                projectId={project.id}
                mode="share"
                disabled={isPending}
                onRequestStart={() => setIsPending(true)}
                onSuccess={handleRequestEnd}
                onError={handleRequestEnd}
                tooltipPosition="left"
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ProjectsDashboardTopRow;
