import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { recoverPassword, resetRecoveryState } from "../../actions/passwordRecoveryActions";
import { validateField } from "../../common/validation";
import { getTranslation } from "../../helpers/getLanguage";
import LoginLayout from "../common/LoginLayout";
import CustomLoginInput from "../inputs/CustomLoginInput";

const initialCredentials = {
  email: "",
};

const RecoverPassword = ({ history, recovery, auth, recoverPassword, resetRecoveryState }) => {
  const [credentials, setCredentials] = useState(initialCredentials);
  const [errors, setErrors] = useState(initialCredentials);
  const { recoveryEmail, isLoading, success, errors: recoveryError } = recovery;
  const { isAuthenticated } = auth;

  useEffect(() => {
    if (recoveryError) {
      setErrors((errors) => ({ ...errors, email: recoveryError.message }));
    }
  }, [recoveryError]);

  useEffect(() => {
    if (recoveryEmail) {
      setCredentials({ email: recoveryEmail });
    }
  }, [recoveryEmail]);

  useEffect(() => {
    if (isAuthenticated) {
      history.push("/");
      resetRecoveryState();
    }
  }, [isAuthenticated, history, resetRecoveryState]);

  const handleSubmit = () => {
    let hasErrors = false;

    for (let key in credentials) {
      const message = validateField({ name: key, value: credentials[key] }, handleSetErrors);
      message.length && (hasErrors = true);
    }

    !hasErrors && recoverPassword(credentials.email);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const handleSetErrors = (e, value) => {
    const { name } = e.target || e;
    setErrors((errors) => ({ ...errors, [name]: value }));
  };

  const handleLoginClick = () => {
    history.push("/login");
  };

  const handleSubmitEnter = (event) => {
    if (!isLoading && event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <LoginLayout>
      <div className="login-form width-100 flex-column align-center">
        <span className="login-title mb-20">{getTranslation("LOGIN_PAGE_RECOVER_PASSWORD")}</span>

        {!success && (
          <>
            <CustomLoginInput
              containerClass="mb-25"
              type="text"
              name="email"
              placeholder={getTranslation("LOGIN_PAGE_RECOVER_PASSWORD_USERNAME")}
              onBlur={(e) => validateField(e, handleSetErrors)}
              errorMessage={errors.email}
              value={credentials.email}
              onChange={handleChange}
              onKeyDown={handleSubmitEnter}
            />

            <button className="login-button mb-20" onClick={handleSubmit} disabled={isLoading}>
              <span>{getTranslation("LOGIN_PAGE_SEND_RECOVERY_EMAIL")}</span>
            </button>

            <div className="login-links">
              <span className="pointer underlined login-link" onClick={handleLoginClick}>
                {getTranslation("LOGIN_PAGE_BACK_TO_LOGIN")}
              </span>
            </div>
          </>
        )}

        {success && (
          <div className="login-text mb-20">
            <span>{getTranslation("LOGIN_PAGE_SEND_VERIFICATION_LINK")} </span>
            <br />
            <a
              className="underlined"
              target="_blank"
              rel="noopener noreferrer"
              href={`http://${credentials.email.split("@")[1]}`}
            >
              {credentials.email.split("@")[1]}
            </a>
          </div>
        )}
      </div>
    </LoginLayout>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  recovery: state.recovery,
});

export default connect(mapStateToProps, { recoverPassword, resetRecoveryState })(RecoverPassword);
