import axios from "axios";

import { baseApiUrl } from "../common/constants";
import { handleError, handleSuccess } from "./handlers";

export const getPrefabsByType = async ({ typePrompt, languagePrompt }, onSuccess, onError) => {
  axios
    .get(`${baseApiUrl}/prefabs`, { params: { typePrompt, languagePrompt } })
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const addNewPrefab = async (data, onSuccess, onError) => {
  axios
    .post(`${baseApiUrl}/prefabs`, data)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const updateOnePrefab = async ({ id, textPrompt, edited, editDate, language }, onSuccess, onError) => {
  await axios
    .patch(`${baseApiUrl}/prefabs/${id}`, { textPrompt, edited, editDate, language })
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const updateSelectedPrefab = async (data, onSuccess, onError) => {
  axios
    .patch(`${baseApiUrl}/prefabs`, data)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const deletePrefabsById = async (ids, onSuccess, onError) => {
  axios
    .put(`${baseApiUrl}/prefabs`, { ids })
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};
