import axios from "axios";

import { baseApiUrl } from "../common/constants";
import { handleError, handleSuccess } from "./handlers";

const baseUrl = `${baseApiUrl}/contradiction`;

export const createcontradiction = async (params, onSuccess, onError) => {
  axios
    .post(`${baseUrl}/create`, params)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const createMultiplecontradiction = async (params, onSuccess, onError) => {
  axios
    .post(`${baseUrl}/create_multiple`, params)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const deletecontradiction = async (params, onSuccess, onError) => {
  axios
    .post(`${baseUrl}/delete`, params)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const updatecontradiction = async (params, onSuccess, onError) => {
  axios
    .post(`${baseUrl}/update`, params)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};
