const fileTypes = {
  pdf: { type: "application/pdf" },
  xlsx: { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" },
};

export const saveBufferInNewTab = async (data, type = "pdf") => {
  const fileBlob = new Blob([new Uint8Array(data.data)], fileTypes[type]);
  const download = URL.createObjectURL(fileBlob);
  const link = document.createElement("a");
  link.href = download;
  link.target = "_blank";
  link.click();
};

export const saveBuffer = async (data, filename, type = "pdf") => {
  const fileBlob = new Blob([new Uint8Array(data.data)], fileTypes[type]);
  const download = URL.createObjectURL(fileBlob);
  const link = document.createElement("a");

  link.href = download;
  link.download = filename;
  link.click();
  URL.revokeObjectURL(download);
};
