import { useCallback, useLayoutEffect, useState } from "react";

import store from "../store";
import useWindowHeight from "./useWindowHeight";
import useWindowWidth from "./useWindowWidth";

const useContainerHeight = (containerRef, offsetY = 20) => {
  const [containerHeight, setContainerHeight] = useState(null);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [sidenavCollapsed, setSidenavCollapsed] = useState(null);
  const windowWidth = useWindowWidth();
  const windowHeight = useWindowHeight();

  const updateHeight = useCallback(() => {
    setShouldUpdate(true);
  }, []);

  useLayoutEffect(() => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      const height = window.innerHeight;
      setContainerHeight(height - rect.y - offsetY);
    }
    shouldUpdate && setShouldUpdate(false);
  }, [windowWidth, windowHeight, containerRef, shouldUpdate, updateHeight, sidenavCollapsed, offsetY]);

  useLayoutEffect(() => {
    let componentExists = true;
    const listener = () => {
      const sidenavState = store.getState().preferences.sidenavCollapsed;
      if (componentExists) sidenavState !== sidenavCollapsed && setSidenavCollapsed(sidenavState);
    };

    const unsubscribe = store.subscribe(listener);

    return () => {
      unsubscribe();
      componentExists = false;
    };
  }, [sidenavCollapsed]);

  return [containerHeight, updateHeight];
};

export default useContainerHeight;
