import React from "react";

import { ReactComponent as UserIcon } from "../../../../assets/images/nav-user.svg";
import { getTranslation } from "../../../../helpers/getLanguage";
import CardModal from "../../../dialogs/CardModal";

const ProfileItem = ({ compact, fullName, history, logoutUser, activeClass }) => {
  const userModalItems = [
    { value: getTranslation("NAV_MENU_PROFILE_EDIT_PROFILE"), action: () => history.push("/profile") },
    {
      value: getTranslation("NAV_MENU_PROFILE_LOGOUT"),
      action: () => {
        logoutUser();
        history.push("/login");
      },
    },
  ];

  return (
    <CardModal items={userModalItems} offsetY={-65} offsetX={10}>
      <div className={"sidenav-item flex align-center profile pointer" + activeClass}>
        <UserIcon color={"#8a93c3"} style={{ marginRight: compact ? "12px" : "17px", marginLeft: "13px" }} />

        {!compact && (
          <div style={{ width: "100%", paddingRight: "10px" }} className="flex align-center disable-pointer-events">
            <div style={{ width: "100%" }}>
              <span
                style={{ width: "100%" }}
                className="sidenav-title username flex disable-pointer-events ellipsed-text"
              >
                {fullName}
              </span>
            </div>
            <div className="triangle-right-icon"></div>
          </div>
        )}
        {compact && (
          <div className="sidenav-item-tooltip flex-center">
            <span>{getTranslation("NAV_MENU_PROFILE")}</span>
          </div>
        )}
      </div>
    </CardModal>
  );
};

export default ProfileItem;
