import React from "react";

import CloseIcon from "../../../assets/images/close-icon.svg";
import { getUrlFromConfig } from "../../../helpers/getUrlFromConfig";
import Dialog from "../Dialog";
import "./styles.css";

const ImageModal = ({ closeModal, imageData, altImage = "" }) => {
  const projectImage = typeof imageData === "object" ? getUrlFromConfig(`images/${imageData.id}`) : imageData;

  return (
    <Dialog closeDialog={closeModal} closeOnClickOutside={false}>
      <div className="image-modal">
        <div className="image-modal-header">
          <span className="image-modal-header__title">{imageData.name ? imageData.name : "Image"}</span>
          <img src={CloseIcon} alt="close" className="pointer image-modal-header__button-exit" onClick={closeModal} />
        </div>
        <img src={projectImage} alt={altImage} className="image-modal__img" />
      </div>
    </Dialog>
  );
};

export default ImageModal;
