import React from "react";

import { getTranslation } from "../../../helpers/getLanguage";
import CustomCheckbox from "../../inputs/CustomCheckbox";

const ReportsTableHeader = ({ sort, handleSortChange, handleSelectAllRows, selectedIdeasAll }) => {
  const SortableCell = ({ name, label }) => {
    const isActive = name === sort.field;

    return (
      <div className="flex align-center width-100">
        <span
          className={"reports-row-text pointer" + (isActive ? " active" : "")}
          onClick={() => handleSortChange(name)}
        >
          {label}
        </span>
        {isActive && <div className={"sort-triangle-icon" + (sort.asc ? " asc" : "")}></div>}
      </div>
    );
  };

  return (
    <div className="reports-container-header">
      <div className="reports-row-header flex align-center">
        <div className="reports-row-color-rating"></div>
        <div className="reports-row-checkbox flex-center">
          <CustomCheckbox
            checked={selectedIdeasAll}
            handleChange={(e) => handleSelectAllRows(e)}
            customClass="reports-checkbox"
          />
        </div>
        <div className="reports-row-rating flex align-center padding-0">
          <SortableCell name="rating" label={getTranslation("SORTABLE_CELL_RATING")} />
        </div>
        <div className="reports-row-name flex align-center">
          <SortableCell name="name" label={getTranslation("SORTABLE_CELL_IDEA_NAME")} />
        </div>
        <div className="reports-row-method">
          <SortableCell name="method" label={getTranslation("SORTABLE_CELL_METHOD")} />
        </div>
        <div className="reports-row-component flex align-center">
          <SortableCell name="component" label={getTranslation("SORTABLE_CELL_COMPONENT")} />
        </div>
        <div className="reports-row-first-attribute flex align-center">
          <SortableCell name="firstAttribute" label={getTranslation("SORTABLE_CELL_ATTRIBUTE_ONE")} />
        </div>
        <div className="reports-row-second-attribute flex align-center">
          <SortableCell name="secondAttribute" label={getTranslation("SORTABLE_CELL_ATTRIBUTE_TWO")} />
        </div>
        <div className="reports-row-benefit flex align-center">
          <SortableCell name="benefits" label={getTranslation("SORTABLE_CELL_BENEFITS")} />
        </div>
        <div className="reports-row-created flex align-center padding-0">
          <SortableCell name="created" label={getTranslation("SORTABLE_CELL_CREATED")} />
        </div>
        <div className="reports-row-created-by flex align-center padding-0">
          <span className="reports-row-text">{getTranslation("SORTABLE_CELL_USER_OR_GROUP")}</span>
        </div>
      </div>
    </div>
  );
};

export default ReportsTableHeader;
