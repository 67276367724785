import React from "react";

import CloseIcon from "../../../../assets/images/close-icon.svg";
import { getTranslation } from "../../../../helpers/getLanguage";
import Dialog from "../../../dialogs/Dialog";
import "./styles.css";

const ContactUsModal = ({
  closeDialog,
  onConfirm,
  autoFocus = false,
  buttonText = getTranslation("CONFIRMATION_MODAL_DEFAULT_BUTTON_TEXT"),
}) => {
  const handleCloseDialog = () => {
    closeDialog && closeDialog();
  };

  return (
    <Dialog closeDialog={closeDialog}>
      <div className="contact-us-popup flex-column">
        <div className="contact-us-popup-close flex width-100">
          <img src={CloseIcon} alt="" className="pointer" onClick={handleCloseDialog} />
        </div>

        <div className="flex-column align-center">
          <span className="contact-us-popup-text">
            {getTranslation("SUPPORT_DIALOG_MESSAGE_PLEASE_VISIT")}{" "}
            <span>
              <a
                className="underlined"
                href="https://omnivati.com/faq/"
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => e.stopPropagation()}
              >
                {getTranslation("SUPPORT_DIALOG_MESSAGE_FAQ")}
              </a>{" "}
            </span>
            {getTranslation("SUPPORT_DIALOG_MESSAGE_PAGE")}
            <br />
            {getTranslation("SUPPORT_DIALOG_MESSAGE_CONTACT")} <br />
            {getTranslation("SUPPORT_DIALOG_MESSAGE_TEAM_AT")}{" "}
            <a href="mailto:support@omnivati.com">{getTranslation("SUPPORT_DIALOG_MESSAGE_LINK")}</a>
          </span>
          <button className="contact-us-popup-button" autoFocus={autoFocus} onClick={() => onConfirm()}>
            <span>{buttonText}</span>
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default ContactUsModal;
