import { getPromoCodes as requestPromoCodes } from "../api/promoCodes";
import { GET_PROMO_CODES, GET_PROMO_CODES_ERROR, GET_PROMO_CODES_SUCCESS } from "./types";

export const getPromoCodes = () => (dispatch) => {
  dispatch({ type: GET_PROMO_CODES });

  const onSuccess = (response) => dispatch({ type: GET_PROMO_CODES_SUCCESS, payload: response });
  const onError = (error) => dispatch({ type: GET_PROMO_CODES_ERROR, payload: error });

  const params = {};
  requestPromoCodes({ params }, onSuccess, onError);
};
