import React from "react";

import ReportsRows from "./ReportsRows";
import ReportsTableHeader from "./ReportsTableHeader";

const ReportsTable = ({
  rows,
  canEdit,
  currentLanguage,
  handleSelectIdea,
  selected,
  handleExpandIdea,
  expanded,
  sort,
  handleSortChange,
  handleOpenDetails,
  handleRedirectToMatrix,
  handleRedirectToSubtraction,
  handleRedirectToEureka,
  handleRedirectToMultiplication,
  handleSelectAllRows,
  selectedIdeasAll,
  currentIdea,
  handleOpenSubtractionsDetails,
  handleRedirectToReplacement,
  handleOpenReplacementsDetails,
  handleOpenMultiplicationsDetails,
  handleOpenEurekaDetails,
  attributes,
  components,
}) => (
  <div className="reports-table">
    <ReportsTableHeader
      sort={sort}
      handleSortChange={handleSortChange}
      handleSelectAllRows={handleSelectAllRows}
      selectedIdeasAll={selectedIdeasAll}
    />

    <ReportsRows
      rows={rows}
      attributes={attributes}
      currentLanguage={currentLanguage}
      components={components}
      canEdit={canEdit}
      handleSelectIdea={handleSelectIdea}
      selected={selected}
      handleExpandIdea={handleExpandIdea}
      expanded={expanded}
      handleOpenDetails={handleOpenDetails}
      handleOpenSubtractionsDetails={handleOpenSubtractionsDetails}
      handleOpenReplacementsDetails={handleOpenReplacementsDetails}
      handleOpenMultiplicationsDetails={handleOpenMultiplicationsDetails}
      handleRedirectToEureka={handleRedirectToEureka}
      handleRedirectToMultiplication={handleRedirectToMultiplication}
      handleOpenEurekaDetails={handleOpenEurekaDetails}
      handleRedirectToMatrix={handleRedirectToMatrix}
      handleRedirectToSubtraction={handleRedirectToSubtraction}
      handleRedirectToReplacement={handleRedirectToReplacement}
      currentIdea={currentIdea}
    />
  </div>
);

export default ReportsTable;
