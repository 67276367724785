import { getCurrentIssues } from "../api/currentIssues";
import { GET_CURRENT_ISSUES, REMOVE_FROM_CURRENT_ISSUES, UNSHIFT_CURRENT_ISSUES, UPDATE_CURRENT_ISSUES } from "./types";

export const getCurrentIssuesRedux = () => (dispatch) => {
  dispatch({ type: GET_CURRENT_ISSUES });

  const onSuccess = (response) => dispatch({ type: GET_CURRENT_ISSUES, payload: response });

  getCurrentIssues({}, onSuccess);
};

export const updateCurrentIssueRedux = (updatedIssue) => (dispatch) => {
  dispatch({ type: UPDATE_CURRENT_ISSUES, payload: updatedIssue });
};

export const unshiftCurrentIssuesRedux = (newIssue) => (dispatch) => {
  dispatch({ type: UNSHIFT_CURRENT_ISSUES, payload: newIssue });
};

export const removeFromCurrentIssuesRedux = (id) => (dispatch) => {
  dispatch({ type: REMOVE_FROM_CURRENT_ISSUES, payload: id });
};
