import React, { useState } from "react";

import { changeUserRoles } from "../../../../api/users";
import { validateField } from "../../../../common/validation";
import { getTranslation } from "../../../../helpers/getLanguage";
import ConfirmationModal from "../../../dialogs/ConfirmationModal";
import CustomInput from "../../../inputs/CustomInput";
import CustomToggle from "../../../inputs/CustomToggle";

const userRoles = { user: true, admin: false, support_admin: false, admin_ai: false };

const translateRoles = {
  user: "CHANGE_USER_ROLES_ROLE_USER",
  admin: "CHANGE_USER_ROLES_ROLE_ADMIN",
  support_admin: "CHANGE_USER_ROLES_ROLE_SUPPORT_ADMIN",
  admin_ai: "CHANGE_USER_ROLES_ROLE_ADMIN_AI",
};

const initialFields = {
  email: "",
  roles: userRoles,
};

const initialErrors = {
  email: "",
};

const ChangeUserRoles = () => {
  const [fields, setFields] = useState(initialFields);
  const [errors, setErrors] = useState(initialErrors);
  const [isPending, setIsPending] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmationText, setConfirmationText] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFields({ ...fields, [name]: value });
  };

  const handleToggleChange = (key, value) => {
    setFields((fields) => ({ ...fields, roles: { ...fields.roles, [key]: value } }));
  };

  const handleSetErrors = (e, value) => {
    const { name } = e.target || e;
    setErrors((errors) => ({ ...errors, [name]: value }));
  };

  const handleChangingUserRoles = () => {
    const message = validateField({ name: "email", value: fields.email });
    if (message.length) {
      setErrors({ ...errors, email: message });
      return;
    }
    const rolesSelection = fields.roles;
    const roles = Object.keys(rolesSelection)
      .map((field) => (rolesSelection[field] ? field : ""))
      .filter((field) => field);

    const params = { email: fields.email, roles };
    const onSuccess = () => {
      setIsPending(false);
      setConfirmationText(getTranslation("CHANGE_USER_ROLES_CONFIRMATION_TEXT_SUCCESSFULLY"));
      setModalOpen(true);
    };
    const onError = (error) => {
      setIsPending(false);
      console.error(error);
      setConfirmationText(
        `${getTranslation("CHANGE_USER_ROLES_CONFIRMATION_TEXT_ERROR")} ${error.response.data.message}`,
      );
      setModalOpen(true);
    };
    setIsPending(true);
    changeUserRoles(params, onSuccess, onError);
  };

  const handleSubmitEnterCodeActivation = (event) => {
    if (!isPending && event.key === "Enter") {
      handleChangingUserRoles();
    }
  };

  return (
    <>
      <div className="manage-user-container mb-30">
        <div className="flex-column admin-password-container">
          <span className="manage-user-label mb-20">{getTranslation("CHANGE_USER_ROLES")}</span>
          <CustomInput
            value={fields.email}
            name="email"
            onChange={handleChange}
            onKeyDown={handleSubmitEnterCodeActivation}
            containerClass="flex-column width-100 mb-10"
            label={getTranslation("CHANGE_USER_ROLES_USER_EMAIL")}
            onBlur={(e) => validateField(e, handleSetErrors)}
            errorMessage={errors.email}
          />

          {Object.keys(fields.roles).map((key, index) => {
            const currentLabel = getTranslation(translateRoles[key]);

            return (
              <div className="flex mb-20" key={index}>
                <CustomToggle
                  gray
                  value={fields.roles[key]}
                  disabled={key === "user"}
                  setValue={(e) => handleToggleChange(key, e)}
                  label={currentLabel}
                />
              </div>
            );
          })}
          <button className="manage-users-button" onClick={handleChangingUserRoles} disabled={isPending}>
            <span>{getTranslation("CHANGE_USER_ROLES_BUTTON")}</span>
          </button>
        </div>
      </div>

      {modalOpen && (
        <ConfirmationModal
          closeOnClickOutside={false}
          closeDialog={() => setModalOpen(false)}
          message={confirmationText}
          buttonText="OK"
          messageWidth={400}
          onConfirm={() => setModalOpen(false)}
        />
      )}
    </>
  );
};

export default ChangeUserRoles;
