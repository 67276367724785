export const languageVersion = {
  ENGLISH: "English",
  CHINESE: "中文版",
  SPANISH: "Español",
};

export const currentLanguage = {
  ENGLISH: "en",
  CHINESE: "cn",
  SPANISH: "es",
};

export const optionsOfLanguageForGlobalConfigurations = [
  {
    value: "en",
    label: "CHANGE_VIDEO_URL_BUTTON_LANGUAGE_ENGLISH",
  },
  {
    value: "es",
    label: "CHANGE_VIDEO_URL_BUTTON_LANGUAGE_SPANISH",
  },
  {
    value: "cn",
    label: "CHANGE_VIDEO_URL_BUTTON_LANGUAGE_CHINESE",
  },
];

export const optionsOfLanguageForChatGPTPrompts = [
  {
    value: "en",
    label: "CHANGE_PROMPT_BUTTON_LANGUAGE_ENGLISH",
  },
  {
    value: "es",
    label: "CHANGE_PROMPT_BUTTON_LANGUAGE_SPANISH",
  },
  {
    value: "cn",
    label: "CHANGE_PROMPT_BUTTON_LANGUAGE_CHINESE",
  },
];
