import classNames from "classnames";
import React, { useCallback, useMemo, useState } from "react";

import { changeUserPassword } from "../../../api/users";
import CloseIcon from "../../../assets/images/close-icon.svg";
import { validateField } from "../../../common/validation";
import { getTranslation } from "../../../helpers/getLanguage";
import CustomInput from "../../inputs/CustomInput";
import Dialog from "../Dialog";
import styles from "./ChangeUserPasswordModal.module.css";

const initialPasswordState = {
  password: "",
  newPassword: "",
  againNewPassword: "",
};

const ChangeUserPasswordModal = ({ handleCloseDialog, closeOnEscape }) => {
  const [errors, setErrors] = useState(initialPasswordState);
  const [fields, setFields] = useState(initialPasswordState);
  const [isPending, setIsPending] = useState(false);

  const handleSubmitEnter = useCallback((event) => {
    if (!isPending && event.key === "Enter") {
      handleEditClick();
    }
  });

  const handleSetErrors = (e, value) => {
    const { name } = e.target || e;
    setErrors((errors) => ({ ...errors, [name]: value }));
  };

  const handleEditClick = () => {
    const errorMessages = {};

    if (fields.newPassword !== fields.againNewPassword) {
      errorMessages.againNewPassword = getTranslation("PASSWORDS_NO_MATCH");
    }
    const params = {
      newPassword: fields.newPassword?.trim(),
      password: fields.password?.trim(),
    };

    for (let key in params) {
      const message = validateField({ name: key, value: params[key] });
      if (message?.length) {
        errorMessages[key] = message;
      }
    }

    setErrors({ ...errors, ...errorMessages });
    if (Object.keys(errorMessages)?.length) return;

    const onSuccess = () => {
      setIsPending(false);
      handleCloseDialog();
    };

    const onError = (error) => {
      console.error(error);
      setIsPending(false);
      if (error.response && error.response.data.code === "10105") {
        setErrors({ ...errors, password: error.response.data.message });
      }
    };

    setIsPending(true);
    changeUserPassword(params, onSuccess, onError);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFields({ ...fields, [name]: value });
  };

  const fieldsData = useMemo(
    () => [
      {
        value: fields.password,
        label: "CHANGE_PASSWORD_MODAL_PASSWORD",
        name: "password",
        errorMessage: errors.password,
        autoFocus: true,
      },
      {
        value: fields.newPassword,
        label: "CHANGE_PASSWORD_MODAL_NEW_PASSWORD",
        name: "newPassword",
        errorMessage: errors.newPassword,
        autoFocus: false,
      },
      {
        value: fields.againNewPassword,
        label: "CHANGE_PASSWORD_MODAL_REPEAT_NEW_PASSWORD",
        name: "againNewPassword",
        errorMessage: errors.againNewPassword,
        autoFocus: false,
      },
    ],
    [
      fields.password,
      fields.againNewPassword,
      fields.newPassword,
      errors.againNewPassword,
      errors.newPassword,
      errors.password,
    ],
  );

  return (
    <Dialog closeDialog={handleCloseDialog} closeOnClickOutside={false} closeOnEscape={closeOnEscape}>
      <div className={styles.dialog}>
        <div className={classNames("username-dialog-header", "flex")}>
          <div className={classNames("flex", "align-center")}>
            <span className="username-dialog-title">{getTranslation("PASSWORD_DIALOG_TITLE")}</span>
          </div>
          <img src={CloseIcon} alt="" className="pointer" onClick={() => handleCloseDialog()} />
        </div>
        <div className={classNames("username-dialog-body", "flex-column", "justify-between", styles["body"])}>
          <div>
            {fieldsData.map(({ value, label, name, errorMessage, autoFocus }) => (
              <CustomInput
                type="password"
                autoFocus={autoFocus}
                value={value}
                label={getTranslation(label)}
                name={name}
                containerClass="flex-column mb-20"
                onChange={handleChange}
                onBlur={(e) => validateField(e, handleSetErrors)}
                errorMessage={errorMessage}
                onKeyDown={handleSubmitEnter}
              />
            ))}
          </div>
          <button disabled={isPending} className="username-dialog-button dark-blue-btn" onClick={handleEditClick}>
            {getTranslation("CHANGE_PASSWORD_MODAL_CHANGE")}
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default ChangeUserPasswordModal;
