import axios from "axios";

import { baseApiUrl } from "../common/constants";
import { handleError, handleSuccess } from "./handlers";

export const getCurrentIssues = async (data, onSuccess, onError) => {
  axios
    .get(`${baseApiUrl}/currentIssues`, data)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const readCurrentIssues = async (data, onSuccess, onError) => {
  axios
    .post(`${baseApiUrl}/users/read-issues`, data)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};
