import classnames from "classnames";
import React from "react";

import { getTranslation } from "../../../../helpers/getLanguage";
import Tooltip from "../../../common/Tooltip";

const DefaultOption = ({ option, onMouseUp, first, isOpen, isActive, isTooltip }) => {
  return (
    <div
      className={classnames(
        "custom-dropdown-container-option flex align-center width-100 pointer",
        { first },
        { open: isOpen },
        { active: isActive },
      )}
      onMouseUp={() => onMouseUp(option?.value)}
    >
      {isTooltip ? (
        <Tooltip
          containerClass="custom-dropdown-option ellipsed-text"
          position="right"
          innerTextClass=""
          message={getTranslation(option?.label)}
          innerText={getTranslation(option?.label)}
          tooltipMessageClass="tooltip-message white"
        />
      ) : (
        <span className="custom-dropdown-option">{getTranslation(option?.label)}</span>
      )}
    </div>
  );
};

export default DefaultOption;
