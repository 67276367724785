import React from "react";

import CustomScrollbar from "../../../common/CustomScrollbar";
import { getDate } from "./helpers";
import TableCell from "./TableCell";

const UsersTableBody = ({ rows, containerHeight, containerRef, openUserModal }) => {
  const handleEditUser = (promoCode) => openUserModal(promoCode);

  const rowElements = rows.map((row, index) => {
    const periodEnd = row.activeSubscription ? getDate(row.activeSubscription) : "No";

    return (
      <div key={index} className={"users-row pointer flex align-center"} onClick={() => handleEditUser(row)}>
        <TableCell value={getDate(row.date)} containerClass="users-body-row-date" />
        <TableCell
          containerClass="users-body-row-created-by"
          textClass="users-row-full-name-text"
          value={`${row.name} ${row.lastName}`}
        />
        <TableCell containerClass="users-body-row-email" value={row.email} />
        <TableCell containerClass="users-body-row-subscription" value={periodEnd} />
      </div>
    );
  });

  return (
    <div className="users-rows-container" ref={containerRef} style={{ height: "560px" }}>
      <CustomScrollbar right={-15} dependencies={[rows, containerHeight]}>
        {rowElements}
      </CustomScrollbar>
    </div>
  );
};

export default UsersTableBody;
