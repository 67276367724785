import classNames from "classnames";
import React from "react";

import CloseIcon from "../../../assets/images/close-icon.svg";
import { getTranslation } from "../../../helpers/getLanguage";
import Dialog from "../Dialog";
import styles from "./CancelConformationSubscriptionModal.module.css";

const messages = ["WE_WERE_SORRY_UPON", "WE_WERE_SORRY_AI_CREDITS", "WE_WERE_SORRY_SUBSCRIPTION"];

const CancelConformationSubscriptionModal = ({
  closeDialog,
  closeOnClickOutside = true,
  closeOnEscape = true,
  confirmDialog,
  credits,
  date,
}) => {
  return (
    <Dialog closeDialog={closeDialog} closeOnClickOutside={closeOnClickOutside} closeOnEscape={closeOnEscape}>
      <div className={styles["dialog"]}>
        <div className="username-dialog-header flex">
          <div className="flex align-center">
            <span className="username-dialog-title">{getTranslation("CANCEL_CONFORMATION")}</span>
          </div>
          <img src={CloseIcon} alt="" className="pointer" onClick={() => closeDialog()} />
        </div>
        <div className={classNames("flex", "flex-column", "align-center")}>
          <span className={styles["header-body"]}>{getTranslation("WE_WERE_SORRY_TO_SEE")}</span>
          <div className={styles["table"]}>
            {messages.map((message, index) => (
              <div className={classNames(styles["table-item"], "flex")} key={message + index}>
                <div className={styles["circle"]} />
                <span>
                  {" "}
                  {message === "WE_WERE_SORRY_AI_CREDITS"
                    ? getTranslation(message).replace("42", credits)
                    : message === "WE_WERE_SORRY_UPON"
                      ? getTranslation(message).replace("07/12/2023", date)
                      : getTranslation(message)}
                </span>
              </div>
            ))}
          </div>
          <div>
            <button
              onClick={confirmDialog}
              className={classNames("username-dialog-button", "dark-blue-btn", styles["cancel-button"])}
            >
              {getTranslation("PROCESSED_WITH_CANCELLATION")}
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default CancelConformationSubscriptionModal;
