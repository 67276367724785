import classnames from "classnames";
import React from "react";

import { currentLanguage } from "../../constants/language";
import { getTranslation } from "../../helpers/getLanguage";

const BASE_CELL_HEIGHT = 37;

const indicatorInfo = {
  external: { className: "external-type-indicator flex-center", label: "LABEL_EXTERNAL" },
  internal: { className: "internal-type-indicator flex-center", label: "LABEL_INTERNAL" },
};

const ComponentTypeIndicator = ({ columnBands, external = false, language }) => {
  const key = external ? "external" : "internal";
  const height = getIndicatorHeight(!external, columnBands);

  if (!height) {
    return null;
  }

  const componentTypeClass = classnames("component-type-text", {
    "ellipsed-text": height <= BASE_CELL_HEIGHT,
    "transform-90-deg": language !== currentLanguage.CHINESE,
  });

  return (
    <div className={indicatorInfo[key].className} style={{ height }}>
      <div className={componentTypeClass}>
        <span>{getTranslation(indicatorInfo[key].label)}</span>
      </div>
    </div>
  );
};

const getIndicatorHeight = (isInternal, columnBands) => {
  const filteredBands = columnBands.filter((band) => band.internal === isInternal);
  const elementsCount = filteredBands.reduce((p, n) => p + n.children.length, 0);
  return BASE_CELL_HEIGHT * elementsCount;
};

export default ComponentTypeIndicator;
