import { getTranslation } from "../../helpers/getLanguage";

const defaultCategories = [
  {
    name: "Consumer",
    translateValue: "SELECT_INPUT_OPTIONS_CONSUMER",
  },
  {
    name: "Company",
    translateValue: "SELECT_INPUT_OPTIONS_COMPANY",
  },
  {
    name: "Other",
    translateValue: "SELECT_INPUT_OPTIONS_OTHER",
  },
];

export const getLabel = (name) => {
  const foundDefaultCategory = defaultCategories.find((category) => category.name === name);

  if (foundDefaultCategory) {
    return getTranslation(foundDefaultCategory.translateValue);
  }

  return name;
};

export const getSelectedValue = (values) => {
  const translatedValues = values.map((value) => {
    const foundDefaultCategory = defaultCategories.find((category) => category.name === value);

    if (foundDefaultCategory) {
      return getTranslation(foundDefaultCategory.translateValue);
    }

    return value;
  });

  return translatedValues.join(", ");
};
