import React from "react";

import { getTranslation } from "../../../helpers/getLanguage";
import TabHeader from "../../common/TabHeader";

const ReportsTabsHeader = ({ activeTab, setActiveTab, userIdeas, otherIdeas, myIdeas, showOthersIdeas = false }) => {
  const tabs = (
    <>
      <TabHeader
        index={0}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        itemCount={userIdeas}
        label={getTranslation("PAGE_TAB_HEADER_ALL_IDEAS")}
      />

      <TabHeader
        index={1}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        itemCount={myIdeas}
        label={getTranslation("PAGE_TAB_HEADER_MY_IDEAS")}
      />

      {showOthersIdeas && (
        <TabHeader
          index={2}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          itemCount={otherIdeas}
          label={getTranslation("PAGE_TAB_HEADER_OTHERS_IDEAS")}
        />
      )}
    </>
  );

  return <div className="project-dashboard-tabs flex mb-30">{tabs}</div>;
};

export default ReportsTabsHeader;
