export const dropdowns = [
  {
    value: "componentsSteps",
    label: "ADMIN_PANEL_PREFABS_TAB_DROPDOWN_COMPONENTS",
  },
  {
    value: "attributes",
    label: "ADMIN_PANEL_PREFABS_TAB_DROPDOWN_ATTRIBUTES",
  },
  {
    value: "names",
    label: "ADMIN_PANEL_PREFABS_TAB_DROPDOWN_NAMES",
  },
  {
    value: "benefits",
    label: "ADMIN_PANEL_PREFABS_TAB_DROPDOWN_BENEFITS",
  },
  {
    value: "challenges",
    label: "ADMIN_PANEL_PREFABS_TAB_DROPDOWN_CHALLENGES",
  },
  {
    value: "images",
    label: "ADMIN_PANEL_PREFABS_TAB_DROPDOWN_IMAGES",
  },
  {
    value: "contradiction",
    label: "ADMIN_PANEL_PREFABS_TAB_DROPDOWN_CONTRADICTION",
  },
];

export const prefabsForComponentsSteps = [
  {
    value: "internalComponent",
    label: "ADMIN_PANEL_PREFABS_TAB_INTERNAL_COMPONENT",
  },
  {
    value: "externalComponent",
    label: "ADMIN_PANEL_PREFABS_TAB_EXTERNAL_COMPONENT",
  },
  {
    value: "internalStepsCustomer",
    label: "ADMIN_PANEL_PREFABS_TAB_STEPS_CUSTOMER",
  },
  {
    value: "internalStepsCompany",
    label: "ADMIN_PANEL_PREFABS_TAB_STEPS_COMPANY",
  },
];

export const prefabsForAttributes = [
  {
    value: "attributeProductService",
    label: "ADMIN_PANEL_PREFABS_TAB_ATTRIBUTE_COMPONENT",
  },
  {
    value: "attributeStepsCustomer",
    label: "ADMIN_PANEL_PREFABS_TAB_ATTRIBUTE_STEP_CUSTOMER",
  },
  {
    value: "attributeStepsCompany",
    label: "ADMIN_PANEL_PREFABS_TAB_ATTRIBUTE_STEP_COMPANY",
  },
];

export const prefabsForNames = [
  {
    value: "namesIdeas",
    label: "ADMIN_PANEL_PREFABS_TAB_NAMES_IDEAS",
  },
];

export const prefabsForBenefits = [
  {
    value: "benefitsIdeas",
    label: "ADMIN_PANEL_PREFABS_TAB_BENEFITS",
  },
];

export const prefabsForChallenges = [
  {
    value: "challengesIdeas",
    label: "ADMIN_PANEL_PREFABS_TAB_CHALLENGES",
  },
];

export const prefabsForImages = [
  {
    value: "imagesIdeas",
    label: "ADMIN_PANEL_PREFABS_TAB_IMAGE_IDEA",
  },
  {
    value: "imagesProjects",
    label: "ADMIN_PANEL_PREFABS_TAB_IMAGE_PROJECT",
  },
];

export const prefabsForContradictions = [
  {
    value: "contradictionsGeneration",
    label: "ADMIN_PANEL_PREFABS_TAB_CONTRADICTION_GENERATING",
  },
  {
    value: "contradictionsSolving",
    label: "ADMIN_PANEL_PREFABS_TAB_CONTRADICTION_SOLVING",
  },
];
