export const initialInputValues = {
  name: "",
  lastName: "",
  email: "",
  organization: "",
};

export const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      "color": "#242c5c",
      "fontFamily": "Inter-Medium, Montserrat, Verdana, sans-serif",
      "fontSize": "22px",
      "::placeholder": { color: "#8a93c3" },
    },
    invalid: {
      color: "#f1666a",
      iconColor: "#f1666a",
    },
  },
};

export const MODES = {
  checkout: "checkout",
  update: "update",
  payment: "payment",
  openai: "openai",
};

export const BUTTON_TEXT = {
  checkout: "CHECKOUT",
  update: "UPDATE PAYMENT METHOD",
  payment: "COMPLETE PAYMENT",
};

export const MODAL_TITLE = {
  checkout: "Checkout",
  update: "Update payment details",
  payment: "Complete payment",
};

export const STATE_BY_MODE = {
  checkout: "creating",
  update: "updatePaymentMethod",
  payment: "requestPayment",
  openai: "openai",
};

export const CONFIRMATION_TEXT = {
  checkout: "CONFIRMATION_TEXT_CHECKOUT",
  update: "CONFIRMATION_TEXT_UPDATE",
  payment: "CONFIRMATION_TEXT_PAYMENT",
  incomplete: "CONFIRMATION_TEXT_INCOMPLETE",
  personal: "CONFIRMATION_TEXT_PERSONAL",
};
