import React from "react";

import { getTranslation } from "../../../../helpers/getLanguage";

const DonutIdeasDiagram = ({ values, colors, ideasCount }) => {
  const total = values.reduce((p, n) => p + n, 0);
  if (total === 0) {
    return null;
  }

  const valuesPercent = values.map((value) => (value / total) * 100);
  const realValuesLength = valuesPercent.filter((value) => value > 0).length;
  const sections = [];
  const dividers = [];
  let dashOffset = 0;
  let offset = -90;

  valuesPercent.forEach((value, index) => {
    let isShadow = index === 2 ? " green-sector-shadow-0" : "";
    if (index === 2) offset += (360 * value) / 100 / 2 - 2;
    if (index === 2 && value >= 50) isShadow = " green-sector-shadow-50";
    if (index === 2 && value === 100) isShadow = " green-sector-shadow-100";

    const elem = (
      <circle
        key={index}
        cx="50%"
        cy="50%"
        r="16"
        className={"ideas-donut-diagram" + isShadow}
        strokeDasharray={`${value === 100 || index === 2 ? value + 1 : value} 100`}
        strokeDashoffset={dashOffset}
        stroke={colors[index] || "#ffffff"}
        strokeWidth={index === 2 ? 5 : 3}
      />
    );
    if (value > 0 && realValuesLength > 0) sections.push(elem);

    dashOffset -= value;
  });

  dashOffset = 0.1;

  valuesPercent.forEach((value, index) => {
    const divider = (
      <circle
        key={index}
        cx="50%"
        cy="50%"
        r="16"
        className="ideas-donut-diagram"
        strokeDasharray={`0.6 100`}
        strokeDashoffset={dashOffset}
        strokeWidth={5}
        stroke="#ffffff"
      />
    );
    dashOffset -= value;

    if (value > 0 && realValuesLength > 1) dividers.push(divider);
  });

  return (
    <div className="donut-diagram-container flex-center">
      <span className="donut-ideas-diagram-label">{total}</span>
      <svg viewBox="0 0 37 37" style={{ transform: `rotate(${offset + 1.5}deg)` }}>
        {sections}
        {dividers}
      </svg>
    </div>
  );
};

export default DonutIdeasDiagram;
