import React from "react";

import Message from "./Message";

const Thread = ({ messages, issueId, userId, ...props }) => {
  const messageElements = messages.map((message, index) => {
    if (!message.userId) {
      message.userId = userId;
    }

    if (!message.issueId) {
      message.issueId = issueId;
    }

    return (
      <Message
        key={index}
        issueId={issueId}
        userFullName={props.userFullName}
        issueIndex={props.issueIndex}
        message={message}
        messages={messages}
        userId={userId}
        index={index}
        handleMessageChange={props.handleMessageChange}
        addMessage={props.addMessage}
        removeMessage={props.removeMessage}
        removeLastMessage={props.removeLastMessage}
      />
    );
  });

  return <div className="thread-container">{messageElements}</div>;
};

export default Thread;
