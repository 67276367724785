import React, { useEffect } from "react";
import { useState } from "react";

import { createCurrentIssue, deleteCurrentIssue, updateCurrentIssue } from "../../../../api/admin";
import { getCurrentIssues } from "../../../../api/currentIssues";
import { getTranslation } from "../../../../helpers/getLanguage";
import IssueItem from "../../../CurrentIssues/issue-item";
import CustomCheckbox from "../../../inputs/CustomCheckbox";
import CustomInput from "../../../inputs/CustomInput";
import "./styles.css";

const IssuesConfiguration = () => {
  const [currentTitle, setTitle] = useState("");
  const [currentText, setText] = useState("");
  const [currentIssues, setCurrentIssues] = useState([]);
  const [createOngoing, setCreateOngoing] = useState(false);
  const [error, setError] = useState({ title: "", text: "" });
  const [isPending, setIsPending] = useState(false);

  useEffect(() => {
    if (!currentIssues.length) {
      getCurrentIssues({}, (response) => setCurrentIssues(response));
    }
  }, []);

  const handleCreateIssue = () => {
    if (isPending) {
      return;
    }

    if (!currentText.length || !currentTitle.length) {
      return setError({
        title: !currentTitle.length ? "Should not be empty" : "",
        text: !currentText.length ? "Should not be empty" : "",
      });
    }

    setIsPending(true);
    setError({
      title: "",
      text: "",
    });
    createCurrentIssue({ title: currentTitle, text: currentText, ongoing: createOngoing }, (response) => {
      setCurrentIssues([response, ...currentIssues]);
      setIsPending(false);
    });
  };

  const handleStatusChange = (id, ongoing) => () => {
    setIsPending(true);
    updateCurrentIssue({ id, ongoing }, (response) => {
      setCurrentIssues(currentIssues.map((issue) => (issue.id === response.id ? response : issue)));
      setIsPending(false);
    });
  };

  const handleDelete = (id) => () => {
    setIsPending(true);

    deleteCurrentIssue({ id }, () => {
      setCurrentIssues(currentIssues.filter((i) => i.id !== id));
      setIsPending(false);
    });
  };

  return (
    <div className="issues-tab flex-column">
      <div className="issue-create-container">
        <span>{getTranslation("ADMIN_CURRENT_ISSUES_CREATE_NEW_ISSUE_TEXT")}</span>
        <CustomInput
          value={currentTitle}
          name="title"
          onChange={(e) => setTitle(e.target.value)}
          onKeyDown={() => {}}
          containerClass="flex-column width-100 mb-10"
          label={getTranslation("ADMIN_CURRENT_ISSUES_CREATE_TITLE")}
          onBlur={(e) => {}}
          errorMessage={error.title}
        />
        <CustomInput
          value={currentText}
          name="text"
          onChange={(e) => setText(e.target.value)}
          onKeyDown={() => {}}
          containerClass="flex-column width-100 mb-10"
          label={getTranslation("ADMIN_CURRENT_ISSUES_CREATE_BODY")}
          onBlur={(e) => {}}
          errorMessage={error.text}
        />
        {createOngoing && <span>{getTranslation("ADMIN_CURRENT_ISSUES_CREATE_UNRESOLVED_WARNING")}</span>}
        <br />
        <div className="flex justify-content-center align-items-center">
          <CustomCheckbox checked={createOngoing} handleChange={setCreateOngoing} />
          <span className="ml-15">{getTranslation("ADMIN_CURRENT_ISSUES_CREATE_UNRESOLVED")}</span>
        </div>
        <button className="button-create" onClick={handleCreateIssue} disabled={isPending}>
          {getTranslation("GENERAL_BUTTON_TEXT_CREATE")}
        </button>
      </div>
      <div className="issue-display">
        <span>{getTranslation("ADMIN_CURRENT_ISSUES_UPDATE_WARNING")}</span>
        {currentIssues.map((i) => (
          <IssueItem
            key={i.id}
            title={i.title}
            text={i.text}
            date={i.date}
            ongoing={i.ongoing}
            onStatusClick={handleStatusChange(i.id, !i.ongoing)}
            handleDelete={handleDelete(i.id)}
            isPending={isPending}
          />
        ))}
      </div>
    </div>
  );
};

export default IssuesConfiguration;
