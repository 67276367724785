import React from "react";

import { ReactComponent as ContradictionIcon } from "../../../assets/images/contradiction-icon.svg";
import { ReactComponent as EurekaIcon } from "../../../assets/images/eureka-report-icon.svg";
import { ReactComponent as DetailsMatrixIcon } from "../../../assets/images/nav-attribute-dependency.svg";
import { ReactComponent as MultiplicationIcon } from "../../../assets/images/nav-multiplication.svg";
import { ReactComponent as ReplacementIcon } from "../../../assets/images/nav-replacement.svg";
import { ReactComponent as SubtractionIcon } from "../../../assets/images/nav-subtraction.svg";
import { getTranslation } from "../../../helpers/getLanguage";

const scoreColors = ["#bde8ca", "#a4dfb6", "#8bd6a2", "#73ce8e", "#5ac57a"];

const getEntityName = (entity) => {
  if (!entity || (!entity.componentId && entity.locked)) {
    return "";
  }

  return entity.name;
};

const IdeasTab = ({
  ideas,
  components,
  attributes,
  navigateToReports,
  inProgress = false,
  projectName,
  contradictions,
}) => {
  const activeClass = inProgress ? " in-progress" : "";
  let eurekaIdeasCount = 0;

  const ideasElements = ideas.map((idea, index) => {
    const {
      idAttributeRow,
      idAttributeCol,
      idRemoteComponent,
      idReplacingComponent,
      name,
      idMultiplicationComponent,
      contradictionSolvingType,
    } = idea;
    const typeofIdea =
      idRemoteComponent && idReplacingComponent
        ? "(R)"
        : idRemoteComponent
          ? "(S)"
          : idAttributeCol
            ? "(AD)"
            : idMultiplicationComponent
              ? "(M)"
              : contradictionSolvingType
                ? "(CA)"
                : "(EU)";
    typeofIdea && eurekaIdeasCount++;

    const firstAttribute = attributes.find((attribute) => attribute.id === idAttributeRow);
    const firstComponent =
      firstAttribute && components.find((component) => component.id === firstAttribute.componentId);

    const secondAttribute = attributes.find((attribute) => attribute.id === idAttributeCol);
    const secondComponent =
      secondAttribute && components.find((component) => component.id === secondAttribute.componentId);
    const remoteComponent = components.find((component) => component.id === idRemoteComponent);
    const replacingComponent = components.find((component) => component.id === idReplacingComponent);
    const multipliedComponent = components.find((component) => component.id === idMultiplicationComponent);

    const isReversed = idea.isReversed;

    const ideaInfo = {
      firstComponent: getEntityName(isReversed ? secondComponent : firstComponent),
      firstAttribute: getEntityName(isReversed ? secondAttribute : firstAttribute),
      secondComponent: getEntityName(isReversed ? firstComponent : secondComponent),
      secondAttribute: getEntityName(isReversed ? firstAttribute : secondAttribute),
    };

    const rating = isReversed ? idea.reversed.rating : idea.rating;

    const methodsTranslation = {
      attributeDependency: "Attribute Dependency",
      taskUnification: "Task Unification",
    };

    const solvingDescription = `Solving for contradiction ${
      contradictions.find((contradiction) => contradiction.id === idea.contradictionId)?.contradictionTitle
    } using the ${methodsTranslation[idea.contradictionSolvingType]}`;

    const contradictionSolvingDescription = idea.contradictionSolvingType && solvingDescription;

    const ideaDescriptionSubtraction = `${getTranslation("IDEAS_TAB_THE")} ${projectName} ${getTranslation(
      "IDEAS_TAB_WITHOUT_THE",
    )} ${remoteComponent?.name}`;

    const ideaDescriptionMultiplication = `${getTranslation("IDEAS_TAB_THE")} ${projectName} ${getTranslation(
      "IDEAS_TAB_IN_WHICH",
    )} ${multipliedComponent?.name} ${getTranslation("IDEAS_TAB_IS_MULTIPLIED")}`;

    const ideaDescriptionEureka = `${name || "Potential eureka idea #" + eurekaIdeasCount}`;

    const ideaDescriptionReplacement = `${getTranslation("IDEAS_TAB_THE")} ${projectName} ${getTranslation(
      "REPLACEMENT_IN_WHICH",
    )} ${remoteComponent?.name} ${getTranslation("REPLACEMENT_IS_REPlACED")} ${replacingComponent?.name}`;

    let ideaDescriptionDetails = ideaInfo.secondAttribute;
    ideaInfo.secondComponent &&
      (ideaDescriptionDetails += ` ${getTranslation("IDEAS_TAB_OF")} ${ideaInfo.secondComponent}`);

    ideaDescriptionDetails += ` ${getTranslation("IDEAS_TAB_INFLUENCED_BY")} ${ideaInfo.firstAttribute}`;
    ideaInfo.firstComponent &&
      (ideaDescriptionDetails += ` ${getTranslation("IDEAS_TAB_OF")} ${ideaInfo.firstComponent}`);

    const description =
      typeofIdea === "(S)"
        ? ideaDescriptionSubtraction
        : typeofIdea === "(R)"
          ? ideaDescriptionReplacement
          : typeofIdea === "(EU)"
            ? ideaDescriptionEureka
            : typeofIdea === "(M)"
              ? ideaDescriptionMultiplication
              : typeofIdea === "(CA)"
                ? contradictionSolvingDescription
                : ideaDescriptionDetails;

    return (
      <div
        key={index}
        className={"tab-idea-container flex align-center justify-space-between pointer" + activeClass}
        onClick={() => navigateToReports(idea, isReversed)}
      >
        <div className="flex tab-idea-container-row">
          {typeofIdea === "(S)" && (
            <div className="tab-idea-text-container flex mr-10">
              <SubtractionIcon width={25} height={25} />
              <span className="tab-idea-text">{typeofIdea}</span>
            </div>
          )}
          {typeofIdea === "(R)" && (
            <div className="tab-idea-text-container flex mr-10">
              <ReplacementIcon width={25} height={25} />
              <span className="tab-idea-text">{typeofIdea}</span>
            </div>
          )}
          {typeofIdea === "(EU)" && (
            <div className="tab-idea-text-container flex">
              <EurekaIcon width={25} height={25} />
              <span className="tab-idea-text" style={{ height: "23px", lineHeight: "23px" }}>
                {typeofIdea}
              </span>
            </div>
          )}
          {typeofIdea === "(AD)" && (
            <div className="tab-idea-text-container flex">
              <DetailsMatrixIcon width={25} height={25} />
              <span className="tab-idea-text">{typeofIdea}</span>
            </div>
          )}
          {typeofIdea === "(M)" && (
            <div className="tab-idea-text-container flex mr-10">
              <MultiplicationIcon width={25} height={25} />
              <span className="tab-idea-text" style={{ height: "23px", lineHeight: "23px" }}>
                {typeofIdea}
              </span>
            </div>
          )}
          {typeofIdea === "(CA)" && (
            <div className="tab-idea-text-container flex mr-10">
              <ContradictionIcon width={25} height={25} />
              <span className="tab-idea-text" style={{ height: "23px", lineHeight: "23px" }}>
                {typeofIdea}
              </span>
            </div>
          )}
          <span className="tab-idea-name">{description}</span>
        </div>
        {!inProgress && (
          <div className="idea-score flex flex-center" style={{ backgroundColor: scoreColors[rating - 1] }}>
            <span>{rating}</span>
          </div>
        )}
      </div>
    );
  });

  return <div className="flex width-100 flex-column">{ideasElements}</div>;
};

export default IdeasTab;
