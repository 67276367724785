import React from "react";

import "./styles.css";

const BulletPoint = () => (
  <div className="flex-center bullet-point-container">
    <div className="bullet-point-item"></div>
  </div>
);

export default BulletPoint;
