import React, { useState } from "react";

import ContactUsModal from "./ContactUsModal";

const SupportModal = ({ children }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleClick = (e) => {
    e && e.stopPropagation();
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <div className="support-button-container pointer" onClick={handleClick}>
        {children}
      </div>
      {modalOpen && (
        <ContactUsModal
          autoFocus={true}
          closeDialog={() => setModalOpen(false)}
          onConfirm={() => setModalOpen(false)}
        />
      )}
    </>
  );
};

export default SupportModal;
