import {
  archiveFolder as requestArchiveFolder,
  createFolder as requestCreateFolder,
  moveProjectOutOfFolder as requestMoveProjectOutOfFolder,
  moveProjectToFolder as requestMoveProjectToFolder,
  getProjectFolders as requestProjectFolders,
  removeFolder as requestRemoveFolder,
  renameFolder as requestRenameFolder,
} from "../api/projectFolders";
import { getProjectView, getProject as requestProject, getProjects as requestProjects } from "../api/projects";
import {
  CREATE_PROJECT_FOLDER,
  DEFAULT_OPENAI_GENERATE,
  GET_PROJECT_FOLDERS,
  GET_PROJECT_INFO,
  GET_PROJECT_INFO_ERROR,
  GET_PROJECT_INFO_SUCCESS,
  GET_PROJECTS_ERROR,
  GET_PROJECTS_SUCCESS,
  MOVE_PROJECT_OUT_OF_FOLDER,
  MOVE_PROJECT_TO_FOLDER,
  REMOVE_PROJECT_FOLDER,
  RENAME_PROJECT_FOLDER,
  RESET_CURRENT_COMPONENTS,
  RESET_OPENAI_GENERATE_COMPONENTS,
  RESET_OPENAI_GENERATE_WORDS,
  RESET_PROJECT_INFO,
  RESET_PROJECTS_STORE,
  SET_COMPONENT_CACHED_AI,
  SET_NUMBER_OPENAI_REQUESTS,
  SET_PROJECT_CACHED_AI,
  SET_STEPS_OPENAI,
} from "./types";

export const getProjectInfo = (id) => (dispatch) => {
  dispatch({ type: GET_PROJECT_INFO });

  const onSuccess = (response) => dispatch({ type: GET_PROJECT_INFO_SUCCESS, payload: response });
  const onError = (error) => dispatch({ type: GET_PROJECT_INFO_ERROR, payload: error });

  const params = { id, excludeDisabled: true };
  requestProject({ params }, onSuccess, onError);
};

export const getProjectViewInfo = (id) => (dispatch) => {
  dispatch({ type: GET_PROJECT_INFO });

  const onSuccess = (response) => dispatch({ type: GET_PROJECT_INFO_SUCCESS, payload: response });
  const onError = (error) => dispatch({ type: GET_PROJECT_INFO_ERROR, payload: error });

  const params = { id, excludeDisabled: true };
  getProjectView({ params }, onSuccess, onError);
};

export const getProjectInfoForReports = (id) => (dispatch) => {
  dispatch({ type: GET_PROJECT_INFO });

  const onSuccess = (response) => dispatch({ type: GET_PROJECT_INFO_SUCCESS, payload: response });
  const onError = (error) => dispatch({ type: GET_PROJECT_INFO_ERROR, payload: error });

  const params = { id, excludeDisabled: false };
  requestProject({ params }, onSuccess, onError);
};

export const setLastWordsToGeneratedOpenAIComponents = (words) => (dispatch) => {
  dispatch({ type: RESET_OPENAI_GENERATE_WORDS, payload: words });
};

export const setGeneratedOpenAIComponents = (components) => (dispatch) => {
  dispatch({ type: RESET_OPENAI_GENERATE_COMPONENTS, payload: components });
};

export const setCurrentComponents = (components) => (dispatch) => {
  dispatch({ type: RESET_CURRENT_COMPONENTS, payload: components });
};

export const setStepsOpenAI = (steps) => (dispatch) => {
  dispatch({ type: SET_STEPS_OPENAI, payload: steps });
};

export const setGeneratedOpenAIRequests = (numberRequests) => (dispatch) => {
  dispatch({ type: SET_NUMBER_OPENAI_REQUESTS, payload: numberRequests });
};

export const getFullProjectInfo = (id) => (dispatch) => {
  dispatch({ type: GET_PROJECT_INFO });

  const onSuccess = (response) => dispatch({ type: GET_PROJECT_INFO_SUCCESS, payload: response });
  const onError = (error) => dispatch({ type: GET_PROJECT_INFO_ERROR, payload: error });

  const params = { id, excludeDisabled: false };
  requestProject({ params }, onSuccess, onError);
};

export const getProjects = () => (dispatch) => {
  const onSuccess = (response) => dispatch({ type: GET_PROJECTS_SUCCESS, payload: response });
  const onError = (error) => dispatch({ type: GET_PROJECTS_ERROR, payload: error });

  const params = { excludeDisabled: true };
  requestProjects({ params }, onSuccess, onError);
};
export const resetProjectInfo = () => (dispatch) => {
  dispatch({ type: DEFAULT_OPENAI_GENERATE });
  return { type: RESET_PROJECT_INFO };
};

export const clearProjectStore = () => {
  return { type: RESET_PROJECTS_STORE };
};

// Folder logic
export const getProjectFolders = () => (dispatch) => {
  const onSuccess = (response) => dispatch({ type: GET_PROJECT_FOLDERS, payload: response });
  const onError = (error) => dispatch({ type: GET_PROJECTS_ERROR, payload: error });

  requestProjectFolders({}, onSuccess, onError);
};

export const moveProjectToFolder = (projectId, folderId, onSuccessAction) => (dispatch) => {
  const onSuccess = () => {
    dispatch({ type: MOVE_PROJECT_TO_FOLDER, payload: { projectId, folderId } });
    onSuccessAction && onSuccessAction();
  };
  const onError = (error) => dispatch({ type: GET_PROJECTS_ERROR, payload: error });

  requestMoveProjectToFolder({ projectId, folderId }, onSuccess, onError);
};

export const moveProjectOutOfFolder = (projectId, folderId, onSuccessAction) => (dispatch) => {
  const onSuccess = () => {
    dispatch({ type: MOVE_PROJECT_OUT_OF_FOLDER, payload: { projectId, folderId } });
    onSuccessAction && onSuccessAction();
  };
  const onError = (error) => dispatch({ type: GET_PROJECTS_ERROR, payload: error });

  requestMoveProjectOutOfFolder({ projectId, folderId }, onSuccess, onError);
};

export const renameFolder = (folderId, newName, onSuccessAction) => (dispatch) => {
  const onSuccess = () => {
    dispatch({ type: RENAME_PROJECT_FOLDER, payload: { folderId, newName } });
    onSuccessAction && onSuccessAction();
  };
  const onError = (error) => dispatch({ type: GET_PROJECTS_ERROR, payload: error });

  requestRenameFolder({ folderId, newName }, onSuccess, onError);
};

export const removeFolder = (folderId, onSuccessAction) => (dispatch) => {
  const onSuccess = () => {
    dispatch({ type: REMOVE_PROJECT_FOLDER, payload: { folderId } });
    onSuccessAction && onSuccessAction();
  };
  const onError = (error) => dispatch({ type: GET_PROJECTS_ERROR, payload: error });

  requestRemoveFolder({ folderId }, onSuccess, onError);
};

export const createFolder = (folderName, onSuccessAction) => (dispatch) => {
  const onSuccess = (response) => {
    dispatch({ type: CREATE_PROJECT_FOLDER, payload: response });
    onSuccessAction && onSuccessAction();
  };
  const onError = (error) => dispatch({ type: GET_PROJECTS_ERROR, payload: error });

  requestCreateFolder({ folderName }, onSuccess, onError);
};

export const archiveFolder = (folderId, onSuccessAction) => (dispatch) => {
  const onSuccess = (response) => {
    onSuccessAction && onSuccessAction();
  };
  const onError = (error) => dispatch({ type: GET_PROJECTS_ERROR, payload: error });

  requestArchiveFolder({ folderId }, onSuccess, onError);
};

export const setProjectHasAIResponsesCache = (status) => (dispatch) => {
  dispatch({ type: SET_PROJECT_CACHED_AI, payload: status });
};

export const setComponentsHasAIResponsesCache = (componentId) => (dispatch) => {
  dispatch({ type: SET_COMPONENT_CACHED_AI, payload: { componentId } });
};
