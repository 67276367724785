import axios from "axios";

import { baseApiUrl } from "../common/constants";
import { handleError, handleSuccess } from "./handlers";

const baseUrl = `${baseApiUrl}/users`;

export const changeUserName = async (params, onSuccess, onError) => {
  axios
    .post(`${baseUrl}/change-name`, params)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const changeUserPasswordByEmail = async (params, onSuccess, onError) => {
  axios
    .post(`${baseUrl}/change-user-password`, params)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const changeUserPassword = async (params, onSuccess, onError) => {
  axios
    .post(`${baseUrl}/change-password`, params)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const changeUserRoles = async (params, onSuccess, onError) => {
  axios
    .post(`${baseUrl}/change-user-roles`, params)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const changeUserFeatures = async (params, onSuccess, onError) => {
  axios
    .post(`${baseUrl}/change-user-features`, params)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const deleteUser = async (params, onSuccess, onError) => {
  axios
    .post(`${baseUrl}/delete`, params)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const verifyEmail = async (params, onSuccess, onError) => {
  axios
    .post(`${baseUrl}/verify-user-email`, params)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const setUserLanguage = async (params, onSuccess, onError) => {
  axios
    .post(`${baseUrl}/change-language`, params)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const getProfile = async (params, onSuccess, onError) => {
  axios
    .get(`${baseUrl}/profile`, params)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const getRoles = async (params, onSuccess, onError) => {
  axios
    .get(`${baseUrl}/role`, params)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const getOrganization = async (params, onSuccess, onError) => {
  axios
    .get(`${baseUrl}/organization`, params)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const getUsersReport = async (params, onSuccess, onError) => {
  axios
    .get(`${baseUrl}/report`, params)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const getAllUsers = async (params, onSuccess, onError) => {
  axios
    .get(`${baseUrl}/get-all`, params)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const getReadIssuesByUser = async (params, onSuccess, onError) => {
  axios
    .get(`${baseUrl}/get-read-issues`, params)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};
