import React, { useEffect, useMemo, useState } from "react";

import { getAnswer, getAnswerWithImage } from "../../../../api/ai";
import {
  addNewPrefab,
  deletePrefabsById,
  getPrefabsByType,
  updateOnePrefab,
  updateSelectedPrefab,
} from "../../../../api/prefabs";
import { ReactComponent as PlusIconSmall } from "../../../../assets/images/plus-icon-small.svg";
import { optionsOfLanguageForChatGPTPrompts } from "../../../../constants/language";
import {
  dropdowns,
  prefabsForAttributes,
  prefabsForBenefits,
  prefabsForChallenges,
  prefabsForComponentsSteps,
  prefabsForContradictions,
  prefabsForImages,
  prefabsForNames,
} from "../../../../constants/prefabsChatGTPtabs";
import { getTranslation } from "../../../../helpers/getLanguage";
import CustomScrollbar from "../../../common/CustomScrollbar";
import Prefab from "../../../common/Prefab";
import AddNewPrefabModal from "../../../dialogs/AddNewPrefabModal";
import AnswersToPrefabModal from "../../../dialogs/AnswersToPrefabModal";
import ConfirmationModal from "../../../dialogs/ConfirmationModal";
import ErrorMessageModal from "../../../dialogs/ErrorMessageModal";
import GenerateImageModal from "../../../dialogs/GenerateImageModal";
import CustomDropdown from "../../../inputs/CustomDropdown";
import CustomInput from "../../../inputs/CustomInput";
import CustomTextarea from "../../../inputs/CustomTextarea";
import "./style.css";

const PrefabsChatGTP = ({ authorizedUser }) => {
  const [activeOption, setActiveOption] = useState({
    typePrompt: prefabsForComponentsSteps[0].value,
    languagePrompt: optionsOfLanguageForChatGPTPrompts[0].value,
  });
  const [openAddPrefabModal, setOpenAddPrefabModal] = useState(false);
  const [allPrefabsByType, setAllPrefabsByType] = useState([]);
  const [error, setError] = useState({
    openErrorModal: false,
    message: "",
    headerError: "",
  });
  const [selectedPrefabs, setSelectedPrefabs] = useState(new Set());
  const [openDeleteModalWindow, setOpenDeleteModalWindow] = useState(false);
  const [testPrefab, setTestPrefab] = useState({
    componentName: "",
    typeName: "",
    descriptionOfIdea: "",
    descriptionOfProject: "",
  });
  const [isAnswerModal, setIsAnswerModal] = useState(false);
  const [suggestedLoading, setSuggestedLoading] = useState(false);
  const [suggestedAnswers, setSuggestedAnswers] = useState([]);
  const [isAnswerWithImage, setIsAnswerWithImage] = useState(false);
  const [urlImage, setUrlImage] = useState("");

  const countDeletedPrefab = selectedPrefabs.size;

  useEffect(() => {
    getAllPrefabs();
  }, [activeOption]);

  console.log(activeOption);

  const getAllPrefabs = () => {
    const onSuccess = (prefabs) => setAllPrefabsByType(prefabs);

    const onError = (error) => {
      setError({
        openErrorModal: true,
        message: error.message,
        headerError: getTranslation("ERROR_MESSAGE_MODAL_HEADER"),
      });
    };

    getPrefabsByType(activeOption, onSuccess, onError);
  };

  const saveEditedPrefab = (editedPrefab) => {
    if (!editedPrefab.textPrompt.trim()) {
      setError({
        openErrorModal: true,
        message: "The field must not be empty",
        headerError: getTranslation("ERROR_MESSAGE_MODAL_HEADER"),
      });
      return;
    }

    const date = new Date();
    const params = {
      id: editedPrefab.id,
      textPrompt: editedPrefab.textPrompt,
      edited: `${authorizedUser.name} ${authorizedUser.lastName}`,
      editDate: date,
      language: activeOption.languagePrompt,
    };

    const onSuccess = (changedPrefab) => {
      const updatePrefabs = [...allPrefabsByType];
      const editedPrefab = updatePrefabs.find((prefab) => prefab.id === changedPrefab.id);
      editedPrefab.textPrompt = changedPrefab.textPrompt;
      editedPrefab.edited = changedPrefab.edited;
      editedPrefab.editDate = changedPrefab.editDate;
      setAllPrefabsByType(updatePrefabs);
    };

    const onError = (error) => {
      setError({
        openErrorModal: true,
        message: error.message,
        headerError: getTranslation("ERROR_MESSAGE_MODAL_HEADER"),
      });
    };

    updateOnePrefab(params, onSuccess, onError);
  };

  const reselectPrefab = (e) => {
    const updatePrefabs = [...allPrefabsByType];
    const oldPrefab = updatePrefabs.find((prefab) => prefab.isSelected === true);
    const newPrefab = updatePrefabs.find((prefab) => prefab.id === e.target.id);

    const params = {
      typePrompt: activeOption.typePrompt,
      idOldPrompt: oldPrefab.id,
      idNewPrompt: newPrefab.id,
      isSelectedOldPrompt: false,
      isSelectedNewPrompt: true,
    };

    const onSuccess = (response) => {
      const firstUpdatePrefab = response.find((prefab) => prefab.id === oldPrefab.id);
      oldPrefab.isSelected = firstUpdatePrefab.isSelected;

      const secondUpdatePrefab = response.find((prefab) => prefab.id === newPrefab.id);
      newPrefab.isSelected = secondUpdatePrefab.isSelected;
      setAllPrefabsByType(updatePrefabs);
    };

    const onError = (error) => {
      setError({
        openErrorModal: true,
        message: error.message,
        headerError: getTranslation("ERROR_MESSAGE_MODAL_HEADER"),
      });
    };

    updateSelectedPrefab(params, onSuccess, onError);
  };

  const closeAddPrefabModal = () => {
    setOpenAddPrefabModal(false);
  };

  const saveNewPrefab = (newPrefab) => {
    if (!newPrefab.trim()) {
      setError({
        openErrorModal: true,
        message: "The field must not be empty",
        headerError: getTranslation("ERROR_MESSAGE_MODAL_HEADER"),
      });
      return;
    }

    const date = new Date();
    const params = {
      typePrompt: activeOption.typePrompt,
      created: `${authorizedUser.name} ${authorizedUser.lastName}`,
      creationDate: date,
      textPrompt: newPrefab,
      language: activeOption.languagePrompt,
    };

    const onSuccess = (newPrefabs) => {
      if (activeOption.languagePrompt === "en") {
        const newPrefab = newPrefabs.find((prefab) => prefab.language === activeOption.languagePrompt);
        setAllPrefabsByType([newPrefab, ...allPrefabsByType]);
        return;
      }

      setAllPrefabsByType([newPrefabs, ...allPrefabsByType]);
    };

    const onError = (error) => {
      setError({
        openErrorModal: true,
        message: error.message,
        headerError: getTranslation("ERROR_MESSAGE_MODAL_HEADER"),
      });
    };

    addNewPrefab(params, onSuccess, onError);
    closeAddPrefabModal();
  };

  const closeErrorWindow = () => {
    setError({
      openErrorModal: false,
      message: "",
      headerError: "",
    });
  };

  const selectPrefabForDelete = (idPrefab) => {
    setSelectedPrefabs((prev) => {
      const prevSelectedItem = new Set(prev);
      prev.has(idPrefab) ? prevSelectedItem.delete(idPrefab) : prevSelectedItem.add(idPrefab);

      return prevSelectedItem;
    });
  };

  const deletePrefab = () => {
    const onSuccess = () => {
      const updatedPrefabsByType = allPrefabsByType.filter((prefab) => !selectedPrefabs.has(prefab.id));
      setAllPrefabsByType(updatedPrefabsByType);
      setSelectedPrefabs(new Set());
      setOpenDeleteModalWindow(false);
    };

    const onError = (error) => {
      setError({
        openErrorModal: true,
        message: error.message,
        headerError: getTranslation("ERROR_MESSAGE_MODAL_HEADER"),
      });
    };

    const allSelectedPrafabForDeleting = [];
    selectedPrefabs.forEach((prefab) => {
      allSelectedPrafabForDeleting.push(prefab);
    });

    deletePrefabsById(allSelectedPrafabForDeleting, onSuccess, onError);
  };

  const titleText = useMemo(() => {
    switch (activeOption.typePrompt) {
      case "externalComponent":
        return "ADMIN_PANEL_PREFABS_TAB_EXTERNAL_COMPONENT";
      case "internalStepsCustomer":
        return "ADMIN_PANEL_PREFABS_TAB_STEPS_CUSTOMER";
      case "internalStepsCompany":
        return "ADMIN_PANEL_PREFABS_TAB_STEPS_COMPANY";
      case "attributeProductService":
        return "ADMIN_PANEL_PREFABS_TAB_ATTRIBUTE_COMPONENT";
      case "attributeStepsCustomer":
        return "ADMIN_PANEL_PREFABS_TAB_ATTRIBUTE_STEP_CUSTOMER";
      case "attributeStepsCompany":
        return "ADMIN_PANEL_PREFABS_TAB_ATTRIBUTE_STEP_COMPANY";
      case "namesIdeas":
        return "ADMIN_PANEL_PREFABS_TAB_NAMES_IDEAS";
      case "benefitsIdeas":
        return "ADMIN_PANEL_PREFABS_TAB_BENEFITS";
      case "challengesIdeas":
        return "ADMIN_PANEL_PREFABS_TAB_CHALLENGES";
      case "imagesIdeas":
        return "ADMIN_PANEL_PREFABS_TAB_IMAGE_IDEA";
      case "imagesProjects":
        return "ADMIN_PANEL_PREFABS_TAB_IMAGE_PROJECT";
      case "contradictionsGeneration":
        return "ADMIN_PANEL_PREFABS_TAB_CONTRADICTION_GENERATING";
      case "contradictionsSolving":
        return "ADMIN_PANEL_PREFABS_TAB_CONTRADICTION_SOLVING";
      default:
        return "ADMIN_PANEL_PREFABS_TAB_INTERNAL_COMPONENT";
    }
  }, [activeOption.typePrompt]);

  const sendTestPrompt = () => {
    const onSuccess = (parsedChoices) => {
      setSuggestedAnswers(parsedChoices);
      setSuggestedLoading(false);
      setIsAnswerModal(true);
    };

    const onError = (error) => {
      setError({
        openErrorModal: true,
        message: error.message,
        headerError: getTranslation("ERROR_MESSAGE_MODAL_HEADER"),
      });
      setSuggestedLoading(false);
    };

    setSuggestedLoading(true);

    const selectedPrompt = allPrefabsByType.filter((prompt) => prompt.isSelected);

    const params = {
      prompt: selectedPrompt[0].textPrompt,
      type: selectedPrompt[0].typePrompt,
      componentName: testPrefab.componentName || null,
      typeName: testPrefab.typeName || null,
      descriptionOfIdea: testPrefab.descriptionOfIdea || null,
      descriptionOfProject: testPrefab.descriptionOfProject || null,
    };

    setIsAnswerModal(true);

    getAnswer(params, onSuccess, onError);
  };

  const sendTestPromptForImage = () => {
    const onSuccess = (imageBase64) => {
      setUrlImage(`data:image/png;base64,${imageBase64}`);
      setSuggestedLoading(false);
    };

    const onError = (error) => {
      setError({
        openErrorModal: true,
        message: error.message,
        headerError: getTranslation("ERROR_MESSAGE_MODAL_HEADER"),
      });
      setSuggestedLoading(false);
      setIsAnswerWithImage(false);
    };

    if (activeOption.typePrompt === "imagesIdeas" && !testPrefab.descriptionOfIdea.trim()) {
      setError({
        openErrorModal: true,
        message: getTranslation("ADMIN_PANEL_PREFABS_TAB_NO_DESCRIPTION_OF_IDEA"),
        headerError: getTranslation("ERROR_MESSAGE_MODAL_HEADER"),
      });
      return;
    }

    if (activeOption.typePrompt === "imagesProjects" && !testPrefab.descriptionOfProject.trim()) {
      setError({
        openErrorModal: true,
        message: getTranslation("ADMIN_PANEL_PREFABS_TAB_NO_DESCRIPTION_OF_PROJECT"),
        headerError: getTranslation("ERROR_MESSAGE_MODAL_HEADER"),
      });
      return;
    }

    const selectedPrompt = allPrefabsByType.filter((prompt) => prompt.isSelected);

    const params = {
      prompt: selectedPrompt[0].textPrompt,
      type: selectedPrompt[0].typePrompt,
      descriptionOfIdea: testPrefab.descriptionOfIdea.trim() || null,
      descriptionOfProject: testPrefab.descriptionOfProject.trim() || null,
    };

    setIsAnswerWithImage(true);
    setSuggestedLoading(true);

    getAnswerWithImage(params, onSuccess, onError);
  };

  return (
    <div className="prefabs-chat-gtp flex flex-column">
      <p className="prefabs-chat-gtp-header">
        {getTranslation("ADMIN_PANEL_PREFABS_TEXT")} {getTranslation(titleText)}
      </p>
      <div className="flex mb-20 flex-wrap">
        <CustomDropdown
          value={dropdowns[0]}
          options={prefabsForComponentsSteps}
          className="white-no-wrap mb-20 mr-15"
          handleChange={(option) => setActiveOption({ ...activeOption, typePrompt: option })}
          isTooltip={true}
        />
        <CustomDropdown
          value={dropdowns[1]}
          options={prefabsForAttributes}
          className="white-no-wrap mb-20 mr-15"
          handleChange={(option) => setActiveOption({ ...activeOption, typePrompt: option })}
          isTooltip={true}
        />
        <CustomDropdown
          value={dropdowns[2]}
          options={prefabsForNames}
          className="white-no-wrap mb-20 mr-15"
          handleChange={(option) => setActiveOption({ ...activeOption, typePrompt: option })}
          isTooltip={true}
        />
        <CustomDropdown
          value={dropdowns[3]}
          options={prefabsForBenefits}
          className="white-no-wrap mb-20 mr-15"
          handleChange={(option) => setActiveOption({ ...activeOption, typePrompt: option })}
          isTooltip={true}
        />
        <CustomDropdown
          value={dropdowns[4]}
          options={prefabsForChallenges}
          className="white-no-wrap mb-20 mr-15"
          handleChange={(option) => setActiveOption({ ...activeOption, typePrompt: option })}
          isTooltip={true}
        />
        <CustomDropdown
          value={dropdowns[5]}
          options={prefabsForImages}
          className="white-no-wrap mb-20 mr-15"
          handleChange={(option) => setActiveOption({ ...activeOption, typePrompt: option })}
          isTooltip={true}
        />
        <CustomDropdown
          value={dropdowns[6]}
          options={prefabsForContradictions}
          className="white-no-wrap mb-20 mr-15"
          handleChange={(option) => setActiveOption({ ...activeOption, typePrompt: option })}
          isTooltip={true}
        />
      </div>

      <div className="flex-column mb-20">
        <div className="flex-column">
          <div className="flex">
            <CustomInput
              value={testPrefab.typeName}
              placeholder="[typeName]"
              containerClass="prefabs-chat-gtp__input"
              onChange={(e) => setTestPrefab({ ...testPrefab, typeName: e.target.value })}
              name="typeName"
            />
            <CustomInput
              value={testPrefab.componentName}
              placeholder="[componentName]"
              containerClass="prefabs-chat-gtp__input"
              onChange={(e) => setTestPrefab({ ...testPrefab, componentName: e.target.value })}
              name="componentName"
            />

            {(activeOption.typePrompt === "contradictionsGeneration" ||
              activeOption.typePrompt === "contradictionsSolving") && (
              <>
                <CustomInput
                  value={testPrefab.typeName}
                  placeholder="[typeName]"
                  containerClass="prefabs-chat-gtp__input"
                  onChange={(e) => setTestPrefab({ ...testPrefab, typeName: e.target.value })}
                  name="typeName"
                />
                <CustomInput
                  value={testPrefab.componentName}
                  placeholder="[componentName]"
                  containerClass="prefabs-chat-gtp__input"
                  onChange={(e) => setTestPrefab({ ...testPrefab, componentName: e.target.value })}
                  name="componentName"
                />
                <CustomInput
                  value={testPrefab.componentName}
                  placeholder="[componentName]"
                  containerClass="prefabs-chat-gtp__input"
                  onChange={(e) => setTestPrefab({ ...testPrefab, componentName: e.target.value })}
                  name="componentName"
                />
              </>
            )}
          </div>

          <div className="flex justify-space-between">
            <CustomTextarea
              value={testPrefab.descriptionOfIdea}
              placeholder="[descriptionOfIdea]"
              onChange={(e) => setTestPrefab({ ...testPrefab, descriptionOfIdea: e.target.value })}
              name="descriptionOfIdea"
              containerClass="prefabs-chat-gtp__textarea"
              height={110}
            />

            <CustomTextarea
              value={testPrefab.descriptionOfProject}
              placeholder="[descriptionOfProject]"
              onChange={(e) => setTestPrefab({ ...testPrefab, descriptionOfProject: e.target.value })}
              name="descriptionOfProject"
              containerClass="prefabs-chat-gtp__textarea ml-10"
              height={110}
            />
          </div>

          <div className="flex-wrap justify-space-between">
            <div className="flex prefabs-chat-gtp__optinon">
              <CustomDropdown
                value={activeOption.languagePrompt}
                options={optionsOfLanguageForChatGPTPrompts}
                className="white-no-wrap"
                handleChange={(language) => setActiveOption({ ...activeOption, languagePrompt: language })}
              />
              <button
                className="prefabs-chat-gtp-button test-button"
                onClick={
                  activeOption.typePrompt === "imagesIdeas" || activeOption.typePrompt === "imagesProjects"
                    ? sendTestPromptForImage
                    : sendTestPrompt
                }
              >
                {getTranslation("ADMIN_PANEL_PREFABS_TEXT_BUTTON_TEST_PROMPT")}
              </button>
            </div>

            <div className="flex justify-flex-end prefabs-chat-gtp__optinon">
              <button
                type="button"
                className="prefabs-chat-gtp-button delete"
                onClick={() => setOpenDeleteModalWindow(true)}
                disabled={!countDeletedPrefab}
              >
                {countDeletedPrefab
                  ? `${getTranslation("ADMIN_PANEL_PREFABS_TEXT_BUTTON_DELETE")} ${countDeletedPrefab} ${getTranslation(
                      "ADMIN_PANEL_PREFABS_TEXT_BUTTON_PROMPTS",
                    )}`
                  : getTranslation("ADMIN_PANEL_PREFABS_TEXT_BUTTON_DELETE_PROMPT")}
              </button>
              <button type="button" className="prefabs-chat-gtp-button add" onClick={() => setOpenAddPrefabModal(true)}>
                <PlusIconSmall style={{ marginRight: "10px" }} />
                {getTranslation("ADMIN_PANEL_PREFABS_TEXT_BUTTON_ADD")}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-column">
        <CustomScrollbar right={-20} dependencies={[allPrefabsByType]} style={{ height: "30vh" }}>
          {allPrefabsByType.map((prefab) => (
            <Prefab
              key={prefab.id}
              prefab={prefab}
              saveEditedPrefab={saveEditedPrefab}
              reselectPrefab={reselectPrefab}
              selectPrefabForDelete={selectPrefabForDelete}
              selectedPrefabs={selectedPrefabs}
            />
          ))}
        </CustomScrollbar>
      </div>

      {openAddPrefabModal && (
        <AddNewPrefabModal closeAddPrefabModal={closeAddPrefabModal} saveNewPrefab={saveNewPrefab} />
      )}

      {openDeleteModalWindow && (
        <ConfirmationModal
          className="prefabs-chat-gtp-delete-modal"
          closeDialog={() => setOpenDeleteModalWindow(false)}
          message={getTranslation("CONFIRM_MODAL_DELETE_PROMPT")}
          autoFocus={true}
          buttonText={`${getTranslation(
            "ADMIN_PANEL_PREFABS_TEXT_BUTTON_DELETE",
          )} ${countDeletedPrefab} ${getTranslation("ADMIN_PANEL_PREFABS_TEXT_BUTTON_PROMPTS")}`}
          onConfirm={deletePrefab}
        />
      )}

      {error.openErrorModal && (
        <ErrorMessageModal
          message={error.message}
          closeModalWindow={closeErrorWindow}
          textButton={getTranslation("CONFIRMATION_MODAL_DEFAULT_BUTTON_TEXT")}
          handleButton={closeErrorWindow}
          header={error.headerError}
        />
      )}

      {isAnswerModal && (
        <AnswersToPrefabModal
          closeGenerateAnswers={() => setIsAnswerModal(false)}
          suggestedAnswers={suggestedAnswers}
          suggestedLoading={suggestedLoading}
        />
      )}

      {isAnswerWithImage && (
        <GenerateImageModal
          closeModalWindow={() => setIsAnswerWithImage(false)}
          header={getTranslation("OPEN_AI_LOADER_HEADER_IMAGE")}
          message={getTranslation("OPEN_AI_LOADER_IMAGE")}
          imageData={urlImage}
          suggestedLoading={suggestedLoading}
          isTestingMode
        />
      )}
    </div>
  );
};

export default PrefabsChatGTP;
