import React from "react";
import { useState } from "react";
import { useEffect } from "react";

import { getUsersReport } from "../../../../api/users";
import { saveBuffer } from "../../../../common/downloads";
import { getTranslation } from "../../../../helpers/getLanguage";
import Loader from "../../../common/Loader";
import MessageModal from "../../../dialogs/MessageModal";
import CustomSearchInput from "../../../inputs/CustomSearchInput";
import "./styles.css";
import UsersTableBody from "./UsersTableBody";
import UsersTableHeader from "./UsersTableHeader";

const UsersTable = ({
  containerHeight,
  containerRef,
  users,
  sort,
  handleSortChange,
  filters,
  setFilters,
  resetFilters,
  openUserModal,
  setPageFn,
  usersIsLoading,
}) => {
  const [searchValue, setSearchValue] = useState(filters.search);
  const [timer, setTimer] = useState(undefined);
  const [isPending, setIsPending] = useState(false);

  const [textLoader, setTextLoader] = useState(false);

  useEffect(() => {
    setSearchValue(filters.search);
  }, [filters]);

  useEffect(() => {
    if (searchValue === filters.search) {
      return;
    }

    clearTimeout(timer);

    setTimer(
      setTimeout(() => {
        setFilters({ ...filters, search: searchValue });
        setPageFn(1, true);
      }, 500),
    );
  }, [searchValue]);

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const handlePrev = () => {
    setPageFn(-1);
  };

  const handleNext = () => {
    setPageFn(1);
  };

  const displayBody = () => {
    if (!users?.length && !usersIsLoading) {
      return (
        <span className="project-dashboard-tab-title" style={{ position: "absolute", left: "50%", top: "50%" }}>
          No users found
        </span>
      );
    }

    return users?.length && !usersIsLoading ? (
      <UsersTableBody
        containerHeight={containerHeight}
        containerRef={containerRef}
        rows={users}
        openUserModal={openUserModal}
      />
    ) : (
      <Loader />
    );
  };

  const generateUsersReport = () => {
    let maxRequests = 5;
    let attempt = 1;
    setTextLoader(true);

    const onSuccess = (data) => {
      setIsPending(false);
      setTextLoader(false);
      saveBuffer(data, "Omnivati_users_report", "xlsx");
    };

    const onError = (error) => {
      if (attempt < maxRequests) {
        attempt++;
        generateUsersReport();
        return;
      }
      setIsPending(false);
      setTextLoader(false);
      console.error(error);
    };

    setIsPending(true);
    getUsersReport({}, onSuccess, onError);
  };

  return (
    <div className="users-table-container">
      <div className="flex">
        <CustomSearchInput
          placeholder={getTranslation("SEARCH_FULL_NAME_EMAIL")}
          value={searchValue}
          containerClass="custom-search-input-users"
          onChange={handleSearch}
        />
        <button
          className="promo-code-button dark-blue-btn flex align-center justify-space-between"
          onClick={resetFilters}
        >
          <span>{getTranslation("RESET_FILTERS_BUTTON")}</span>
        </button>
        <button
          className="promo-code-button dark-blue-btn flex align-center justify-space-between"
          onClick={generateUsersReport}
          disabled={isPending}
        >
          <span>{getTranslation("GENERATE_USERS_REPORT_BUTTON")}</span>
        </button>
        <p style={{ marginBottom: "0", marginTop: "0.5rem" }}>{textLoader}</p>
      </div>
      <div style={{ height: "607px" }}>
        <UsersTableHeader sort={sort} handleSortChange={handleSortChange} />
        {displayBody()}
      </div>

      <div className="pagination-container">
        <button onClick={handlePrev} className="pagination-prev">
          {getTranslation("USERS_MODAL_PREV")}
        </button>
        <button onClick={handleNext} className="pagination-next">
          {getTranslation("USERS_MODAL_NEXT")}
        </button>
      </div>

      {textLoader && (
        <MessageModal
          header={getTranslation("LOADING_MESSAGE_HEADER_GENERATE_REPORT")}
          message={getTranslation("LOADING_MESSAGE_TEXT")}
          closeDialog={() => setTextLoader(false)}
        />
      )}
    </div>
  );
};

export default UsersTable;
