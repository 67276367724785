import { GET_PREFERENCES, SET_PREFERENCE } from "./types";

export const getPreferences = () => {
  return {
    type: GET_PREFERENCES,
  };
};

export const setPreference = (preference) => {
  return {
    type: SET_PREFERENCE,
    payload: preference,
  };
};
