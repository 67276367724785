import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import "./styles.css";

const initialDisableArrow = {
  top: false,
  bottom: false,
};

const Scrollbar = ({
  children,
  className,
  width = 7,
  right = 0,
  height = 7,
  style = {},
  scrollHandleColor = "#d9dde5",
  backgroundColor = "white",
  offsetX,
  invisibleScrolling,
  isHorizontalScroll,
  disablingArrowColor = "#ECEEF5",
  isShowArrows,
  dependencies = [],
  heightScroll,
  ...restProps
}) => {
  const [scrollBoxHeight, setScrollBoxHeight] = useState(0);
  const [scrollBoxWidth, setScrollBoxWidth] = useState(0);
  const [scrollBoxTop, setScrollBoxTop] = useState(0);
  const [scrollBoxLeft, setScrollBoxLeft] = useState(0);
  const [scrollContainerHeight, setScrollContainerHeight] = useState(0);
  const [scrollContainerWidth, setScrollContainerWidth] = useState(0);
  const [scrollHandlePosition, setScrollHandlePosition] = useState(0);
  const [isDragging, setDragging] = useState(false);
  const [disablingArrow, setDisablingArrow] = useState(initialDisableArrow);
  const scrollHostRef = useRef(null);

  const updateHandleSize = useCallback(() => {
    if (!scrollHostRef) {
      return;
    }
    const scrollHostElement = scrollHostRef.current;
    const { clientHeight, scrollHeight, clientWidth, scrollWidth } = scrollHostElement;

    scrollContainerHeight !== scrollHeight && setScrollContainerHeight(scrollHeight);
    scrollContainerWidth !== scrollWidth && setScrollContainerWidth(scrollWidth);

    if (!isHorizontalScroll) {
      if (!scrollHostElement || clientHeight >= scrollHeight) {
        setScrollBoxHeight(0);
        return;
      }

      const scrollThumbPercentage = clientHeight / scrollHeight || 0;
      const scrollThumbHeight = scrollThumbPercentage * clientHeight || 0;
      setScrollBoxHeight(scrollThumbHeight);
    } else {
      if (!scrollHostElement || clientWidth >= scrollWidth) {
        setScrollBoxWidth(0);
        return;
      }

      const scrollThumbPercentage = clientWidth / scrollWidth || 0;
      const scrollThumbWidth = scrollThumbPercentage * clientWidth || 0;
      setScrollBoxWidth(scrollThumbWidth);
    }
  }, [scrollContainerHeight, scrollContainerWidth]);

  const handleScroll = useCallback(() => {
    if (!scrollHostRef) {
      return;
    }

    const scrollHostElement = scrollHostRef.current;
    if (!isHorizontalScroll) {
      const { scrollTop, scrollHeight, offsetHeight } = scrollHostElement;
      scrollContainerHeight !== scrollHeight && updateHandleSize();

      let newTop = (parseInt(scrollTop, 10) / parseInt(scrollHeight, 10)) * offsetHeight;
      newTop = Math.min(newTop, offsetHeight - scrollBoxHeight);
      setScrollBoxTop(newTop);
    } else {
      const { scrollLeft, scrollWidth, offsetWidth } = scrollHostElement;
      scrollContainerHeight !== scrollWidth && updateHandleSize();

      const newLeft = (parseInt(scrollLeft, 10) / parseInt(scrollWidth, 10)) * offsetWidth;

      setScrollBoxLeft(newLeft);
    }
  }, [updateHandleSize, scrollBoxHeight, scrollContainerHeight, scrollBoxLeft, scrollContainerWidth]);

  const handleMouseMove = useCallback(
    (e) => {
      if (isDragging) {
        e.preventDefault();
        e.stopPropagation();
        const scrollHostElement = scrollHostRef.current;

        if (!isHorizontalScroll) {
          const { scrollHeight, offsetHeight } = scrollHostElement;
          let deltaY = e.clientY - scrollHandlePosition;
          let percentage = deltaY * (scrollHeight / offsetHeight);

          setScrollHandlePosition(e.clientY);
          setScrollBoxTop(Math.min(Math.max(0, scrollBoxTop + deltaY), offsetHeight - scrollBoxHeight));

          scrollHostElement.scrollTop = Math.min(scrollHostElement.scrollTop + percentage, scrollHeight - offsetHeight);
        } else {
          const { scrollWidth, offsetWidth } = scrollHostElement;
          let deltaX = e.clientX - scrollHandlePosition;
          let percentage = deltaX * (scrollWidth / offsetWidth);

          setScrollHandlePosition(e.clientX);
          setScrollBoxLeft(Math.min(Math.max(0, scrollBoxLeft + deltaX), offsetWidth - scrollBoxWidth));

          scrollHostElement.scrollLeft = Math.min(scrollHostElement.scrollLeft + percentage, scrollWidth - offsetWidth);
        }
      }
    },
    [isDragging, scrollHandlePosition, scrollBoxHeight, scrollBoxTop, scrollBoxWidth, scrollBoxLeft],
  );

  const handleArrowClick = useCallback(
    (direction) => () => {
      const directionSign = direction === "top" ? -1 : 1;

      const scrollHostElement = scrollHostRef.current;
      const { scrollHeight, offsetHeight } = scrollHostElement;

      setScrollBoxTop(
        Math.min(Math.max(0, scrollBoxTop + offsetHeight * directionSign), offsetHeight - scrollBoxHeight),
      );

      scrollHostElement.scrollTop = Math.min(
        scrollHostElement.scrollTop + offsetHeight * directionSign,
        scrollHeight - offsetHeight,
      );
    },
    [scrollBoxTop, scrollBoxHeight],
  );

  const handleMouseUp = useCallback(
    (e) => {
      if (isDragging) {
        setDragging(false);
        e.preventDefault();
      }
    },
    [isDragging],
  );

  useLayoutEffect(() => {
    const scrollHostElement = scrollHostRef.current;
    if (scrollHostElement) {
      updateHandleSize();
      scrollHostElement.addEventListener("scroll", handleScroll, true);
      return () => scrollHostElement.removeEventListener("scroll", handleScroll, true);
    }
  }, [handleScroll, updateHandleSize]);

  useLayoutEffect(() => {
    updateHandleSize();
  }, [restProps.preferences, scrollHostRef, updateHandleSize, ...dependencies]);

  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("mouseleave", handleMouseUp);
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("mouseleave", handleMouseUp);
    };
  }, [handleMouseMove, handleMouseUp]);

  const handleScrollbarMouseDown = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setScrollHandlePosition(e.clientY);
    setDragging(true);
  }, []);

  const scrollHandleStyle = {
    height: isHorizontalScroll ? height : scrollBoxHeight,
    width: isHorizontalScroll ? scrollBoxWidth : width,
    top: scrollBoxTop,
    left: scrollBoxLeft,
    backgroundColor: scrollHandleColor,
    display: invisibleScrolling && "none",
  };
  const horizontalScrollStyle = isHorizontalScroll ? { position: "relative", height: 7, width: "100%" } : {};

  const scrollHostClass = className ? ` ${className}` : "";

  const disablingTopArrow = !scrollBoxTop;
  const disablingBottomArrow = (scrollBoxHeight + scrollBoxTop) % heightScroll === 0;

  const colorTopArrow = disablingTopArrow
    ? { borderColor: disablingArrowColor + " transparent transparent transparent" }
    : {};
  const colorBottomArrow = disablingBottomArrow
    ? { borderColor: disablingArrowColor + " transparent transparent transparent" }
    : {};
  return (
    <div style={style} className="custom-scrollbar-container">
      <div ref={scrollHostRef} className={"custom-scrollbar-scrollable-container" + scrollHostClass}>
        {children}
      </div>
      <div
        className="custom-scrollbar"
        style={{ backgroundColor, width, right, height: heightScroll, ...horizontalScrollStyle }}
      >
        {isShowArrows && (
          <>
            <button disabled={disablingTopArrow} className="hide">
              <div
                style={{ transform: "rotate(180deg)", ...colorTopArrow }}
                className="select-triangle-icon scrollbar-arrow"
                onClick={handleArrowClick("top")}
              />
            </button>
            <button disabled={disablingBottomArrow} className="hide">
              <div
                style={{ top: "102%", ...colorBottomArrow }}
                className="select-triangle-icon scrollbar-arrow"
                onClick={handleArrowClick("low")}
              />
            </button>
          </>
        )}

        <div className="custom-scrollbar-handle" style={scrollHandleStyle} onMouseDown={handleScrollbarMouseDown} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  preferences: state.preferences,
});

export default connect(mapStateToProps)(Scrollbar);
