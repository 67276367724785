import React from "react";

import { ReactComponent as PlusIconSmall } from "../../assets/images/plus-icon-small.svg";
import { getTranslation } from "../../helpers/getLanguage";
import Tooltip from "../common/Tooltip";
import ViewTutorialButtonWithModal from "../common/ViewTutorialButtonWithModal";
import CustomSearchInput from "../inputs/CustomSearchInput";
import CustomSwitch from "../inputs/CustomSwitch";
import CustomToggle from "../inputs/CustomToggle";

const ComponentsDashboardHeader = ({
  inputValues,
  handleChange,
  labels,
  sortLabel,
  componentsCount,
  handleModalOpen,
  projectType,
  compact,
  setCompact,
  isSorted,
  setSorted,
  children,
  labelsPerson,
  handleChangeSwitch,
  isCustomerState,
}) => {
  const isProcessType = projectType === "process";

  return (
    <div className="flex flex-wrap justify-space-between">
      <div className="flex-column width-100">
        <ViewTutorialButtonWithModal className="mb-20" name="Components" />
        {isProcessType && (
          <div className="flex dashboard-header-vantage-point">
            <p style={{ marginBottom: 0 }}>{getTranslation("CUSTOM_INPUT_LABEL_VANTAGE_POINT")}:</p>

            <CustomSwitch
              value={isCustomerState}
              setValue={handleChangeSwitch}
              labels={labelsPerson}
              invert
              disabled={componentsCount && true}
            />
          </div>
        )}
      </div>

      <div className="flex-wrap mb-20 align-center justify-space-between width-100">
        <div className="flex-wrap justify-space-between">
          <div className="flex align-center">
            <div className="flex align-center white-no-wrap">
              <Tooltip
                containerClass="components-dashboard-title"
                position="right"
                innerTextClass=""
                message={getTranslation(isProcessType ? "TOOLTIP_MESSAGE_STEP" : "TOOLTIP_MESSAGE_COMPONENT")}
                innerText={getTranslation(isProcessType ? "PAGE_STEP_TITLE" : "PAGE_COMPONENT_TITLE")}
              />
              {componentsCount > 0 && <span className="components-dashboard-count">{componentsCount}</span>}
            </div>
            <button className="add-component-button-title" onClick={() => handleModalOpen("component")}>
              <PlusIconSmall style={{ marginBottom: 3 }} /> {getTranslation("PAGE_COMPONENT_ADD")}
            </button>
            {children}
          </div>
        </div>

        <div className="flex align-center components-dashboard-settings">
          <CustomSearchInput
            name="search"
            placeholder={getTranslation(
              isProcessType ? "PAGE_STEPS_SEARCH_COMPONENTS" : "PAGE_COMPONENT_SEARCH_COMPONENTS",
            )}
            value={inputValues.search}
            onChange={handleChange}
            containerClass="search-input-container-components mb-0 project-input-container first flex align-center"
          />
          <div className="flex margin-left-20px align-center flex-grow-1 justify-flex-end">
            <div className="flex align-center components-inputs">
              <div className="toggle-container flex align-center">
                <CustomToggle
                  value={compact}
                  setValue={(e) => setCompact(e)}
                  label={labels[1]}
                  left
                  labelClass="custom-toggle-label-default sort-toggle mb-0"
                />
              </div>
            </div>
            <div className="flex align-center components-inputs">
              <div className="toggle-container flex align-center">
                <CustomToggle
                  value={isSorted}
                  setValue={(e) => setSorted(e)}
                  label={sortLabel}
                  labelClass=" sort-toggle mb-0"
                  left
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComponentsDashboardHeader;
