import React, { useMemo, useState } from "react";

import { createAttributes as requestCreateAttributes } from "../../../api/attributes";
import { ReactComponent as AIGenerateIcon } from "../../../assets/images/ai-generate-icon.svg";
import CloseIcon from "../../../assets/images/close-icon.svg";
import { ReactComponent as Components } from "../../../assets/images/nav-components.svg";
import { ReactComponent as ProcessImage } from "../../../assets/images/process.svg";
import { ReactComponent as ProductImage } from "../../../assets/images/product.svg";
import { ReactComponent as SeparatorIcon } from "../../../assets/images/separator-project-type.svg";
import { ReactComponent as ServiceImage } from "../../../assets/images/service.svg";
import { ReactComponent as Steps } from "../../../assets/images/steps.svg";
import { getTranslation } from "../../../helpers/getLanguage";
import CustomScrollbar from "../../common/CustomScrollbar";
import DotFlashing from "../../common/DotFlashing";
import Tooltip from "../../common/Tooltip";
import Dialog from "../../dialogs/Dialog";
import CustomCheckbox from "../../inputs/CustomCheckbox";
import "./styles.css";

const GenerateAttributesModal = ({
  closeDialog,
  handleCreateSuggested,
  suggestedAttributes,
  suggestedMode,
  suggestedLoading,
  productId,
  internal,
  componentId,
  currentProjectName,
  projectTypeName,
  componentName,
  teamId,
  projectType,
  onSuccess,
}) => {
  const [selectedItems, setSelectedItems] = useState(new Set());
  const [isCheckedAllAttributes, setIsCheckedAllAttributes] = useState(false);

  const CurrentProjectImage = useMemo(() => {
    switch (projectType) {
      case "product":
        return ProductImage;
      case "service":
        return ServiceImage;
      case "process":
      default:
        return ProcessImage;
    }
  }, [projectType]);

  const ComponentImage = useMemo(() => {
    switch (projectType) {
      case "product":
      case "service":
        return Components;
      case "process":
      default:
        return Steps;
    }
  }, [projectType]);

  const selectItem = (index) => {
    setSelectedItems((prev) => {
      const prevSelectedItem = new Set(prev);
      prev.has(index) ? prevSelectedItem.delete(index) : prevSelectedItem.add(index);

      return prevSelectedItem;
    });
  };

  const selectAllItem = (index, isCheckedAll) => {
    setSelectedItems((prev) => {
      const prevSelectedItem = new Set(prev);

      isCheckedAll
        ? prev.has(index) && prevSelectedItem.delete(index)
        : !prev.has(index) && prevSelectedItem.add(index);

      return prevSelectedItem;
    });
  };

  const handleSelectAll = (suggestedAttributes) => {
    if (suggestedAttributes.length) {
      setIsCheckedAllAttributes(!isCheckedAllAttributes);
    } else {
      return;
    }

    suggestedAttributes.map((_, index) => {
      const newArr = selectAllItem(index, isCheckedAllAttributes);
      return newArr;
    });
  };

  const saveAttributes = () => {
    const attributes = [];

    suggestedAttributes.map((name, index) => {
      if (name.trim() && selectedItems.has(index)) {
        const params = {
          productId: productId,
          componentId: componentId,
          name: name.trim(),
          importance: 1,
          internal: internal,
          description: "",
          disabled: false,
          teamId: teamId || "",
          comments: "",
        };

        attributes.push(params);
        selectedItems.has(index) && handleCreateSuggested(name);
      }
    });

    requestCreateAttributes(
      {
        attributes,
        teamId,
        productId,
      },
      onSuccess,
    );
    closeDialog();
  };

  const countSelectedAttributes = selectedItems.size;

  return (
    <Dialog closeDialog={closeDialog} draggable closeOnClickOutside={false}>
      <div className="attribute-dialog-wrapper">
        <div className="attribute-generate-dialog-header flex flex-column generate-modal-title">
          <div
            style={{ padding: "10px 28px 0" }}
            className="flex justify-between align-center width-100 pt-10 draggable-handle"
          >
            <div className="suggested-attribute-modal-title-container flex align-start">
              <AIGenerateIcon style={{ color: "#CC4B4F", height: 30, width: 30 }} />
              <Tooltip
                message={
                  getTranslation("TOOLTIP_MESSAGE_SUGGEST_ATTRIBUTE_1") +
                  suggestedMode?.attribute?.component?.name +
                  getTranslation("TOOLTIP_MESSAGE_SUGGEST_ATTRIBUTE_2")
                }
                containerClass="attribute-dialog-title"
                innerTextClass="suggested-attribute-modal-title"
                innerText={getTranslation("GENERATE_ATTRIBUTE_DIALOG_TITLE")}
              />
            </div>
            <img src={CloseIcon} alt="" className="pointer" onClick={closeDialog} />
          </div>
          <div className="attribute-project-type flex width-100 align-center">
            <div style={{ width: 280 }}>
              <CurrentProjectImage color="#8A93C3" width={26} height={26} />
              <Tooltip
                message={projectTypeName}
                containerClass="attribute-dialog-title"
                innerTextClass="suggested-attribute-modal-title"
                innerText={projectTypeName}
              />
            </div>
            <SeparatorIcon className="type-separator" />
            <div style={{ width: 240, marginLeft: 30 }}>
              <ComponentImage color="#8A93C3" width={26} height={26} />
              <Tooltip
                message={componentName}
                containerClass="attribute-dialog-title"
                innerTextClass="suggested-attribute-modal-title"
                innerText={componentName}
              />
            </div>
          </div>
        </div>
        <div style={{ height: "52%" }} className="suggest-attribute-dialog-body flex-column align-flex-end">
          <CustomScrollbar
            backgroundColor="#f5f5f5"
            scrollHandleColor="#C6CBED"
            isShowArrows
            width={3}
            heightScroll={176}
            className={`suggested-attribute-scrollbar-host ${suggestedLoading?.attribute && "justify-content-center"}`}
          >
            {suggestedLoading?.attribute ? (
              <div className="flex-center height-100">
                <DotFlashing textLoader={getTranslation("OPEN_AI_LOADER_TEXT")} />
              </div>
            ) : (
              <div className="flex-column align-flex-end width-100">
                <div
                  className={`attribute-dialog-wrapper_select-all ${!suggestedAttributes.length && "disabled"}`}
                  onClick={() => handleSelectAll(suggestedAttributes)}
                >
                  <div className="attribute-dialog-wrapper_checkbox">
                    <CustomCheckbox
                      checked={isCheckedAllAttributes}
                      handleChange={() => handleSelectAll(suggestedAttributes)}
                      disabled={!suggestedAttributes.length}
                    />
                    <label className={`attribute-dialog-wrapper_label ${!suggestedAttributes.length && "disabled"}`}>
                      {getTranslation("GENERATE_ATTRIBUTE_MODAL_LABEL_BUTTON")}
                    </label>
                  </div>
                </div>

                {!suggestedAttributes.length && (
                  <div className="flex justify-center width-100">
                    <span className="ai-generate-modal-window-message">
                      {getTranslation("GENERATE_ATTRIBUTES_MODAL_TEXT_WHEN_NO_ATTRIBUTES")}
                    </span>
                  </div>
                )}

                <div className="flex-wrap width-100">
                  {suggestedAttributes.map((name, index) => (
                    <span
                      className={`suggest-item-attributes ${selectedItems.has(index) ? "border-item" : ""}`}
                      style={{ maxWidth: "47%" }}
                      key={index}
                      onClick={() => selectItem(index)}
                    >
                      <Tooltip
                        containerClass="attribute-dialog-wrapper_tooltip ellipsed-text"
                        position="top"
                        innerTextClass=""
                        message={name}
                        innerText={name}
                        tooltipMessageClass="tooltip-message white"
                      />
                    </span>
                  ))}
                </div>
              </div>
            )}
            {suggestedAttributes.length % 2 === 1 && <div style={{ width: 271 }}></div>}
          </CustomScrollbar>
        </div>
        <div className="flex align-center justify-center">
          <button
            disabled={!countSelectedAttributes}
            onClick={saveAttributes}
            className="suggest-attribute-button flex align-center justify-center generage-button"
          >
            {countSelectedAttributes
              ? `+ ${getTranslation("GENERATE_ATTRIBUTES_MODAL_ADD_SELECTED")} ${countSelectedAttributes}  ${getTranslation(
                  "ATTRIBUTES_DIALOG_BUTTON",
                )}`
              : `+ ${getTranslation("SELECT_ATTRIBUTE_DIALOG_BUTTON")}`}
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default GenerateAttributesModal;
