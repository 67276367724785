import axios from "axios";

import { baseApiUrl } from "../common/constants";
import { handleError, handleSuccess } from "./handlers";

const baseUrl = `${baseApiUrl}/components`;

export const createComponent = async (params, onSuccess, onError) => {
  axios
    .post(`${baseUrl}/create`, params)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const updateComponent = async (params, onSuccess, onError) => {
  axios
    .post(`${baseUrl}/update`, params)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const createComponents = async (params, onSuccess, onError) => {
  axios
    .post(`${baseUrl}/create/many`, params)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const deleteComponent = async (params, onSuccess, onError) => {
  axios
    .post(`${baseUrl}/delete`, params)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const disableComponent = async (params, onSuccess, onError) => {
  axios
    .post(`${baseUrl}/disable`, params)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const duplicateComponent = async (params, onSuccess, onError) => {
  axios
    .post(`${baseUrl}/duplicate`, params)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};

export const copyComponent = async (params, onSuccess, onError) => {
  axios
    .post(`${baseUrl}/copy`, params)
    .then((response) => handleSuccess(response, onSuccess))
    .catch((error) => handleError(error, onError));
};
