import React, { useEffect, useState } from "react";

import { getTranslation } from "../../../helpers/getLanguage";
import { getUrlFromConfig } from "../../../helpers/getUrlFromConfig";
import "./styles.css";

const ImageUploader = ({
  reversed = false,
  currentProjectImageId,
  uploadInfo,
  label,
  handleFileSelect,
  height = 0,
  displayPreview = false,
  supportDescription = "EDIT_PROJECT_DIALOG_OVERVIEW_TAB_LABEL_PROJECT_IMAGE_SUPPORT",
  acceptTypes = "*",
  sizeLimit = 2097152, // 2 * 1024 * 1024
  customHeight = null,
  maxHeight = "",
  openImage,
  generatedImage,
  getPathImage = null,
}) => {
  const dragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const dragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const dragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const fileDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const files = e.dataTransfer.files;
    if (files.length) {
      handleFileUpload(files);
    }
  };

  const validateFile = (file) => {
    if (!file || file.size > sizeLimit) {
      return false;
    }
    return true;
  };

  const containerStyle = {};
  height && (containerStyle.height = height);

  const projectImage = getUrlFromConfig(`images/${currentProjectImageId}`);

  const shouldDisplayPreview = displayPreview && uploadInfo.uploaded;
  const activeClass = shouldDisplayPreview ? " preview" : "";

  const handleFileUpload = (event) => {
    const file = event.target ? event.target.files[0] : event[0];

    if (!validateFile(file)) return;

    const reader = new FileReader();

    reader.onload = (e) => {
      const buffer = e.target.result;
      const imageUrl = `data:image/png;base64,${Buffer.from(buffer).toString("base64")}`;

      if (getPathImage) {
        getPathImage(imageUrl);
      }

      handleFileSelect(file, imageUrl);
    };
    reader.readAsArrayBuffer(file);
  };

  return (
    <div className="mb-10 flex-column width-100" style={{ height: customHeight, maxHeight: maxHeight }}>
      <span className="dnd-label align-self-start">{label}</span>
      <div className={"dnd" + activeClass} style={containerStyle}>
        <div
          className="drop-zone"
          onDragOver={dragOver}
          onDragEnter={dragEnter}
          onDragLeave={dragLeave}
          onDrop={fileDrop}
        >
          <label className="pointer width-100 height-100 dnd-preview-label">
            {!currentProjectImageId && (
              <input
                className="display-none"
                type="file"
                key={uploadInfo.name}
                onChange={handleFileUpload}
                accept={acceptTypes}
              />
            )}

            <div className="dnd-image-preveiw height-100 flex-column flex-center">
              <span className="dnd-text">
                {getTranslation("EDIT_PROJECT_DIALOG_OVERVIEW_TAB_LABEL_PROJECT_IMAGE_DRAG_DROP")}
                <span className="light-blue pointer" style={{ paddingLeft: 5 }}>
                  {getTranslation("EDIT_PROJECT_DIALOG_OVERVIEW_TAB_LABEL_PROJECT_IMAGE_BROWSE")}
                </span>
              </span>
              <span className="dnd-bottom-text">{getTranslation(supportDescription)}</span>
            </div>
          </label>
        </div>
      </div>
    </div>
  );
};

export default ImageUploader;
