import React from "react";

import CloseIcon from "../../../assets/images/close-icon.svg";
import { getTranslation } from "../../../helpers/getLanguage";
import Dialog from "../Dialog";
import "./styles.css";
import TextareaInfo from "./TextareaInfo";

//TODO: add correct translated error message
const errorMessages = ["Invalid code", "The code is unavailable", "The code cannot be activated", "Code is required"];

const InformationModal = ({
  disabled = false,
  closeDialog,
  onConfirm,
  errorsEmails,
  successEmails,
  allEmails,
  closeOnClickOutside = true,
  closeOnEscape = true,
  buttonText = "OK",
  hideCloseIcon = false,
  autoFocus = false,
}) => {
  let emailsError = "";
  let emailsSuccess = "";
  let filteredEmails = "";
  let counter = 0;

  const handleCloseDialog = () => {
    closeDialog && closeDialog();
  };

  for (let email of successEmails) {
    emailsSuccess += email + "\n";
  }

  for (let email of errorsEmails) {
    emailsError += email.email + " - " + email.error + "\n";
    if (errorMessages.includes(email.error)) {
      filteredEmails = allEmails.slice(counter + 1).join("\n");
      break;
    }
    counter++;
  }

  return (
    <Dialog closeDialog={closeDialog} closeOnClickOutside={closeOnClickOutside} closeOnEscape={closeOnEscape}>
      <div className="information-modal flex-column">
        <div className="information-modal-close flex align-center justify-space-between width-100 mb-20">
          <span className="manage-user-label width-100">{getTranslation("INFORMATION_MODAL_REPORT")}</span>
          {!hideCloseIcon && <img src={CloseIcon} alt="" className="pointer" onClick={handleCloseDialog} />}
        </div>

        {emailsSuccess.length > 0 && <TextareaInfo values={emailsSuccess} label="INFORMATION_EMAILS_SUCCESS" />}

        {emailsError.length > 0 && <TextareaInfo values={emailsError} label="INFORMATION_EMAILS_ERRORS" />}

        {filteredEmails.length > 0 && <TextareaInfo values={filteredEmails} label="INFORMATION_FILTERED_EMAILS" />}

        <div className="flex-center">
          <div className="information-modal-button-contener flex-column align-center ">
            <button
              className="information-modal-button width-100"
              autoFocus={autoFocus}
              disabled={disabled}
              onClick={() => onConfirm()}
            >
              <span>{buttonText}</span>
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default InformationModal;
