import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import { checkLocale } from "../../utils/regularExpression";
import "./style.css";

const TranslateTemplate = ({ className, children, as = "div" }) => {
  const C = as;

  const isChine = checkLocale(children);

  return <C className={classnames(className, { cn: isChine })}>{children}</C>;
};

TranslateTemplate.propTypes = {
  as: PropTypes.elementType,
};

export default TranslateTemplate;
