import React, { useCallback, useEffect, useRef, useState } from "react";

import useMouseClick from "../../../../common/useMouseClick";
import CustomSearchInput from "../../../inputs/CustomSearchInput";
import DefaultOption from "./DefaultOption";
import PlaceholderOption from "./PlaceholderOption";
import "./styles.css";

const InvitationDropdown = ({
  value,
  setValue,
  isOpen,
  setIsOpen,
  placeholder,
  OptionComponent = DefaultOption,
  FirstOptionComponent,
  options,
  showSearch = false,
  searchPlaceholder = "",
  name = "",
}) => {
  const ref = useRef(null);
  const [search, setSearch] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const mouseClick = useMouseClick();

  const handleSetIsOpen = useCallback(
    (value, name = "") => {
      if (name) setIsOpen(value, name);
      if (!name) setIsOpen(value);
    },
    [setIsOpen],
  );

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setSearch("");
        handleSetIsOpen(false, name);
      }
    };

    mouseClick && handleClickOutside(mouseClick);
  }, [mouseClick, setIsOpen, handleSetIsOpen, name]);

  useEffect(() => {
    if (search) {
      const newOptions = options.filter((option) => option.label.toLowerCase().includes(search.toLowerCase()));
      setFilteredOptions(newOptions);
    } else {
      setFilteredOptions(options);
    }
  }, [search, options]);

  const FirstOption = ({ value }) => {
    const acceptsMultiple = value instanceof Array;

    if (acceptsMultiple) {
      if (!value.length || !options.length) {
        return <PlaceholderOption value={placeholder} />;
      }

      return (
        <FirstOptionComponent
          options={options}
          setValue={setValue}
          setIsOpen={(value) => handleSetIsOpen(value, name)}
          value={value}
        />
      );
    } else {
      const currentOption = options.find((option) => option.value === value);
      if (!currentOption) {
        return <PlaceholderOption value={placeholder} />;
      }

      return (
        <OptionComponent
          option={currentOption}
          setValue={setValue}
          setIsOpen={(value) => handleSetIsOpen(value, name)}
          isFirst
          value={value}
        />
      );
    }
  };

  const selectOptions = filteredOptions.map((option, index) => (
    <OptionComponent
      key={index}
      option={option}
      setValue={setValue}
      setIsOpen={(value) => handleSetIsOpen(value, name)}
      isFirst={false}
      isLast={index === filteredOptions.length - 1}
      value={value}
    />
  ));

  return (
    <>
      <div className="invitation-dropdown-container" ref={ref}>
        <div
          className={"invitation-dropdown flex align-center pointer" + (isOpen ? " active" : "")}
          onMouseDown={() => {
            setSearch("");
            handleSetIsOpen(!isOpen, name);
          }}
        >
          <FirstOption value={value} />
          <div className="invitation-dropdown-triangle"></div>
        </div>
        {isOpen && (
          <>
            <hr className="option-divider" />
            <div className="invitation-dropdown-options">
              {showSearch && (
                <div className="invitation-dropdown-search-input" onMouseDown={(e) => e.stopPropagation()}>
                  <CustomSearchInput
                    name="search"
                    placeholder={searchPlaceholder}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              )}

              {selectOptions}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default InvitationDropdown;
