import * as dotenv from "dotenv";

dotenv.config();

export const REACT_APP_STRIPE_PUBLIC = process.env.REACT_APP_STRIPE_PUBLIC;
export const REACT_APP_PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;
export const REACT_APP_SENTRY_URL = process.env.REACT_APP_SENTRY_URL;
export const REACT_APP_SENTRY_ENV = process.env.REACT_APP_SENTRY_ENV;
export const REACT_APP_OPENAI_PUBLIC_KEY = process.env.REACT_APP_OPENAI_PUBLIC_KEY;
export const REACT_APP_SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
export const SKIP_PREFLIGHT_CHECK = process.env.SKIP_PREFLIGHT_CHECK;
export const REACT_APP_AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;
export const REACT_APP_AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
export const REACT_APP_AUTH0_SAML_URL = process.env.REACT_APP_AUTH0_SAML_URL;
export const REACT_APP_COLUMBINA_SAML_URL = process.env.REACT_APP_COLUMBINA_SAML_URL;
