import React, { useEffect, useState } from "react";

import { ReactComponent as CloseIcon } from "../../../../../assets/images/close-icon.svg";
import { ReactComponent as PlusIcon } from "../../../../../assets/images/plus-icon-small.svg";
import { listOfInitialColors } from "../../../../../constants/listOfInitialColors";
import { getColorByInitials } from "../../../../../helpers/getInitials";
import { getTranslation } from "../../../../../helpers/getLanguage";
import Initials from "../../../../common/Initials";
import Tooltip from "../../../../common/Tooltip";
import CustomTextarea from "../../../../inputs/CustomTextarea";
import CommentSolve from "./CommentSolve";
import Thread from "./Thread";

const Issue = ({
  issue,
  issues,
  index,
  addItem,
  removeItem,
  removeLastIssue,
  handleChange,
  userFullName,
  reversed,
  userId,
  ...props
}) => {
  const { comment, userInfo, description, createdByUser } = issue;

  const [isShowThread, setIsShowThread] = useState(false);
  const createdBy = createdByUser ? createdByUser : `${userFullName.name} ${userFullName.lastName}`;
  const [initialsBackgroundColor, initialsTextColor] = getColorByInitials(
    `${userFullName.name} ${userFullName.lastName}`,
    listOfInitialColors,
  );
  const isCanChange = issue.userId === userId;

  useEffect(() => {
    if (props.messages.length && props.messages[props?.messages?.length - 1]?.message !== "") {
      setIsShowThread(true);
    }
  }, []);

  const handleChangeIssue = ({ target: { value, name } }) => {
    if (isCanChange) {
      handleChange(index, value, name);
    }
  };

  const handleDelete = () => {
    if (isCanChange) {
      removeItem(index, issue.id);
      setIsShowThread(false);
    }
  };

  const handleDeleteLastIssue = () => {
    removeLastIssue(index, issue.id);
    setIsShowThread(false);
  };

  return (
    <div className="issue-container width-100 flex flex-column align-flex-start">
      <div className="width-100 flex align-center">
        <div className="dependency-modal-challenges__initials">
          <Tooltip color={initialsTextColor} message={createdBy} tooltipMessageClass="width-fit-content">
            <Initials
              isRounded
              userFullName={createdBy}
              initialsBackgroundColor={initialsBackgroundColor}
              initialsTextColor={initialsTextColor}
            />
          </Tooltip>
        </div>
        <div className="flex-column width-100 mb-20">
          <CustomTextarea
            value={comment}
            name="comment"
            height={60}
            maxHeight={60}
            onChange={handleChangeIssue}
            placeholder={getTranslation("IDEA_MODAL_PLACEHOLDER_IMPLEMENTATION_CHALLENGES")}
            autoResize
            containerClass="dependency-modal-benefit__title textarea"
            textareaClass="dependency-modal-challenges-input__empty dependency-modal-challenges-input__empty_title"
            isIssue
          />
          <CustomTextarea
            value={description ? description : ""}
            onChange={handleChangeIssue}
            height={60}
            maxHeight={60}
            name="description"
            placeholder={getTranslation("SUBTRACTION_MODAL_BENEFITS_PLACEHOLDER_DESCRIPTION")}
            containerClass="dependency-modal-challenges-input"
            textareaClass="dependency-modal-challenges-input__empty"
          />
        </div>

        <div className="flex-column" style={index === issues.length - 1 ? { marginTop: "24px" } : { marginTop: "0" }}>
          <button
            className="dependency-modal__issue-button"
            onClick={index === issues.length - 1 ? handleDeleteLastIssue : handleDelete}
          >
            <CloseIcon className="dependency-modal__close-issue-icon" />
          </button>

          {index === issues.length - 1 && (
            <button className="dependency-modal__issue-button" onClick={addItem}>
              <PlusIcon className="dependency-modal__add-issue-icon" />
            </button>
          )}
        </div>
      </div>

      {comment.length > 0 && (
        <CommentSolve
          setIsShowThread={setIsShowThread}
          onCommentSolve={props.onCommentSolve}
          issueId={issue.id}
          issueIndex={index}
        />
      )}

      {isShowThread && (
        <Thread
          key={index}
          userFullName={userFullName}
          userId={userId}
          issueId={issue.id}
          issueIndex={index}
          isShowThread={isShowThread}
          messages={props.messages}
          handleMessageChange={props.handleMessageChange}
          addMessage={props.addMessage}
          removeMessage={props.removeMessage}
          removeLastMessage={props.removeLastMessage}
        />
      )}
    </div>
  );
};

export default Issue;
