import React from "react";

import { ReactComponent as CheckmarkIcon } from "../../../../assets/images/checkmark-circle.svg";
import CloseIcon from "../../../../assets/images/close-icon.svg";
import "./styles.css";

const TeamOptionComponent = ({ option, setValue, isFirst, isLast, value }) => {
  const activeClass = isFirst ? " first" : "";
  const disabledClass = option.disabled ? " disabled" : "";
  const isActive = value.includes(option.value);

  const labels = option.labels.map((label, index) => (
    <div key={index} className="teams-option-label">
      <span>{label}</span>
    </div>
  ));

  return (
    <div
      className={"teams-dropdown-option flex-column pointer" + activeClass + disabledClass}
      onClick={() => setValue(option.value)}
    >
      <div className="teams-dropdown-team-name flex align-center">
        <span className="teams-option-label-text">{option.label}</span>

        {isActive && <CheckmarkIcon color="#3e6fd9" fill="white" />}
        {!isActive && <div className="teams-round-button"></div>}
      </div>

      <div className={"flex flex-wrap teams-labels-container" + activeClass}>{labels}</div>

      {!isLast && !isFirst && <hr className="teams-option-divider" />}
    </div>
  );
};

const TeamLabel = ({ value, label, setValue }) => {
  const handleMouseDown = (e) => {
    e.stopPropagation();
    setValue(value);
  };

  return (
    <div className="flex teams-option-label-container">
      <div className="users-option-label">
        <span>{label}</span>
      </div>
      <button className="flex-center remove-user-button" onMouseDown={handleMouseDown}>
        <img src={CloseIcon} alt="" />
      </button>
    </div>
  );
};

export const FirstTeamOption = ({ options, setValue, value }) => {
  const teams = options
    .map((option, index) => {
      if (value.includes(option.value)) {
        const label = option.label === "Individual" ? option.labels[0] : option.label;
        return <TeamLabel key={index} value={option.value} label={label} setValue={setValue} />;
      }

      return "";
    })
    .filter((option) => option);

  return (
    <div className="teams-dropdown-option first flex-column pointer">
      <span className="flex flex-wrap teams-labels-container-first">{teams}</span>
    </div>
  );
};

export default TeamOptionComponent;
