import React, { useEffect, useState } from "react";
import { useMemo } from "react";
import { connect } from "react-redux";

import { getProjects } from "../../../actions/projectActions";
import Illustration from "../../../assets/images/ideation-illustration-1d.svg";
import { getTranslation } from "../../../helpers/getLanguage";

const initialProjectStats = {
  total: 0,
  feasibleIdeas: 0,
};

const DashboardHeader = ({ auth, projects }) => {
  const { user } = auth;
  const { language } = auth.userInfo;
  const [projectStats, setProjectStats] = useState(initialProjectStats);
  const [projectStatsReady, setProjectStatsReady] = useState(false);

  useEffect(() => {
    setProjectStatsReady(false);

    let feasibleIdeas = 0;

    projects.forEach((project) => {
      feasibleIdeas += project.cellsCount.green;
    });

    setProjectStats({
      total: projects.length,
      feasibleIdeas,
    });
    setProjectStatsReady(true);
  }, [projects, user.id]);

  const chooseTextForWelcome = useMemo(() => {
    if (projectStats.total > 1) {
      return getTranslation("WELCOME_TEXT_INNOVATION_PROJECTS");
    }

    return getTranslation("WELCOME_TEXT_INNOVATION_PROJECT");
  }, [language, projectStats.total]);

  return (
    <div className="header-image-container flex">
      <div className="blue-rectangle flex">
        <div className="welcome-container flex-column">
          {projectStatsReady && (
            <>
              <span className="welcome-title">
                {getTranslation("WELCOME_TITLE")} {auth.user.name}
              </span>
              {!projectStats.total && (
                <span className="welcome-text">
                  {getTranslation("WELCOME_TEXT_READY_TO_BEGIN")} <br /> {getTranslation("WELCOME_TEXT_FIRST_PROJECT")}
                </span>
              )}
              {projectStats.total > 0 && (
                <span className="welcome-text">
                  {getTranslation("WELCOME_TEXT_READY_TO_BEGIN")} <span className="white">{projectStats.total}</span>{" "}
                  {chooseTextForWelcome}
                  {projectStats.feasibleIdeas === 0 && "!"}
                  {projectStats.feasibleIdeas > 0 && (
                    <>
                      <br /> {getTranslation("WELCOME_TEXT_IDENTIFIED")}{" "}
                      <span className="white">{projectStats.feasibleIdeas}</span>{" "}
                      {getTranslation("WELCOME_TEXT_FEASIBLE_IDEAS")}{" "}
                    </>
                  )}
                </span>
              )}
            </>
          )}
        </div>
      </div>
      <div className="image-container">
        <img src={Illustration} alt=""></img>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  projects: state.project.projects,
  projectsLoading: state.project.projectsLoading,
});

export default connect(mapStateToProps, { getProjects })(DashboardHeader);
