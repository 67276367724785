import React, { useEffect, useState } from "react";

import CloseIcon from "../../../assets/images/close-icon.svg";
import { ReactComponent as FileIcon } from "../../../assets/images/file-icon.svg";
import { getTranslation } from "../../../helpers/getLanguage";
import { getUrlFromConfig } from "../../../helpers/getUrlFromConfig";
import "./styles.css";

const DragAndDrop = ({
  reversed = false,
  currentProjectImageId,
  uploadInfo,
  label,
  handleFileSelect,
  handleCancelClick,
  height = 0,
  displayPreview = false,
  supportDescription = "EDIT_PROJECT_DIALOG_OVERVIEW_TAB_LABEL_PROJECT_IMAGE_SUPPORT",
  acceptTypes = "*",
  sizeLimit = 2097152, // 2 * 1024 * 1024
  customHeight = null,
  maxHeight = "",
  openImage,
  generatedImage,
  getPathImage = null,
}) => {
  const [currentImage, setCurrentImage] = useState("");

  useEffect(() => {
    setCurrentImage(null);
  }, [reversed]);

  useEffect(() => {
    if (generatedImage) {
      setCurrentImage(generatedImage);
    }
  }, [generatedImage]);

  const dragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const dragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const dragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const fileDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const files = e.dataTransfer.files;
    if (files.length) {
      handleFileUpload(files);
    }
  };

  const validateFile = (file) => {
    if (!file || file.size > sizeLimit) {
      return false;
    }
    return true;
  };

  const getDate = (date) => {
    const uploadDate = new Date(date);
    const month = uploadDate.toLocaleString("default", { month: "long" });
    const day = uploadDate.getDate();
    const year = uploadDate.getFullYear();

    return `${month} ${day}, ${year}`;
  };

  const getSize = (size) => {
    let fileSize = Math.round(size / 1024);
    if (fileSize < 1024) {
      return `${fileSize}kb`;
    }

    fileSize = (fileSize / 1024).toFixed(2);
    return `${fileSize}mb`;
  };

  const containerStyle = {};
  height && (containerStyle.height = height);

  const projectImage = getUrlFromConfig(`images/${currentProjectImageId}`);

  const shouldDisplayPreview = displayPreview && uploadInfo.uploaded;
  const activeClass = shouldDisplayPreview ? " preview" : "";

  const handleFileUpload = (event) => {
    const file = event.target ? event.target.files[0] : event[0];

    if (!validateFile(file)) return;

    const reader = new FileReader();

    reader.onload = (e) => {
      const buffer = e.target.result;
      const imageUrl = `data:image/png;base64,${Buffer.from(buffer).toString("base64")}`;
      setCurrentImage(imageUrl);

      if (getPathImage) {
        getPathImage(imageUrl);
      }
      handleFileSelect(file, imageUrl);
    };
    reader.readAsArrayBuffer(file);
  };

  return (
    <div className="mb-10 flex-column width-100" style={{ height: customHeight, maxHeight: maxHeight }}>
      <span className="dnd-label align-self-start">{label}</span>
      <div className={"dnd" + activeClass} style={containerStyle}>
        <div
          className="drop-zone"
          onDragOver={dragOver}
          onDragEnter={dragEnter}
          onDragLeave={dragLeave}
          onDrop={fileDrop}
        >
          <label className="pointer width-100 height-100 dnd-preview-label">
            {!currentProjectImageId && (
              <input
                className="display-none"
                type="file"
                key={uploadInfo.name}
                onChange={handleFileUpload}
                accept={acceptTypes}
              />
            )}

            <div className="dnd-image-preveiw height-100 flex-column flex-center">
              {(projectImage || currentImage || generatedImage) && uploadInfo.uploaded && (
                <img
                  style={{ height: 90 }}
                  src={currentImage || projectImage || generatedImage}
                  alt=""
                  onClick={openImage}
                />
              )}

              {!(uploadInfo.uploaded && uploadInfo.progress >= 100) && (
                <>
                  <span className="dnd-text">
                    {getTranslation("EDIT_PROJECT_DIALOG_OVERVIEW_TAB_LABEL_PROJECT_IMAGE_DRAG_DROP")}
                    <span className="light-blue pointer" style={{ paddingLeft: 5 }}>
                      {getTranslation("EDIT_PROJECT_DIALOG_OVERVIEW_TAB_LABEL_PROJECT_IMAGE_BROWSE")}
                    </span>
                  </span>
                  <span className="dnd-bottom-text">{getTranslation(supportDescription)}</span>
                </>
              )}
            </div>
          </label>
        </div>
      </div>

      {uploadInfo.name && (
        <div className="dnd dnd-progress flex align-center justify-space-between">
          <div className="flex align-center dnd-filename">
            <FileIcon color={uploadInfo.uploaded ? "#3e6fd9" : "#888da8"} />
            <span>{uploadInfo.name}</span>
          </div>

          <div className="flex align-center">
            {(!uploadInfo.uploaded || uploadInfo.progress < 100) && (
              <div className="dnd-progress-bar">
                <div className="dnd-progress-bar filled" style={{ width: `${uploadInfo.progress}%` }}></div>
              </div>
            )}
            {uploadInfo.uploaded && uploadInfo.progress >= 100 && (
              <div className="flex-column dnd-file-info">
                <span>{getDate(uploadInfo.date)}</span>
                <span>{getSize(uploadInfo.size)}</span>
              </div>
            )}

            <img className="dnd-cancel-icon pointer" src={CloseIcon} alt="" onClick={handleCancelClick} />
          </div>
        </div>
      )}
    </div>
  );
};

export default DragAndDrop;
