import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { setTutorialUrls } from "../../../../actions/globalConfigurationsActions";
import { deleteTutorialVideo, updateDisplayNameTutorialVideo, uploadTutorialVideo } from "../../../../api/admin";
import { getUrl } from "../../../../api/params";
import { optionsOfLanguageForGlobalConfigurations } from "../../../../constants/language";
import { getTranslation } from "../../../../helpers/getLanguage";
import Loader from "../../../common/Loader";
import ConfirmationModal from "../../../dialogs/ConfirmationModal";
import WarningModal from "../../../dialogs/WarningModal";
import CustomDropdown from "../../../inputs/CustomDropdown";
import CustomInput from "../../../inputs/CustomInput";
import DragAndDrop from "../../../inputs/DragAndDrop";

const ChangeVideoUrl = ({ setTutorialUrls, nameTabs }) => {
  const [isPending, setIsPending] = useState(false);
  const [modalInfo, setModalInfo] = useState({ isOpen: true, name: null });
  const [errorText, setErrorText] = useState("");
  const [sections, setSections] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [language, setLanguage] = useState("en");

  const handleErrorModal = (error) => {
    console.error(error);
    setIsPending(false);
    setErrorText(error?.response?.data?.message ?? "Error sending request");
  };

  useEffect(() => {
    loadUrl();
  }, []);

  const loadUrl = async () => {
    const onSuccess = (response) => {
      setTutorialUrls(response);

      const allTutorials = response.map((tutorial) => ({
        id: tutorial.id,
        name: tutorial.name,
        displayName: tutorial?.displayName ?? tutorial.name,
        filename: tutorial?.filename ?? "",
        isEmpty: tutorial?.isEmpty ?? true,
        file: null,
        language: tutorial.language,
      }));

      setSections(allTutorials);
      setIsLoading(false);
    };

    const onError = (error) => {
      console.error(error);
      setIsLoadingError(true);
      setIsLoading(false);
    };

    await getUrl({}, onSuccess, onError);
  };

  const handleFileSelect = (file, index) => {
    setSections(sections.map((v, i) => (i === index ? { ...v, file } : v)));
  };

  const handleFileUpload = (name, index) => async () => {
    setIsPending(true);

    const onSuccess = () => {
      loadUrl();
      setIsPending(false);
    };

    const file = sections[index].file;

    const fd = new FormData();
    fd.append("video", file);
    fd.append("filename", name);
    fd.append("language", language);
    await uploadTutorialVideo(fd, onSuccess, handleErrorModal);
  };

  const handleDelete = async (name) => {
    setIsPending(true);

    const onSuccess = () => {
      loadUrl();
      setIsPending(false);
    };

    await deleteTutorialVideo({ name, language }, onSuccess, handleErrorModal);
  };

  const handleDisplayNameChange = (displayName, tutorialName) => {
    setSections(sections.map((section) => (section.name === tutorialName ? { ...section, displayName } : section)));
  };

  const handleSaveDisplayNameChange = (name) => () => {
    setIsPending(true);
    const foundSection = sections.find((section) => section.name === name && section.language === language);

    updateDisplayNameTutorialVideo(
      { name, displayName: foundSection.displayName, language },
      () => {
        loadUrl();
        setIsPending(false);
      },
      handleErrorModal,
    );
  };

  const modalCloseFn = () => {
    setModalInfo({ open: false, name: null });
  };

  return (
    <>
      <div className="global-configuration-container mb-30">
        <div className="flex-column admin-url-input-container">
          <span className="global-configuration-label mb-20">{getTranslation("GLOBAL_CONFIGURATIONS_TITLE")}</span>
          {isLoading && <Loader />}
          {!isLoading &&
            sections.map((tutorialValue, index) =>
              tutorialValue.name === nameTabs && tutorialValue.language === language && tutorialValue.isEmpty ? (
                <div key={`${tutorialValue.id}-key`}>
                  <span className="flex dnd-label align-self-start">{tutorialValue.name}</span>
                  <div className="flex flex-row justify-content-between align-items-center">
                    {tutorialValue?.file ? (
                      <span className="global-configurations-filename-span">{tutorialValue?.file?.name}</span>
                    ) : (
                      <DragAndDrop
                        uploadInfo={{
                          progress: 100,
                          cancel: null,
                          name: "",
                          date: "",
                          size: 0,
                          uploaded: false,
                        }}
                        handleCancelClick={() => {}}
                        handleFileSelect={(e) => handleFileSelect(e, index)}
                        isLoadingImage={true}
                        supportDescription="GLOBAL_CONFIG_DND_LABEL"
                        acceptTypes="mp4"
                        sizeLimit={52428800} // 50 * 1024 * 1024 (50mb)
                        customHeight={"86px"}
                      />
                    )}

                    {tutorialValue.file && (
                      <button
                        className="global-configurations-small-button align-self-center"
                        onClick={handleFileUpload(tutorialValue.name, index)}
                        disabled={isPending}
                      >
                        <span>{getTranslation("GLOBAL_CONFIG_UPLOAD_BTN")}</span>
                      </button>
                    )}
                  </div>
                </div>
              ) : (
                tutorialValue.name === nameTabs &&
                tutorialValue.language === language && (
                  <div key={`${tutorialValue.id}-key`}>
                    <span className="flex dnd-label align-self-start">{tutorialValue.name}</span>
                    <div className="flex flex-row justify-content-between align-items-center">
                      <span className="global-configurations-filename-span">{tutorialValue.filename}</span>
                      <button
                        className="global-configurations-remove-button"
                        onClick={() => setModalInfo({ open: true, name: tutorialValue.name })}
                        disabled={isPending}
                      >
                        <span>{getTranslation("GLOBAL_CONFIG_REMOVE_BTN")}</span>
                      </button>
                    </div>
                    <div
                      className="flex flex-row justify-content-between align-items-center"
                      style={{ marginBottom: "15px" }}
                    >
                      <CustomInput
                        value={tutorialValue.displayName}
                        name={tutorialValue.id}
                        onChange={(e) => handleDisplayNameChange(e.target.value, tutorialValue.name)}
                        containerClass="flex-column width-100 mb-10"
                        label={getTranslation("GLOBAL_CONFIG_DISPLAY_NAME_TITLE")}
                      />
                      <button
                        className="global-configurations-small-button"
                        onClick={handleSaveDisplayNameChange(tutorialValue.name)}
                        disabled={isPending}
                      >
                        <span>{getTranslation("GLOBAL_CONFIG_SAVE_BTN")}</span>
                      </button>
                    </div>
                  </div>
                )
              ),
            )}

          <div className="flex">
            <CustomDropdown
              value={language}
              options={optionsOfLanguageForGlobalConfigurations}
              handleChange={(e) => setLanguage(e)}
              className="admin-url-input-container__dropdown"
            />
          </div>

          {!isLoading && isLoadingError && (
            <div className="download-error-message">
              {getTranslation("GLOBAL_CONFIGURATIONS_DOWNLOAD_ERROR_MESSAGE")}
            </div>
          )}
        </div>
      </div>

      {modalInfo.open && (
        <WarningModal
          closeOnClickOutside={false}
          closeDialog={() => modalCloseFn()}
          message={"Are you sure you want to remove this video? This action is irreversible."}
          leftButtonText="NO"
          rightButtonText="YES"
          messageWidth={400}
          onConfirm={() => {
            handleDelete(modalInfo.name);
            modalCloseFn();
          }}
          onCancel={() => modalCloseFn()}
        />
      )}

      {!!errorText.length && (
        <ConfirmationModal
          closeOnClickOutside={false}
          closeDialog={() => setErrorText("")}
          message={errorText}
          buttonText={getTranslation("CONFIRMATION_MODAL_DEFAULT_BUTTON_TEXT")}
          messageWidth={400}
          onConfirm={() => setErrorText("")}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  language: state.auth.userInfo.language,
  tutorialUrls: state.globalConfigurations.tutorialUrls,
});

const mapDispatchToProps = { setTutorialUrls };

export default connect(mapStateToProps, mapDispatchToProps)(ChangeVideoUrl);
