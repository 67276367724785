import React from "react";

import CloseIcon from "../../../../assets/images/close-icon.svg";
import MultiplicationIcon from "../../../../assets/images/nav-multiplication.svg";
import { getTranslation } from "../../../../helpers/getLanguage";
import SubtractionTabs from "./MultiplicationTabs";

const SubtractionModalHeader = ({
  currentTab,
  closeDialog,
  multiplication,
  isCompletedIdea,
  productType,
  getMultiplicationHeader,
  changeCurrentTab,
}) => {
  const getCurrentProductType = () => {
    switch (productType) {
      case "process":
        return getTranslation("PRODUCT_TYPE_PROCESS");
      case "service":
        return getTranslation("PRODUCT_TYPE_SERVICE");
      default:
        return getTranslation("PRODUCT_TYPE_PRODUCT");
    }
  };

  const tabsLabels = [
    <span>{`${getTranslation("MULTIPLICATION_TAB_DESCRIBE_NEW_PRODUCT")} ${getCurrentProductType()}`}</span>,
    <span>{getTranslation("SUBTRACTION_MODAL_TABS_LIST_BENEFITS")}</span>,
    <span>{getTranslation("IDEA_MODAL_TABS_LIST_IMPLEMENTATION_CHALLENGES")}</span>,
  ];

  return (
    <div className="multiplication-dialog-header flex-column">
      <div className="multiplication-dialog-title-container flex mb-20">
        <div className="flex align-center">
          <img className="subtraction-dialog-header-icon" src={MultiplicationIcon} alt="" />
          <div>
            <span className="subtraction-dialog-title">{getTranslation("MODAL_TITLE_MULTIPLICATION")}</span>
          </div>
        </div>

        <div className="flex align-center subtraction-dialog-controls">
          <img src={CloseIcon} alt="" className="pointer" onClick={closeDialog} />
        </div>
      </div>

      <div className="flex-center">{getMultiplicationHeader()}</div>

      <SubtractionTabs
        currentTab={currentTab}
        subtraction={multiplication}
        labels={tabsLabels}
        isCompletedIdea={isCompletedIdea}
        changeCurrentTab={changeCurrentTab}
      />
    </div>
  );
};

export default SubtractionModalHeader;
