import React from "react";

import CloseIcon from "../../../assets/images/close-icon.svg";
import { getTranslation } from "../../../helpers/getLanguage";
import CustomScrollbar from "../../common/CustomScrollbar";
import DotFlashing from "../../common/DotFlashing";
import Dialog from "../Dialog";
import "./styles.css";

const AnswersToPrefabModal = ({ closeGenerateAnswers, suggestedAnswers, suggestedLoading }) => {
  return (
    <Dialog>
      <div className="flex-column answers-prefab-modal">
        <div className="answers-prefab-modal-header flex">
          <div className="flex justify-between align-center">
            <h2>{getTranslation("ADMIN_PANEL_PREFABS_ANSWERS_MODAL_HEADER")}</h2>
          </div>
          <img src={CloseIcon} alt="close modal" className="pointer" onClick={closeGenerateAnswers} />
        </div>

        <CustomScrollbar
          backgroundColor="#f5f5f5"
          scrollHandleColor="#C6CBED"
          isShowArrows
          width={5}
          className={`${suggestedLoading && "justify-content-center"}`}
          style={{ overflow: "hidden" }}
        >
          {suggestedLoading ? (
            <div className="flex-center height-100">
              <DotFlashing textLoader={getTranslation("OPEN_AI_LOADER_TEXT")} />
            </div>
          ) : (
            <div className="flex-column align-flex-start answers-prefab-modal-list">
              {suggestedAnswers.map((answer, index) => (
                <div key={index} className="answers-prefab-modal-list__name">
                  {answer.split("\n").map((answerPart) => (
                    <p>{answerPart}</p>
                  ))}
                </div>
              ))}
            </div>
          )}
        </CustomScrollbar>
        <button className="answers-prefab-modal__button" onClick={closeGenerateAnswers} type="button">
          {getTranslation("CONFIRMATION_MODAL_DEFAULT_BUTTON_TEXT")}
        </button>
      </div>
    </Dialog>
  );
};

export default AnswersToPrefabModal;
