import React, { useRef } from "react";

import useContainerHeight from "../../../common/useContainerHeight";
import { getTranslation } from "../../../helpers/getLanguage";
import CustomScrollbar from "../../common/CustomScrollbar";
import ReportRow from "./ReportRow";

const ReportsRows = ({
  rows,
  canEdit,
  currentLanguage,
  handleSelectIdea,
  selected,
  handleExpandIdea,
  expanded,
  handleOpenDetails,
  handleOpenSubtractionsDetails,
  handleOpenReplacementsDetails,
  handleOpenMultiplicationsDetails,
  handleOpenEurekaDetails,
  handleRedirectToMatrix,
  handleRedirectToEureka,
  handleRedirectToMultiplication,
  handleRedirectToSubtraction,
  handleRedirectToReplacement,
  currentIdea,
}) => {
  const tableRef = useRef(null);
  const [containerHeight] = useContainerHeight(tableRef, 20);

  const reportsTableStyle = {};
  containerHeight && (reportsTableStyle.height = containerHeight);

  const rowElements = rows.map((row, index) => (
    <ReportRow
      key={index}
      row={row}
      canEdit={canEdit}
      currentLanguage={currentLanguage}
      handleSelectIdea={handleSelectIdea}
      selected={selected}
      handleExpandIdea={handleExpandIdea}
      expanded={expanded}
      handleOpenDetails={handleOpenDetails}
      handleOpenSubtractionsDetails={handleOpenSubtractionsDetails}
      handleOpenMultiplicationsDetails={handleOpenMultiplicationsDetails}
      handleOpenReplacementsDetails={handleOpenReplacementsDetails}
      handleRedirectToMatrix={handleRedirectToMatrix}
      handleRedirectToMultiplication={handleRedirectToMultiplication}
      handleRedirectToSubtraction={handleRedirectToSubtraction}
      handleRedirectToReplacement={handleRedirectToReplacement}
      handleRedirectToEureka={handleRedirectToEureka}
      handleOpenEurekaDetails={handleOpenEurekaDetails}
      currentIdea={currentIdea}
    />
  ));

  return (
    <div className="reports-container" ref={tableRef} style={reportsTableStyle}>
      {rows.length > 0 && (
        <CustomScrollbar right={-15} dependencies={[rows, expanded]}>
          {rowElements}
        </CustomScrollbar>
      )}
      {rows.length === 0 && (
        <div className="flex-column report-placeholder">
          <span>{getTranslation("NO_REPORTS_IN_PROJECT")}</span>
          <span>
            {getTranslation("NO_REPORTS_MESSAGE")}
            <p>
              (
              <span className="pointer underlined" onClick={handleRedirectToMatrix}>
                {getTranslation("NO_REPORTS_MESSAGE_FORECASTING_MATRIX")}
              </span>
              ,
              <span className="pointer underlined" onClick={handleRedirectToSubtraction}>
                {getTranslation("NO_REPORTS_MESSAGE_SUBTRACTION")}
              </span>
              ,{getTranslation("NO_REPORTS_MESSAGE_ETC")})
            </p>
          </span>
        </div>
      )}
    </div>
  );
};

export default ReportsRows;
