import React, { useEffect, useRef, useState } from "react";

import useContainerHeight from "../../common/useContainerHeight";
import { getTranslation } from "../../helpers/getLanguage";
import Loader from "../common/Loader";
import ComponentTypeIndicator from "./ComponentTypeIndicator";
import { getTableHeader, getTableRows } from "./tableRows";

const initialState = {
  row: null,
  column: null,
  isLocked: false,
};

const DependencyMatrixTable = ({
  rows,
  columns,
  handleOpenDetails,
  columnBands,
  detailsMatrix,
  filters,
  binaryMode,
  redirectToComponents,
  isLoading,
  history,
  projectId,
  language,
}) => {
  const [selectedCell, setSelectedCell] = useState(initialState);
  const [activeCell, setActiveCell] = useState({ row: "", column: "", isLocked: false });

  const containerRef = useRef(null);
  const [containerHeight, updateHeight] = useContainerHeight(containerRef, 20);

  const ref = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside, true);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside, true);
    };
  });

  const handleClickOutside = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      if (activeCell.isLocked) {
        setActiveCell({ ...activeCell, isLocked: false });
      }
    }
  };

  useEffect(() => {
    updateHeight();
  }, [rows, updateHeight]);

  if ((columns.length <= 3 || !rows.length) && !isLoading) {
    return (
      <div className="flex-column matrix-placeholder">
        <span>{getTranslation("MATRIX_NO_DATA")}</span>
        <span>
          {getTranslation("DEFINE_MATRIX")}{" "}
          <span className="pointer underlined" onClick={redirectToComponents}>
            {getTranslation("ATTRIBUTES_LOWER_CASE")}
          </span>
        </span>
      </div>
    );
  }

  const tableHeader = getTableHeader(columnBands, selectedCell, history, projectId);

  const tableRows = getTableRows(
    rows,
    columns,
    detailsMatrix,
    filters,
    binaryMode,
    columnBands,
    selectedCell,
    setSelectedCell,
    handleOpenDetails,
    history,
    projectId,
    activeCell,
    setActiveCell,
  );

  const containerStyle = {};
  containerHeight && (containerStyle.height = containerHeight);

  const handleTableClick = () => {
    if (activeCell.isLocked && !selectedCell.row && !selectedCell.column) {
      setActiveCell({ ...activeCell, isLocked: false });
    }
  };

  const tableMatrix = () => (
    <div className="dependency-matrix-container width-100 flex">
      <div className="component-type-indicator-container">
        <ComponentTypeIndicator columnBands={columnBands} language={language} />
        <ComponentTypeIndicator columnBands={columnBands} external language={language} />
      </div>
      <div className="dependency-matrix-table width-100">
        <table>
          <tbody ref={ref} onClick={() => handleTableClick()}>
            {tableHeader}
            {tableRows}
          </tbody>
        </table>
      </div>
    </div>
  );

  return (
    <div className="dependency-matrix-table-wrapper flex" ref={containerRef} style={containerStyle}>
      {isLoading && <Loader />}
      {!isLoading && tableMatrix()}
    </div>
  );
};

export default DependencyMatrixTable;
