export const projectFilters = {
  private: (projects) => projects.filter((project) => !project.isShared && !project.teamId),
  shared: (projects, userId) =>
    projects.filter((project) => project.userId === userId && (project.isShared || project.teamId)),
  team: (projects, userId) => projects.filter((project) => project.teamId && project.userId !== userId),
  search: (projects, search) => projects.filter((project) => project.name.toLowerCase().includes(search.toLowerCase())),
};

export const projectSorting = {
  lastVisited: (projects, reverseSort = false) => {
    const sorted = projects.slice().sort((a, b) => new Date(a.lastVisited) - new Date(b.lastVisited));
    if (reverseSort) {
      return sorted.reverse();
    }
    return sorted;
  },

  created: (projects, reverseSort = false) => {
    const sorted = projects.slice().sort((a, b) => new Date(a.created) - new Date(b.created));
    if (reverseSort) {
      return sorted.reverse();
    }
    return sorted;
  },

  name: (projects, reverseSort = false) => {
    const sorted = projects.slice().sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
    if (reverseSort) {
      return sorted.reverse();
    }
    return sorted;
  },
  ideasCount: (projects, reverseSort = false) => {
    const sorted = projects.slice().sort((a, b) => b.cellsCount.totalIdeas - a.cellsCount.totalIdeas);
    if (reverseSort) {
      return sorted.reverse();
    }
    return sorted;
  },

  completion: (projects, reverseSort = false) => {
    const getCompletionRate = (project) => project.cellsCount.explored / project.cellsCount.total;
    const compareByCompletion = (a, b) => {
      return getCompletionRate(b) - getCompletionRate(a);
    };

    const sorted = projects.slice().sort((a, b) => compareByCompletion(a, b));
    if (reverseSort) {
      return sorted.reverse();
    }
    return sorted;
  },
};
