import { changePassword as requestPasswordChange, recoverPassword as requestPasswordRecovery } from "../api/auth";
import {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_SUCCESS,
  REQUEST_PASSWORD_RECOVERY,
  REQUEST_PASSWORD_RECOVERY_ERROR,
  REQUEST_PASSWORD_RECOVERY_SUCCESS,
  RESET_RECOVERY_STATE,
  SET_RECOVERY_EMAIL,
} from "./types";

export const setRecoveryEmail = (email) => {
  return {
    type: SET_RECOVERY_EMAIL,
    payload: email,
  };
};

export const resetRecoveryState = () => {
  return {
    type: RESET_RECOVERY_STATE,
  };
};

export const recoverPassword = (email) => (dispatch) => {
  dispatch({ type: REQUEST_PASSWORD_RECOVERY });

  const onSuccess = () => dispatch({ type: REQUEST_PASSWORD_RECOVERY_SUCCESS });
  const onError = (error) => dispatch({ type: REQUEST_PASSWORD_RECOVERY_ERROR, payload: error.response.data });

  const params = { email };
  requestPasswordRecovery(params, onSuccess, onError);
};

export const changePassword = (token, password) => (dispatch) => {
  dispatch({ type: CHANGE_PASSWORD });

  const onSuccess = () => dispatch({ type: CHANGE_PASSWORD_SUCCESS });
  const onError = (error) => dispatch({ type: CHANGE_PASSWORD_ERROR, payload: error.response.data });

  const params = { token, password };
  requestPasswordChange(params, onSuccess, onError);
};
