import axios from "axios";

import {
  CREATE_SUBSCRIPTION_ERROR,
  CREATE_SUBSCRIPTION_OPENAI_ERROR,
  CREATE_SUBSCRIPTION_OPENAI_SUCCESS,
  CREATE_SUBSCRIPTION_SUCCESS,
  RETRY_SUBSCRIPTION_PAYMENT_ERROR,
  RETRY_SUBSCRIPTION_PAYMENT_SUCCESS,
} from "../../actions/types";
import { baseApiUrl } from "../../common/constants";

export const clearPaymentInfo = () => {
  localStorage.removeItem("latestInvoiceId");
  localStorage.removeItem("latestInvoicePaymentIntentStatus");
};

const savePaymentInfo = (subscription) => {
  localStorage.setItem("latestInvoiceId", subscription.latest_invoice.id);
  localStorage.setItem("latestInvoicePaymentIntentStatus", subscription.latest_invoice.payment_intent.status);
};

const handlePaymentWithCustomerAction = async ({ subscription, invoice, paymentMethodId, isRetry, stripe }) => {
  if (subscription && subscription.status === "active") return { subscription };

  const paymentIntent = invoice ? invoice.payment_intent : subscription.latest_invoice.payment_intent;

  if (paymentIntent.status === "requires_action" || (isRetry && paymentIntent.status === "requires_payment_method")) {
    return await stripe
      .confirmCardPayment(paymentIntent.client_secret, { payment_method: paymentMethodId })
      .then((result) => {
        if (result.error) throw result;
        if (result.paymentIntent.status === "succeeded") {
          return { subscription };
        }
      });
  }

  return { subscription };
};

export const createSubscription = (
  paymentMethodId,
  interval,
  quantity,
  dispatch,
  stripe,
  fields,
  promoCode,
  socketId,
  creditsId,
  planId,
) => {
  const params = {
    paymentMethodId,
    interval,
    quantity,
    ...fields,
    promoCode,
    socketId,
    creditsId,
    planId,
  };

  axios
    .post(`${baseApiUrl}/stripe/create-subscription`, params)
    .then((response) => response.data.data)
    .then((response) => {
      if (response.error) throw response;
      return response;
    })
    .then((response) => {
      return { subscription: response, paymentMethodId, stripe };
    })
    .then(handlePaymentWithCustomerAction)
    .then(handleRequiresPaymentMethod)
    .then(({ subscription }) => subscription?.session?.url && window.location.replace(subscription.session.url))
    .then(() => onSubscriptionComplete(dispatch))
    .catch((error) => {
      dispatch({ type: CREATE_SUBSCRIPTION_ERROR, payload: error });
    });
};

export const createOpenAISubscription = (
  paymentMethodId,
  interval,
  quantity,
  dispatch,
  stripe,
  creditsId,
  promoCode,
  socketId,
) => {
  const params = {
    paymentMethodId,
    interval,
    quantity,
    creditsId,
    promoCode,
    socketId,
  };

  axios
    .post(`${baseApiUrl}/stripe/create-openai`, params)
    .then((response) => response.data.data)
    .then((response) => {
      if (response.error) throw response;
      return response;
    })
    .then((response) => {
      return { subscription: response, invoice: response.invoice, paymentMethodId, stripe };
    })
    .then(handlePaymentWithCustomerAction)
    .then(handleRequiresPaymentMethod)
    .then(() => {
      dispatch({ type: CREATE_SUBSCRIPTION_OPENAI_SUCCESS });
      clearPaymentInfo();
    })
    .catch((error) => {
      dispatch({ type: CREATE_SUBSCRIPTION_OPENAI_ERROR, payload: error });
    });
};

const handleRequiresPaymentMethod = ({ subscription }) => {
  if (subscription.status === "active") return { subscription };
  if (subscription?.latest_invoice?.payment_intent?.status === "requires_payment_method") {
    savePaymentInfo(subscription);
    throw new Error("Your card was declined.");
  }
  return { subscription };
};

export const retryInvoiceWithNewPaymentMethod = (paymentMethodId, invoiceId, dispatch, stripe) => {
  const params = { paymentMethodId, invoiceId };

  axios
    .post(`${baseApiUrl}/stripe/retry-invoice`, params)
    .then((response) => response.data.data)
    .then((response) => {
      if (response.error) throw response;
      return response;
    })
    .then((response) => {
      return { invoice: response, paymentMethodId, isRetry: true, stripe };
    })
    .then(handlePaymentWithCustomerAction)
    .then(() => onSubscriptionComplete(dispatch))
    .catch((error) => {
      dispatch({ type: CREATE_SUBSCRIPTION_ERROR, payload: error });
    });
};

export const retrySubscriptionPayment = (subscriptionId, paymentMethodId, dispatch, stripe) => {
  const params = { paymentMethodId, subscriptionId };

  axios
    .post(`${baseApiUrl}/stripe/retry-payment`, params)
    .then((response) => response.data.data)
    .then((response) => {
      if (response.error) throw response;
      return response;
    })
    .then((response) => {
      return { invoice: response, paymentMethodId, isRetry: true, stripe };
    })
    .then(handlePaymentWithCustomerAction)
    .then(() => dispatch({ type: RETRY_SUBSCRIPTION_PAYMENT_SUCCESS }))
    .catch((error) => {
      dispatch({ type: RETRY_SUBSCRIPTION_PAYMENT_ERROR, payload: error });
    });
};

const onSubscriptionComplete = (dispatch) => {
  dispatch({ type: CREATE_SUBSCRIPTION_SUCCESS });
  clearPaymentInfo();
};
