import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

import {
  getCurrentIssuesRedux,
  removeFromCurrentIssuesRedux,
  unshiftCurrentIssuesRedux,
  updateCurrentIssueRedux,
} from "../../../../actions/currentIssuesActions";
import { readCurrentIssues } from "../../../../api/currentIssues";
import { getReadIssuesByUser } from "../../../../api/users";
import { getTranslation } from "../../../../helpers/getLanguage";
import WebSocketInstance, { WebSocketEvents } from "../../../../helpers/socketInstance";
import useGetSocketData from "../../../../hooks/useGetSocketData";

const IssuesItem = ({
  compact,
  activePath,
  activeClass,
  handlePathChange,
  currentIssues,
  getCurrentIssuesRedux,
  updateCurrentIssueRedux,
  unshiftCurrentIssuesRedux,
  removeFromCurrentIssuesRedux,
  user,
  currentSocket,
}) => {
  const [currentIssuesArr, setCurrentIssuesArr] = useState(currentIssues?.filter((issue) => Boolean(issue?.ongoing)));
  const [unreadIssues, setUnreadIssues] = useState([]);
  const [readIssues, setReadIssues] = useState(undefined);
  const [modalVisible, setModalVisible] = useState(false);
  const location = useLocation();
  const issuesByUser = useGetSocketData({
    emitEvent: "issues",
    receiveEvent: "issues",
    socket: WebSocketInstance.currentSocket,
    dependencies: [currentIssuesArr, user],
  });

  useEffect(() => {
    setReadIssues(issuesByUser?.readIssues);

    if (!user?.id && currentIssuesArr?.length && currentIssuesArr?.filter((i) => Boolean(i.ongoing)).length) {
      checkShouldDisplayModal();
    }
  }, [issuesByUser]);

  useEffect(() => {
    if (!currentIssuesArr?.length) {
      getCurrentIssuesRedux();
    }

    if (currentSocket) {
      // Applying websocket to append data to front of currentIssues array to dynamically add issues.
      WebSocketInstance.applyCallBack(WebSocketEvents.issueAdded, unshiftCurrentIssuesRedux);
      WebSocketInstance.applyCallBack(WebSocketEvents.issueUpdated, updateCurrentIssueRedux);
      WebSocketInstance.applyCallBack(WebSocketEvents.issueRemoved, removeFromCurrentIssuesRedux);
    }

    return () => {
      WebSocketInstance.clearCallback(WebSocketEvents.issueAdded);
      WebSocketInstance.clearCallback(WebSocketEvents.issueUpdated);
      WebSocketInstance.clearCallback(WebSocketEvents.issueRemoved);
    };
  }, [currentSocket]);

  useEffect(() => {
    setCurrentIssuesArr(currentIssues?.filter((issue) => Boolean(issue?.ongoing)));
  }, [currentIssues]);

  useEffect(() => {
    if (currentIssuesArr?.length && currentIssuesArr?.filter((i) => Boolean(i.ongoing)).length) {
      checkShouldDisplayModal();
    }
  }, [currentIssuesArr, user]);

  function checkShouldDisplayModal() {
    let flag = false;

    currentIssuesArr.forEach((issue) => {
      if (readIssues !== undefined && !readIssues.includes(issue.id)) {
        !unreadIssues.includes(issue.id) && setUnreadIssues((prev) => [...prev, issue.id]);
        flag = true;
      }
    });

    setModalVisible(flag);
  }

  const doNotShowAgainModal = () => {
    const onSuccess = () => {};

    const onError = () => {};

    unreadIssues.length && readCurrentIssues(unreadIssues, onSuccess, onError);

    setModalVisible(false);
  };

  const pathChangeHandler = () => {
    if (location.pathname === "/issues") {
      return;
    }
    doNotShowAgainModal();
    handlePathChange({ value: "/issues", baseUrl: "undefined" }, true);
  };

  return (
    Boolean(currentIssuesArr?.length) && (
      <>
        <div
          className={
            "sidenav-item flex align-center position-relative profile pointer" +
            activeClass +
            (activePath === "default" ? " active" : "")
          }
          onClick={pathChangeHandler}
        >
          <span className="support-icon flex-center issue-ongoing-counter">!</span>

          {!compact && (
            <div className="flex align-center">
              <span className="sidenav-title" style={activePath === "default" ? { color: "#fff" } : {}}>
                {getTranslation("NAV_MENU_ISSUES")}
              </span>
            </div>
          )}
          {compact && (
            <div className="sidenav-item-tooltip flex-center">
              <span>{getTranslation("NAV_MENU_ISSUES")}</span>
            </div>
          )}
        </div>

        {modalVisible && (
          <>
            <div className="issues-modal-background"></div>
            <div className="issues-modal flex flex-column">
              <span className="issues-modal-title">{getTranslation("ISSUE_MODAL_TITLE")}</span>
              <span className="issues-modal-text">
                {getTranslation("ISSUE_MODAL_BODY_1")}

                <span className="issues-modal-link" onClick={pathChangeHandler}>
                  {getTranslation("ISSUE_MODAL_BODY_LINK")} {}
                </span>
                {getTranslation("ISSUE_MODAL_BODY_2")}
              </span>
              <div className="flex flex-row justify-content-between align-self-center issues-modal-button-container">
                <button onClick={doNotShowAgainModal} className="issues-modal-buttons">
                  {getTranslation("ISSUE_MODAL_OKAY")}
                </button>
              </div>
            </div>
          </>
        )}
      </>
    )
  );
};
const mapStateToProps = (state) => ({
  currentIssues: state.currentIssues.currentIssues,
  currentSocket: state.webSocket.current,
  user: state.auth.userInfo,
});

const mapDispatchToProps = {
  getCurrentIssuesRedux,
  updateCurrentIssueRedux,
  unshiftCurrentIssuesRedux,
  removeFromCurrentIssuesRedux,
};

export default connect(mapStateToProps, mapDispatchToProps)(IssuesItem);
