import { combineReducers } from "redux";

import adminReducers from "./adminReducers";
import authReducer from "./authReducers.js";
import currentIssuesReducers from "./currentIssueReducers";
import globalConfigurationsReducers from "./globalConfigurationsReducers";
import passwordRecoveryReducer from "./passwordRecoveryReducers";
import prefabReducer from "./prefabReducer";
import preferencesReducer from "./preferencesReducers";
import projectReducer from "./projectReducers";
import promoCodeReducers from "./promoCodesReducers";
import stripeReducer from "./stripeReducers";
import usersReducers from "./usersReducers";
import websocketReducers from "./websocketReducers";

export default combineReducers({
  auth: authReducer,
  recovery: passwordRecoveryReducer,
  project: projectReducer,
  preferences: preferencesReducer,
  subscription: stripeReducer,
  promoCodes: promoCodeReducers,
  users: usersReducers,
  adminInfo: adminReducers,
  globalConfigurations: globalConfigurationsReducers,
  currentIssues: currentIssuesReducers,
  webSocket: websocketReducers,
  prefabs: prefabReducer,
});
