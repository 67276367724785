import * as io from "socket.io-client";

import { SET_NUMBER_OPENAI_REQUESTS } from "../actions/types";
import { updateCurrentSocket } from "../actions/webSocketActions";
import { REACT_APP_PUBLIC_URL } from "../common/config";
import store from "../store";

/**
 * Event namespace for websocket.
 * All applied callbacks are stored in the class below.
 */
export const WebSocketEvents = {
  "issueAdded": "issueAdded", // emitted by server when an issue is added.
  "issueUpdated": "issueUpdated", // emitted by server when an issue is updated ()
  "issueRemoved": "issueRemoved",
  "open-ai": "open-ai",
};

/**
 * WebSocket class. Initiates a connection to backend socket.
 *
 * If any of your components rely on WebSocket instance. Please refer to using Redux as a state changer: state.webSocket.current
 */
export default class WebSocketInstance {
  static currentSocket = null;
  static appliedCallbacks = {};
  static disconnectTimer = null;
  static manualDisconnect = false;

  static connect(tokenOverride = null) {
    if (!WebSocketInstance.currentSocket) {
      const jwtToken = localStorage.getItem("jwtToken");

      if (!jwtToken) {
        return;
      }

      WebSocketInstance.currentSocket = io.connect(REACT_APP_PUBLIC_URL, {
        path: "/ws",
        reconnection: true,
        reconnectionDelay: 1500,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: 1,
        extraHeaders: {
          Authorization: tokenOverride ?? jwtToken,
        },
      });

      WebSocketInstance.currentSocket.on("connect_error", (err) => {
        console.log(`connect_error due to ${err.message}`);

        clearTimeout(WebSocketInstance.disconnectTimer);
        WebSocketInstance.disconnectTimer = setTimeout(() => {
          WebSocketInstance.currentSocket && WebSocketInstance.currentSocket.removeAllListeners();
          WebSocketInstance.currentSocket = null;
          WebSocketInstance.connect();
        }, 5000);
      });

      WebSocketInstance.currentSocket.on("disconnect", function () {
        if (WebSocketInstance.manualDisconnect) {
          WebSocketInstance.currentSocket && WebSocketInstance.currentSocket.removeAllListeners();
          WebSocketInstance.currentSocket = null;
          WebSocketInstance.manualDisconnect = false;
          return;
        }

        console.log("%cSocket disconnected. Retrying...", "color: red");

        clearTimeout(WebSocketInstance.disconnectTimer);
        WebSocketInstance.disconnectTimer = setTimeout(() => {
          WebSocketInstance.currentSocket && WebSocketInstance.currentSocket.removeAllListeners();
          WebSocketInstance.currentSocket = null;
          WebSocketInstance.connect();
        }, 2000);
      });

      return WebSocketInstance.currentSocket.on("connect", () => {
        WebSocketInstance.disconnectTimer && clearTimeout(WebSocketInstance.disconnectTimer);

        console.info("%cWebSocket connected. ", "color: #00FF00");

        Object.keys(WebSocketEvents).forEach((eventName) => {
          WebSocketInstance.currentSocket.on(eventName, (data) => {
            eventName === "open-ai" && store.dispatch({ type: SET_NUMBER_OPENAI_REQUESTS, payload: data });
            if (!WebSocketInstance.appliedCallbacks[eventName]) {
              return;
            }

            this.appliedCallbacks[eventName](data);
          });
        });

        updateCurrentSocket(WebSocketInstance.currentSocket);
      });
    }

    console.warn("Cannot create a new websocket connection whilst an active exits.");
  }

  /**
   * Applies a given callback to the event emitted from server.
   * @param {String} eventName - socket event that server emits.
   * @param {Function} callback - a callback function to run with the first argument as data.
   * @param {Boolean} forceReplace - force replaces callback if passed true.
   */
  static applyCallBack(eventName, callback, forceReplace = false) {
    if (!Object.keys(WebSocketEvents).includes(eventName)) {
      return console.error("`eventName` should be in an array of server events.");
    }

    if (WebSocketInstance.appliedCallbacks[eventName] && !forceReplace) {
      return console.error(`Callback for \`${eventName}\` has been already applied.`);
    }

    WebSocketInstance.appliedCallbacks[eventName] = callback;
  }

  static clearCallback(eventName) {
    delete WebSocketInstance.appliedCallbacks[eventName];
  }

  static disconnect() {
    Object.keys(WebSocketInstance.appliedCallbacks).forEach((key) => {
      delete WebSocketInstance.appliedCallbacks[key];
    });

    WebSocketInstance.manualDisconnect = true;
    WebSocketInstance.currentSocket.disconnect();
    console.info("WebSocket manually disconnected.");
  }
}
