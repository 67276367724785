import { GET_PROMO_CODES, GET_PROMO_CODES_ERROR, GET_PROMO_CODES_SUCCESS } from "../actions/types";

const initialState = {
  payload: [],
  errors: null,
  isLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PROMO_CODES:
      return { ...initialState, isLoading: true };
    case GET_PROMO_CODES_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        errors: null,
        isLoading: false,
      };
    case GET_PROMO_CODES_ERROR:
      return {
        ...state,
        payload: [],
        errors: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
}
