import React from "react";
import { useDrop } from "react-dnd";

export default function BackButton({ className, setCurrentFolder, moveProjectOutOfFolder, isDragging }) {
  const [{ isHovered }, dropRef] = useDrop({
    accept: "projectCard",
    drop: (item) => {
      moveProjectOutOfFolder(item.id);
    },
    collect: (monitor) => ({
      isHovered: monitor.isOver({ shallow: true }),
    }),
  });

  return (
    <div
      ref={dropRef}
      className={`flex project justify-content-center ${className}`}
      style={{ cursor: "pointer", backgroundColor: isHovered ? "#3e6fd9" : "#ecf1fc" }}
      onClick={() => setCurrentFolder(null)}
    >
      <span style={isHovered ? { color: "white" } : {}}>{isDragging ? "MOVE PROJECT UP" : "GO BACK"}</span>
    </div>
  );
}
