import * as Sentry from "@sentry/react";
import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";

import rootReducer from "./reducers";

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const initialState = {};

const middleware = [thunk];

const store = createStore(rootReducer, initialState, compose(applyMiddleware(...middleware), sentryReduxEnhancer));

export default store;
