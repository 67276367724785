import React from "react";

import CloseIcon from "../../../assets/images/close-icon.svg";
import { ReactComponent as ComponentIcon } from "../../../assets/images/component-icon-big.svg";
import { ReactComponent as PlusIconSmall } from "../../../assets/images/plus-icon-small.svg";
import { ReactComponent as StepIcon } from "../../../assets/images/steps.svg";
import { getTranslation } from "../../../helpers/getLanguage";
import CustomScrollbar from "../../common/CustomScrollbar";
import Tooltip from "../../common/Tooltip";
import Dialog from "../Dialog";
import "./styles.css";

const ComponentMultiplyModal = ({
  attributes,
  closeDialog,
  activeAttribute,
  currentComponent,
  handleChooseAttribute,
  setPendingComponentCreation,
  handleOpenIdeaModalAndCloseMultiplication,
  handleOpenAddAttributeAndCloseMultiplication,
  productType,
}) => {
  const activeComponentId = activeAttribute.id || "keepAttributes";
  const internalComponents = attributes.map((attribute) => (
    <span
      className={
        "flex align-center attributes-list-element mt-2" +
        (activeComponentId === attribute.id ? " active-attribute" : "")
      }
      key={attribute.id}
      onClick={() => handleChooseAttribute(attribute)}
    >
      <Tooltip
        containerClass="component-to-multiply-in-title_tooltip ellipsed-text"
        position="top"
        innerTextClass=""
        message={attribute.name}
        innerText={attribute.name}
        tooltipMessageClass="tooltip-message white"
      />
    </span>
  ));
  const keepAllAttributes = (
    <span
      className={
        "attributes-list-element mt-2 keepAttributes" +
        (activeComponentId === "keepAttributes" ? " active-attribute" : "")
      }
      onClick={() => handleChooseAttribute("keepAttributes")}
      key="keepAttributes"
    >
      {getTranslation("MULTIPLICATION_MODAL_KEEP_ALL_ATTRIBUTES")}
    </span>
  );
  const handleClickNext = () => {
    if (activeComponentId) {
      handleOpenIdeaModalAndCloseMultiplication();
    } else {
      return;
    }
  };

  const handleAddNewComponent = () => {
    setPendingComponentCreation(true);
    handleOpenAddAttributeAndCloseMultiplication();
  };

  return (
    <Dialog closeDialog={closeDialog} closeOnClickOutside={false}>
      <div className="component-multiplication-dialog-wrapper">
        <div className="component-multiplication-dialog-header flex">
          <div className="flex align-center">
            {productType === "process" ? (
              <StepIcon style={{ color: "#838fdb", width: "40px" }} />
            ) : (
              <ComponentIcon style={{ color: "#838fdb" }} />
            )}
            <span className="component-to-multiply-in-title">
              <span className="flex">{getTranslation("MULTIPLICATION_MODAL_ATTRIBUTE_SELECTION")}</span>
              <span className="flex">
                <u className="flex">
                  <Tooltip
                    containerClass="component-to-multiply-in-title_tooltip ellipsed-text"
                    position="top"
                    innerTextClass=""
                    message={currentComponent?.name}
                    innerText={currentComponent?.name}
                    tooltipMessageClass="tooltip-message white"
                  />
                </u>
                {productType === "process" && getTranslation("SUBTRACTION_MODAL_HEADER_STEP")}
              </span>
              <span className="flex" style={{ marginLeft: "-5px" }}>
                {getTranslation("MULTIPLICATION_MODAL_TITLE")}
              </span>
            </span>
          </div>
          <img src={CloseIcon} alt="" className="pointer" onClick={closeDialog} />
        </div>

        <div className="component-multiply-dialog-body">
          <div className="attributes-multiply-list">
            <CustomScrollbar
              className={"component-multiplication-dialog-scrollbar-container"}
              backgroundColor="transparent"
              dependencies={[internalComponents, keepAllAttributes]}
              right={5}
              width={5}
            >
              {[...internalComponents, keepAllAttributes]}
            </CustomScrollbar>

            <button
              className="add-component-button dark-blue-btn create-new-multiplication-attribute"
              onClick={handleAddNewComponent}
            >
              <span>{getTranslation("PAGE_COMPONENT_ADD_ATTRIBUTE_BUTTON")}</span>
              <PlusIconSmall style={{ height: "18px", width: "18px" }} />
            </button>
          </div>
        </div>

        <div className="component-replace-dialog-buttons">
          <button className="component-dialog-button secondary" onClick={closeDialog}>
            {getTranslation("REPLACEMENT_CANCEL")}
          </button>
          <div className={activeComponentId ? "" : "button-not-allowed"}>
            <button
              className={"component-dialog-button dark-blue-btn" + (activeComponentId ? "" : " button-disabled")}
              onClick={handleClickNext}
            >
              {getTranslation("REPLACEMENT_NEXT")}
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ComponentMultiplyModal;
