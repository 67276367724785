import React, { useEffect, useState } from "react";

import { createPromoCode, editPromoCode } from "../../../api/promoCodes";
import CloseIcon from "../../../assets/images/close-icon.svg";
import { copyTextToClipboard } from "../../../common/copyTextToClipboard";
import { validateField } from "../../../common/validation";
import { getTranslation } from "../../../helpers/getLanguage";
import Tooltip from "../../common/Tooltip";
import CustomCheckbox from "../../inputs/CustomCheckbox";
import CustomInput from "../../inputs/CustomInput";
import CustomNumberInput from "../../inputs/CustomNumberInput";
import RadioInput from "../../inputs/CustomRadioInput";
import CustomTextarea from "../../inputs/CustomTextarea";
import Dialog from "../Dialog";
import styles from "./PromoCodeModal.module.css";
import "./styles.css";

const durationOptionsPeriod = [
  { label: "DURATION_OPTIONS_DAYS", value: "day" },
  { label: "DURATION_OPTIONS_WEEKS", value: "week" },
  { label: "DURATION_OPTIONS_MONTHS", value: "month" },
  { label: "DURATION_OPTIONS_YEARS", value: "year" },
];

const initialFields = {
  id: "",
  totalLicenses: 1,
  durationType: "month",
  durationCount: 1,
  price: 19.99,
  interval: "month",
  discount: 0,
  comments: "",
  singlePayment: true,
  customer: "",
  canEdit: true,
  language: "en",
  openAIRequests: 0,
};

const initialErrors = { customer: "" };

const paymentTypeTooltip = "PAYMENT_TYPE_TOOLTIP";

const timeLimitTooltip = "TIME_LIMIT_TOOLTIP";

const durationTranslation = {
  "year": "DURATION_YEAR",
  "month": "DURATION_MONTH",
  "week": "DURATION_WEEK",
  "day": "DURATION_DAY",
  "": "",
};

const durationLimits = {
  year: 1,
  month: 12,
  week: 52,
  day: 365,
};

const getPaymentPeriodErrors = ({ durationCount, durationType, discount, price }) => {
  if (discount === 100 || price === 0) return false;

  const maxValue = durationLimits[durationType];
  if (durationCount > maxValue) return true;
  return false;
};

const getTotalPaymentText = ({ price, discount, totalLicenses, singlePayment }) => {
  const pricePerPerson = ((price * (100 - discount)) / 100).toFixed(2);
  const isSubscriptionFree = discount === 100 || price === 0;

  if (isSubscriptionFree) return getTranslation("TOTAL_PAYMENT_TEXT_PAYMENT_NONE");
  if (singlePayment)
    return `${getTranslation("TOTAL_PAYMENT_TEXT_PAYMENT_SINGLE")} $${(pricePerPerson * totalLicenses).toFixed(
      2,
    )} ${getTranslation("TOTAL_PAYMENT_TEXT_PAYMENT")}`;
  return `${getTranslation("TOTAL_PAYMENT_TEXT_PAYMENT_SEPARATE")} $${pricePerPerson} ${getTranslation(
    "TOTAL_PAYMENT_TEXT_PAYMENTS",
  )}`;
};

const getPaymentText = ({ durationType, durationCount, price, discount, totalLicenses, language }) => {
  const pricePerPerson = ((price * (100 - discount)) / 100).toFixed(2);
  const isSubscriptionFree = discount === 100 || price === 0;
  const priceTag = isSubscriptionFree
    ? getTranslation("PAYMENT_TEXT_FREE_SUB")
    : `${getTranslation("PAYMENT_TEXT_PAYMENT")}${pricePerPerson}`;
  const duration =
    getTranslation(durationTranslation[durationType]) + (durationCount > 1 && language === "en" ? "s" : "");
  const userText = getTranslation("PAYMENT_TEXT_USER") + (totalLicenses > 1 && language === "en" ? "s" : "");

  return `${getTranslation("PAYMENT_TEXT_PREVIEW")}${totalLicenses} ${userText} ${getTranslation(
    "PAYMENT_TEXT_WITH",
  )} ${priceTag}${durationType ? ` ${getTranslation("PAYMENT_TEXT_FOR")} ${durationCount} ${duration}` : ""}`;
};

const errorMessage = "PAYMENT_ERROR_MESSAGE";

const PromoCodeModal = ({ closeDialog, onCodeCreation, editMode = false, codeInfo = null, language }) => {
  const [isPending, setIsPending] = useState(false);
  const [fields, setFields] = useState(initialFields);
  const [errors, setErrors] = useState(initialErrors);

  useEffect(() => {
    if (editMode && codeInfo) {
      const data = {
        id: codeInfo.id,
        code: codeInfo.code,
        totalLicenses: codeInfo.totalLicenses || 1,
        durationType: codeInfo.durationType || "",
        durationCount: "durationCount" in codeInfo ? codeInfo.durationCount : 1,
        price: Number(codeInfo.price) || 0,
        interval: codeInfo.interval || "month",
        openAIRequests: codeInfo.openAIRequests,
        discount: codeInfo.discount || 0,
        comments: codeInfo.comments || "",
        singlePayment: Boolean(codeInfo.singlePayment),
        customer: codeInfo.customer || "",
        canEdit: codeInfo.canEdit,
        language: language,
      };

      setFields(data);
    }
  }, [editMode, codeInfo]);

  useEffect(() => {
    setFields((fields) => ({ ...fields, language: language }));
  }, [language]);

  const handleCloseDialog = () => {
    closeDialog && closeDialog();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFields((fields) => ({ ...fields, [name]: value }));
  };

  const handleSetErrors = (e, value) => {
    const { name } = e.target || e;
    setErrors((errors) => ({ ...errors, [name]: value }));
  };

  const handleSaveCode = (editMode) => {
    const params = { ...fields };

    if (params.durationCount === 0) params.durationType = "";

    params.customer = params.customer.trim();
    params.comments = params.comments.trim();

    if (!params.customer.length) {
      setErrors((errors) => ({ ...errors, customer: getTranslation("CUSTOM_TEXT_AREA_CUSTOMER_ERROR_MESSAGE") }));
      return;
    }

    if (params.durationType === "month") {
      params.openAIRequests = params.openAIRequests + 10;
    }

    if (params.durationType === "year") {
      params.openAIRequests = params.openAIRequests + 100;
    }

    const onSuccess = () => {
      setIsPending(false);
      onCodeCreation && onCodeCreation();
      closeDialog();
    };

    const onError = (error) => {
      setIsPending(false);
      console.error(error);
      closeDialog();
    };

    setIsPending(true);

    if (!editMode) createPromoCode(params, onSuccess, onError);
    if (editMode) editPromoCode(params, onSuccess, onError);
  };

  const buttonText = editMode
    ? getTranslation("GENERATE_PROMO_CODE_DIALOG_EDIT_PROMO_CODE_BUTTON")
    : getTranslation("GENERATE_PROMO_CODE_DIALOG_GENERATE_PROMO_CODE_BUTTON");
  const modalTitle = !fields.canEdit
    ? getTranslation("GENERATE_PROMO_CODE_DIALOG_TITLE_VIEW_PROMO_CODE")
    : editMode
      ? getTranslation("GENERATE_PROMO_CODE_DIALOG_TITLE_EDIT_PROMO_CODE")
      : getTranslation("GENERATE_PROMO_CODE_DIALOG_TITLE_GENERATE_PROMO_CODE");

  const hasPaymentPeriodErrors = getPaymentPeriodErrors(fields, errors);

  const handleSubmitEnter = (event) => {
    if (!isPending && event.key === "Enter") {
      handleSaveCode(editMode);
    }
  };

  return (
    <Dialog closeDialog={handleCloseDialog} closeOnClickOutside={false}>
      <div className="promocode-dialog-wrapper">
        <div className="promocode-dialog-header flex">
          <div className="flex align-center">
            <span className="promocode-dialog-title">{modalTitle}</span>
          </div>
          <img src={CloseIcon} alt="" className="pointer" onClick={() => handleCloseDialog()} />
        </div>

        <div className="promocode-dialog-body flex-column">
          {editMode && (
            <div className="flex align-center mb-10">
              <span className="project-dialog-label">
                {getTranslation("GENERATE_PROMO_CODE_DIALOG_PROMO_CODE")} {fields.code}
              </span>
              <button
                className="promocode-copy-button dark-blue-btn flex align-center justify-center"
                onClick={() => copyTextToClipboard(fields.code)}
              >
                {getTranslation("GENERATE_PROMO_CODE_DIALOG_BUTTON_COPY")}
              </button>
            </div>
          )}
          <div className="flex-column mb-20">
            <div className="flex">
              <span className="project-dialog-label align-self-start mr-20">
                {getTranslation("GENERATE_PROMO_CODE_DIALOG_CODE_TIME_LIMIT")}
              </span>
              <Tooltip message={getTranslation(timeLimitTooltip)} position="top" />
            </div>
            <div className="flex width-100 align-center">
              <div className="mr-20">
                <CustomNumberInput
                  value={fields.durationCount}
                  onChange={(e) => setFields({ ...fields, durationCount: e })}
                  minValue={0}
                />
              </div>
              <div className={styles["radio-input"]}>
                <RadioInput
                  options={durationOptionsPeriod}
                  value={fields.durationType}
                  styleRadioInput={{ paddingLeft: 0 }}
                  setValue={(e) =>
                    setFields((fields) => ({
                      ...fields,
                      durationType: e === fields.durationType ? "" : e,
                    }))
                  }
                  canUnset={false}
                />
              </div>
            </div>
          </div>

          <div className="flex mb-20">
            <div className="flex-column promocode-input-container mr-30">
              <span className="promocode-dialog-label">{getTranslation("CUSTOM_INPUT_USERS_LIMIT")}</span>
              <CustomNumberInput
                value={fields.totalLicenses}
                onChange={(e) => setFields({ ...fields, totalLicenses: e })}
                minValue={1}
              />
            </div>

            <div className="flex-column promocode-input-container mr-30">
              <span className="promocode-dialog-label">{getTranslation("CUSTOM_INPUT_DISCOUNT")}</span>
              <CustomNumberInput
                value={fields.discount}
                onChange={(e) => setFields({ ...fields, discount: e })}
                minValue={0}
                maxValue={100}
                couldBeEmpty={true}
              />
            </div>

            <div className="flex-column promocode-input-container mr-30">
              <span className="promocode-dialog-label">{getTranslation("CUSTOM_INPUT_PRICE")}</span>

              <CustomNumberInput
                value={fields.price}
                onChange={(e) => setFields({ ...fields, price: e })}
                minValue={0}
                precision={2}
              />
            </div>
            <div className="flex-column promocode-input-container mr-30">
              <div className="flex-center">
                <span className="promocode-dialog-label mr-15">{getTranslation("CUSTOM_INPUT_TOKENS")}</span>
                <Tooltip message={getTranslation("GENERATE_PROMO_CODE_MODAL_TOKENS_INPUT")} position="top" />
              </div>
              <CustomNumberInput
                value={fields.openAIRequests}
                onChange={(e) => setFields({ ...fields, openAIRequests: e })}
                minValue={0}
              />
            </div>
          </div>

          <div className="flex align-center mb-20">
            <CustomCheckbox
              checked={fields.singlePayment}
              handleChange={(e) => setFields((fields) => ({ ...fields, singlePayment: e }))}
              customClass="mr-20"
            />
            <span className="promocode-dialog-label mr-20">
              {getTranslation("PROMO_CODE_DIALOG_LABEL_SINGLE_PAYMENT")}
            </span>
            <Tooltip message={getTranslation(paymentTypeTooltip)} position="top" />
          </div>

          <CustomInput
            autoFocus={true}
            value={fields.customer}
            onChange={handleChange}
            onKeyDown={handleSubmitEnter}
            name="customer"
            containerClass="flex-column mb-30"
            placeholder={getTranslation("CUSTOM_TEXT_AREA_CUSTOMER")}
            onBlur={(e) => validateField(e, handleSetErrors)}
            errorMessage={errors.customer}
          />

          <CustomTextarea
            value={fields.comments}
            onChange={handleChange}
            name="comments"
            containerClass="flex-column mb-20"
            placeholder={getTranslation("CUSTOM_TEXT_AREA_COMMENTS")}
          />

          {!hasPaymentPeriodErrors && (
            <div className="flex-column align-flex-start mb-20">
              <span className="promocode-dialog-label">{getPaymentText(fields)}</span>
              <span className="promocode-dialog-label">{getTotalPaymentText(fields)}</span>
            </div>
          )}

          {fields.canEdit && (
            <>
              {hasPaymentPeriodErrors && (
                <div className="flex">
                  <span className="promocode-dialog-label error-message flex mr-20">
                    {getTranslation(errorMessage)}
                  </span>
                  <Tooltip message={getTranslation(timeLimitTooltip)} position="top" />
                </div>
              )}

              {!errors.customer.length && !hasPaymentPeriodErrors && (
                <div className="promocode-dialog-buttons">
                  <button
                    disabled={isPending}
                    className="promocode-dialog-button dark-blue-btn"
                    onClick={() => handleSaveCode(editMode)}
                  >
                    {buttonText}
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default PromoCodeModal;
