import React from "react";

import CloseIcon from "../../../../assets/images/close-icon.svg";
import EurekaIcon from "../../../../assets/images/solid/icon-eureka-blue.svg";
import { getTranslation } from "../../../../helpers/getLanguage";
import EurekaTabs from "./EurekaTabs";

const EurekaModalHeader = ({
  closeDialog,
  subtraction,
  currentTab,
  isCompletedIdea,
  projectName,
  changeCurrentTab,
  productName,
}) => {
  const tabsLabels = [
    <span style={{ color: "inherit" }}>{getTranslation("EUREKA_TAB_DESCRIBE_NEW_PRODUCT")}</span>,
    <span style={{ color: "inherit" }}>{getTranslation("SUBTRACTION_MODAL_TABS_LIST_BENEFITS")}</span>,
    <span style={{ color: "inherit" }}>{getTranslation("IDEA_MODAL_TABS_LIST_IMPLEMENTATION_CHALLENGES")}</span>,
  ];

  return (
    <div className="subtraction-dialog-header flex-column">
      <div className="subtraction-dialog-title-container flex mb-20">
        <div className="flex align-center">
          <img className="subtraction-dialog-header-icon" src={EurekaIcon} alt="" />
          <div>
            <span className="subtraction-dialog-title">{getTranslation("MODAL_TITLE_EUREKA")}</span>
          </div>
        </div>

        <div className="flex align-center subtraction-dialog-controls">
          <img src={CloseIcon} alt="" className="pointer" onClick={closeDialog} />
        </div>
      </div>

      <span style={{ width: "100%" }} className="eureka-modal-title flex justify-content-center">
        <span>{getTranslation("EUREKA_MODAL_TITLE")}</span>
        <u>{productName ? productName : projectName}</u>
      </span>

      <EurekaTabs
        currentTab={currentTab}
        subtraction={subtraction}
        labels={tabsLabels}
        isCompletedIdea={isCompletedIdea}
        changeCurrentTab={changeCurrentTab}
      />
    </div>
  );
};

export default EurekaModalHeader;
