import React from "react";

import CloseIcon from "../../../assets/images/close-icon.svg";
import { getTranslation } from "../../../helpers/getLanguage";
import Dialog from "../Dialog";
import "./styles.css";

const ErrorMessageModal = ({ message = "", closeModalWindow, textButton, handleButton, header = "" }) => {
  return (
    <Dialog>
      <div className="flex-column error-message-modal">
        <div className="flex error-message-modal-header">
          <h2>{header ? header : getTranslation("ERROR_MESSAGE_MODAL_HEADER")}</h2>
          <img src={CloseIcon} alt="close modal" className="pointer" onClick={closeModalWindow} />
        </div>
        <div className="flex-column justify-center height-100 error-message-modal-body">
          {Array.isArray(message) ? (
            message.map((oneMessage) => (
              <>
                <div className="error-message-modal-text">{oneMessage}</div>
              </>
            ))
          ) : (
            <div className="error-message-modal-text">{message}</div>
          )}
        </div>

        <div className="error-message-modal-footer flex justify-center">
          <button className="error-message-modal-footer__button" onClick={handleButton}>
            {textButton}
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default ErrorMessageModal;
