import classnames from "classnames";
import React, { useRef, useState } from "react";

import Portal from "../../common/Portal";
import "./styles.css";

const positionClass = {
  top: "top",
  right: "right",
  bottom: "bottom",
  left: "left",
};

const initialCoordinates = { x: 0, y: 0 };

const TooltipMessage = ({ message, containerRef, className, positionStyles }) => {
  if (!message.length) return null;

  return (
    <Portal>
      <div ref={containerRef} className={className} style={positionStyles}>
        <span className="tooltip-text">{message}</span>
      </div>
    </Portal>
  );
};

const TextTooltip = ({ className, onMouseEnter, onMouseLeave, innerText, children }) => {
  return (
    <span className={className} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {innerText}
      {children}
    </span>
  );
};

const BlockTooltip = ({ className, backgroundColor, color, onMouseEnter, onMouseLeave, children }) => {
  return (
    <div className={className} style={{ backgroundColor }} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {children}
      <div className="tooltip-indicator disable-pointer-events" style={{ color }}>
        i
      </div>
    </div>
  );
};

const WrapperTooltip = ({ className, onMouseEnter, onMouseLeave, children }) => {
  return (
    <div className={className} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {children}
    </div>
  );
};

const transformPositions = {
  top: "translate(0, calc(-50% - #{val}))",
  right: "translate(calc(50% + #{val}), 0)",
  bottom: "translate(0, calc(50% + #{val}))",
  left: "translate(calc(-50% - #{val}), 0)",
};

const getTransformPosition = (width, height, position = "top") => {
  const currentValue = ["top", "bottom"].includes(position) ? height : width;
  const offset = 7;
  const positionValue = `${currentValue / 2 + offset}px`;
  const transformPosition = (transformPositions[position] || "").replace("#{val}", positionValue);
  return transformPosition;
};

const Tooltip = ({
  message = "",
  innerText = "",
  position: startPosition = "top",
  containerClass = "",
  backgroundColor = "#cdd1e5",
  color = "white",
  innerTextClass = "tooltip-inner-text",
  children = null,
  tooltipMessageClass = "",
}) => {
  const [coordinates, setCoordinates] = useState(initialCoordinates);
  const [transform, setTransform] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState(startPosition);

  const ref = useRef(null);

  const currentPosition = positionClass[position];

  const positionStyles = {
    left: coordinates.x,
    top: coordinates.y,
    transform: transform,
  };

  const handleMouseEnter = (e) => {
    const rect = e.target.getBoundingClientRect() || {};
    const { right, bottom, width, height } = rect;

    const refRect = (ref.current && ref.current.getBoundingClientRect()) || {};
    const { width: refWidth, height: refHeight } = refRect;

    const x = right - width / 2 - refWidth / 2;
    const y = bottom - height / 2 - refHeight / 2;

    const bodyPosition = document.body.getBoundingClientRect();

    if (coordinates.x !== x || coordinates.y !== y) {
      setCoordinates({ x, y });
      if (x + refWidth > bodyPosition.right) {
        setPosition("left");

        setTransform(getTransformPosition(width, height, positionClass.left));
      } else {
        setTransform(getTransformPosition(width, height, position));
      }
    }
    if (!isOpen) setIsOpen(true);
  };

  const toolTipClassList = classnames(
    "tooltip-message-container disable-pointer-events",
    tooltipMessageClass,
    { [positionClass[position]]: currentPosition },
    { hidden: !isOpen },
  );

  const TooltipMessageComponent = () => (
    <TooltipMessage message={message} containerRef={ref} className={toolTipClassList} positionStyles={positionStyles} />
  );

  if (children) {
    return (
      <WrapperTooltip
        innerText={innerText}
        className={`position-relative pointer ${containerClass}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={() => setIsOpen(false)}
      >
        {children}
        <TooltipMessageComponent />
      </WrapperTooltip>
    );
  }

  if (!innerText) {
    return (
      <BlockTooltip
        className={"tooltip-container flex-center pointer " + containerClass}
        backgroundColor={backgroundColor}
        color={color}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={() => setIsOpen(false)}
      >
        <TooltipMessageComponent />
      </BlockTooltip>
    );
  } else {
    return (
      <TextTooltip
        innerText={innerText}
        className={`position-relative pointer ${innerTextClass} ${containerClass}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={() => setIsOpen(false)}
      >
        <TooltipMessageComponent />
      </TextTooltip>
    );
  }
};

export default Tooltip;
