import React from "react";

import { getTranslation } from "../../helpers/getLanguage";
import ViewTutorialButtonWithModal from "../common/ViewTutorialButtonWithModal";
import CustomSearchInput from "../inputs/CustomSearchInput";
import CustomToggle from "../inputs/CustomToggle";

const ReplacementHeader = ({ inputValues, handleChange, isSorted, setSorted, sortLabel, projectType }) => {
  return (
    <div className="replacement-header-container width-100">
      <div className="flex-column replacement-header-container-tutorial">
        <div style={{ flexGrow: 1, marginBottom: "10px" }}>
          <ViewTutorialButtonWithModal name="Replacement" />
        </div>
        <span className="components-dashboard-title">
          {getTranslation(
            projectType === "process" ? "PAGE_REPLACEMENT_TITLE_STEPS" : "PAGE_REPLACEMENT_TITLE_COMPONENTS",
          )}
        </span>
      </div>

      <div className="flex replacement-header-container-settings">
        <CustomSearchInput
          name="search"
          placeholder={getTranslation(
            projectType === "process" ? "PAGE_STEPS_SEARCH_COMPONENTS" : "PAGE_COMPONENT_SEARCH_COMPONENTS",
          )}
          value={inputValues.search}
          onChange={handleChange}
          containerClass="search-input-container-subtraction-components mb-10"
        />

        <div className="toggle-container flex align-center">
          <CustomToggle
            value={isSorted}
            setValue={(e) => setSorted(e)}
            label={sortLabel}
            labelClass="custom-toggle-label-default sort-toggle"
            left
          />
        </div>
      </div>
    </div>
  );
};

export default ReplacementHeader;
