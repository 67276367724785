import axios from "axios";

import { UNAUTHORIZED_USER_ERROR } from "../actions/types";
import store from "../store";

export default () =>
  axios.interceptors.response.use(
    (confirm) => confirm,
    (error) => {
      if (error.message.includes(401)) {
        store.dispatch({ type: UNAUTHORIZED_USER_ERROR });
      }

      return Promise.reject(error);
    },
  );
