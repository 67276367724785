import React, { useEffect, useState } from "react";

import { ReactComponent as CheckmarkIcon } from "../../../../assets/images/checkmark-circle.svg";
import { getTranslation } from "../../../../helpers/getLanguage";
import "./styles.css";

const SubtractionTabs = ({ currentTab, labels, subtraction, isCompletedIdea, changeCurrentTab }) => {
  const [lastCompletedTab, setLastCompletedTab] = useState(0);

  useEffect(() => {
    const updateLastCompletedTab = () => {
      const {
        existingMultiplication,
        isIdeaNew,
        isIdeaFeasible,
        score,
        benefits,
        issues,
        comment,
        removeBenefit,
        image,
      } = subtraction;

      if (existingMultiplication === null || !isIdeaNew) {
        setLastCompletedTab(0);
        return;
      }

      if (issues.filter((issue) => issue).length > 0 && isIdeaFeasible === false) {
        setLastCompletedTab(3);
        return;
      }

      const benefitsLength = benefits.filter((benefit) => benefit.name && benefit.target).length;
      if (benefitsLength > 0 && score > 0) {
        setLastCompletedTab(2);
        return;
      }

      if (removeBenefit.length && (comment.length > 0 || image.name)) {
        setLastCompletedTab(1);
        return;
      }

      setLastCompletedTab(0);
    };

    updateLastCompletedTab();
  }, [currentTab, subtraction]);

  const tabs = labels.map((label, index) => {
    const activeClass = index <= currentTab || index <= lastCompletedTab || isCompletedIdea ? " active" : "";
    const pointerClass = " pointer";
    const labelClass = index <= currentTab ? " active" : "";
    const completedIdea = isCompletedIdea ? " completed-idea-button" : "";

    return (
      <div key={index} className="flex align-center subtraction-tab">
        <div className="multiplication-tabs-line-container">
          <div className={"multiplication-tabs-line" + activeClass}></div>
        </div>
        <div className={"subtraction-tabs-circle" + activeClass + pointerClass} onClick={() => changeCurrentTab(index)}>
          <CheckmarkIcon className={isCompletedIdea ? " completed" : ""} color={"#3e6fd9"} fill="white" />

          <button className={"multiplication-tab-button" + completedIdea}>
            <div>
              <span className={"subtraction-tab-label" + labelClass}>{getTranslation(label)}</span>
            </div>
          </button>
        </div>
      </div>
    );
  });

  return <div className="flex multiplication-tabs-container">{tabs}</div>;
};

export default SubtractionTabs;
